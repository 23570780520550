import IGiftEntity from '@/core/entities/gift.entity';
import { TGiftType, TRelativeDate } from '@/types';

/**
 * @param payload таймстамп
 */
function getRelativeDate(payload: number): TRelativeDate {
  try {
    const today = new Date().setHours(0, 0, 0, 0);

    const isToday = payload >= today;

    const yesterday = new Date(Date.now() - 24 * 60 * 60 * 1000).setHours(0, 0, 0, 0);

    const isYesterday = !isToday && payload >= yesterday;

    return {
      isToday,
      isYesterday,
      timestamp: payload,
    };
  } catch (error) {
    throw error;
  }
}

function getGiftTypeById(payload: IGiftEntity['id']): TGiftType {
  try {
    switch (payload) {
      case 15323387168356:
        return 'donut';
      case 14792970994592:
        return 'bouquet';
      case 14794468052104:
        return 'cat';
      case 14794468835295:
        return 'brilliant';
      case 14804169525798:
        return 'diadem';
      case 14805911882111:
        return 'strawberry';
      default:
        throw new Error(`unknown gift id: ${payload}`);
    }
  } catch (error) {
    throw error;
  }
}

function getGiftIdFromGiftType(payload: TGiftType): IGiftEntity['id'] {
  try {
    switch (payload) {
      case 'donut':
        return 15323387168356;
      case 'bouquet':
        return 14792970994592;
      case 'cat':
        return 14794468052104;
      case 'brilliant':
        return 14794468835295;
      case 'diadem':
        return 14804169525798;
      case 'strawberry':
        return 14805911882111;
      default:
        throw new Error(`unknown gift type: ${payload}`);
    }
  } catch (error) {
    throw error;
  }
}

// eslint-disable-next-line import/prefer-default-export
export {
  getRelativeDate,
  getGiftTypeById,
  getGiftIdFromGiftType,
};
