import {
  createRouter,
  createWebHistory,
  NavigationGuardNext,
  RouteLocationNormalized,
  RouteRecordRaw,
} from 'vue-router';

// eslint-disable-next-line import/no-cycle
import ShowPageLoadErrorAttentionActionController from '@/core/controllers/show-page-load-error-attention-action.controller';

function disableDirectOpening(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
): void {
  try {
    // Если перешли по прямой ссылке - возвращаем на главную
    if (from.name === null || from.name === undefined) {
      next({ name: 'videochat' });
    }

    next();
  } catch (error) {
    throw error;
  }
}

const routes: Array<RouteRecordRaw> = [
  {
    path: '/gender',
    name: 'gender',
    component: () => import(/* webpackChunkName: "gender" */ '@/views/gender.vue'),
  },

  {
    path: '/subscription',
    name: 'subscription',
    component: () => import(/* webpackChunkName: "subscription" */ '@/views/subscription.vue'),
  },

  {
    path: '/forbidden',
    name: 'forbidden',
    component: () => import(/* webpackChunkName: "forbidden" */ '@/views/forbidden.vue'),
  },

  {
    path: '/',
    component: () => import(/* webpackChunkName: "main" */ '@/views/main.vue'),
    children: [
      {
        path: '',
        name: 'videochat',
        component: () => import(/* webpackChunkName: "videochat" */ '@/views/videochat.vue'),
      },

      {
        path: 'messages/:id(\\d+)?',
        name: 'messages',
        component: () => import(/* webpackChunkName: "messages" */ '@/views/messages.vue'),
      },

      {
        path: 'top',
        name: 'top',
        component: () => import(/* webpackChunkName: "top" */ '@/views/top.vue'),
        beforeEnter: disableDirectOpening,
      },

      {
        path: 'profile',
        name: 'profile',
        component: () => import(/* webpackChunkName: "profile" */ '@/views/profile.vue'),
      },

      {
        path: 'stories',
        name: 'stories',
        component: () => import(/* webpackChunkName: "profile" */ '@/views/stories.vue'),
        beforeEnter: disableDirectOpening,
      },

      {
        path: 'story-record',
        name: 'story-record',
        component: () => import(/* webpackChunkName: "profile" */ '@/views/story-record.vue'),
        beforeEnter: disableDirectOpening,
      },

      {
        path: ':pathMatch(.*)*',
        name: 'not-found',
        component: () => import(/* webpackChunkName: "not-found" */ '@/views/not-found.vue'),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.onError((cbError) => {
  try {
    if (cbError.name === 'ChunkLoadError') {
      ShowPageLoadErrorAttentionActionController.execute();
    }
  } catch (error) {
    throw error;
  }
});

export default router;
