import config from '@/config';
import StorageRepository from '@/repositories/storage-repository';
import { ELocale } from '@/enums';
import WindowConfigRepository from '@/repositories/window-config-repository';
import LocationRepository from '@/repositories/location-repository';
import { ILocaleRepository } from '@/core/interactors/types/repositories';

class LocaleRepository implements ILocaleRepository {
  private static instance: LocaleRepository;

  private static async asyncGetLocaleFromWindowConfig(): Promise<ELocale | undefined> {
    try {
      const locale = await WindowConfigRepository.getInstance().asyncGetLocale();

      if (locale !== undefined && Object.values(ELocale).includes(locale)) {
        return locale;
      }

      return undefined;
    } catch (error) {
      throw error;
    }
  }

  private static getLocaleFromBrowser(): ELocale | undefined {
    try {
      const browserLocale: string = window.navigator.language.replace(/-.*/, '');

      let locale: ELocale | undefined;

      switch (browserLocale) {
        case 'de':
          locale = ELocale.DE;

          break;
        case 'en':
          locale = ELocale.EN;

          break;
        case 'es':
          locale = ELocale.ES;

          break;
        case 'fr':
          locale = ELocale.FR;

          break;
        case 'it':
          locale = ELocale.IT;

          break;
        case 'pl':
          locale = ELocale.PL;

          break;
        case 'ro':
          locale = ELocale.RO;

          break;
        case 'ru':
          locale = ELocale.RU;

          break;
        case 'tr':
          locale = ELocale.TR;

          break;
        case 'nl':
          locale = ELocale.NL;

          break;
        case 'fi':
          locale = ELocale.FI;

          break;
        case 'uk':
          locale = ELocale.UK;

          break;
        case 'ja':
          locale = ELocale.JA;

          break;
        case 'pt':
          locale = ELocale.PT;

          break;
        case 'zh':
          locale = ELocale.ZH;

          break;
        default:
          locale = undefined;
      }

      return locale;
    } catch (error) {
      throw error;
    }
  }

  private static async asyncGetLocaleFromStorage(): Promise<ELocale | undefined> {
    try {
      const locale = await StorageRepository.getInstance().asyncGetLocale();

      if (locale !== undefined && Object.values(ELocale).includes(locale)) {
        return locale;
      }

      return undefined;
    } catch (error) {
      throw error;
    }
  }

  // eslint-disable-next-line no-useless-constructor, no-empty-function, @typescript-eslint/no-empty-function
  private constructor() {}

  static getInstance(): LocaleRepository {
    try {
      if (LocaleRepository.instance === undefined) {
        LocaleRepository.instance = new LocaleRepository();
      }

      return LocaleRepository.instance;
    } catch (error) {
      throw error;
    }
  }

  asyncGetLocale: ILocaleRepository['asyncGetLocale'] = async (): Promise<ELocale | undefined> => {
    try {
      const originResponse = await LocationRepository.getInstance().asyncGetOrigin();

      if (originResponse.environment.origin === config.rusVideoDatingMainAppLink) {
        return ELocale.RU;
      }

      let locale = await LocaleRepository.asyncGetLocaleFromStorage();

      if (locale === undefined) {
        locale = await LocaleRepository.asyncGetLocaleFromWindowConfig();
      }

      if (locale === undefined) {
        locale = LocaleRepository.getLocaleFromBrowser();
      }

      return locale;
    } catch (error) {
      throw error;
    }
  }

  /**
   * @param payload язык приложения
   */
  asyncSaveLocale: ILocaleRepository['asyncSaveLocale'] = async (payload: ELocale): Promise<void> => {
    try {
      await StorageRepository.getInstance().asyncSetLocale(payload);
    } catch (error) {
      throw error;
    }
  }
}

export default LocaleRepository;
