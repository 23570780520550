import ProcedureEmitter from '@/packages/procedure-emitter';
import config from '@/config';
import { ELocale } from '@/enums';
import { ILocationRepository } from '@/core/interactors/types/repositories';
import IEnvironmentEntity from '@/core/entities/environment.entity';
import { NonNullablePick } from '@/core/types';

class LocationRepository extends ProcedureEmitter {
  private static instance: LocationRepository;

  // eslint-disable-next-line no-useless-constructor
  private constructor() {
    super('LocationRepository');
  }

  /**
   * @param payload адресная строка браузера
   */
  private static getParamFromQuery(payload: string): string | undefined {
    try {
      const url = new URL(window.location.href);
      const searchParams = new URLSearchParams(url.search);

      return searchParams.get(payload) || undefined;
    } catch (error) {
      throw error;
    }
  }

  static getInstance(): LocationRepository {
    try {
      if (LocationRepository.instance === undefined) {
        LocationRepository.instance = new LocationRepository();
      }

      return LocationRepository.instance;
    } catch (error) {
      throw error;
    }
  }

  asyncGetOrigin: ILocationRepository['asyncGetOrigin'] = async (): Promise<{
    environment: NonNullablePick<IEnvironmentEntity, 'origin'>,
  }> => {
    try {
      if (!this.isAgent) {
        return await this.sendToAgent({ procedure: 'asyncGetOrigin' });
      }

      return { environment: { origin: window.location.origin } };
    } catch (error) {
      throw error;
    }
  }

  asyncGetReferral: ILocationRepository['asyncGetReferral'] = async (): Promise<{
    environment: Pick<IEnvironmentEntity, 'referral'>,
  }> => {
    try {
      if (!this.isAgent) {
        return await this.sendToAgent({ procedure: 'asyncGetReferral' });
      }

      const isReferralAllowedUrlDetected = config.referralAllowedUrls.includes(
        window.location.origin,
      );

      if (config.isReferralForceEnabled || isReferralAllowedUrlDetected) {
        const regexpMatch = window.location.href.match(/(\/id)(\d+)/);

        return { environment: { referral: regexpMatch ? regexpMatch[2] : undefined } };
      }

      return { environment: { referral: undefined } };
    } catch (error) {
      throw error;
    }
  }

  asyncGetUtmSource: ILocationRepository['asyncGetUtmSource'] = async (): Promise<{
    environment: Pick<IEnvironmentEntity, 'utmSource'>,
  }> => {
    try {
      if (!this.isAgent) {
        return await this.sendToAgent({ procedure: 'asyncGetUtmSource' });
      }

      return { environment: { utmSource: LocationRepository.getParamFromQuery('utm_source') } };
    } catch (error) {
      throw error;
    }
  }

  /**
   * @param payload язык приложения
   */
  asyncSetLocale: ILocationRepository['asyncSetLocale'] = async (payload: ELocale): Promise<void> => {
    try {
      if (!this.isAgent) {
        return await this.sendToAgent({
          payload,
          procedure: 'asyncSetLocale',
        });
      }

      if (window.history === undefined) {
        throw new Error('window history is undefined');
      }

      let url = (new URL('/camchat', window.location.origin)).toString();

      if (payload !== config.defaultLocale) {
        url = (new URL(`/${payload}/camchat`, window.location.origin)).toString();
      }

      return window.history.pushState(null, '', url);
    } catch (error) {
      throw error;
    }
  }

  asyncGetEnvironment: ILocationRepository['asyncGetEnvironment'] = async (): Promise<{
    environment: Pick<
      IEnvironmentEntity,
      'partnerId'
      | 'partnerChannel'
      | 'origin'
      | 'referral'
      | 'utmSource'
      | 'utmMedium'
      | 'utmContent'
      | 'utmCampaign'
      | 'affiliateId'
      | 'features'
      | 'testCountry'
      | 'storyAuthorId'
    >
  }> => {
    try {
      if (!this.isAgent) {
        return await this.sendToAgent({ procedure: 'asyncGetEnvironment' });
      }

      let partnerId: string | undefined;

      const isPartnerIdAllowedUrlDetected = config.partnerIdAllowedUrls.includes(
        window.location.origin,
      );

      if (config.isPartnerIdForceEnabled || isPartnerIdAllowedUrlDetected) {
        partnerId = LocationRepository.getParamFromQuery('id');
      }

      let partnerChannel: string | undefined;

      const isPartnerChannelAllowedUrlDetected = config.partnerChannelAllowedUrls.includes(
        window.location.origin,
      );

      if (config.isPartnerChannelForceEnabled || isPartnerChannelAllowedUrlDetected) {
        partnerChannel = LocationRepository.getParamFromQuery('p');
      }

      let referral: string | undefined;

      const isReferralAllowedUrlDetected = config.referralAllowedUrls.includes(
        window.location.origin,
      );

      if (config.isReferralForceEnabled || isReferralAllowedUrlDetected) {
        const regexpMatch = window.location.href.match(/(\/id)(\d+)/);

        referral = regexpMatch ? regexpMatch[2] : undefined;
      }

      const featuresQueryParam = LocationRepository.getParamFromQuery('features');

      let features: string[] = [];

      if (featuresQueryParam !== undefined) {
        features = featuresQueryParam.split(',');
      }

      const testCountry: string | undefined = LocationRepository.getParamFromQuery('testCountry');

      const storyAuthorId: string | undefined = LocationRepository.getParamFromQuery('story');

      return {
        environment: {
          partnerId,
          partnerChannel,
          referral,
          features,
          testCountry,
          storyAuthorId: storyAuthorId !== undefined ? +storyAuthorId : undefined,
          origin: window.location.origin,
          utmSource: LocationRepository.getParamFromQuery('utm_source'),
          utmMedium: LocationRepository.getParamFromQuery('utm_medium'),
          utmContent: LocationRepository.getParamFromQuery('utm_content'),
          utmCampaign: LocationRepository.getParamFromQuery('utm_campaign'),
          affiliateId: LocationRepository.getParamFromQuery('aff_clickid'),
        },
      };
    } catch (error) {
      throw error;
    }
  }

  getClientDomain: ILocationRepository['getClientDomain'] = (): string => {
    try {
      return window.location.hostname;
    } catch (error) {
      throw error;
    }
  }

  reload: ILocationRepository['reload'] = (): void => {
    try {
      window.location.reload();
    } catch (error) {
      throw error;
    }
  }

  asyncForceReload: ILocationRepository['asyncForceReload'] = async (): Promise<void> => {
    try {
      if (!this.isAgent) {
        return await this.sendToAgent({ procedure: 'asyncForceReload' });
      }

      return window.location.reload();
    } catch (error) {
      throw error;
    }
  }

  asyncChangeLocation: ILocationRepository['asyncChangeLocation'] = async (payload): Promise<void> => {
    try {
      if (!this.isAgent) {
        return await this.sendToAgent({
          payload,
          procedure: 'asyncChangeLocation',
        });
      }

      return window.location.replace(payload);
    } catch (error) {
      throw error;
    }
  }
}

export default LocationRepository;
