import ILocaleMessagesEntity from '@/core/entities/locale-messages.entity';
import { ELocale } from '@/enums';
import axios, { AxiosError, AxiosResponse } from 'axios';
import config from '@/config';
import {
  IAjaxRepositoryConfigServerResponse,
  IAjaxRepositoryTranslationServerResponse,
} from '@/repositories/ajax-repository/types';
import { IAjaxRepository } from '@/core/interactors/types/repositories';
import IErrorEntity from '@/core/entities/error.entity';

class AjaxRepository {
  private static readonly requestTimeout: number;

  private static instance: AjaxRepository;

  // eslint-disable-next-line no-useless-constructor, no-empty-function, @typescript-eslint/no-empty-function
  private constructor() {}

  /**
   * @param payload ссылка для установки соединения
   */
  private static async asyncDoGetRequest(payload: string): Promise<any> {
    try {
      return await axios.get(payload, { timeout: this.requestTimeout })
        .then((response: AxiosResponse) => response.data);
    } catch (error) {
      throw error;
    }
  }

  /**
   * @param payload.url ссылка для установки соединения
   * @param payload.data данные для отправки
   */
  private static async asyncDoPostRequest(payload: {
    url: string,
    data: string,
  }): Promise<null | { error: IErrorEntity }> {
    try {
      return await axios
        .post(
          payload.url,
          payload.data,
          {
            timeout: this.requestTimeout,
          },
        )
        .then(() => null)
        .catch((error: AxiosError) => {
          if (error.code === undefined) {
            throw new Error('error code is undefined');
          }

          return { error: { code: +error.code } };
        });
    } catch (error) {
      throw error;
    }
  }

  static getInstance(): AjaxRepository {
    try {
      if (AjaxRepository.instance === undefined) {
        AjaxRepository.instance = new AjaxRepository();
      }

      return AjaxRepository.instance;
    } catch (error) {
      throw error;
    }
  }

  static async asyncGetSocketConfig(): Promise<{
    origin: string,
    origin2: string,
    token: string,
    token2: string,
  }> {
    try {
      return await AjaxRepository.asyncDoGetRequest(config.ajax.configuration)
        .then((response: IAjaxRepositoryConfigServerResponse) => {
          if (response.status !== 'OK') {
            throw new Error('failed load data from config server');
          }

          return {
            origin: response.url,
            origin2: response.url2,
            token: response.token,
            token2: response.token2,
          };
        });
    } catch (error) {
      throw error;
    }
  }

  /**
   * @param payload код перевода
   */
  asyncGetLocaleMessages: IAjaxRepository['asyncGetLocaleMessages'] = async (
    payload: ELocale,
  ): Promise<{
    localeMessages: ILocaleMessagesEntity,
  }> => {
    try {
      const url = config.ajax.translation.replace('%CODE%', payload);

      return await AjaxRepository.asyncDoGetRequest(url)
        .then((response: IAjaxRepositoryTranslationServerResponse) => {
          if (response.status !== 'OK') {
            throw new Error(`failed load translation for code '${payload}' from server`);
          }

          return {
            localeMessages: {
              list: {
                ...response.i18n,
              },
            },
          };
        });
    } catch (error) {
      throw error;
    }
  }

  /**
   * @param payload данные в формате JSON
   */
  asyncPostReports: IAjaxRepository['asyncPostReports'] = async (
    payload: string,
  ): Promise<null | { error: IErrorEntity }> => {
    try {
      return await AjaxRepository.asyncDoPostRequest({
        url: config.reporter.url,
        data: payload,
      });
    } catch (error) {
      throw error;
    }
  }
}

export default AjaxRepository;
