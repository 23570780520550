import config from '@/config';
import { IRecorderRepository } from '@/core/interactors/types/repositories';
import { NonNullablePick } from '@/core/types';
import IRecorderEntity from '@/core/entities/recorder.entity';

class RecorderRepository {
  private recorder?: MediaRecorder;

  private buffer: BlobPart[] = [];

  private static instance: RecorderRepository;

  // eslint-disable-next-line no-useless-constructor, no-empty-function, @typescript-eslint/no-empty-function
  private constructor() {}

  static getInstance(): RecorderRepository {
    try {
      if (RecorderRepository.instance === undefined) {
        RecorderRepository.instance = new RecorderRepository();
      }

      return RecorderRepository.instance;
    } catch (error) {
      throw error;
    }
  }

  getIsSupported: IRecorderRepository['getIsSupported'] = (): boolean => {
    try {
      /*
        TODO: на текущий момент бэк принимает строго webm в vp8 кодировке
        поэтому проверяем не только наличие рекордера, но и возможность записи
        в нужном формате и кодировке.
        Примечание: в Safari со включеным в экспериментальных фичах MediaRecorder
        отсутсвует метод проверки isTypeSupported.
        По этому решили проверять этим методом только при его наличии
      */
      const hasMediaRecorder = 'MediaRecorder' in window;

      if (!hasMediaRecorder) {
        return false;
      }

      if (MediaRecorder.isTypeSupported !== undefined) {
        return MediaRecorder.isTypeSupported(config.webmRecordMimeType);
      }

      return true;
    } catch (error) {
      throw error;
    }
  }

  /**
   * @param payload медиа стрим
   */
  startRecord: IRecorderRepository['startRecord'] = (payload: MediaStream): void => {
    try {
      this.recorder = new MediaRecorder(payload, { mimeType: config.webmRecordMimeType });

      this.buffer = [];

      this.recorder.ondataavailable = (event) => {
        this.buffer.push(event.data);
      };

      this.recorder.start();
    } catch (error) {
      throw error;
    }
  }

  asyncStopRecord: IRecorderRepository['asyncStopRecord'] = async (): Promise<{
    recorder: NonNullablePick<IRecorderEntity, 'video'>,
  }> => {
    try {
      return new Promise((resolve, reject) => {
        if (this.recorder === undefined) {
          reject(new Error('recorder is undefined'));
          return;
        }

        if (this.recorder.state !== 'recording') {
          reject(new Error('recording not started'));
          return;
        }

        this.recorder.onstop = () => {
          const blob = new Blob(this.buffer, { type: config.webmRecordMimeType });

          this.recorder = undefined;
          this.buffer = [];

          resolve({ recorder: { video: blob } });
        };

        this.recorder.onerror = (value) => reject(value);

        this.recorder.stop();
      });
    } catch (error) {
      throw error;
    }
  }

  resetRecord: IRecorderRepository['resetRecord'] = (): void => {
    if (this.recorder === undefined) {
      throw new Error('recorder is undefined');
    }

    if (this.recorder.state !== 'recording') {
      throw new Error('recording not started');
    }

    this.recorder.stop();
    this.recorder = undefined;
    this.buffer = [];
  }
}

export default RecorderRepository;
