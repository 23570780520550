import config from '@/config';
import ProcedureEmitter from '@/packages/procedure-emitter';
import { EGender, ELocale } from '@/enums';
import { IReport } from '@/repositories/reporter-repository/types';
import { IStorageRepository } from '@/core/interactors/types/repositories';
import IUserEntity from '@/core/entities/user.entity';
import ISettingsEntity from '@/core/entities/settings.entity';

class StorageRepository extends ProcedureEmitter {
  private static instance: StorageRepository;

  // eslint-disable-next-line no-useless-constructor, no-empty-function, @typescript-eslint/no-empty-function
  private constructor() {
    super('StorageRepository');
  }

  /**
   * @param payload ключ записи в localStorage
   */
  private static getRecord(payload: string) {
    try {
      const data = localStorage.getItem(payload);

      if (data !== null) {
        return data;
      }

      return undefined;
    } catch (error) {
      throw error;
    }
  }

  /**
   * @param payload.key ключ записи в localStorage
   * @param payload.value значение записи в localStorage
   */
  private static setRecord(payload: {
    key: string,
    value: string | undefined,
  }): void {
    try {
      if (payload.value === undefined) {
        localStorage.removeItem(payload.key);
      } else {
        localStorage.setItem(payload.key, payload.value);
      }
    } catch (error) {
      throw error;
    }
  }

  static getInstance(): StorageRepository {
    try {
      if (StorageRepository.instance === undefined) {
        StorageRepository.instance = new StorageRepository();
      }

      return StorageRepository.instance;
    } catch (error) {
      throw error;
    }
  }

  /**
   * @param payload пол пользователя
   */
  asyncSetUserGender: IStorageRepository['asyncSetUserGender'] = async (
    payload: IUserEntity['gender'],
  ): Promise<void> => {
    try {
      if (!this.isAgent) {
        return await this.sendToAgent({
          payload,
          procedure: 'asyncSetUserGender',
        });
      }

      if (payload === undefined) {
        return StorageRepository.setRecord({
          key: config.storage.userGender,
          value: undefined,
        });
      }

      return StorageRepository.setRecord({
        key: config.storage.userGender,
        value: payload === EGender.MALE ? 'male' : 'female',
      });
    } catch (error) {
      throw error;
    }
  }

  asyncGetUserGender: IStorageRepository['asyncGetUserGender'] = async (): Promise<{
    user: Pick<IUserEntity, 'gender'>,
  }> => {
    try {
      if (!this.isAgent) {
        return await this.sendToAgent({ procedure: 'asyncGetUserGender' });
      }

      const record = StorageRepository.getRecord(config.storage.userGender);

      let gender;

      if (record !== undefined) {
        gender = record === 'male' ? EGender.MALE : EGender.FEMALE;
      }

      return { user: { gender } };
    } catch (error) {
      throw error;
    }
  }

  /**
   * @param payload таймстамп истечения времени блокировки
   */
  asyncSetUserLockExpirationTime: IStorageRepository['asyncSetUserLockExpirationTime'] = async (
    payload: IUserEntity['lockExpirationTime'],
  ): Promise<void> => {
    try {
      if (!this.isAgent) {
        return await this.sendToAgent({
          payload,
          procedure: 'asyncSetUserLockExpirationTime',
        });
      }

      if (payload === undefined) {
        return StorageRepository.setRecord({
          key: config.storage.userLockExpirationTime,
          value: undefined,
        });
      }

      return StorageRepository.setRecord({
        key: config.storage.userLockExpirationTime,
        value: `${payload}`,
      });
    } catch (error) {
      throw error;
    }
  }

  asyncGetUserLockExpirationTime: IStorageRepository['asyncGetUserLockExpirationTime'] = async (): Promise<{
    user: Pick<IUserEntity, 'lockExpirationTime'>,
  }> => {
    try {
      if (!this.isAgent) {
        return await this.sendToAgent({ procedure: 'asyncGetUserLockExpirationTime' });
      }

      const record = StorageRepository.getRecord(config.storage.userLockExpirationTime);

      let lockExpirationTime;

      if (record !== undefined) {
        lockExpirationTime = parseInt(record, 10);
      }

      return { user: { lockExpirationTime } };
    } catch (error) {
      throw error;
    }
  }

  /**
   * @param payload хэш пользователя
   */
  asyncSetUserHash: IStorageRepository['asyncSetUserHash'] = async (payload: IUserEntity['hash']): Promise<void> => {
    try {
      if (!this.isAgent) {
        return await this.sendToAgent({
          payload,
          procedure: 'asyncSetUserHash',
        });
      }

      if (payload === undefined) {
        return StorageRepository.setRecord({
          key: config.storage.userHash,
          value: undefined,
        });
      }

      return StorageRepository.setRecord({
        key: config.storage.userHash,
        value: payload,
      });
    } catch (error) {
      throw error;
    }
  }

  asyncGetUserHash: IStorageRepository['asyncGetUserHash'] = async (): Promise<{ user: Pick<IUserEntity, 'hash'> }> => {
    try {
      if (!this.isAgent) {
        return await this.sendToAgent({ procedure: 'asyncGetUserHash' });
      }

      const hash = StorageRepository.getRecord(config.storage.userHash);

      return { user: { hash } };
    } catch (error) {
      throw error;
    }
  }

  /**
   * @param payload включена ли громкость в чате
   */
  asyncSetVolumeEnabled: IStorageRepository['asyncSetVolumeEnabled'] = async (
    payload: ISettingsEntity['isVolumeEnabled'],
  ): Promise<void> => {
    try {
      if (!this.isAgent) {
        return await this.sendToAgent({
          payload,
          procedure: 'asyncSetVolumeEnabled',
        });
      }

      if (payload === undefined) {
        return StorageRepository.setRecord({
          key: config.storage.volume,
          value: undefined,
        });
      }

      return StorageRepository.setRecord({
        key: config.storage.volume,
        value: payload ? 'on' : 'off',
      });
    } catch (error) {
      throw error;
    }
  }

  asyncGetVolumeEnabled: IStorageRepository['asyncGetVolumeEnabled'] = async (): Promise<{
    settings: Pick<ISettingsEntity, 'isVolumeEnabled'>,
  }> => {
    try {
      if (!this.isAgent) {
        return await this.sendToAgent({ procedure: 'asyncGetVolumeEnabled' });
      }

      const record = StorageRepository.getRecord(config.storage.volume);

      let isVolumeEnabled;

      if (record !== undefined) {
        isVolumeEnabled = record === 'on';
      }

      return { settings: { isVolumeEnabled } };
    } catch (error) {
      throw error;
    }
  }

  /**
   * @param payload включен ли микрофон в чате
   */
  asyncSetMicrophoneEnabled: IStorageRepository['asyncSetMicrophoneEnabled'] = async (
    payload: ISettingsEntity['isMicrophoneEnabled'],
  ): Promise<void> => {
    try {
      if (!this.isAgent) {
        return await this.sendToAgent({
          payload,
          procedure: 'asyncSetMicrophoneEnabled',
        });
      }

      if (payload === undefined) {
        return StorageRepository.setRecord({
          key: config.storage.microphone,
          value: undefined,
        });
      }

      return StorageRepository.setRecord({
        key: config.storage.microphone,
        value: payload ? 'on' : 'off',
      });
    } catch (error) {
      throw error;
    }
  }

  asyncGetMicrophoneEnabled: IStorageRepository['asyncGetMicrophoneEnabled'] = async (): Promise<{
    settings: Pick<ISettingsEntity, 'isMicrophoneEnabled'>,
  }> => {
    try {
      if (!this.isAgent) {
        return await this.sendToAgent({ procedure: 'asyncGetMicrophoneEnabled' });
      }

      const record = StorageRepository.getRecord(config.storage.microphone);

      let isMicrophoneEnabled;

      if (record !== undefined) {
        isMicrophoneEnabled = record === 'on';
      }

      return { settings: { isMicrophoneEnabled } };
    } catch (error) {
      throw error;
    }
  }

  /**
   * @param payload язык приложения
   */
  asyncSetLocale: IStorageRepository['asyncSetLocale'] = async (payload: ELocale): Promise<void> => {
    try {
      if (!this.isAgent) {
        return await this.sendToAgent({
          payload,
          procedure: 'asyncSetLocale',
        });
      }

      if (payload === undefined) {
        return StorageRepository.setRecord({
          key: config.storage.locale,
          value: undefined,
        });
      }

      return StorageRepository.setRecord({
        key: config.storage.locale,
        value: `${payload}`,
      });
    } catch (error) {
      throw error;
    }
  }

  asyncGetLocale: IStorageRepository['asyncGetLocale'] = async (): Promise<ELocale | undefined> => {
    try {
      if (!this.isAgent) {
        return await this.sendToAgent({ procedure: 'asyncGetLocale' });
      }

      const storageLocale = StorageRepository.getRecord(config.storage.locale);

      let locale: ELocale | undefined;

      switch (storageLocale) {
        case 'de':
          locale = ELocale.DE;

          break;
        case 'en':
          locale = ELocale.EN;

          break;
        case 'es':
          locale = ELocale.ES;

          break;
        case 'fr':
          locale = ELocale.FR;

          break;
        case 'it':
          locale = ELocale.IT;

          break;
        case 'pl':
          locale = ELocale.PL;

          break;
        case 'ro':
          locale = ELocale.RO;

          break;
        case 'ru':
          locale = ELocale.RU;

          break;
        case 'tr':
          locale = ELocale.TR;

          break;
        case 'nl':
          locale = ELocale.NL;

          break;
        case 'fi':
          locale = ELocale.FI;

          break;
        case 'uk':
          locale = ELocale.UK;

          break;
        case 'ja':
          locale = ELocale.JA;

          break;
        case 'pt':
          locale = ELocale.PT;

          break;
        case 'zh':
          locale = ELocale.ZH;

          break;
        default:
          locale = undefined;
      }

      return locale;
    } catch (error) {
      throw error;
    }
  }

  /**
   * @param payload таймстамп загрузки страницы
   */
  asyncSetPageLoadTimestamp: IStorageRepository['asyncSetPageLoadTimestamp'] = async (payload: number): Promise<void> => {
    try {
      if (!this.isAgent) {
        return await this.sendToAgent({
          payload,
          procedure: 'asyncSetPageLoadTimestamp',
        });
      }

      if (payload === undefined) {
        return StorageRepository.setRecord({
          key: config.storage.pageLoadTimestamp,
          value: undefined,
        });
      }

      return StorageRepository.setRecord({
        key: config.storage.pageLoadTimestamp,
        value: `${payload}`,
      });
    } catch (error) {
      throw error;
    }
  }

  asyncGetPageLoadTimestamp: IStorageRepository['asyncGetPageLoadTimestamp'] = async (): Promise<number | undefined> => {
    try {
      if (!this.isAgent) {
        return await this.sendToAgent({ procedure: 'asyncGetPageLoadTimestamp' });
      }

      const record = StorageRepository.getRecord(config.storage.pageLoadTimestamp);

      if (record === undefined) {
        return undefined;
      }

      return parseInt(record, 10);
    } catch (error) {
      throw error;
    }
  }

  /**
   * @param payload массив отчётов
   */
  asyncSetReports: IStorageRepository['asyncSetReports'] = async (payload: IReport[] | undefined): Promise<void> => {
    try {
      if (!this.isAgent) {
        return await this.sendToAgent({
          payload,
          procedure: 'asyncSetReports',
        });
      }

      if (payload === undefined) {
        return StorageRepository.setRecord({
          key: config.storage.reporter,
          value: undefined,
        });
      }

      return StorageRepository.setRecord({
        key: config.storage.reporter,
        value: `${JSON.stringify(payload)}`,
      });
    } catch (error) {
      throw error;
    }
  }

  asyncGetReports: IStorageRepository['asyncGetReports'] = async (): Promise<IReport[] | undefined> => {
    try {
      if (!this.isAgent) {
        return await this.sendToAgent({ procedure: 'asyncGetReports' });
      }

      const record = StorageRepository.getRecord(config.storage.reporter);

      if (record === undefined) {
        return undefined;
      }

      return JSON.parse(record);
    } catch (error) {
      throw error;
    }
  }

  /**
   * Кастомный метод для добавления отчетов в список хранения
   * Отчёты добавляются в конец
   *
   * @param payload массив отчётов
   */
  asyncPushReports: IStorageRepository['asyncPushReports'] = async (payload: IReport[]): Promise<void> => {
    try {
      if (!this.isAgent) {
        return await this.sendToAgent({
          payload,
          procedure: 'asyncPushReports',
        });
      }

      const reportsToAdd = [...payload];

      const storedReports = await StorageRepository.getInstance().asyncGetReports();

      if (storedReports !== undefined) {
        reportsToAdd.push(...storedReports);
      }

      return await StorageRepository.getInstance().asyncSetReports(reportsToAdd);
    } catch (error) {
      throw error;
    }
  }

  /**
   * Кастомный метод для добавления отчетов в список хранения
   * Все отчёты до указанной даты заменяются на переданные
   *
   * @param payload.reports массив логов
   * @param payload.time время, до которого нужно переписать переданные отчёты
   */
  asyncOverwriteReportsBefore: IStorageRepository['asyncOverwriteReportsBefore'] = async (payload: {
    reports: IReport[],
    time: number,
  }): Promise<void> => {
    try {
      if (!this.isAgent) {
        return await this.sendToAgent({
          payload,
          procedure: 'asyncOverwriteReportsBefore',
        });
      }

      const storedReports = await StorageRepository.getInstance().asyncGetReports() || [];

      const newerReports = storedReports.filter((report: IReport) => (
        report.timestamp !== undefined && report.timestamp > payload.time
      ));

      const reportsToSet = [...payload.reports, ...newerReports];

      return await StorageRepository.getInstance().asyncSetReports(
        reportsToSet.length > 0 ? reportsToSet : undefined,
      );
    } catch (error) {
      throw error;
    }
  }

  asyncSetNewVersion: IStorageRepository['asyncSetNewVersion'] = async (): Promise<void> => {
    try {
      if (!this.isAgent) {
        return await this.sendToAgent({ procedure: 'asyncSetNewVersion' });
      }

      return StorageRepository.setRecord({
        key: 'coomeet-version',
        value: 'new',
      });
    } catch (error) {
      throw error;
    }
  }
}

export default StorageRepository;
