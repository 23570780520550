import { createStore, StoreOptions } from 'vuex';
import mutations from '@/store/mutations';
// eslint-disable-next-line import/no-cycle
import getters from '@/store/getters';
import config from '@/config';
import {
  EContactsDisplayMode,
  EContactsFiltration,
  ELocalStreamSize,
  ERegistrationStatus,
  EUserMaleSearchFiltration,
  EVideochatHistorySorting,
  EVideochatPageStage,
} from '@/enums';
import IViewmodel from '@/core/interactors/types/viewmodels';

const options: StoreOptions<IViewmodel> = {
  state: {
    entities: {
      user: {
        gender: undefined,
        hash: undefined,
        id: undefined,
        registrationStatus: ERegistrationStatus.NONE,
        avatar: null,
        name: undefined,
        email: undefined,
        confirmationEmail: undefined,
        ageRange: undefined,
        lockExpirationTime: undefined,
        socials: [],
        accountDeletionCodeFirstPart: undefined,
        accountDeletionCodeFull: undefined,
        emailNotificationSettings: null,
        pushNotificationSettings: {
          isSubscribed: undefined,
          isForClosedAppRequired: undefined,
          isMessageTextDisplayed: undefined,
          isCountdownLockEndNotificationEnabled: undefined,
          isBonusMinutesEnabled: undefined,
          serverKey: undefined,
        },
        isSettingsViewed: undefined,
        malePurchaseAvailableCardVendors: [],
        isMalePurchaseVatIncluded: undefined,
        malePurchasePaymentSystems: {
          list: [],
        },
        malePremiumPackages: {
          list: {},
        },
        malePremiumSubscription: null,
        isTrialMalePremiumPackageEnabled: undefined,
        maleMinutesPackages: {
          list: {},
          autoPurchase: null,
        },
        maleCards: {
          list: [],
          minutesAutoPurchase: null,
          premiumSubscription: null,
        },
        maleCardConfirmation: null,
        malePremiumExpirationTime: undefined,
        maleNumberOfMessagesPerMinuteOfVideochat: undefined,
        maleNumberOfMessagesAvailableForSending: undefined,
        maleNumberOfMillisecondsRemaining: undefined,
        isTranslationEnabled: undefined,
        translationLang: undefined,
        isMalePremium: undefined,
        isMalePremiumExtensionEnabled: undefined,
        hasMalePayments: undefined,
        hasMaleMinutesAutoPurchaseError: undefined,
        isEmailConfirmationBonusAvailable: undefined,
        pushNotificationBonusMinutes: undefined,
        emailConfirmationBonusMinutes: undefined,
        emailConfirmationForFreebieMinutes: undefined,
        isEmailConfirmationReminderEnabled: undefined,

        femalePoints: undefined,
        femaleHolidays: undefined,
        femaleFirstMinuteRate: undefined,
        femaleSecondMinuteRate: undefined,
        femaleDailyStatistics: {
          list: [],
        },
        femaleTariffs: {
          list: {},
        },
        femaleCurrentTariffId: undefined,
        femaleMinTariffIdWithAvailableStoryRecording: undefined,
        femaleWithdrawalAvailableCardVendors: [],
        femaleWithdrawalAvailableUsdtFormats: [],
        femaleWithdrawalPaymentSystems: {
          list: [],
        },

        isUsageRulesAttentionAccepted: undefined,
        isEmailChangeBlocked: undefined,
        isNeedEmailConfirmation: undefined,
        isAnyContentAllowed: undefined,

        rating: undefined,
        role: undefined,

        isMalePurchasePremiumShowRequired: undefined,
        isMalePurchaseMinutesShowRequired: undefined,
        maleSelectedPackageId: undefined,
        isMalePartnerPaymentBlocked: undefined,
        femaleCurrentTopPlace: undefined,
        femaleCurrentPointsToNextTopPlace: undefined,
        femaleWorkedMinutes: undefined,
        isWithdrawalAvailableOnlyBySupport: undefined,
        femaleWithdrawalCurrencies: [],
        withdrawalDocumentTypes: {},
        withdrawalBankCodes: {},
        withdrawalBankAccountTypes: {},
        withdrawalNewMethods: {
          list: [],
        },
        withdrawalSavedMethods: {
          list: [],
        },

        ban: null,
        pointsForPremiumPurchase: undefined,
        pointsForTextMessage: undefined,
        pointsForPhotoMessage: undefined,
        pointsForVideoMessage: undefined,
        referralBonusPercent: undefined,
        storyLockExpirationTime: undefined,
        recordedStory: null,
        pointsForRecordedStoryLike: undefined,
        storyEarnedPoints: undefined,
        isSearchWithProxyBlocked: false,
        isFingerprintRequired: false,
      },

      promoMessageTemplates: {
        list: {},
      },

      accountTransactions: {
        list: {},
      },

      withdrawalRequest: null,

      devices: {
        list: {
          audio: {},
          video: {},
        },
        active: {
          audio: null,
          video: null,
        },
      },

      streams: {
        local: null,
        remote: null,
      },

      recorder: {
        photo: undefined,
        video: null,
      },

      settings: {
        isVolumeEnabled: undefined,
        isMicrophoneEnabled: undefined,
        contactsFiltration: EContactsFiltration.RECENT,
        contactsDisplayMode: EContactsDisplayMode.DEFAULT,
        userMaleSearchFiltration: EUserMaleSearchFiltration.ALL,
        localStreamCoordinates: {
          x: 0,
          y: 0,
        },
        localStreamSize: ELocalStreamSize.SMALL,
        videochatHistorySorting: EVideochatHistorySorting.RECENT,
        isSafeSearchEnabled: undefined,
        isMessagesAutoReadEnabled: true,
        isPushNotificationOnClientEnabled: undefined,
        screenshotCaptureDelay: undefined,
        screenshotCaptureInterval: undefined,
        millisecondsForSearchFiltration: undefined,
        promoDurationMinutes: undefined,
        usernameMaxLength: undefined,
        emailMaxLength: undefined,
        messageMaxLength: undefined,
        imageMaxUploadSize: undefined,
        minutesCountForAutoPurchaseActivation: undefined,
        firstAutoPurchaseAttemptMinutesCount: undefined,
        secondAutoPurchaseAttemptMinutesCount: undefined,
        isStoryVolumeEnabled: false,
        recordedStoryMaxDuration: undefined,
        recordedStoryLifetime: undefined,
        locale: undefined,
      },

      videochat: {
        id: undefined,
        stage: undefined,
        stopReason: undefined,
        companion: null,
        rateCompanionId: undefined,
        promoCompanionId: undefined,
        startTime: undefined,
        duration: undefined,
        lastScreenshotTime: undefined,
        queuePosition: undefined,
        lastFirstFrameTime: undefined,
        lastCompanionTime: undefined,
        shouldContinueSearch: undefined,
      },

      videochatHistory: {
        list: {},
      },

      top: null,

      processes: {
        isVideoCallActive: false,
        isTimeOverLockActive: false,
        isUserRemainingTimeReduceActive: false,
        isConnectionActive: false,
        isVideoRecordActive: false,
        isLocalStreamStartActive: false,
        isContactsLoadingActive: false,
        isVideochatRecoveryWaitingDurationIncreaseActive: false,
        isWaitingAfterCameraFailActive: false,
      },

      referrals: {
        list: {},
        total: 0,
        contactId: undefined,
      },

      gifts: {
        list: {},
        lastId: undefined,
        pending: null,
      },

      contacts: {
        list: {},
        selected: null,
        unreadCount: 0,
        supportIds: null,
      },

      environment: {
        partnerId: undefined,
        partnerChannel: undefined,
        restoreToken: undefined,
        origin: undefined,
        referral: undefined,
        utmSource: undefined,
        utmMedium: undefined,
        utmContent: undefined,
        utmCampaign: undefined,
        affiliateId: undefined,
        isAppVisible: undefined,
        testCountry: undefined,
        storyAuthorId: undefined,
        features: [],
        isMalePurchasePremiumShowRequired: undefined,
        isMalePurchaseMinutesShowRequired: undefined,
        selectedPurchasePackageId: undefined,
      },

      stories: {
        list: [],
        active: null,
        preview: null,
      },

      outgoingCall: null,

      incomingCall: null,
    },
    ui: {
      buffer: {
        cardLastNumbersForPurchase: undefined,
        cardLastNumbersForRemoval: undefined,
        cardLastNumbersForChange: undefined,
        cardLastNumbersForPremiumSubscription: undefined,
        premiumSubscriptionPurchaseGateway: null,
        purchaseType: undefined,
        purchasedPackageId: undefined,
        purchaseRetryUrl: undefined,
        passwordRecoveryEmail: undefined,
        contactForRemovalId: undefined,
        markedContactsIds: undefined,
        messageDrafts: undefined,
        userAvatarUploadError: undefined,
        messageSendError: undefined,
        socketConnectionEstablishTime: undefined,
        promoBonusContactId: undefined,
        userAvatarValidationError: undefined,
        userAvatarEditState: undefined,
        userTemporaryAvatar: undefined,
        companionAbuseId: undefined,
        mediaFileToView: undefined,
        userCardConfirmationValidationError: undefined,
        userVerificationStage: undefined,
        accountDeletionReasonValidationError: undefined,
        accountDeletionCodeValidationError: undefined,
        userProfileNameValidationError: undefined,
        userProfileEmailValidationError: undefined,
        userProfilePasswordValidationError: undefined,
        purchaseError: undefined,
        userSignUpNameValidationError: undefined,
        userSignUpPasswordValidationError: undefined,
        userSignUpEmailValidationError: undefined,
        userEmailConfirmationValidationError: undefined,
        userEmailConfirmationStatus: undefined,
        userEmailConfirmationForFreebieEmail: undefined,
        userEmailConfirmationCodeValidationError: undefined,
        withdrawalRequestCardNumberValidationError: undefined,
        withdrawalRequestCardExpirationValidationError: undefined,
        withdrawalRequestCardCurrencyValidationError: undefined,
        withdrawalRequestCardHolderFirstNameValidationError: undefined,
        withdrawalRequestCardHolderLastNameValidationError: undefined,
        withdrawalRequestCardHolderBirthdayValidationError: undefined,
        withdrawalRequestCardAddressStreetValidationError: undefined,
        withdrawalRequestCardAddressCityValidationError: undefined,
        withdrawalRequestBankAccountHolderDocumentNumberValidationError: undefined,
        withdrawalRequestBankAccountHolderFirstNameValidationError: undefined,
        withdrawalRequestBankAccountHolderLastNameValidationError: undefined,
        withdrawalRequestBankAccountHolderAddressValidationError: undefined,
        withdrawalRequestBankAccountHolderPhoneValidationError: undefined,
        withdrawalRequestBankAccountHolderEmailValidationError: undefined,
        withdrawalRequestBankBranchCodeValidationError: undefined,
        withdrawalRequestBankAccountNumberValidationError: undefined,
        withdrawalRequestError: undefined,
        withdrawalRequestMinPoints: undefined,
        userSignInPasswordValidationError: undefined,
        userSignInEmailValidationError: undefined,
        passwordRecoveryEmailValidationError: undefined,
        withdrawalRequestWebmoneyNumberValidationError: undefined,
        withdrawalRequestYandexNumberValidationError: undefined,
        withdrawalRequestBitcoinNumberValidationError: undefined,
        withdrawalRequestEpayserviceNumberValidationError: undefined,
        outgoingCallSource: undefined,
        withdrawalMethodForRemoval: undefined,
        userFemalePreviousTariffId: undefined,
        paymentAllowedUrl: undefined,
        isContactsLoadingAvailable: undefined,
        isUserStoppedVideochat: undefined,
        isCompanionRateAfterRecoveryRequired: undefined,
        videochatRecoveryWaitingDuration: undefined,
        actionImpossibleReason: undefined,
        contactsScrollPosition: undefined,
        contactNameValidationError: undefined,
        withdrawalRequestRejectionReason: undefined,
        isUsageRulesDisplayedAfterAuthorization: undefined,
        withdrawalConfirmationValidationError: undefined,
        isWithdrawalConfirmationDisabled: false,
        isSendEmailConfirmationCodeDisabled: false,
        isAccountDeletionDisabled: false,
      },
      flags: {
        shouldShowMessengerNotificationAppeal: true,
        shouldShowVideochatNotificationAppeal: true,
        isAppForbidden: false,
        isAuthorizationControlsDisabled: false,
        isPurchaseControlsDisabled: false,
        isContactRequestSentActionsDisabled: false,
        isContactRequestDisabled: false,
        videochatPageStage: EVideochatPageStage.START,
        isVideochatHistoryClearAttentionDisplayed: false,
        isCameraNotAvailableAttentionDisplayed: false,
        isCameraNeedPermissionAttentionDisplayed: false,
        isSignInAttentionDisplayed: false,
        isSignUpAttentionDisplayed: false,
        isPasswordRecoveryAttentionDisplayed: false,
        isPasswordRecoverySuccessAttentionDisplayed: false,
        isTariffsAndStatisticsAttentionDisplayed: false,
        isUserNameEditFormDisplayed: false,
        isUserLogoutAttentionDisplayed: false,
        isWithdrawalCardRequisitesAttentionDisplayed: false,
        isWithdrawalCardHolderAttentionDisplayed: false,
        isWithdrawalCardAddressAttentionDisplayed: false,
        isWithdrawalRequestErrorAttentionDisplayed: false,
        isUserEmailConfirmationReminderAttentionDisplayed: false,
        isUserEmailConfirmationAttentionDisplayed: false,
        isMinutesPurchaseAttentionDisplayed: false,
        isMinutesAddedAttentionDisplayed: false,
        isMinutesReducedAttentionDisplayed: false,
        isPremiumAddedAttentionDisplayed: false,
        isPurchaseRetryAttentionDisplayed: false,
        isPurchaseSuccessAttentionDisplayed: false,
        isPurchaseErrorAttentionDisplayed: false,
        isCompanionAbuseAttentionDisplayed: false,
        isUsageRulesAttentionDisplayed: false,
        isPremiumPurchaseAttentionDisplayed: false,
        isTopDayLeaderAttentionDisplayed: false,
        isTopWeekLeaderAttentionDisplayed: false,
        isTopMonthLeaderAttentionDisplayed: false,
        isAvatarUploadErrorAttentionDisplayed: false,
        isMessageSendErrorAttentionDisplayed: false,
        isUserBanAttentionDisplayed: false,
        isUserEmailHasChangedAttentionDisplayed: false,
        isUserProfileSettingsFormInInitialState: true,
        isCompanionNoteAttentionDisplayed: false,
        isContactNoteAttentionDisplayed: false,
        isAccountTransactionsAttentionDisplayed: false,
        isCompanionRateLockDisplayed: false,
        isCompanionReviewLockDisplayed: false,
        isCompanionReviewSentLockDisplayed: false,
        isCompanionGiftLockDisplayed: false,
        isCompleteSignUpAttentionDisplayed: false,
        isReferralAttentionDisplayed: false,
        isAccountDeletionAttentionDisplayed: false,
        isAccountDeletionConfirmationAttentionDisplayed: false,
        isAccountDeletionReasonAttentionDisplayed: false,
        isAccountDeletionSuccessAttentionDisplayed: false,
        isMicrophoneNoticeDisplayed: false,
        isDoubleEntryAttentionDisplayed: false,
        isCompanionLeaveVideocallLockDisplayed: false,
        isCompanionDisconnectVideocallLockDisplayed: false,
        isSwitchPageDuringVideochatAttentionDisplayed: false,
        isSignUpAppealAttentionDisplayed: false,
        isCompanionBannedVideocallLockDisplayed: false,
        isCompanionOutOfMinutesLockDisplayed: false,
        isFemalePromoVideocallLockDisplayed: false,
        isCompanionHasAnotherVideocallLockDisplayed: false,
        isCompanionPaysLockDisplayed: false,
        isUserVerificationLockDisplayed: false,
        isCardConfirmationLockDisplayed: false,
        isUserPurchaseCardRemovalConfirmDisplayed: false,
        shouldShowFaceNotDetectedAttention: false,
        isGiftAttentionDisplayed: false,
        isGiftErrorAttentionDisplayed: false,
        isServerUnavailableAttentionDisplayed: false,
        isMessengerEmailConfirmationDisplayed: true,
        isUserOutOfMessagesAttentionDisplayed: false,
        isContactRequestAttentionDisplayed: false,
        isRemoveSelectedContactsMessagesAttentionDisplayed: false,
        isRemoveAllContactsMessagesAttentionDisplayed: false,
        isServerPingAttentionDisplayed: false,
        isServerNetworkAttentionDisplayed: false,
        isContactRemoveAttentionDisplayed: false,
        isSearchSettingsDisplayed: false,
        isCompanionPervertAttentionDisplayed: false,
        isImageSendAttentionDisplayed: false,
        isPhotoSendAttentionDisplayed: false,
        isImageViewAttentionDisplayed: false,
        isVideoViewAttentionDisplayed: false,
        isVideoSendAttentionDisplayed: false,
        isCameraNotSupportedAttentionDisplayed: false,
        isCameraNotExistAttentionDisplayed: false,
        isIncomingCallAttentionDisplayed: false,
        isUnsecuredConnectionAttentionDisplayed: false,
        isPromoBonusAttentionDisplayed: false,
        isContactAddDisabled: false,
        isVideochatSearchNextControlsDisabled: false,
        isAppWaitingActive: false,
        isStartWithdrawalAttentionDisplayed: false,
        isPremiumRequiredForSearchBestAttentionDisplayed: false,
        isMinutesRequiredForSearchBestAttentionDisplayed: false,
        isWithdrawalWebmoneyRequisitesAttentionDisplayed: false,
        isWithdrawalYandexRequisitesAttentionDisplayed: false,
        isWithdrawalBitcoinRequisitesAttentionDisplayed: false,
        isWithdrawalUsdtRequisitesAttentionDisplayed: false,
        isWithdrawalEpayserviceRequisitesAttentionDisplayed: false,
        isWithdrawalBankFirstStepDisplayed: false,
        isWithdrawalBankSecondStepDisplayed: false,
        isFinishWithdrawalAttentionDisplayed: false,
        isWithdrawalQiwiRequisitesAttentionDisplayed: false,
        isTariffsControlsDisabled: false,
        isReferralsLoadingDisabled: false,
        isWithdrawalRequestLoadControlsDisabled: false,
        isWithdrawalRequestRejectedAttentionDisplayed: false,
        isWithdrawalRequestSendControlsDisabled: false,
        isWithdrawalMethodRemovalAttentionDisplayed: false,
        isWithdrawalConfirmationAttentionDisplayed: false,
        isWithdrawalCancelledAttentionDisplayed: false,
        isPurchaseUnavailableAttentionDisplayed: false,
        isVideochatControlsHidden: false,
        isVideochatControlsAutoHidingEnabled: false,
        isSettingsAttentionDisplayed: false,
        isSettingsAttentionControlsDisabled: false,
        isIncomingCallControlsDisabled: false,
        pushNotificationSubscriptionState: undefined,
        isChangeAccountAttentionDisplayed: false,
        isChangeAccountAttentionControlsDisabled: false,
        isLocalStreamHidden: false,
        isPartnerPaymentBlockAttentionDisplayed: false,
        isCardConfirmationLockControlsDisabled: false,
        isContactsLoadingErrorDisplayed: false,
        isVideochatRecoveryAttentionDisplayed: false,
        isVideochatRecoveryFailAttentionDisplayed: false,
        isVideochatRecoveryWaitingAttentionDisplayed: false,
        isPageLoadErrorAttentionDisplayed: false,
        isContactRequestAcceptionErrorAttentionDisplayed: false,
        isMinutesAutoPurchaseUnavailableAttentionDisplayed: false,
        isMinutesAutoPurchaseFailedAttentionDisplayed: false,
        isMinutesAutoPurchaseTermsAttentionDisplayed: false,
        isCardChangeSuccessAttentionDisplayed: false,
        isNotEnoughMinutesAttentionDisplayed: false,
        isPremiumSubscriptionRenewalAttentionDisplayed: false,
        isCardConfirmationWarningAttentionDisplayed: false,
        isNotEnoughMinutesDisabled: false,
        isBrowserNotSupportedAttentionDisplayed: false,
        isPushNotificationBonusAttentionDisplayed: false,
        isUserPremiumSubscriptionEnablingCardSelectionDisplayed: false,
        isUserPremiumSubscriptionEnablingCardRemovalConfirmDisplayed: false,
        isUserPremiumSubscriptionChangingCardSelectionDisplayed: false,
        isUserPremiumSubscriptionChangingCardRemovalConfirmDisplayed: false,
        isBonusMinutesAppealAttentionDisplayed: false,
        isBackToVideochatDisplayed: false,
        isCountdownLockDisplayed: false,
        isStoryLockDisplayed: false,
        isStoryNotFoundDisplayed: false,
        shouldShowStoryNavigation: false,
        isStoryRecordCloseDisplayed: true,
        isStoryRemovalConfirmDisplayed: false,
        isFunctionalUnavailableDisplayed: false,
        isStoryEarnedPointsDisplayed: false,
        isPremiumSubscriptionBonusMinutesRenewalDisplayed: false,
        isVideochatStopDisabled: false,
        isPersonalOfferDisplayed: false,
        isActivePremiumSubscriptionDisplayed: false,
        isInactivePremiumSubscriptionDisplayed: false,
        isPremiumSubscriptionPurchasedDisplayed: false,
        isPremiumSubscriptionExplanationDisplayed: false,
        isPremiumSubscriptionDeactivateDisplayed: false,
        isVideochatModerationDisabled: false,
        isPremiumSubscriptionCardSelectionDisplayed: false,
        isPremiumSubscriptionCardRemovalDisplayed: false,
        isPremiumSubscriptionPurchaseErrorDisplayed: false,
        isContactNameEditingDisplayed: false,
        isContactNameEditingAfterContactAcceptingDisplayed: false,
        isUnavailableSearchAttentionDisplayed: false,
        isStoryAbuseAttentionDisplayed: false,
        isContactBlockConfirmationAttentionDisplayed: false,
        isMessagesSendingDisabled: false,
        isEmailConfirmationForFreebieAttentionDisplayed: false,
        isSearchStartWarningDisplayed: false,
        isUserAccessDeniedAttentionDisplayed: false,
        isNewVersionButtonHighlighted: true,
      },
    },
  },
  mutations,
  getters,
  modules: {},
  strict: config.isProduction,
};

export default createStore<IViewmodel>(options);
