import { GetterTree } from 'vuex';
import {
  TAbuseReason,
  TAccountTransaction,
  TAccountTransactionActivityType,
  TActionImpossibleReason,
  TAppIconPaymentName,
  TBanStatus,
  TCallContact,
  TCard,
  TCardCountry,
  TCompanion,
  TCompanionContactStatus,
  TCompanionModerationStatus,
  TCompanionRate,
  TCompanionReviewReason,
  TContact,
  TContactPromoRequestStatus,
  TContactRequestStatus,
  TContactRole,
  TContactsDisplayMode,
  TContactsFiltration,
  TContactsItem,
  TContactStatus,
  TCurrency,
  TDurationFormat,
  TEmailConfirmationStatus,
  TGender,
  TGift,
  TGiftType,
  TLocale,
  TLocaleCode,
  TLocalStreamSize,
  TMessage,
  TMessageSendError,
  TMessageSendingStatus,
  TMessageType,
  TMessengerFeedPeriod,
  TMessengerState,
  TOutgoingCallAvailabilityStatus,
  TOutgoingCallRejectionReason,
  TPaymentSystem,
  TPaymentSystemType,
  TPhotoGridStoryStatus,
  TPromoMessageTemplate,
  TPurchaseError,
  TPurchaseType,
  TPushNotificationSubscriptionState,
  TRatingLevel,
  TRecordedStoryModerationStatus,
  TReferralsItem,
  TRelativeDate,
  TSocial,
  TSticker,
  TStoryAuthor,
  TStoryAuthorContactStatus,
  TSystemMessageData,
  TSystemMessageVariant,
  TTopChangeDirection,
  TTopLeader,
  TTranslationLang,
  TUserAccountDeletionCodeValidationError,
  TUserAccountDeletionReasonValidationError,
  TUserAgeRange,
  TUserAgeRangeItem,
  TUserAvatarEditState,
  TUserAvatarUploadError,
  TUserAvatarValidationError,
  TUserCardConfirmationValidationError,
  TUserEmailValidationError,
  TUserMessageData,
  TUserMessageDirection,
  TUserMessageGift,
  TUserMessagePhoto,
  TUserMessageStatus,
  TUserMessageText,
  TUserMessageType,
  TUserMessageVideo,
  TUserNameValidationError,
  TUserPasswordValidationError,
  TUserVerificationStage,
  TUserVerificationStatus,
  TVideochatHistorySorting,
  TVideochatMessengerFeedItem,
  TVideochatPageStage,
  TWithdrawalBankAccountTypes,
  TWithdrawalBankCodes,
  TWithdrawalBankDocumentTypes,
  TWithdrawalMethod,
  TWithdrawalRequestAvailabilityValidationError,
  TWithdrawalRequestBankAccountHolderAddressValidationError,
  TWithdrawalRequestBankAccountHolderDocumentNumberValidationError,
  TWithdrawalRequestBankAccountHolderEmailValidationError,
  TWithdrawalRequestBankAccountHolderFirstNameValidationError,
  TWithdrawalRequestBankAccountHolderLastNameValidationError,
  TWithdrawalRequestBankAccountHolderPhoneValidationError,
  TWithdrawalRequestBankAccountNumberValidationError,
  TWithdrawalRequestBankBranchCodeValidationError,
  TWithdrawalRequestCardAddressCityValidationError,
  TWithdrawalRequestCardAddressStreetValidationError,
  TWithdrawalRequestCardCurrencyValidationError,
  TWithdrawalRequestCardExpirationValidationError,
  TWithdrawalRequestCardHolderBirthdayValidationError,
  TWithdrawalRequestCardHolderFirstNameValidationError,
  TWithdrawalRequestCardHolderLastNameValidationError,
  TWithdrawalRequestCardNumberValidationError,
  TWithdrawalRequestEpayserviceNumberValidationError,
  TWithdrawalRequestError,
  TWithdrawalRequestQiwiNumberValidationError,
  TWithdrawalRequestWebmoneyNumberValidationError,
  TWithdrawalRequestYandexNumberValidationError,
  TContactNameValidationError,
  TStoryAbuseReason,
  TWithdrawalRequestRejectionReason,
  TWithdrawalRequestBitcoinNumberValidationError,
  TPaymentCardVendor,
  TWithdrawalRequestUsdtNumberValidationError,
  TWithdrawalConfirmationValidationError,
  TWithdrawalMethodDuration,
  TEmailConfirmationCodeValidationError,
} from '@/types';
import {
  EAccountTransactionActivityType,
  EActionImpossibleReason,
  EBanStatus,
  ECardVendor,
  ECompanionContactStatus,
  ECompanionModerationStatus,
  ECompanionRate,
  ECompanionRole,
  EContactPromoRequestStatus,
  EContactRequestStatus,
  EContactRole,
  EContactsDisplayMode,
  EContactsFiltration,
  EContactStatus,
  ECountry,
  EGender,
  ELocale,
  ELocalStreamSize,
  EMessageSendError,
  EMessageSendingStatus,
  EMessageType,
  EOutgoingCallRejectionReason,
  EPaymentSystemType,
  EPurchaseError,
  EPurchaseType,
  EPushNotificationSubscriptionState,
  ERecordedStoryModerationStatus,
  ERegistrationStatus,
  ESocial,
  EStoryAuthorContactStatus,
  ESystemMessageText,
  ETopChangeDirection,
  ETopPeriod,
  ETranslationLangCode,
  EUserAccountDeletionCodeValidationError,
  EUserAccountDeletionReasonValidationError,
  EUserAgeRange,
  EUserAvatarEditState,
  EUserAvatarUploadError,
  EUserAvatarValidationError,
  EUserCardConfirmationValidationError,
  EUserEmailConfirmationStatus,
  EUserEmailValidationError,
  EUserMaleSearchFiltration,
  EUserNameValidationError,
  EUserPasswordValidationError,
  EUserRole,
  EUserVerificationStage,
  EUserVerificationStatus,
  EVideochatHistorySorting,
  EVideochatPageStage,
  EVideochatStage,
  EWithdrawalRequestBankAccountHolderAddressValidationError,
  EWithdrawalRequestBankAccountHolderDocumentNumberValidationError,
  EWithdrawalRequestBankAccountHolderEmailValidationError,
  EWithdrawalRequestBankAccountHolderFirstNameValidationError,
  EWithdrawalRequestBankAccountHolderLastNameValidationError,
  EWithdrawalRequestBankAccountHolderPhoneValidationError,
  EWithdrawalRequestBankAccountNumberValidationError,
  EWithdrawalRequestBankBranchCodeValidationError,
  EWithdrawalRequestCardAddressCityValidationError,
  EWithdrawalRequestCardAddressStreetValidationError,
  EWithdrawalRequestCardCurrencyValidationError,
  EWithdrawalRequestCardExpirationValidationError,
  EWithdrawalRequestCardHolderBirthdayValidationError,
  EWithdrawalRequestCardHolderFirstNameValidationError,
  EWithdrawalRequestCardHolderLastNameValidationError,
  EWithdrawalRequestCardNumberValidationError,
  EWithdrawalRequestEpayserviceNumberValidationError,
  EWithdrawalRequestError,
  EWithdrawalRequestQiwiNumberValidationError,
  EWithdrawalRequestStatus,
  EWithdrawalRequestWebmoneyNumberValidationError,
  EWithdrawalRequestYandexNumberValidationError,
  EWithdrawalRequestBitcoinNumberValidationError,
  EContactNameValidationError,
  EPurchaseAvailableCardVendor,
  EWithdrawalAvailableCardVendor,
  EWithdrawalRequestUsdtNumberValidationError,
  EWithdrawalConfirmationValidationError,
  EWithdrawalMethodDuration,
  EEmailConfirmationCodeValidationError,
  EWithdrawalAvailableUsdtFormat,
} from '@/enums';

import config from '@/config';
// eslint-disable-next-line import/no-cycle
import i18n from '@/i18n';

import IDailyStatisticsItemEntity from '@/core/entities/daily-statistics-item.entity';
import IPremiumPackageEntity from '@/core/entities/premium-package.entity';
import ITariffEntity from '@/core/entities/tariff.entity';
import IMessageEntity from '@/core/entities/message.entity';
import IVideoEntity from '@/core/entities/video.entity';
import IContactEntity from '@/core/entities/contact.entity';
import {
  IWithdrawalRequestPaymentSystemCard,
  IWithdrawalRequestPaymentSystemQiwi,
  IWithdrawalRequestPaymentSystemYandex,
  IWithdrawalRequestPaymentSystemBitcoin,
  IWithdrawalRequestPaymentSystemUsdt,
} from '@/core/entities/withdrawal-request.entity';
import IWithdrawalMethodEntity from '@/core/entities/withdrawal-method.entity';
import ITopLeadershipRecordEntity from '@/core/entities/top-leadership-record.entity';
import { getGiftTypeById, getRelativeDate } from '@/presenters';
import IAccountTransactionEntity from '@/core/entities/account-transaction.entity';
import IGiftEntity from '@/core/entities/gift.entity';
import IDeviceEntity from '@/core/entities/device.entity';
import IMinutesPackageEntity from '@/core/entities/minutes-package.entity';
import IViewmodel from '@/core/interactors/types/viewmodels';
import RecorderRepository from '@/repositories/recorder-repository';
import PushNotificationRepository from '@/repositories/push-notification-repository';
import ICardEntity from '@/core/entities/card.entity';
import IPurchaseGatewayEntity from '@/core/entities/purchase-gateway.entity';
import IAvatarEntity from '@/core/entities/avatar.entity';
import ICompanionEntity from '@/core/entities/companion.entity';
import IPaymentSystemEntity from '@/core/entities/payment-system.entity';
import IMessengerBotCommandsEntity from '@/core/entities/messenger-bot-commands.entity';

const getters: GetterTree<IViewmodel, IViewmodel> = {
  isRusVideoDatingApp(state): boolean {
    return state.entities.environment.origin === config.rusVideoDatingMainAppLink;
  },

  userGender(state): TGender {
    if (state.entities.user.gender === undefined) {
      return 'male';
    }

    return state.entities.user.gender === EGender.FEMALE ? 'female' : 'male';
  },

  companionGender(state): TGender {
    return state.entities.user.gender === EGender.FEMALE ? 'male' : 'female';
  },

  videochatCompanionRate(state): TCompanionRate | undefined {
    const videochatCompanion = state.entities.videochat.companion;

    if (videochatCompanion === null || videochatCompanion.rate === undefined) {
      return undefined;
    }

    switch (videochatCompanion.rate) {
      case ECompanionRate.ONE:
        return 'angry';
      case ECompanionRate.TWO:
        return 'displeased';
      case ECompanionRate.THREE:
        return 'neutral';
      case ECompanionRate.FOUR:
        return 'satisfied';
      case ECompanionRate.FIVE:
        return 'happy';
      default:
        throw new Error(`unknown videochat companion rate ${videochatCompanion.rate}`);
    }
  },

  companionRateList(): TCompanionRate[] {
    return [
      'angry',
      'displeased',
      'neutral',
      'satisfied',
      'happy',
    ];
  },

  companionReviewReasons(): TCompanionReviewReason[] {
    return [
      'rudeness',
      'deception',
      'child',
      'other',
    ];
  },

  userId(state): number | undefined {
    return state.entities.user.id;
  },

  referralLink(state): string {
    if (state.entities.user.id === undefined) {
      throw new Error('user id cannot be undefined');
    }

    return config.referralLink.replace('%ID%', `${state.entities.user.id}`);
  },

  hasUserAuthorization(state): boolean {
    return state.entities.user.registrationStatus === ERegistrationStatus.AUTHORIZED;
  },

  userSocials(state): TSocial[] {
    const result: TSocial[] = [];

    state.entities.user.socials.forEach((item: ESocial) => {
      switch (item) {
        case ESocial.MAILRU:
          result.push('mailru');

          break;
        case ESocial.VKONTAKTE:
          result.push('vkontakte');

          break;
        case ESocial.YANDEX:
          result.push('yandex');

          break;
        case ESocial.GOOGLE:
          result.push('google');

          break;
        case ESocial.FACEBOOK:
          result.push('facebook');

          break;
        case ESocial.MSN:
          result.push('msn');

          break;
        default:
          throw new Error(`unsupported social network type ${item}`);
      }
    });

    return result;
  },

  localStream(state): MediaStream | null {
    return state.entities.streams.local;
  },

  remoteStream(state): MediaStream | null {
    return state.entities.streams.remote;
  },

  currentTopPlace(state): number | undefined {
    return state.entities.user.femaleCurrentTopPlace;
  },

  currentNextTopPlace(state): number | undefined {
    const currentTopPlace = state.entities.user.femaleCurrentTopPlace;

    if (currentTopPlace === undefined) {
      return undefined;
    }

    return currentTopPlace > config.topStartingPlace
      ? config.topStartingPlace
      : currentTopPlace - 1;
  },

  currentPointsToNextTopPlace(state): number | undefined {
    return state.entities.user.femaleCurrentPointsToNextTopPlace;
  },

  topCountdown(state): number | undefined {
    const { top } = state.entities;

    if (top === null) {
      throw new Error('top is null');
    }

    return top.countdown;
  },

  topLeaders(state): TTopLeader[] {
    const { top } = state.entities;

    if (top === null) {
      throw new Error('top is null');
    }

    const leaders = Object.values(top.leaders.list);

    return leaders.map((leader) => {
      let changeDirection: TTopChangeDirection | undefined;

      switch (leader.changeDirection) {
        case ETopChangeDirection.UP:
          changeDirection = 'up';

          break;
        case ETopChangeDirection.DOWN:
          changeDirection = 'down';

          break;
        case ETopChangeDirection.NO_CHANGE:
          changeDirection = 'no-change';

          break;
        default:
          throw new Error(`unknown top place change direction ${leader.changeDirection}`);
      }

      return { ...leader, changeDirection };
    });
  },

  topNextPlace(state): number | undefined {
    const { top } = state.entities;

    if (top === null) {
      throw new Error('top is null');
    }

    const userLeader = Object.values(top.leaders.list).find((leader) => leader.isCurrentUser);

    return userLeader === undefined ? Object.values(top.leaders.list).length : userLeader.place - 1;
  },

  pointsToTopNextPlace(state): number | undefined {
    const { top } = state.entities;

    if (top === null) {
      throw new Error('top is null');
    }

    return top.pointsToNextPlace;
  },

  isTopDayLeaderAttentionDisplayed(state) {
    return state.ui.flags.isTopDayLeaderAttentionDisplayed;
  },

  isTopWeekLeaderAttentionDisplayed(state) {
    return state.ui.flags.isTopWeekLeaderAttentionDisplayed;
  },

  isTopMonthLeaderAttentionDisplayed(state) {
    return state.ui.flags.isTopMonthLeaderAttentionDisplayed;
  },

  topDayLeaderPlace(state): number | undefined {
    const { top } = state.entities;

    if (top === null) {
      throw new Error('top is null');
    }

    const record = Object.values(top.leadershipRecords.list).find(
      (item: ITopLeadershipRecordEntity) => item.period === ETopPeriod.DAY,
    );

    if (record === undefined) {
      return undefined;
    }

    return record.place;
  },

  topWeekLeaderPlace(state): number | undefined {
    const { top } = state.entities;

    if (top === null) {
      throw new Error('top is null');
    }

    const record = Object.values(top.leadershipRecords.list).find(
      (item: ITopLeadershipRecordEntity) => item.period === ETopPeriod.WEEK,
    );

    if (record === undefined) {
      return undefined;
    }

    return record.place;
  },

  topMonthLeaderPlace(state): number | undefined {
    const { top } = state.entities;

    if (top === null) {
      throw new Error('top is null');
    }

    const record = Object.values(top.leadershipRecords.list).find(
      (item: ITopLeadershipRecordEntity) => item.period === ETopPeriod.MONTH,
    );

    if (record === undefined) {
      return undefined;
    }

    return record.place;
  },

  topDayLeaderBonus(state): number | undefined {
    const { top } = state.entities;

    if (top === null) {
      throw new Error('top is null');
    }

    const record = Object.values(top.leadershipRecords.list).find(
      (item: ITopLeadershipRecordEntity) => item.period === ETopPeriod.DAY,
    );

    if (record === undefined) {
      return undefined;
    }

    return record.bonus;
  },

  topWeekLeaderBonus(state): number | undefined {
    const { top } = state.entities;

    if (top === null) {
      throw new Error('top is null');
    }

    const record = Object.values(top.leadershipRecords.list).find(
      (item: ITopLeadershipRecordEntity) => item.period === ETopPeriod.WEEK,
    );

    if (record === undefined) {
      return undefined;
    }

    return record.bonus;
  },

  topMonthLeaderBonus(state): number | undefined {
    const { top } = state.entities;

    if (top === null) {
      throw new Error('top is null');
    }

    const record = Object.values(top.leadershipRecords.list).find(
      (item: ITopLeadershipRecordEntity) => item.period === ETopPeriod.MONTH,
    );

    if (record === undefined) {
      return undefined;
    }

    return record.bonus;
  },

  videochatPageStage(state): TVideochatPageStage {
    if (state.ui.flags.videochatPageStage === EVideochatPageStage.START) {
      return 'start';
    }

    if (state.ui.flags.videochatPageStage === EVideochatPageStage.PROCESS) {
      return 'process';
    }

    if (state.ui.flags.videochatPageStage === EVideochatPageStage.CALL) {
      return 'call';
    }

    return 'finish';
  },

  videochatHistorySorting(state): TVideochatHistorySorting {
    if (state.entities.settings.videochatHistorySorting === EVideochatHistorySorting.DURATION) {
      return 'duration';
    }

    if (state.entities.settings.videochatHistorySorting === EVideochatHistorySorting.RECENT) {
      return 'recent';
    }

    if (state.entities.settings.videochatHistorySorting === EVideochatHistorySorting.ONLINE) {
      return 'online';
    }

    throw new Error(`unknown history sorting: ${state.entities.settings.videochatHistorySorting}`);
  },

  videochatHistorySortings(): TVideochatHistorySorting[] {
    return [
      'duration',
      'recent',
      'online',
    ];
  },

  companions(state): TCompanion[] {
    const { videochatHistorySorting } = state.entities.settings;

    let companions = Object.values(state.entities.videochatHistory.list);

    if (videochatHistorySorting === EVideochatHistorySorting.DURATION) {
      companions.sort((a, b) => {
        if (
          a.communicationDurationTime === undefined
          || b.communicationDurationTime === undefined
        ) {
          throw new Error('communication duration time is undefined');
        }

        if (a.communicationDurationTime > b.communicationDurationTime) {
          return -1;
        }

        if (a.communicationDurationTime < b.communicationDurationTime) {
          return 1;
        }

        return 0;
      });
    } else if (videochatHistorySorting === EVideochatHistorySorting.RECENT) {
      companions.sort((a, b) => {
        if (
          a.lastCommunicationTime === undefined
          || b.lastCommunicationTime === undefined
        ) {
          throw new Error('last communication time is undefined');
        }

        if (a.lastCommunicationTime > b.lastCommunicationTime) {
          return -1;
        }

        if (a.lastCommunicationTime < b.lastCommunicationTime) {
          return 1;
        }

        return 0;
      });
    } else {
      companions = companions.filter((a) => a.isOnline);
    }

    return companions.map((companion) => {
      if (companion.id === undefined) {
        throw new Error('companion id is undefined');
      }

      if (companion.name === undefined) {
        throw new Error('companion name is undefined');
      }

      let moderationStatus: TCompanionModerationStatus | undefined;

      if (state.entities.user.role === EUserRole.MODERATOR) {
        if (companion.moderationStatus === ECompanionModerationStatus.NONE) {
          moderationStatus = 'none';
        } else if (companion.moderationStatus === ECompanionModerationStatus.APPROVED) {
          moderationStatus = 'approved';
        } else if (companion.moderationStatus === ECompanionModerationStatus.REJECTED) {
          moderationStatus = 'rejected';
        } else {
          throw new Error(`unknown companion moderation status: ${companion.moderationStatus}`);
        }
      }

      return {
        moderationStatus,
        id: companion.id,
        name: companion.name,
        avatar: companion.avatar === null ? undefined : companion.avatar.medium,
        isOnline: !!companion.isOnline,
        isContact: !!companion.isContact,
        isContactRequestAccepted:
          companion.contactStatus === ECompanionContactStatus.REQUEST_ACCEPTED,
        isPremium: !!companion.isPremium,
        isModerator: companion.isModerator,
        hasStory: companion.hasStory,
        isStoryViewed: companion.isStoryViewed,
      };
    });
  },

  isVideochatHistoryDisplayed(state): boolean {
    /**
     * Блок истории может показываться без контактов в ней, в случае если выбрана сортировка
     * "Только онлайн" и ни одного пользователя нет онлайн, поэтому нужно проверять реальное кол-во
     * контактов в истории, а не отсортированный массив. Поэтому существует этот геттер
     */
    return Object.keys(state.entities.videochatHistory.list).length !== 0;
  },

  isVideochatHistoryClearAttentionDisplayed(state) {
    return state.ui.flags.isVideochatHistoryClearAttentionDisplayed;
  },

  isCameraNotAvailableAttentionDisplayed(state) {
    return state.ui.flags.isCameraNotAvailableAttentionDisplayed;
  },

  isCameraNeedPermissionAttentionDisplayed(state) {
    return state.ui.flags.isCameraNeedPermissionAttentionDisplayed;
  },

  isUsageRulesAttentionDisplayed(state) {
    return state.ui.flags.isUsageRulesAttentionDisplayed;
  },

  userLockExpirationTime(state): number | undefined {
    return state.entities.user.lockExpirationTime;
  },

  isPasswordRecoveryAttentionDisplayed(state) {
    return state.ui.flags.isPasswordRecoveryAttentionDisplayed;
  },

  isPasswordRecoverySuccessAttentionDisplayed(state) {
    return state.ui.flags.isPasswordRecoverySuccessAttentionDisplayed;
  },

  passwordRecoveryEmail(state): string | undefined {
    return state.ui.buffer.passwordRecoveryEmail;
  },

  passwordRecoveryEmailValidationError(state): TUserEmailValidationError | undefined {
    if (
      state.ui.buffer.passwordRecoveryEmailValidationError === EUserEmailValidationError.INCORRECT
    ) {
      return 'incorrect';
    }

    if (
      state.ui.buffer.passwordRecoveryEmailValidationError
      === EUserEmailValidationError.WRONG_CREDENTIALS
    ) {
      return 'wrong-credentials';
    }

    return undefined;
  },

  isCompanionRateAfterRecoveryRequired(state): boolean {
    return !!state.ui.buffer.isCompanionRateAfterRecoveryRequired;
  },

  isSignInAttentionDisplayed(state) {
    return state.ui.flags.isSignInAttentionDisplayed;
  },

  isSignUpAttentionDisplayed(state) {
    return state.ui.flags.isSignUpAttentionDisplayed;
  },

  signInEmailValidationError(state): TUserEmailValidationError | undefined {
    if (state.ui.buffer.userSignInEmailValidationError === EUserEmailValidationError.EMPTY) {
      return 'empty';
    }

    if (state.ui.buffer.userSignInEmailValidationError === EUserEmailValidationError.INCORRECT) {
      return 'incorrect';
    }

    if (
      state.ui.buffer.userSignInEmailValidationError === EUserEmailValidationError.WRONG_CREDENTIALS
    ) {
      return 'wrong-credentials';
    }

    return undefined;
  },

  signInPasswordValidationError(state): TUserPasswordValidationError | undefined {
    if (state.ui.buffer.userSignInPasswordValidationError === EUserPasswordValidationError.EMPTY) {
      return 'empty';
    }

    if (state.ui.buffer.userSignInPasswordValidationError === EUserPasswordValidationError.SHORT) {
      return 'short';
    }

    return undefined;
  },

  companionAbuseId(state): number | undefined {
    return state.ui.buffer.companionAbuseId;
  },

  videochatCompanion(state): ICompanionEntity | null {
    return state.entities.videochat.companion;
  },

  isVideochatCompanionTyping(state): boolean {
    const videochatCompanion = state.entities.videochat.companion;

    if (videochatCompanion === null || videochatCompanion.isTyping === undefined) {
      return false;
    }

    return videochatCompanion.isTyping;
  },

  messagesForMinuteCount(state): number | undefined {
    return state.entities.user.maleNumberOfMessagesPerMinuteOfVideochat;
  },

  availableMessagesCount(state): number | undefined {
    return state.entities.user.maleNumberOfMessagesAvailableForSending;
  },

  hasMinutesAndNoPremium(state): boolean {
    return !state.entities.user.isMalePremium
      && state.entities.user.maleNumberOfMillisecondsRemaining !== undefined
      && state.entities.user.maleNumberOfMillisecondsRemaining > 0;
  },

  hasUserFewMinutes(state): boolean {
    const { user } = state.entities;

    return (
      user.maleNumberOfMillisecondsRemaining !== undefined
      && user.maleNumberOfMillisecondsRemaining <= 1000 * 60 * 5
      && user.maleMinutesPackages.autoPurchase === null
    );
  },

  isPremiumExtensionEnabled(state): boolean {
    return !!state.entities.user.isMalePremiumExtensionEnabled;
  },

  remainingMinutesCount(state): number {
    return state.entities.user.maleNumberOfMillisecondsRemaining !== undefined
      ? Math.floor(state.entities.user.maleNumberOfMillisecondsRemaining / (60 * 1000))
      : 0;
  },

  pointsCount(state): number | undefined {
    return state.entities.user.femalePoints;
  },

  cashForPoints(state): number {
    if (state.entities.user.femalePoints === undefined || state.entities.user.femalePoints <= 0) {
      return 0;
    }

    return parseFloat((state.entities.user.femalePoints / 100).toFixed(2));
  },

  withdrawalCurrencies(state): TCurrency[] {
    return state.entities.user.femaleWithdrawalCurrencies as TCurrency[];
  },

  withdrawalBankCodes(state): TWithdrawalBankCodes {
    return state.entities.user.withdrawalBankCodes;
  },

  withdrawalBankAccountTypes(state): TWithdrawalBankAccountTypes {
    return state.entities.user.withdrawalBankAccountTypes;
  },

  withdrawalRequestBankAccountType(state): keyof TWithdrawalBankAccountTypes | undefined {
    if (
      state.entities.withdrawalRequest === null
      || state.entities.withdrawalRequest.requisites === null
      || !('bankAccountType' in state.entities.withdrawalRequest.requisites)
    ) {
      return undefined;
    }

    return state.entities.withdrawalRequest.requisites.bankAccountType;
  },

  withdrawalRequestBankCode(state): string | undefined {
    if (
      state.entities.withdrawalRequest === null
      || state.entities.withdrawalRequest.requisites === null
      || !('bankCode' in state.entities.withdrawalRequest.requisites)
    ) {
      return undefined;
    }

    return state.entities.withdrawalRequest.requisites.bankCode;
  },

  withdrawalRequestBankBranchCode(state): string | undefined {
    if (
      state.entities.withdrawalRequest === null
      || state.entities.withdrawalRequest.requisites === null
      || !('bankBranchCode' in state.entities.withdrawalRequest.requisites)
    ) {
      return undefined;
    }

    return state.entities.withdrawalRequest.requisites.bankBranchCode;
  },

  withdrawalRequestBankAccountNumber(state): string | undefined {
    if (
      state.entities.withdrawalRequest === null
      || state.entities.withdrawalRequest.requisites === null
      || !('number' in state.entities.withdrawalRequest.requisites)
    ) {
      return undefined;
    }

    return state.entities.withdrawalRequest.requisites.number;
  },

  withdrawalDocumentTypes(state): TWithdrawalBankDocumentTypes {
    return state.entities.user.withdrawalDocumentTypes;
  },

  withdrawalBankAccountHolderDocumentType(state): string | undefined {
    if (
      state.entities.withdrawalRequest === null
      || state.entities.withdrawalRequest.requisites === null
      || !('bankAccountHolderDocumentType' in state.entities.withdrawalRequest.requisites)
    ) {
      return undefined;
    }

    return state.entities.withdrawalRequest.requisites.bankAccountHolderDocumentType;
  },

  withdrawalBankAccountHolderDocumentNumber(state): string | undefined {
    if (
      state.entities.withdrawalRequest === null
      || state.entities.withdrawalRequest.requisites === null
      || !('bankAccountHolderDocumentNumber' in state.entities.withdrawalRequest.requisites)
    ) {
      return undefined;
    }

    return state.entities.withdrawalRequest.requisites.bankAccountHolderDocumentNumber;
  },

  isWithdrawalRequestBankAccountHolderFirstNameRequired(state): boolean {
    const method = state.entities.user.withdrawalNewMethods.list.find(
      (item: IWithdrawalMethodEntity): boolean => item.type === EPaymentSystemType.BANK,
    );

    if (method === undefined || method.requiredRequisites === null) {
      return false;
    }

    return method.requiredRequisites.includes('bankAccountHolderFirstName');
  },

  isWithdrawalRequestBankAccountHolderLastNameRequired(state): boolean {
    const method = state.entities.user.withdrawalNewMethods.list.find(
      (item: IWithdrawalMethodEntity): boolean => item.type === EPaymentSystemType.BANK,
    );

    if (method === undefined || method.requiredRequisites === null) {
      return false;
    }

    return method.requiredRequisites.includes('bankAccountHolderLastName');
  },

  isWithdrawalRequestBankAccountNumberRequired(state): boolean {
    const method = state.entities.user.withdrawalNewMethods.list.find(
      (item: IWithdrawalMethodEntity): boolean => item.type === EPaymentSystemType.BANK,
    );

    if (method === undefined || method.requiredRequisites === null) {
      return false;
    }

    return method.requiredRequisites.includes('bankAccountNumber');
  },

  isWithdrawalRequestBankAccountHolderDocumentNumberRequired(state): boolean {
    const method = state.entities.user.withdrawalNewMethods.list.find(
      (item: IWithdrawalMethodEntity): boolean => item.type === EPaymentSystemType.BANK,
    );

    if (method === undefined || method.requiredRequisites === null) {
      return false;
    }

    return method.requiredRequisites.includes('bankAccountHolderDocumentNumber');
  },

  isWithdrawalRequestBankAccountHolderAddressRequired(state): boolean {
    const method = state.entities.user.withdrawalNewMethods.list.find(
      (item: IWithdrawalMethodEntity): boolean => item.type === EPaymentSystemType.BANK,
    );

    if (method === undefined || method.requiredRequisites === null) {
      return false;
    }

    return method.requiredRequisites.includes('bankAccountHolderAddress');
  },

  isWithdrawalRequestBankBranchCodeRequired(state): boolean {
    const method = state.entities.user.withdrawalNewMethods.list.find(
      (item: IWithdrawalMethodEntity): boolean => item.type === EPaymentSystemType.BANK,
    );

    if (method === undefined || method.requiredRequisites === null) {
      return false;
    }

    return method.requiredRequisites.includes('bankBranchCode');
  },

  isWithdrawalRequestBankAccountHolderPhoneRequired(state): boolean {
    const method = state.entities.user.withdrawalNewMethods.list.find(
      (item: IWithdrawalMethodEntity): boolean => item.type === EPaymentSystemType.BANK,
    );

    if (method === undefined || method.requiredRequisites === null) {
      return false;
    }

    return method.requiredRequisites.includes('bankAccountHolderPhone');
  },

  isWithdrawalRequestBankAccountHolderEmailRequired(state): boolean {
    const method = state.entities.user.withdrawalNewMethods.list.find(
      (item: IWithdrawalMethodEntity): boolean => item.type === EPaymentSystemType.BANK,
    );

    if (method === undefined || method.requiredRequisites === null) {
      return false;
    }

    return method.requiredRequisites.includes('bankAccountHolderEmail');
  },

  withdrawalRequestBankAccountHolderFirstName(state): string | undefined {
    if (
      state.entities.withdrawalRequest === null
      || state.entities.withdrawalRequest.requisites === null
      || !('bankAccountHolderFirstName' in state.entities.withdrawalRequest.requisites)
    ) {
      return undefined;
    }

    return state.entities.withdrawalRequest.requisites.bankAccountHolderFirstName;
  },

  withdrawalRequestBankAccountHolderLastName(state): string | undefined {
    if (
      state.entities.withdrawalRequest === null
      || state.entities.withdrawalRequest.requisites === null
      || !('bankAccountHolderLastName' in state.entities.withdrawalRequest.requisites)
    ) {
      return undefined;
    }

    return state.entities.withdrawalRequest.requisites.bankAccountHolderLastName;
  },

  withdrawalRequestBankAccountHolderAddress(state): string | undefined {
    if (
      state.entities.withdrawalRequest === null
      || state.entities.withdrawalRequest.requisites === null
      || !('bankAccountHolderAddress' in state.entities.withdrawalRequest.requisites)
    ) {
      return undefined;
    }

    return state.entities.withdrawalRequest.requisites.bankAccountHolderAddress;
  },

  withdrawalRequestBankAccountHolderPhone(state): string | undefined {
    if (
      state.entities.withdrawalRequest === null
      || state.entities.withdrawalRequest.requisites === null
      || !('bankAccountHolderPhone' in state.entities.withdrawalRequest.requisites)
    ) {
      return undefined;
    }

    return state.entities.withdrawalRequest.requisites.bankAccountHolderPhone;
  },

  withdrawalRequestBankAccountHolderEmail(state): string | undefined {
    if (
      state.entities.withdrawalRequest === null
      || state.entities.withdrawalRequest.requisites === null
      || !('bankAccountHolderEmail' in state.entities.withdrawalRequest.requisites)
    ) {
      return undefined;
    }

    return state.entities.withdrawalRequest.requisites.bankAccountHolderEmail;
  },

  withdrawalRequestBankAccountHolderAddressValidationError(
    state,
  ): TWithdrawalRequestBankAccountHolderAddressValidationError | undefined {
    const error = state.ui.buffer.withdrawalRequestBankAccountHolderAddressValidationError;

    if (error === EWithdrawalRequestBankAccountHolderAddressValidationError.EMPTY) {
      return 'empty';
    }

    if (error === EWithdrawalRequestBankAccountHolderAddressValidationError.INCORRECT) {
      return 'incorrect';
    }

    return undefined;
  },

  withdrawalRequestBankAccountHolderDocumentNumberValidationError(
    state,
  ): TWithdrawalRequestBankAccountHolderDocumentNumberValidationError | undefined {
    const error = state.ui.buffer.withdrawalRequestBankAccountHolderDocumentNumberValidationError;

    if (error === EWithdrawalRequestBankAccountHolderDocumentNumberValidationError.EMPTY) {
      return 'empty';
    }

    if (error === EWithdrawalRequestBankAccountHolderDocumentNumberValidationError.INCORRECT) {
      return 'incorrect';
    }

    return undefined;
  },

  withdrawalRequestBankAccountHolderFirstNameValidationError(
    state,
  ): TWithdrawalRequestBankAccountHolderFirstNameValidationError | undefined {
    const error = state.ui.buffer.withdrawalRequestBankAccountHolderFirstNameValidationError;

    if (error === EWithdrawalRequestBankAccountHolderFirstNameValidationError.EMPTY) {
      return 'empty';
    }

    if (error === EWithdrawalRequestBankAccountHolderFirstNameValidationError.INCORRECT) {
      return 'incorrect';
    }

    return undefined;
  },

  withdrawalRequestBankAccountHolderLastNameValidationError(
    state,
  ): TWithdrawalRequestBankAccountHolderLastNameValidationError | undefined {
    const error = state.ui.buffer.withdrawalRequestBankAccountHolderLastNameValidationError;

    if (error === EWithdrawalRequestBankAccountHolderLastNameValidationError.EMPTY) {
      return 'empty';
    }

    if (error === EWithdrawalRequestBankAccountHolderLastNameValidationError.INCORRECT) {
      return 'incorrect';
    }

    return undefined;
  },

  withdrawalRequestBankBranchCodeValidationError(
    state,
  ): TWithdrawalRequestBankBranchCodeValidationError | undefined {
    const error = state.ui.buffer.withdrawalRequestBankBranchCodeValidationError;

    if (error === EWithdrawalRequestBankBranchCodeValidationError.EMPTY) {
      return 'empty';
    }

    if (error === EWithdrawalRequestBankBranchCodeValidationError.INCORRECT) {
      return 'incorrect';
    }

    return undefined;
  },

  withdrawalRequestBankAccountNumberValidationError(
    state,
  ): TWithdrawalRequestBankAccountNumberValidationError | undefined {
    const error = state.ui.buffer.withdrawalRequestBankAccountNumberValidationError;

    if (error === EWithdrawalRequestBankAccountNumberValidationError.EMPTY) {
      return 'empty';
    }

    if (error === EWithdrawalRequestBankAccountNumberValidationError.INCORRECT) {
      return 'incorrect';
    }

    return undefined;
  },

  withdrawalRequestBankAccountHolderPhoneValidationError(
    state,
  ): TWithdrawalRequestBankAccountHolderPhoneValidationError | undefined {
    const error = state.ui.buffer.withdrawalRequestBankAccountHolderPhoneValidationError;

    if (error === EWithdrawalRequestBankAccountHolderPhoneValidationError.EMPTY) {
      return 'empty';
    }

    return undefined;
  },

  withdrawalRequestBankAccountHolderEmailValidationError(
    state,
  ): TWithdrawalRequestBankAccountHolderEmailValidationError | undefined {
    const error = state.ui.buffer.withdrawalRequestBankAccountHolderEmailValidationError;

    if (error === EWithdrawalRequestBankAccountHolderEmailValidationError.EMPTY) {
      return 'empty';
    }

    return undefined;
  },

  userAvatar(state): IAvatarEntity | null {
    return state.entities.user.avatar;
  },

  isUserNameEditFormDisplayed(state) {
    return state.ui.flags.isUserNameEditFormDisplayed;
  },

  userName(state) {
    return state.entities.user.name;
  },

  isPayPalEnabled(state): boolean {
    if (config.isPayPalForceEnabled) {
      return true;
    }

    if (state.entities.environment.origin !== undefined) {
      return config.payPalAllowedUrls.includes(state.entities.environment.origin);
    }

    return false;
  },

  userNameValidationError(state): TUserNameValidationError | undefined {
    if (state.ui.buffer.userProfileNameValidationError === EUserNameValidationError.EMPTY) {
      return 'empty';
    }

    if (state.ui.buffer.userProfileNameValidationError === EUserNameValidationError.SHORT) {
      return 'short';
    }

    if (
      state.ui.buffer.userProfileNameValidationError === EUserNameValidationError.CONTAINS_EMOJI
    ) {
      return 'contains-emoji';
    }

    return undefined;
  },

  isLogoutAttentionDisplayed(state): boolean {
    return state.ui.flags.isUserLogoutAttentionDisplayed;
  },

  isWithdrawalCardRequisitesAttentionDisplayed(state) {
    return state.ui.flags.isWithdrawalCardRequisitesAttentionDisplayed;
  },

  isWithdrawalCardHolderAttentionDisplayed(state) {
    return state.ui.flags.isWithdrawalCardHolderAttentionDisplayed;
  },

  isWithdrawalCardAddressAttentionDisplayed(state) {
    return state.ui.flags.isWithdrawalCardAddressAttentionDisplayed;
  },

  isWithdrawalRequestErrorAttentionDisplayed(state) {
    return state.ui.flags.isWithdrawalRequestErrorAttentionDisplayed;
  },

  withdrawalRequestCardHolderFirstNameValidationError(
    state,
  ): TWithdrawalRequestCardHolderFirstNameValidationError | undefined {
    if (
      state.ui.buffer.withdrawalRequestCardHolderFirstNameValidationError
      === EWithdrawalRequestCardHolderFirstNameValidationError.EMPTY
    ) {
      return 'empty';
    }

    if (
      state.ui.buffer.withdrawalRequestCardHolderFirstNameValidationError
      === EWithdrawalRequestCardHolderFirstNameValidationError.INCORRECT
    ) {
      return 'incorrect';
    }

    return undefined;
  },

  withdrawalRequestCardHolderLastNameValidationError(
    state,
  ): TWithdrawalRequestCardHolderLastNameValidationError | undefined {
    if (
      state.ui.buffer.withdrawalRequestCardHolderLastNameValidationError
      === EWithdrawalRequestCardHolderLastNameValidationError.EMPTY
    ) {
      return 'empty';
    }

    if (
      state.ui.buffer.withdrawalRequestCardHolderLastNameValidationError
      === EWithdrawalRequestCardHolderLastNameValidationError.INCORRECT
    ) {
      return 'incorrect';
    }

    return undefined;
  },

  withdrawalRequestCardHolderBirthdayValidationError(
    state,
  ): TWithdrawalRequestCardHolderBirthdayValidationError | undefined {
    if (
      state.ui.buffer.withdrawalRequestCardHolderBirthdayValidationError
      === EWithdrawalRequestCardHolderBirthdayValidationError.EMPTY
    ) {
      return 'empty';
    }

    if (
      state.ui.buffer.withdrawalRequestCardHolderBirthdayValidationError
      === EWithdrawalRequestCardHolderBirthdayValidationError.INCORRECT
    ) {
      return 'incorrect';
    }

    if (
      state.ui.buffer.withdrawalRequestCardHolderBirthdayValidationError
      === EWithdrawalRequestCardHolderBirthdayValidationError.YOUNG
    ) {
      return 'young';
    }

    return undefined;
  },

  withdrawalRequestCardNumberValidationError(
    state,
  ): TWithdrawalRequestCardNumberValidationError | undefined {
    if (
      state.ui.buffer.withdrawalRequestCardNumberValidationError
      === EWithdrawalRequestCardNumberValidationError.EMPTY
    ) {
      return 'empty';
    }

    if (
      state.ui.buffer.withdrawalRequestCardNumberValidationError
      === EWithdrawalRequestCardNumberValidationError.INCORRECT
    ) {
      return 'incorrect';
    }

    if (
      state.ui.buffer.withdrawalRequestCardNumberValidationError
      === EWithdrawalRequestCardNumberValidationError.WRONG_EMITTER_COUNTRY
    ) {
      return 'wrong-emitter-country';
    }

    return undefined;
  },

  withdrawalRequestCardCurrencyValidationError(
    state,
  ): TWithdrawalRequestCardCurrencyValidationError | undefined {
    if (
      state.ui.buffer.withdrawalRequestCardCurrencyValidationError
      === EWithdrawalRequestCardCurrencyValidationError.EMPTY
    ) {
      return 'empty';
    }

    if (
      state.ui.buffer.withdrawalRequestCardCurrencyValidationError
      === EWithdrawalRequestCardCurrencyValidationError.INCORRECT
    ) {
      return 'incorrect';
    }

    return undefined;
  },

  withdrawalRequestCardExpirationValidationError(
    state,
  ): TWithdrawalRequestCardExpirationValidationError | undefined {
    if (
      state.ui.buffer.withdrawalRequestCardExpirationValidationError
      === EWithdrawalRequestCardExpirationValidationError.EMPTY
    ) {
      return 'empty';
    }

    if (
      state.ui.buffer.withdrawalRequestCardExpirationValidationError
      === EWithdrawalRequestCardExpirationValidationError.INCORRECT
    ) {
      return 'incorrect';
    }

    return undefined;
  },

  withdrawalRequestCardAddressStreetValidationError(
    state,
  ): TWithdrawalRequestCardAddressStreetValidationError | undefined {
    if (
      state.ui.buffer.withdrawalRequestCardAddressStreetValidationError
      === EWithdrawalRequestCardAddressStreetValidationError.EMPTY
    ) {
      return 'empty';
    }

    return undefined;
  },

  withdrawalRequestCardAddressCityValidationError(
    state,
  ): TWithdrawalRequestCardAddressCityValidationError | undefined {
    if (
      state.ui.buffer.withdrawalRequestCardAddressCityValidationError
      === EWithdrawalRequestCardAddressCityValidationError.EMPTY
    ) {
      return 'empty';
    }

    return undefined;
  },

  withdrawalRequestError(state): TWithdrawalRequestError | undefined {
    const { withdrawalRequestError } = state.ui.buffer;

    if (withdrawalRequestError === undefined) {
      return undefined;
    }

    switch (withdrawalRequestError) {
      case EWithdrawalRequestError.WRONG_DATA:
        return 'wrong-data';
      case EWithdrawalRequestError.WRONG_GENDER:
        return 'wrong-gender';
      case EWithdrawalRequestError.SERVER_ERROR:
        return 'server-error';
      case EWithdrawalRequestError.ACCESS_RESTRICTED:
        return 'access-restricted';
      case EWithdrawalRequestError.DATABASE_API_ERROR:
        return 'database-api-error';
      case EWithdrawalRequestError.BILLING_ERROR:
        return 'billing-error';
      case EWithdrawalRequestError.MANUAL_ONLY:
        return 'manual-only';
      case EWithdrawalRequestError.NO_ACTIVE_REQUEST:
        return 'no-active-request';
      case EWithdrawalRequestError.REQUEST_EXISTS:
        return 'request-exists';
      case EWithdrawalRequestError.LOW_POINTS:
        return 'low-points';
      case EWithdrawalRequestError.GENERAL_ERROR:
        return 'general-error';
      case EWithdrawalRequestError.GUEST_ACTION:
        return 'guest-action';
      default:
        throw new Error(`unknown withdrawal error: ${withdrawalRequestError}`);
    }
  },

  withdrawalRequestMinPoints(state): string | undefined {
    return state.ui.buffer.withdrawalRequestMinPoints;
  },

  withdrawalMethodForRemoval(state): IWithdrawalMethodEntity | undefined {
    return state.ui.buffer.withdrawalMethodForRemoval;
  },

  withdrawalNewMethods(state): TWithdrawalMethod[] {
    const {
      withdrawalNewMethods,
      femaleWithdrawalAvailableCardVendors,
      femaleWithdrawalAvailableUsdtFormats,
    } = state.entities.user;

    return withdrawalNewMethods.list.map((item) => {
      let duration: TWithdrawalMethodDuration | undefined;

      if (item.duration !== undefined) {
        switch (item.duration) {
          case EWithdrawalMethodDuration.SHORT:
            duration = 'short';

            break;
          case EWithdrawalMethodDuration.MEDIUM:
            duration = 'medium';

            break;
          case EWithdrawalMethodDuration.TWO_DAYS:
            duration = 'two-days';

            break;
          default:
            throw new Error('duration is undefined');
        }
      }

      switch (item.type) {
        case EPaymentSystemType.CARD:
          return {
            duration,
            type: 'card',
            icons: femaleWithdrawalAvailableCardVendors.map((cardVendor: EWithdrawalAvailableCardVendor) => {
              switch (cardVendor) {
                case EWithdrawalAvailableCardVendor.VISA:
                  return 'visa';
                case EWithdrawalAvailableCardVendor.MASTER_CARD:
                  return 'master-card';
                default:
                  throw new Error(`unknown card vendor: ${cardVendor}`);
              }
            }),
            minimumPoints: item.minimumPoints,
            isSaved: false,
          };
        case EPaymentSystemType.BANK:
          return {
            duration,
            type: 'bank',
            icons: ['bank'],
            minimumPoints: item.minimumPoints,
            isSaved: false,
          };
        case EPaymentSystemType.EPSWALLET:
          return {
            duration,
            type: 'e-pay-service',
            icons: ['epayservice'],
            minimumPoints: item.minimumPoints,
            isSaved: false,
          };
        case EPaymentSystemType.YANDEX:
          return {
            duration,
            type: 'yandex-money',
            icons: ['yandex-money'],
            minimumPoints: item.minimumPoints,
            isSaved: false,
          };
        case EPaymentSystemType.WEBMONEY:
          return {
            duration,
            type: 'web-money',
            icons: ['webmoney'],
            minimumPoints: item.minimumPoints,
            isSaved: false,
          };
        case EPaymentSystemType.QIWI:
          return {
            duration,
            type: 'qiwi',
            icons: ['qiwi'],
            minimumPoints: item.minimumPoints,
            isSaved: false,
          };
        case EPaymentSystemType.BITCOIN:
          return {
            duration,
            type: 'bitcoin',
            icons: ['bitcoin'],
            minimumPoints: item.minimumPoints,
            isSaved: false,
          };
        case EPaymentSystemType.USDT:
          return {
            duration,
            type: 'usdt',
            icons: ['usdt'],
            minimumPoints: item.minimumPoints,
            isSaved: false,
            usdtFormats: femaleWithdrawalAvailableUsdtFormats.map((format: EWithdrawalAvailableUsdtFormat) => {
              switch (format) {
                case EWithdrawalAvailableUsdtFormat.TRC20:
                  return 'TRC-20';
                case EWithdrawalAvailableUsdtFormat.ERC20:
                  return 'ERC-20';
                default:
                  throw new Error(`unknown usdt format: ${format}`);
              }
            }),
          };
        default:
          throw new Error(`unknown payment system type: ${item.type}`);
      }
    });
  },

  withdrawalSavedMethods(state): TWithdrawalMethod[] | undefined {
    const {
      femaleWithdrawalPaymentSystems,
      withdrawalSavedMethods,
    } = state.entities.user;

    if (
      femaleWithdrawalPaymentSystems.list.length === 0
      || withdrawalSavedMethods.list.length === 0
    ) {
      return undefined;
    }

    const result = withdrawalSavedMethods.list.map((item: IWithdrawalMethodEntity) => {
      let icons: TAppIconPaymentName[];

      let type: TPaymentSystemType;

      let title;

      let minimumPoints;

      if (item.title !== undefined) {
        title = item.title.slice(-4);
      }

      switch (item.type) {
        case EPaymentSystemType.CARD:
          type = 'card';

          title = `**** **** **** ${title}`;

          switch (item.cardVendor) {
            case ECardVendor.VISA:
              icons = ['visa'];

              break;
            case ECardVendor.MASTER_CARD:
              icons = ['master-card'];

              break;
            case ECardVendor.MAESTRO:
              icons = ['maestro'];

              break;
            case ECardVendor.JCB:
              icons = ['jcb'];

              break;
            case ECardVendor.DISCOVER:
              icons = ['discover'];

              break;
            case ECardVendor.AMERICAN_EXPRESS:
              icons = ['american-express'];

              break;
            case ECardVendor.DINERS_CLUB:
              icons = ['diners-club'];

              break;
            case ECardVendor.MIR:
              icons = ['mir'];

              break;
            case ECardVendor.UNKNOWN:
              icons = ['unknown'];

              break;
            default:
              throw new Error(`unknown card vendor: ${item.cardVendor}`);
          }
          break;
        case EPaymentSystemType.BANK:
          type = 'bank';

          icons = ['bank'];

          title = item.title;

          break;
        case EPaymentSystemType.EPSWALLET:
          type = 'e-pay-service';

          icons = ['epayservice'];

          title = `**** **** **** ${title}`;

          break;
        case EPaymentSystemType.YANDEX:
          type = 'yandex-money';

          icons = ['yandex-money'];

          title = `**** **** ${title}`;

          break;
        case EPaymentSystemType.WEBMONEY:
          type = 'web-money';

          icons = ['webmoney'];

          title = `**** **** ${title}`;

          break;
        case EPaymentSystemType.QIWI:
          type = 'qiwi';

          icons = ['qiwi'];

          title = `**** **** ${title}`;

          break;
        case EPaymentSystemType.BITCOIN:
          type = 'bitcoin';

          icons = ['bitcoin'];

          title = item.title;

          break;
        case EPaymentSystemType.USDT:
          type = 'usdt';

          icons = ['usdt'];

          title = item.title;

          break;
        default:
          throw new Error(`unknown payment system type: ${item.type}`);
      }

      if (item.minimumPoints !== undefined) {
        ({ minimumPoints } = item);
      }

      return {
        type,
        icons,
        title,
        minimumPoints,
        duration: undefined,
        isSaved: true,
      };
    });

    return result.length > 0 ? result : undefined;
  },

  purchaseAvailablePaymentSystems(
    state,
    { isPayPalEnabled },
  ): TPaymentSystem[] {
    const { malePurchasePaymentSystems } = state.entities.user;

    if (malePurchasePaymentSystems.list.length === 0) {
      return [];
    }

    const paymentSystems: TPaymentSystem[] = [];

    malePurchasePaymentSystems.list.forEach((paymentSystem: IPaymentSystemEntity) => {
      switch (paymentSystem.type) {
        case EPaymentSystemType.CARD:
          state.entities.user.malePurchaseAvailableCardVendors.forEach((cardVendor: EPurchaseAvailableCardVendor) => {
            switch (cardVendor) {
              case EPurchaseAvailableCardVendor.VISA:
                paymentSystems.push({
                  type: 'card',
                  icons: ['visa'],
                });

                break;
              case EPurchaseAvailableCardVendor.MASTER_CARD:
                paymentSystems.push({
                  type: 'card',
                  icons: ['master-card'],
                });

                break;
              case EPurchaseAvailableCardVendor.MAESTRO:
                paymentSystems.push({
                  type: 'card',
                  icons: ['maestro'],
                });

                break;
              case EPurchaseAvailableCardVendor.MIR:
                paymentSystems.push({
                  type: 'card',
                  icons: ['mir'],
                });

                break;
              case EPurchaseAvailableCardVendor.JCB:
                paymentSystems.push({
                  type: 'card',
                  icons: ['jcb'],
                });

                break;
              case EPurchaseAvailableCardVendor.DISCOVER:
                paymentSystems.push({
                  type: 'card',
                  icons: ['discover'],
                });

                break;
              case EPurchaseAvailableCardVendor.DINERS_CLUB:
                paymentSystems.push({
                  type: 'card',
                  icons: ['diners-club'],
                });

                break;
              case EPurchaseAvailableCardVendor.AMERICAN_EXPRESS:
                paymentSystems.push({
                  type: 'card',
                  icons: ['american-express'],
                });

                break;
              default:
                throw new Error(`unknown card vendor: ${cardVendor}`);
            }
          });

          break;
        case EPaymentSystemType.PAYPAL:
          if (isPayPalEnabled) {
            paymentSystems.push({
              type: 'paypal',
              icons: ['paypal'],
            });
          }

          break;
        case EPaymentSystemType.BITCOIN:
          paymentSystems.push({
            type: 'bitcoin',
            icons: ['bitcoin'],
          });

          break;
        case EPaymentSystemType.SBP:
          paymentSystems.push({
            type: 'sbp',
            icons: ['sbp'],
          });

          break;
        default:
          break;
      }
    });

    return paymentSystems;
  },

  availableCardVendors(state): TPaymentCardVendor[] {
    return state.entities.user.malePurchaseAvailableCardVendors.map((cardVendor: EPurchaseAvailableCardVendor) => {
      switch (cardVendor) {
        case EPurchaseAvailableCardVendor.VISA:
          return 'visa';
        case EPurchaseAvailableCardVendor.MASTER_CARD:
          return 'master-card';
        case EPurchaseAvailableCardVendor.MAESTRO:
          return 'maestro';
        case EPurchaseAvailableCardVendor.MIR:
          return 'mir';
        case EPurchaseAvailableCardVendor.JCB:
          return 'jcb';
        case EPurchaseAvailableCardVendor.DISCOVER:
          return 'jcb';
        case EPurchaseAvailableCardVendor.DINERS_CLUB:
          return 'diners-club';
        case EPurchaseAvailableCardVendor.AMERICAN_EXPRESS:
          return 'american-express';
        default:
          throw new Error(`unknown card vendor: ${cardVendor}`);
      }
    });
  },

  withdrawalCardNumber(state): string | undefined {
    if (
      state.entities.withdrawalRequest === null
      || state.entities.withdrawalRequest.requisites === null
    ) {
      return undefined;
    }

    return state.entities.withdrawalRequest.requisites.number;
  },

  withdrawalCardCurrency(state): TCurrency | undefined {
    if (
      state.entities.withdrawalRequest === null
      || state.entities.withdrawalRequest.requisites === null
    ) {
      return undefined;
    }

    return (
      state.entities.withdrawalRequest.requisites as IWithdrawalRequestPaymentSystemCard
    ).currency as TCurrency;
  },

  withdrawalCardExpiration(state): string | undefined {
    if (
      state.entities.withdrawalRequest === null
      || state.entities.withdrawalRequest.requisites === null
    ) {
      return undefined;
    }

    return (
      state.entities.withdrawalRequest.requisites as IWithdrawalRequestPaymentSystemCard
    ).expiration;
  },

  withdrawalCardHolderFirstName(state): string | undefined {
    if (
      state.entities.withdrawalRequest === null
      || state.entities.withdrawalRequest.requisites === null
    ) {
      return undefined;
    }

    return (
      state.entities.withdrawalRequest.requisites as IWithdrawalRequestPaymentSystemCard
    ).holderFirstName;
  },

  withdrawalCardHolderLastName(state): string | undefined {
    if (
      state.entities.withdrawalRequest === null
      || state.entities.withdrawalRequest.requisites === null
    ) {
      return undefined;
    }

    return (
      state.entities.withdrawalRequest.requisites as IWithdrawalRequestPaymentSystemCard
    ).holderLastName;
  },

  withdrawalCardHolderBirthday(state): string | undefined {
    if (
      state.entities.withdrawalRequest === null
      || state.entities.withdrawalRequest.requisites === null
    ) {
      return undefined;
    }

    return (
      state.entities.withdrawalRequest.requisites as IWithdrawalRequestPaymentSystemCard
    ).holderBirthday;
  },

  withdrawalCardAddressStreet(state): string | undefined {
    if (
      state.entities.withdrawalRequest === null
      || state.entities.withdrawalRequest.requisites === null
    ) {
      return undefined;
    }

    return (
      state.entities.withdrawalRequest.requisites as IWithdrawalRequestPaymentSystemCard
    ).addressStreet;
  },

  withdrawalCardAddressCity(state): string | undefined {
    if (
      state.entities.withdrawalRequest === null
      || state.entities.withdrawalRequest.requisites === null
    ) {
      return undefined;
    }

    return (
      state.entities.withdrawalRequest.requisites as IWithdrawalRequestPaymentSystemCard
    ).addressCity;
  },

  withdrawalCardAddressCountry(state): string | undefined {
    if (
      state.entities.withdrawalRequest === null
      || state.entities.withdrawalRequest.requisites === null
    ) {
      return undefined;
    }

    return (
      state.entities.withdrawalRequest.requisites as IWithdrawalRequestPaymentSystemCard
    ).addressCountry;
  },

  withdrawalCardAddressCountries(): TCardCountry[] {
    return Object.values(ECountry).map((code) => {
      const title = i18n.global.t(`APP_COUNTRY_${code}`) as string;

      return {
        code,
        title,
      };
    }).sort((a, b) => {
      if (a.title > b.title) {
        return 1;
      }

      if (a.title < b.title) {
        return -1;
      }

      return 0;
    });
  },

  isWithdrawalWebmoneyRequisitesAttentionDisplayed(state) {
    return state.ui.flags.isWithdrawalWebmoneyRequisitesAttentionDisplayed;
  },

  withdrawalRequestWebmoneyNumberValidationError(state):
    TWithdrawalRequestWebmoneyNumberValidationError | undefined {
    if (
      state.ui.buffer.withdrawalRequestWebmoneyNumberValidationError
      === EWithdrawalRequestWebmoneyNumberValidationError.EMPTY
    ) {
      return 'empty';
    }

    if (
      state.ui.buffer.withdrawalRequestWebmoneyNumberValidationError
      === EWithdrawalRequestWebmoneyNumberValidationError.INCORRECT
    ) {
      return 'incorrect';
    }

    return undefined;
  },

  withdrawalRequisitesNumber(state): string | undefined {
    const { withdrawalRequest } = state.entities;

    if (withdrawalRequest === null || withdrawalRequest.requisites === null) {
      return undefined;
    }

    if (
      withdrawalRequest.status === EWithdrawalRequestStatus.WAIT
      && withdrawalRequest.requisites.number !== undefined
    ) {
      const requisitesNumber = withdrawalRequest.requisites.number.slice(-4);

      switch (withdrawalRequest.type) {
        case EPaymentSystemType.CARD:
        case EPaymentSystemType.EPSWALLET:
          return `**** **** **** ${requisitesNumber}`;
        case EPaymentSystemType.YANDEX:
        case EPaymentSystemType.WEBMONEY:
        case EPaymentSystemType.QIWI:
        case EPaymentSystemType.BITCOIN:
        case EPaymentSystemType.USDT:
          return withdrawalRequest.requisites.number;
        case EPaymentSystemType.BANK:
          return withdrawalRequest.requisites.number.replace(/.(?=.{4,}$)/g, '*');
        default:
          throw new Error(`unknown payment system type: ${withdrawalRequest.type}`);
      }
    }

    return withdrawalRequest.requisites.number;
  },

  withdrawalPaymentSystemType(state): TPaymentSystemType | undefined {
    const { withdrawalRequest } = state.entities;

    if (withdrawalRequest === null || withdrawalRequest.type === undefined) {
      return undefined;
    }

    switch (withdrawalRequest.type) {
      case EPaymentSystemType.CARD:
        return 'card';
      case EPaymentSystemType.BANK:
        return 'bank';
      case EPaymentSystemType.EPSWALLET:
        return 'e-pay-service';
      case EPaymentSystemType.YANDEX:
        return 'yandex-money';
      case EPaymentSystemType.WEBMONEY:
        return 'web-money';
      case EPaymentSystemType.QIWI:
        return 'qiwi';
      case EPaymentSystemType.BITCOIN:
        return 'bitcoin';
      case EPaymentSystemType.USDT:
        return 'usdt';
      default:
        throw new Error(`unknown payment system type: ${withdrawalRequest.type}`);
    }
  },

  withdrawalMinimumPoints(): number {
    return config.withdrawalMinimumPoints;
  },

  isEmailConfirmationReminderAttentionDisplayed(state): boolean {
    return state.ui.flags.isUserEmailConfirmationReminderAttentionDisplayed;
  },

  premiumPackages(state): IPremiumPackageEntity[] {
    const { malePremiumPackages } = state.entities.user;

    if (Object.keys(malePremiumPackages.list).length === 0) {
      return [];
    }

    return Object.values(malePremiumPackages.list).sort((packageA, packageB) => {
      if (packageA.order > packageB.order) {
        return 1;
      }

      if (packageA.order < packageB.order) {
        return -1;
      }

      return 0;
    });
  },

  defaultSelectedPremiumPackageId(state, { premiumPackages }): IPremiumPackageEntity['id'] | undefined {
    if (premiumPackages.length === 0) {
      return undefined;
    }

    // Для премиума по умолчанию выбран первый пакет
    return premiumPackages[0].id;
  },

  isContactRequestDisabled(state): boolean {
    return state.ui.flags.isContactRequestDisabled;
  },

  isPremiumPurchaseAttentionDisplayed(state) {
    return state.ui.flags.isPremiumPurchaseAttentionDisplayed;
  },

  minutesPackages(state): IMinutesPackageEntity[] {
    const { maleMinutesPackages } = state.entities.user;

    if (Object.keys(maleMinutesPackages.list).length === 0) {
      return [];
    }

    // Правила показа пакетов минут, от дешевого к дорогому
    return Object.values(maleMinutesPackages.list).sort((packageA, packageB) => {
      if (packageA.price > packageB.price) {
        return 1;
      }

      return -1;
    });
  },

  highlightedMinutesPackageId(state, { minutesPackages }): IMinutesPackageEntity['id'] {
    if (minutesPackages.length === 0) {
      throw new Error('user male minutes packages is empty');
    }

    return minutesPackages[minutesPackages.length - 1].id;
  },

  isMinutesPurchaseAttentionDisplayed(state) {
    return state.ui.flags.isMinutesPurchaseAttentionDisplayed;
  },

  isMinutesAddedAttentionDisplayed(state) {
    return state.ui.flags.isMinutesAddedAttentionDisplayed;
  },

  isMinutesReducedAttentionDisplayed(state) {
    return state.ui.flags.isMinutesReducedAttentionDisplayed;
  },

  isPremiumAddedAttentionDisplayed(state) {
    return state.ui.flags.isPremiumAddedAttentionDisplayed;
  },

  isPurchaseRetryAttentionDisplayed(state) {
    return state.ui.flags.isPurchaseRetryAttentionDisplayed;
  },

  isPurchaseSuccessAttentionDisplayed(state) {
    return state.ui.flags.isPurchaseSuccessAttentionDisplayed;
  },

  isPurchaseErrorAttentionDisplayed(state) {
    return state.ui.flags.isPurchaseErrorAttentionDisplayed;
  },

  purchaseError(state): TPurchaseError | undefined {
    const { purchaseError } = state.ui.buffer;

    if (purchaseError === undefined) {
      return undefined;
    }

    switch (purchaseError) {
      case EPurchaseError.NO_FUNDS:
        return 'no-funds';
      case EPurchaseError.PAYMENT_FAILED:
        return 'payment-failed';
      case EPurchaseError.NO_FUNDS_BUY_CHEAPER:
        return 'no-funds-buy-cheaper';
      case EPurchaseError.TRIAL_UNAVAILABLE:
        return 'trial-unavailable';
      case EPurchaseError.NO_CARD:
        return 'no-card';
      case EPurchaseError.VERIFICATION_ERROR:
        return 'verification-error';
      case EPurchaseError.SUBSCRIPTION_FAILED:
        return 'subscription-failed';
      case EPurchaseError.PAYMENT_RESTRICTED:
        return 'payment-restricted';
      default:
        throw new Error(`unknown purchase error: ${purchaseError}`);
    }
  },

  isCompanionAbuseAttentionDisplayed(state) {
    return state.ui.flags.isCompanionAbuseAttentionDisplayed;
  },

  isEmailConfirmationAttentionDisplayed(state): boolean {
    return state.ui.flags.isUserEmailConfirmationAttentionDisplayed;
  },

  userEmail(state): string | undefined {
    return state.entities.user.email;
  },

  emailConfirmationStatus(state): TEmailConfirmationStatus | undefined {
    const { userEmailConfirmationStatus } = state.ui.buffer;

    if (userEmailConfirmationStatus === EUserEmailConfirmationStatus.INITIAL) {
      return 'initial';
    }

    if (userEmailConfirmationStatus === EUserEmailConfirmationStatus.LOADING) {
      return 'loading';
    }

    if (userEmailConfirmationStatus === EUserEmailConfirmationStatus.SUCCESS) {
      return 'success';
    }

    if (userEmailConfirmationStatus === EUserEmailConfirmationStatus.UNAVAILABLE_ERROR) {
      return 'unavailable-error';
    }

    if (userEmailConfirmationStatus === EUserEmailConfirmationStatus.ALREADY_EXISTS_ERROR) {
      return 'already-exists-error';
    }

    if (userEmailConfirmationStatus === EUserEmailConfirmationStatus.UNKNOWN_ERROR) {
      return 'unknown-error';
    }

    return undefined;
  },

  emailConfirmationValidationError(state): TUserEmailValidationError | undefined {
    if (state.ui.buffer.userEmailConfirmationValidationError === EUserEmailValidationError.EMPTY) {
      return 'empty';
    }

    if (
      state.ui.buffer.userEmailConfirmationValidationError === EUserEmailValidationError.INCORRECT
    ) {
      return 'incorrect';
    }

    return undefined;
  },

  avatarEditState(state): TUserAvatarEditState {
    if (state.ui.buffer.userAvatarEditState === EUserAvatarEditState.MAKE) {
      return 'make';
    }

    if (state.ui.buffer.userAvatarEditState === EUserAvatarEditState.MAKED) {
      return 'maked';
    }

    if (state.ui.buffer.userAvatarEditState === EUserAvatarEditState.SELECTED) {
      return 'selected';
    }

    return 'initial';
  },

  isAvatarUploadErrorAttentionDisplayed(state) {
    return state.ui.flags.isAvatarUploadErrorAttentionDisplayed;
  },

  isMessageSendErrorAttentionDisplayed(state) {
    return state.ui.flags.isMessageSendErrorAttentionDisplayed;
  },

  temporaryAvatar(state): string | undefined {
    return state.ui.buffer.userTemporaryAvatar;
  },

  isBanAttentionDisplayed(state): boolean {
    return state.ui.flags.isUserBanAttentionDisplayed;
  },

  userConfirmationEmail(state) {
    return state.entities.user.confirmationEmail;
  },

  isEmailChangeBlocked(state): boolean {
    return !!state.entities.user.isEmailChangeBlocked;
  },

  isSafeSearchEnabled(state) {
    return state.entities.settings.isSafeSearchEnabled;
  },

  userAgeRange(state): TUserAgeRange | undefined {
    return state.entities.user.ageRange;
  },

  ageRangesList(): TUserAgeRangeItem[] {
    return [
      {
        value: EUserAgeRange.YOUNG,
        from: 18,
        to: 24,
      },
      {
        value: EUserAgeRange.GROWN,
        from: 25,
        to: 34,
      },
      {
        value: EUserAgeRange.MIDDLE,
        from: 35,
        to: 44,
      },
      {
        value: EUserAgeRange.MATURE,
        from: 45,
      },
    ];
  },

  profileEmailValidationError(state): TUserEmailValidationError | undefined {
    if (state.ui.buffer.userProfileEmailValidationError === EUserEmailValidationError.EMPTY) {
      return 'empty';
    }

    if (state.ui.buffer.userProfileEmailValidationError === EUserEmailValidationError.INCORRECT) {
      return 'incorrect';
    }

    if (state.ui.buffer.userProfileEmailValidationError === EUserEmailValidationError.EXISTS) {
      return 'exists';
    }

    if (state.ui.buffer.userSignUpEmailValidationError === EUserEmailValidationError.FORBIDDEN) {
      return 'forbidden';
    }

    return undefined;
  },

  signUpEmailValidationError(state): TUserEmailValidationError | undefined {
    if (state.ui.buffer.userSignUpEmailValidationError === EUserEmailValidationError.EMPTY) {
      return 'empty';
    }

    if (state.ui.buffer.userSignUpEmailValidationError === EUserEmailValidationError.INCORRECT) {
      return 'incorrect';
    }

    if (state.ui.buffer.userSignUpEmailValidationError === EUserEmailValidationError.EXISTS) {
      return 'exists';
    }

    if (state.ui.buffer.userProfileEmailValidationError === EUserEmailValidationError.FORBIDDEN) {
      return 'forbidden';
    }

    return undefined;
  },

  signUpPasswordValidationError(state): TUserPasswordValidationError | undefined {
    if (state.ui.buffer.userSignUpPasswordValidationError === EUserPasswordValidationError.EMPTY) {
      return 'empty';
    }

    if (state.ui.buffer.userSignUpPasswordValidationError === EUserPasswordValidationError.SHORT) {
      return 'short';
    }

    return undefined;
  },

  signUpNameValidationError(state): TUserNameValidationError | undefined {
    if (state.ui.buffer.userSignUpNameValidationError === EUserNameValidationError.EMPTY) {
      return 'empty';
    }

    if (state.ui.buffer.userSignUpNameValidationError === EUserNameValidationError.SHORT) {
      return 'short';
    }

    if (state.ui.buffer.userSignUpNameValidationError === EUserNameValidationError.CONTAINS_EMOJI) {
      return 'contains-emoji';
    }

    return undefined;
  },

  isEmailChangedAttentionDisplayed(state): boolean {
    return state.ui.flags.isUserEmailHasChangedAttentionDisplayed;
  },

  isProfileSettingsFormInInitialState(state): boolean {
    return state.ui.flags.isUserProfileSettingsFormInInitialState;
  },

  isCompanionNoteAttentionDisplayed(state) {
    return state.ui.flags.isCompanionNoteAttentionDisplayed;
  },

  isContactNoteAttentionDisplayed(state) {
    return state.ui.flags.isContactNoteAttentionDisplayed;
  },

  isAccountTransactionsAttentionDisplayed(state) {
    return state.ui.flags.isAccountTransactionsAttentionDisplayed;
  },

  accountTransactions(state): TAccountTransaction[] {
    return Object.values(state.entities.accountTransactions.list)
      .sort((a: IAccountTransactionEntity, b: IAccountTransactionEntity) => (
        b.activityTime - a.activityTime
      ))
      .map((item) => {
        let activityType: TAccountTransactionActivityType | undefined;

        if (item.activityType !== undefined) {
          switch (item.activityType) {
            case EAccountTransactionActivityType.MALE_GOT_DAILY_MINUTES:
              activityType = 'male-got-daily-minutes';

              break;
            case EAccountTransactionActivityType.MALE_WON_GAME:
              activityType = 'male-won-game';

              break;
            case EAccountTransactionActivityType.MALE_SENT_GIFT:
              activityType = 'male-sent-gift';

              break;
            case EAccountTransactionActivityType.MALE_RECEIVED_GIFT:
              activityType = 'male-received-gift';

              break;
            case EAccountTransactionActivityType.MALE_SENT_MESSAGES:
              activityType = 'male-sent-messages';

              break;
            case EAccountTransactionActivityType.MALE_SPENT_ACCOUNT_FUNDS:
              activityType = 'male-spent-account-funds';

              break;
            case EAccountTransactionActivityType.MALE_REFILLED_ACCOUNT:
              activityType = 'male-refilled-account';

              break;
            case EAccountTransactionActivityType.MALE_RETURNED_FUNDS:
              activityType = 'male-returned-funds';

              break;
            case EAccountTransactionActivityType.FEMALE_GOT_BONUS:
              activityType = 'female-got-bonus';

              break;
            case EAccountTransactionActivityType.FEMALE_CANCELED_SALARY:
              activityType = 'female-canceled-salary';

              break;
            case EAccountTransactionActivityType.FEMALE_CONFIRMED_EMAIL:
              activityType = 'female-confirmed-email';

              break;
            case EAccountTransactionActivityType.FEMALE_WITHDREW_FUNDS:
              activityType = 'female-withdrew-funds';

              break;
            case EAccountTransactionActivityType.FEMALE_RECEIVED_MESSAGES:
              activityType = 'female-received-messages';

              break;
            case EAccountTransactionActivityType.FEMALE_GOT_PREMIUM_BONUS:
              activityType = 'female-got-premium-bonus';

              break;
            case EAccountTransactionActivityType.FEMALE_RECEIVED_GIFT:
              activityType = 'female-received-gift';

              break;
            case EAccountTransactionActivityType.FEMALE_EARNED_REFERRAL_POINTS:
              activityType = 'female-earned-referral-points';

              break;
            case EAccountTransactionActivityType.FEMALE_RETURNED_POINTS:
              activityType = 'female-returned-points';

              break;
            case EAccountTransactionActivityType.GOT_CHAT_POINTS_PENALTY:
              activityType = 'got-chat-points-penalty';

              break;
            case EAccountTransactionActivityType.SPENT_TIME_IN_CHAT:
              activityType = 'spent-time-in-chat';

              break;
            case EAccountTransactionActivityType.FEMALE_POINTS_TAKEN_BY_ADMIN:
              activityType = 'female-points-taken-by-admin';

              break;
            case EAccountTransactionActivityType.FEMALE_POINTS_FOR_STORY_LIKES:
              activityType = 'female-points-for-story-likes';

              break;
            case EAccountTransactionActivityType.FEMALE_REDUCED_POINTS_FOR_PROMO:
              activityType = 'female-reduced-points-for-promo';

              break;
            case EAccountTransactionActivityType.FEMALE_REDUCED_POINTS_FOR_REFERRAL:
              activityType = 'female-reduced-points-for-referral';

              break;
            default:
              throw new Error(`unknown account detail activity type: ${item.activityType}`);
          }
        }

        return { ...item, activityType };
      });
  },

  isTariffsAndStatisticsAttentionDisplayed(state): boolean {
    return state.ui.flags.isTariffsAndStatisticsAttentionDisplayed;
  },

  holidaysCount(state): number {
    if (state.entities.user.femaleHolidays === undefined) {
      return 0;
    }

    return state.entities.user.femaleHolidays;
  },

  workedMinutesCount(state): number {
    if (state.entities.user.femaleWorkedMinutes === undefined) {
      return 0;
    }

    return state.entities.user.femaleWorkedMinutes;
  },

  firstMinuteRate(state): number {
    if (
      Object.keys(state.entities.user.femaleTariffs.list).length === 0
      || state.entities.user.femaleCurrentTariffId === undefined
    ) {
      return 0;
    }

    const tariff = (
      state.entities.user.femaleTariffs.list[state.entities.user.femaleCurrentTariffId]
    );

    if (tariff === undefined || tariff.firstMinuteRate === undefined) {
      return 0;
    }

    return tariff.firstMinuteRate;
  },

  secondMinuteRate(state): number {
    if (
      Object.keys(state.entities.user.femaleTariffs.list).length === 0
      || state.entities.user.femaleCurrentTariffId === undefined
    ) {
      return 0;
    }

    const tariff = (
      state.entities.user.femaleTariffs.list[state.entities.user.femaleCurrentTariffId]
    );

    if (tariff === undefined || tariff.secondMinuteRate === undefined) {
      return 0;
    }

    return tariff.secondMinuteRate;
  },

  dailyStatistics(state): IDailyStatisticsItemEntity[] {
    const result: IDailyStatisticsItemEntity[] = state.entities.user.femaleDailyStatistics.list;

    const daysForDisplayWithToday = config.femaleStatisticsDaysForDisplay + 1;

    return result.slice(-daysForDisplayWithToday);
  },

  tariffs(state): ITariffEntity[] {
    if (Object.keys(state.entities.user.femaleTariffs.list).length === 0) {
      return [];
    }

    const femaleTariffs = Object.values(state.entities.user.femaleTariffs.list);

    return femaleTariffs.sort((tariffA, tariffB) => {
      if (tariffA !== undefined && tariffB !== undefined) {
        return tariffB.id - tariffA.id;
      }

      return 0;
    });
  },

  currentTariffId(state): number | undefined {
    return state.entities.user.femaleCurrentTariffId;
  },

  profilePasswordValidationError(state): TUserPasswordValidationError | undefined {
    if (state.ui.buffer.userProfilePasswordValidationError === EUserPasswordValidationError.EMPTY) {
      return 'empty';
    }

    if (state.ui.buffer.userProfilePasswordValidationError === EUserPasswordValidationError.SHORT) {
      return 'short';
    }

    if (
      state.ui.buffer.userProfilePasswordValidationError === EUserPasswordValidationError.NO_MATCH
    ) {
      return 'no-match';
    }

    return undefined;
  },

  isCompanionRateLockDisplayed(state): boolean {
    return state.ui.flags.isCompanionRateLockDisplayed;
  },

  isCompanionReviewLockDisplayed(state): boolean {
    return state.ui.flags.isCompanionReviewLockDisplayed;
  },

  isCompanionReviewSentLockDisplayed(state): boolean {
    return state.ui.flags.isCompanionReviewSentLockDisplayed;
  },

  isCompanionGiftLockDisplayed(state): boolean {
    return state.ui.flags.isCompanionGiftLockDisplayed;
  },

  isCompleteSignUpAttentionDisplayed(state): boolean {
    return state.ui.flags.isCompleteSignUpAttentionDisplayed;
  },

  isReferralAttentionDisplayed(state): boolean {
    return state.ui.flags.isReferralAttentionDisplayed;
  },

  referrals(state): TReferralsItem[] {
    const values = Object.values(state.entities.referrals.list).sort((a, b) => a.id - b.id);

    return values.map((referralItem) => ({
      name: referralItem.name,
      avatar: referralItem.avatar !== null && !referralItem.isAccountDeleted
        ? referralItem.avatar.small
        : undefined,
      reward: referralItem.reward,
      isOnline: !!referralItem.isOnline,
      isRegisteredBefore: referralItem.isRegisteredBefore,
      isAccountDeleted: referralItem.isAccountDeleted,
    }));
  },

  referralsPagesCount(state): number {
    return Math.ceil(state.entities.referrals.total / config.referralsPerPage);
  },

  referralAttentionMaxPagesAmount(): number {
    return config.referralAttentionMaxPagesAmount;
  },

  isAccountDeletionAttentionDisplayed(state): boolean {
    return state.ui.flags.isAccountDeletionAttentionDisplayed;
  },

  isAccountDeletionConfirmationAttentionDisplayed(state): boolean {
    return state.ui.flags.isAccountDeletionConfirmationAttentionDisplayed;
  },

  accountDeletionCodeFirstPart(state): string | undefined {
    return state.entities.user.accountDeletionCodeFirstPart;
  },

  accountDeletionCodeFull(state): string | undefined {
    return state.entities.user.accountDeletionCodeFull;
  },

  accountDeletionCodeValidationError(state): TUserAccountDeletionCodeValidationError | undefined {
    if (
      state.ui.buffer.accountDeletionCodeValidationError
      === EUserAccountDeletionCodeValidationError.EMPTY
    ) {
      return 'empty';
    }

    if (
      state.ui.buffer.accountDeletionCodeValidationError
      === EUserAccountDeletionCodeValidationError.SHORT
    ) {
      return 'short';
    }

    if (
      state.ui.buffer.accountDeletionCodeValidationError
      === EUserAccountDeletionCodeValidationError.INCORRECT
    ) {
      return 'incorrect';
    }

    return undefined;
  },

  isAccountDeletionReasonAttentionDisplayed(state) {
    return state.ui.flags.isAccountDeletionReasonAttentionDisplayed;
  },

  accountDeletionReasonValidationError(state)
    : TUserAccountDeletionReasonValidationError | undefined {
    if (
      state.ui.buffer.accountDeletionReasonValidationError
      === EUserAccountDeletionReasonValidationError.EMPTY
    ) {
      return 'empty';
    }

    return undefined;
  },

  isAccountDeletionSuccessAttentionDisplayed(state) {
    return state.ui.flags.isAccountDeletionSuccessAttentionDisplayed;
  },

  isVolumeOff(state) {
    return !state.entities.settings.isVolumeEnabled;
  },

  isMicrophoneOff(state) {
    return !state.entities.settings.isMicrophoneEnabled;
  },

  isStoryNotFoundDisplayed(state) {
    return state.ui.flags.isStoryNotFoundDisplayed;
  },

  isMicrophoneDisplayed(state) {
    if (state.entities.streams.local !== null) {
      return state.entities.streams.local.getAudioTracks().some((track) => track !== null);
    }

    return false;
  },

  audioDevices(state): IDeviceEntity[] {
    return Object.values(state.entities.devices.list.audio).filter(
      (device: IDeviceEntity) => device.label.length > 0,
    );
  },

  videoDevices(state): IDeviceEntity[] {
    return Object.values(state.entities.devices.list.video).filter(
      (device: IDeviceEntity) => device.label.length > 0,
    );
  },

  selectedAudioDevice(state): IDeviceEntity | null {
    return state.entities.devices.active.audio;
  },

  selectedVideoDevice(state): IDeviceEntity | null {
    return state.entities.devices.active.video;
  },

  isMicrophoneNoticeDisplayed(state) {
    return state.ui.flags.isMicrophoneNoticeDisplayed;
  },

  videochatCompanionMessagesNumber(state): number {
    const { videochat } = state.entities;

    if (
      videochat.stage !== EVideochatStage.IN_PROCESS
      || videochat.companion === null
    ) {
      return 0;
    }

    return Object.keys(videochat.companion.messages.list).length;
  },

  videochatMessages(state): TVideochatMessengerFeedItem[] {
    const videochatCompanion = state.entities.videochat.companion;

    if (
      state.entities.videochat.stage !== EVideochatStage.IN_PROCESS
      || videochatCompanion === null
    ) {
      return [];
    }

    // В видеочате должны отображаться только 20 последних сообщений
    return Object.values(videochatCompanion.messages.list)
      .sort((a, b) => a.id - b.id)
      .slice(-1 * config.maxVideochatMessagesNumber)
      .map((message) => {
        let primaryText: string | undefined;

        let secondaryText: string | undefined;

        if (state.entities.user.isTranslationEnabled && message.translation !== undefined) {
          primaryText = `${message.translation}`;

          secondaryText = `${message.content}`;
        } else {
          primaryText = `${message.content}`;

          secondaryText = undefined;
        }

        return {
          primaryText,
          secondaryText,
          companionName: message.isIncoming ? videochatCompanion.name : undefined,
          direction: message.isIncoming ? 'incoming' : 'outgoing',
        };
      });
  },

  isDoubleEntryAttentionDisplayed(state): boolean {
    return state.ui.flags.isDoubleEntryAttentionDisplayed;
  },

  supportLink(state, { locale }): string {
    return config.supportLink.replace('%LOCALE%', locale);
  },

  isCompanionLeaveVideocallLockDisplayed(state): boolean {
    return state.ui.flags.isCompanionLeaveVideocallLockDisplayed;
  },

  isCompanionDisconnectVideocallLockDisplayed(state): boolean {
    return state.ui.flags.isCompanionDisconnectVideocallLockDisplayed;
  },

  isSwitchPageDuringVideochatAttentionDisplayed(state): boolean {
    return state.ui.flags.isSwitchPageDuringVideochatAttentionDisplayed;
  },

  isSignUpAppealAttentionDisplayed(state): boolean {
    return state.ui.flags.isSignUpAppealAttentionDisplayed;
  },

  isUserPremium(state): boolean {
    return !!state.entities.user.isMalePremium;
  },

  selectedPremiumPurchaseCard(state, { savedCards }): TCard | null {
    if (savedCards.length === 0) {
      return null;
    }

    const { cardLastNumbersForPurchase } = state.ui.buffer;

    if (cardLastNumbersForPurchase === undefined) {
      return savedCards[0];
    }

    const card = savedCards.find(
      (item: TCard) => item.lastNumbers === cardLastNumbersForPurchase,
    );

    if (card === undefined) {
      throw new Error('card is undefined');
    }

    return card;
  },

  savedCards(state): TCard[] {
    const { maleCards } = state.entities.user;

    if (maleCards.list.length === 0) {
      return [];
    }

    const result: TCard[] = [];

    maleCards.list.forEach((rawCard) => {
      let vendor: TAppIconPaymentName;

      switch (rawCard.vendor) {
        case ECardVendor.VISA:
          vendor = 'visa';

          break;
        case ECardVendor.MASTER_CARD:
          vendor = 'master-card';

          break;
        case ECardVendor.MAESTRO:
          vendor = 'maestro';

          break;
        case ECardVendor.JCB:
          vendor = 'jcb';

          break;
        case ECardVendor.DISCOVER:
          vendor = 'discover';

          break;
        case ECardVendor.AMERICAN_EXPRESS:
          vendor = 'american-express';

          break;
        case ECardVendor.DINERS_CLUB:
          vendor = 'diners-club';

          break;
        case ECardVendor.MIR:
          vendor = 'mir';

          break;
        case ECardVendor.UNKNOWN:
          vendor = 'unknown';

          break;
        default:
          throw new Error(`unknown card vendor: ${rawCard.vendor}`);
      }

      result.push({
        vendor,
        lastNumbers: rawCard.lastNumbers,
      });
    });

    return result;
  },

  cardConfirmationCurrency(state): string | undefined {
    const { maleCardConfirmation } = state.entities.user;

    if (maleCardConfirmation === null) {
      return undefined;
    }

    return maleCardConfirmation.currency;
  },

  cardConfirmationNumber(state): string | undefined {
    const { maleCardConfirmation } = state.entities.user;

    if (maleCardConfirmation === null) {
      return undefined;
    }

    return maleCardConfirmation.number;
  },

  cardConfirmationValidationError(state): TUserCardConfirmationValidationError | undefined {
    if (
      state.ui.buffer.userCardConfirmationValidationError
      === EUserCardConfirmationValidationError.WRONG_SUM
    ) {
      return 'wrong-sum';
    }

    if (
      state.ui.buffer.userCardConfirmationValidationError
      === EUserCardConfirmationValidationError.INVALID_SUM
    ) {
      return 'invalid-sum';
    }

    if (
      state.ui.buffer.userCardConfirmationValidationError
      === EUserCardConfirmationValidationError.NO_ATTEMPTS_LEFT
    ) {
      return 'no-attempts-left';
    }

    return undefined;
  },

  isCardConfirmationLockDisplayed(state) {
    return state.ui.flags.isCardConfirmationLockDisplayed;
  },

  isUserPurchaseCardRemovalConfirmDisplayed(state): boolean {
    return state.ui.flags.isUserPurchaseCardRemovalConfirmDisplayed;
  },

  isCompanionBannedVideocallLockDisplayed(state) {
    return state.ui.flags.isCompanionBannedVideocallLockDisplayed;
  },

  banExpirationTime(state): number | undefined {
    return state.entities.user.ban !== null ? state.entities.user.ban.expirationTime : undefined;
  },

  banStatus(state): TBanStatus {
    if (state.entities.user.ban === null) {
      return 'none';
    }

    switch (state.entities.user.ban.status) {
      case EBanStatus.NONE:
        return 'none';
      case EBanStatus.PORN:
        return 'porn';
      case EBanStatus.ADVERTISING:
        return 'advertising';
      case EBanStatus.PHOTO:
        return 'photo';
      case EBanStatus.AGE:
        return 'age';
      case EBanStatus.GENDER:
        return 'gender';
      case EBanStatus.LIFETIME:
        return 'lifetime';
      case EBanStatus.CHILD:
        return 'child';
      case EBanStatus.TEN_DAYS:
        return 'ten_days';
      case EBanStatus.RULES:
        return 'rules';
      case EBanStatus.PAYPAL_DISPUTE:
        return 'paypal-dispute';
      default:
        throw new Error('unknown ban status');
    }
  },

  hasStrictBan(state): boolean {
    return (
      state.entities.user.ban !== null
      && state.entities.user.ban.isStrict
    );
  },

  rulesLink(state, { locale, isRusVideoDatingApp }) {
    return isRusVideoDatingApp
      ? config.rusVideoDatingRulesLink
      : config.coomeetRulesLink.replace('%LOCALE%', locale);
  },

  isCompanionOutOfMinutesLockDisplayed(state) {
    return state.ui.flags.isCompanionOutOfMinutesLockDisplayed;
  },

  isVideocallPromoLockDisplayed(state) {
    return state.ui.flags.isFemalePromoVideocallLockDisplayed;
  },

  promoMessageTemplates(state): TPromoMessageTemplate[] {
    const promoMessageTemplates: TPromoMessageTemplate[] = Object.values(
      state.entities.promoMessageTemplates.list,
    );

    return promoMessageTemplates.sort((a, b) => {
      if (a.creationTime > b.creationTime) {
        return -1;
      }

      return 1;
    });
  },

  isCompanionHasAnotherVideocallLockDisplayed(state) {
    return state.ui.flags.isCompanionHasAnotherVideocallLockDisplayed;
  },

  isCompanionPaysLockDisplayed(state): boolean {
    return state.ui.flags.isCompanionPaysLockDisplayed;
  },

  locales(): TLocale[] {
    return [
      {
        code: ELocale.EN,
        title: 'English',
      },
      {
        code: ELocale.DE,
        title: 'Deutsch',
      },
      {
        code: ELocale.ES,
        title: 'Espanol',
      },
      {
        code: ELocale.FR,
        title: 'Français',
      },
      {
        code: ELocale.IT,
        title: 'Italiano',
      },
      {
        code: ELocale.PL,
        title: 'Polski',
      },
      {
        code: ELocale.RO,
        title: 'Romana',
      },
      {
        code: ELocale.RU,
        title: 'Русский',
      },
      {
        code: ELocale.TR,
        title: 'Turkce',
      },
      {
        code: ELocale.NL,
        title: 'Nederlands',
      },
      {
        code: ELocale.FI,
        title: 'Suomi',
      },
      {
        code: ELocale.UK,
        title: 'Українська',
      },
      {
        code: ELocale.JA,
        title: '日本語',
      },
      {
        code: ELocale.PT,
        title: 'Português',
      },
      {
        code: ELocale.ZH,
        title: '中文(简体)',
      },
    ];
  },

  locale(state): TLocaleCode {
    let locale: TLocaleCode;

    switch (state.entities.settings.locale) {
      case undefined:
        locale = config.defaultLocale;

        break;
      case ELocale.EN:
        locale = 'en';

        break;
      case ELocale.RU:
        locale = 'ru';

        break;
      case ELocale.DE:
        locale = 'de';

        break;
      case ELocale.FR:
        locale = 'fr';

        break;
      case ELocale.ES:
        locale = 'es';

        break;
      case ELocale.IT:
        locale = 'it';

        break;
      case ELocale.PL:
        locale = 'pl';

        break;
      case ELocale.RO:
        locale = 'ro';

        break;
      case ELocale.TR:
        locale = 'tr';

        break;
      case ELocale.NL:
        locale = 'nl';

        break;
      case ELocale.FI:
        locale = 'fi';

        break;
      case ELocale.UK:
        locale = 'uk';

        break;
      case ELocale.JA:
        locale = 'ja';

        break;
      case ELocale.PT:
        locale = 'pt';

        break;
      case ELocale.ZH:
        locale = 'zh';

        break;
      default:
        throw new Error(`unknown locale: ${state.entities.settings.locale}`);
    }

    return locale;
  },

  isVerificationLockDisplayed(state) {
    return state.ui.flags.isUserVerificationLockDisplayed;
  },

  isVideochatStageInProcess(state) {
    return state.entities.videochat.stage === EVideochatStage.IN_PROCESS;
  },

  isFaceNotDetectedAttentionDisplayed(state) {
    return state.ui.flags.shouldShowFaceNotDetectedAttention && state.entities.processes.isVideoCallActive;
  },

  stickers(state): TSticker[] {
    const commonStickers: TSticker[] = [
      'sticker-31',
      'sticker-32',
      'sticker-33',
    ];

    if (state.entities.user.gender === EGender.MALE) {
      const maleStickers: TSticker[] = [
        'sticker-1',
        'sticker-2',
        'sticker-3',
        'sticker-4',
        'sticker-5',
        'sticker-6',
        'sticker-7',
        'sticker-8',
        'sticker-9',
        'sticker-10',
        'sticker-11',
        'sticker-12',
        'sticker-13',
        'sticker-14',
        'sticker-15',
        'sticker-35',
        'sticker-36',
        'sticker-37',
        'sticker-38',
        'sticker-39',
      ];

      return [
        ...maleStickers,
        ...commonStickers,
      ];
    }

    const femaleStickers: TSticker[] = [
      'sticker-16',
      'sticker-17',
      'sticker-18',
      'sticker-19',
      'sticker-20',
      'sticker-21',
      'sticker-22',
      'sticker-23',
      'sticker-24',
      'sticker-25',
      'sticker-26',
      'sticker-27',
      'sticker-28',
      'sticker-29',
      'sticker-30',
      'sticker-40',
      'sticker-41',
      'sticker-42',
      'sticker-43',
      'sticker-44',
      'sticker-45',
      'sticker-46',
      'sticker-47',
      'sticker-48',
      'sticker-49',
      'sticker-34',
    ];

    return [
      ...femaleStickers,
      ...commonStickers,
    ];
  },

  gifts(state): TGift[] {
    /**
     * Правила показа списка подарков
     * От дешевого к дорогому
     */
    return Object.values(state.entities.gifts.list)
      .filter((gift: IGiftEntity) => gift.isSendAvailable)
      .sort((a: IGiftEntity, b: IGiftEntity) => (
        a.maleCostInMilliseconds - b.maleCostInMilliseconds
      ))
      .map((gift: IGiftEntity) => ({
        type: getGiftTypeById(gift.id),
        maleCostInMilliseconds: gift.maleCostInMilliseconds,
      }));
  },

  companionGiftLockGifts(state): TGift[] {
    /**
     * Правила показа списка подарков
     * От дешевого к дорогому
     */
    return Object.values(state.entities.gifts.list)
      .filter((gift: IGiftEntity) => gift.isSendAvailable)
      .sort((a: IGiftEntity, b: IGiftEntity) => (
        a.maleCostInMilliseconds - b.maleCostInMilliseconds
      ))
      .slice(0, config.lockScreensGiftsNumber)
      .map((gift: IGiftEntity) => ({
        type: getGiftTypeById(gift.id),
        maleCostInMilliseconds: gift.maleCostInMilliseconds,
      }));
  },

  userOutOfMessagesGifts(state): TGift[] {
    /**
     * Правила показа списка подарков
     * От дешевого к дорогому
     */
    return Object.values(state.entities.gifts.list)
      .filter((gift: IGiftEntity) => gift.isSendAvailable)
      .sort((a: IGiftEntity, b: IGiftEntity) => (
        a.maleCostInMilliseconds - b.maleCostInMilliseconds
      ))
      .slice(0, config.lockScreensGiftsNumber)
      .map((gift: IGiftEntity) => {
        if (state.entities.user.maleNumberOfMessagesPerMinuteOfVideochat === undefined) {
          throw new Error('male number of messages per minute of videochat is undefined');
        }

        const maleEarnedMessagesNumber = (
          state.entities.user.maleNumberOfMessagesPerMinuteOfVideochat
          * (gift.maleCostInMilliseconds / (60 * 1000))
        );

        return {
          type: getGiftTypeById(gift.id),
          maleEarnedMessagesNumber,
          maleCostInMilliseconds: gift.maleCostInMilliseconds,
        };
      });
  },

  currentVideochatCompanionGiftsList(state): TGift[] {
    const videochatCompanion = state.entities.videochat.companion;

    if (videochatCompanion === null) {
      return [];
    }

    /**
     * Правила показа списка подарков
     * От дешевого к дорогому
     */
    return Object.values(state.entities.gifts.list)
      .filter((gift: IGiftEntity) => gift.isSendAvailable)
      .sort((a: IGiftEntity, b: IGiftEntity) => (
        a.femalePoints - b.femalePoints
      ))
      .map((gift: IGiftEntity) => {
        let count = 0;

        if (videochatCompanion.gifts.list[gift.id] !== undefined) {
          count = videochatCompanion.gifts.list[gift.id];
        }

        return {
          type: getGiftTypeById(gift.id),
          femalePoints: gift.femalePoints,
          count,
        };
      });
  },

  isGiftAttentionDisplayed(state) {
    return state.ui.flags.isGiftAttentionDisplayed;
  },

  lastGift(state): TGift | undefined {
    const { gifts } = state.entities;

    if (gifts.lastId === undefined) {
      return undefined;
    }

    const lastGift = gifts.list[gifts.lastId];

    if (lastGift === undefined) {
      throw new Error('last gift is undefined');
    }

    return {
      type: getGiftTypeById(lastGift.id),
      maleCostInMilliseconds: lastGift.maleCostInMilliseconds,
      femalePoints: lastGift.femalePoints,
    };
  },

  lastGiftType(state): TGiftType | undefined {
    const { gifts } = state.entities;

    return gifts.lastId === undefined
      ? undefined
      : getGiftTypeById(gifts.lastId);
  },

  isGiftErrorAttentionDisplayed(state): boolean {
    return state.ui.flags.isGiftErrorAttentionDisplayed;
  },

  isServerUnavailableAttentionDisplayed(state): boolean {
    return state.ui.flags.isServerUnavailableAttentionDisplayed;
  },

  termsLink(state, { locale, isRusVideoDatingApp }): string {
    return isRusVideoDatingApp
      ? config.rusVideoDatingTermsLink
      : config.coomeetTermsLink.replace('%LOCALE%', locale);
  },

  privacyPolicyLink(state, { locale, isRusVideoDatingApp }): string {
    return isRusVideoDatingApp
      ? config.rusVideoDatingPrivacyPolicyLink
      : config.coomeetPrivacyPolicyLink.replace('%LOCALE%', locale);
  },

  cookiePolicyLink(state, { locale, isRusVideoDatingApp }): string {
    return isRusVideoDatingApp
      ? config.rusVideoDatingCookiePolicyLink
      : config.coomeetCookiePolicyLink.replace('%LOCALE%', locale);
  },

  contacts(state): TContactsItem[] {
    const result: TContactsItem[] = Object.values(state.entities.contacts.list)
      .filter((contact) => {
        /**
         * Если список контактов находится в режиме поиска,
         * показываем все найденные в поиске контакты,
         * скрываем саппортов, UI контакт для регистрации и текущего выбранного
         */
        const { settings, contacts } = state.entities;

        const isContactsSearchDisplayMode = settings.contactsDisplayMode === EContactsDisplayMode.SEARCH;

        const isContactNotSupport = (
          contact.role !== EContactRole.SUPPORT
          && contact.role !== EContactRole.UI_SUPPORT
        );

        const isContactNotUiRegistration = contact.role !== EContactRole.UI_REGISTRATION;

        if (isContactsSearchDisplayMode) {
          const isContactNotSelected = contacts.selected === null || contacts.selected.id !== contact.id;

          const selectedIsInContacts = contacts.selected === null || contacts.list[contacts.selected.id] !== undefined;

          return (
            isContactNotSupport
            && isContactNotUiRegistration
            && (isContactNotSelected || selectedIsInContacts)
          );
        }

        /**
         * Если список контактов НЕ находится в режиме поиска
         * показываем контакт пользователя, если он есть,
         * показываем контакт реферала, если он есть,
         * показываем все контакты по текущему фильтру
         */
        const isContactNotFilteredByUnread = (
          settings.contactsFiltration !== EContactsFiltration.UNREAD
          || (
            contact.unreadMessagesNumber !== 0
            && isContactNotSupport
            && isContactNotUiRegistration
          )
        );

        const isContactNotFilteredByFavorite = (
          settings.contactsFiltration !== EContactsFiltration.FAVORITE
          || (
            contact.isFavorite
            && isContactNotSupport
            && isContactNotUiRegistration
          )
        );

        const isContactNotFilteredByOnline = (
          settings.contactsFiltration !== EContactsFiltration.ONLINE
          || contact.isOnline
        );

        const isContactNotFilteredByBlocked = (
          settings.contactsFiltration !== EContactsFiltration.BLOCKED
          || (
            contact.status === EContactStatus.BLOCKED_CONTACT
            && isContactNotSupport
            && isContactNotUiRegistration
          )
        );

        const isContactUser = contact.id === state.entities.user.id;

        const isContactReferral = contact.id === state.entities.referrals.contactId;

        return (
          isContactUser
          || isContactReferral
          || (
            isContactNotFilteredByUnread
            && isContactNotFilteredByFavorite
            && isContactNotFilteredByOnline
            && isContactNotFilteredByBlocked
          )
        );
      })
      .map((contactItem) => {
        // Находим последнее сообщение от собеседника
        const lastMessageFromContact: IMessageEntity = (
          Object.values(contactItem.messages.list).sort((a, b) => b.id - a.id)[0]
        );

        // Формируем текст сообщения, исходя из его типа и настроек переводов
        let lastMessageText: string;

        let isTextMessage = false;

        switch (lastMessageFromContact.type) {
          case EMessageType.TEXT:
            isTextMessage = true;

            if (state.entities.user.isTranslationEnabled && lastMessageFromContact.translation !== undefined) {
              lastMessageText = `${lastMessageFromContact.translation}`;
            } else {
              lastMessageText = `${lastMessageFromContact.content}`;
            }

            break;
          case EMessageType.IMAGE:
            lastMessageText = 'S_IMAGE';

            break;
          case EMessageType.VIDEO:
            lastMessageText = 'SYS_MSG_4';

            break;
          case EMessageType.STICKER:
            lastMessageText = 'APP_STICKER';

            break;
          case EMessageType.SYSTEM:
            switch (lastMessageFromContact.content) {
              case ESystemMessageText.CONTACT_FROM_VIDEOCHAT:
                lastMessageText = 'SYS_MSG_1';

                break;
              case ESystemMessageText.CONTACT_DELETE:
                lastMessageText = lastMessageFromContact.isIncoming
                  ? 'SYS_MSG_2'
                  : 'APP_CONTACT_REMOVED';

                break;
              case ESystemMessageText.REMOVED_CONTACT:
                lastMessageText = 'APP_YOU_REMOVED_CONTACT';

                break;
              case ESystemMessageText.BLOCKED_BY_CONTACT:
                lastMessageText = 'SYS_MSG_3';

                break;
              case ESystemMessageText.VIDEOMESSAGE:
                lastMessageText = 'SYS_MSG_4';

                break;
              case ESystemMessageText.HAS_NOT_MESSAGES:
                if (contactItem.status === EContactStatus.DELETED_BY_CONTACT) {
                  lastMessageText = 'SYS_MSG_2';
                } else if (contactItem.contactRequestStatus === EContactRequestStatus.RECEIVED) {
                  lastMessageText = 'APP_WANTS_TO_MAKE_FRIENDS';
                } else if (contactItem.contactRequestStatus === EContactRequestStatus.SENT) {
                  lastMessageText = 'SYS_MSG_6_OUT';
                } else {
                  lastMessageText = 'SYS_MSG_5';
                }

                break;
              case ESystemMessageText.CONTACT_REQUEST:
                lastMessageText = lastMessageFromContact.isIncoming
                  ? 'APP_WANTS_TO_MAKE_FRIENDS'
                  : 'SYS_MSG_6_OUT';

                break;
              case ESystemMessageText.CONTACT_REQUEST_ACCEPTED:
                lastMessageText = 'SYS_MSG_7';

                break;
              case ESystemMessageText.CONTACT_REQUEST_DECLINED:
                lastMessageText = 'SYS_MSG_8';

                break;
              case ESystemMessageText.CONTACT_BOUGHT_PREMIUM:
                lastMessageText = 'SYS_MSG_13';

                break;
              case ESystemMessageText.CONTACT_SUPPORT:
                lastMessageText = 'SYS_MSG_14';

                break;
              case ESystemMessageText.SELF_CONTACT_REQUEST:
                lastMessageText = contactItem.id === state.entities.user.id
                  ? 'S_ITS_YOU'
                  : 'A_FRIEND_REQUEST';

                break;
              case ESystemMessageText.MISSED_CALL:
                lastMessageText = 'LOGGER_CALL_MALE';

                break;
              case ESystemMessageText.WITHDRAWAL_REQUEST:
                lastMessageText = 'SYS_MSG_10';

                break;
              case ESystemMessageText.WITHDRAWAL_REQUEST_2:
                lastMessageText = 'SYS_MSG_15';

                break;
              case ESystemMessageText.PREMIUM_PURCHASE:
                lastMessageText = 'SYS_MSG_21';

                break;
              default:
                throw new Error(`unknown system message type: ${lastMessageFromContact.content}`);
            }
            break;
          case EMessageType.GIFT:
            if (
              contactItem.contactRequestStatus !== EContactRequestStatus.ACCEPTED
              && contactItem.contactRequestGift !== undefined
            ) {
              lastMessageText = state.entities.user.gender === EGender.MALE ? 'SYS_MSG_6_OUT' : 'APP_WANTS_TO_MAKE_FRIENDS';
            } else {
              lastMessageText = state.entities.user.gender === EGender.MALE ? 'C_INVOICE_MALE_PRESENT' : 'TIPS_LOG_IN';
            }

            break;
          default:
            throw new Error(`message type ${lastMessageFromContact.type} is unknown`);
        }

        const lastMessageCreationDate = getRelativeDate(lastMessageFromContact.creationTime);

        let role: TContactRole;

        switch (contactItem.role) {
          case EContactRole.NONE:
            role = 'none';

            break;
          case EContactRole.UI_SUPPORT:
            role = 'ui-support';

            break;
          case EContactRole.SUPPORT:
            role = 'support';

            break;
          case EContactRole.UI_REGISTRATION:
            role = 'ui-registration';

            break;
          default:
            throw new Error(`unknown contact role: ${contactItem.role}`);
        }

        let contactPromoRequestStatus: TContactPromoRequestStatus;

        switch (contactItem.contactPromoRequestStatus) {
          case EContactPromoRequestStatus.NONE:
            contactPromoRequestStatus = 'none';

            break;
          case EContactPromoRequestStatus.SENT:
            contactPromoRequestStatus = 'sent';

            break;
          case EContactPromoRequestStatus.RECEIVED:
            contactPromoRequestStatus = 'received';

            break;
          default:
            throw new Error(`unknown contact promo request status: ${contactItem.contactPromoRequestStatus}`);
        }

        let lastMessageSendingStatus: TMessageSendingStatus | undefined;

        if (lastMessageFromContact.sendingStatus !== undefined) {
          switch (lastMessageFromContact.sendingStatus) {
            case EMessageSendingStatus.SENDING:
              lastMessageSendingStatus = 'sending';

              break;
            case EMessageSendingStatus.SENT:
              lastMessageSendingStatus = 'sent';

              break;
            case EMessageSendingStatus.ERROR:
              lastMessageSendingStatus = 'error';

              break;
            default:
              throw new Error(`unknown last message sending status: ${lastMessageFromContact.sendingStatus}`);
          }
        }

        // Формируем из сущности стора сущность представления
        return {
          role,
          contactPromoRequestStatus,
          unreadMessagesNumber: contactItem.unreadMessagesNumber,
          id: contactItem.id,
          avatar: contactItem.avatar !== null && !contactItem.isAccountDeleted ? contactItem.avatar.small : undefined,
          name: contactItem.name,
          isOnline: contactItem.isOnline,
          isFavorite: contactItem.isFavorite,
          isPremium: contactItem.isPremium,
          isTyping: contactItem.isTyping,
          isAccountDeleted: contactItem.isAccountDeleted,
          lastMessage: {
            isTextType: isTextMessage,
            text: lastMessageText,
            sendingStatus: lastMessageSendingStatus,
            isIncoming: lastMessageFromContact.isIncoming,
            creationDate: lastMessageCreationDate,
            isRead: lastMessageFromContact.isRead,
          },
          promoRequestExpirationTime: contactItem.promoRequestExpirationTime,
          hasStory: contactItem.hasStory,
          isStoryViewed: contactItem.isStoryViewed,
          contactRequestGift: contactItem.contactRequestGift !== undefined
            ? getGiftTypeById(contactItem.contactRequestGift)
            : undefined,
          order: contactItem.order,
          isBlocked: contactItem.status === 'blocked-contact',
        };
      });

    result.sort((a, b) => {
      // Ui-контакт саппорта должен всегда быть первым элементом
      if (a.role === 'ui-support') {
        return -1;
      }

      if (b.role === 'ui-support' || b.role === 'ui-registration') {
        return 1;
      }

      return b.order - a.order;
    });

    return result;
  },

  contactsCount(state, { contacts }): number {
    /**
     * Геттер возвращает число реальных контактов, поэтому подсчет идёт без учета
     * - своего контакта
     * - реферального контакта
     * - контакта пикера саппорта
     */
    return contacts
      .filter((contact: TContact) => {
        const isContactNotUser = contact.id !== state.entities.user.id;

        const isContactNotReferral = contact.id !== state.entities.referrals.contactId;

        const isContactNotSupportPicker = contact.role !== 'ui-support';

        return isContactNotUser && isContactNotReferral && isContactNotSupportPicker;
      }).length;
  },

  isContactsLoadingAvailable(state) {
    return state.ui.buffer.isContactsLoadingAvailable;
  },

  isProcessContactsLoadingActive(state) {
    return state.entities.processes.isContactsLoadingActive;
  },

  contactsFiltration(state): TContactsFiltration {
    let contactsFiltration: TContactsFiltration;

    switch (state.entities.settings.contactsFiltration) {
      case EContactsFiltration.NONE:
        contactsFiltration = 'none';

        break;
      case EContactsFiltration.RECENT:
        contactsFiltration = 'recent';

        break;
      case EContactsFiltration.ONLINE:
        contactsFiltration = 'online';

        break;
      case EContactsFiltration.FAVORITE:
        contactsFiltration = 'favorite';

        break;
      case EContactsFiltration.UNREAD:
        contactsFiltration = 'unread';

        break;
      case EContactsFiltration.BLOCKED:
        contactsFiltration = 'blocked';

        break;
      default:
        throw new Error(`unknown contacts filtration: ${state.entities.settings.contactsFiltration}`);
    }

    return contactsFiltration;
  },

  contactsFilters(): TContactsFiltration[] {
    return [
      'none',
      'recent',
      'online',
      'favorite',
      'unread',
      'blocked',
    ];
  },

  avatarUploadError(state): TUserAvatarUploadError | undefined {
    const { userAvatarUploadError } = state.ui.buffer;

    if (userAvatarUploadError === undefined) {
      return undefined;
    }

    switch (userAvatarUploadError) {
      case EUserAvatarUploadError.LONG_REQUEST:
        return 'long-request';
      case EUserAvatarUploadError.CORRUPTED:
        return 'corrupted';
      case EUserAvatarUploadError.FORMAT_DENIED:
        return 'format-denied';
      default:
        throw new Error(`unknown user avatar upload error: ${userAvatarUploadError}`);
    }
  },

  messageSendError(state): TMessageSendError | undefined {
    const { messageSendError } = state.ui.buffer;

    if (messageSendError === undefined) {
      return undefined;
    }

    switch (messageSendError) {
      case EMessageSendError.FILE_CORRUPTED:
        return 'file-corrupted';
      case EMessageSendError.FORMAT_DENIED:
        return 'format-denied';
      default:
        throw new Error(`unknown message send error: ${messageSendError}`);
    }
  },

  contactsDisplayMode(state): TContactsDisplayMode {
    return state.entities.settings.contactsDisplayMode;
  },

  contactsUnreadCount(state): number {
    return state.entities.contacts.unreadCount;
  },

  isEmailConfirmationRequired(state): boolean {
    return !!state.entities.user.isNeedEmailConfirmation;
  },

  isEmailConfirmationControlDisplayed(state): boolean {
    return state.entities.user.registrationStatus === ERegistrationStatus.AUTHORIZED
      && !!state.entities.user.isNeedEmailConfirmation;
  },

  userRatingValue(state): number | undefined {
    return state.entities.user.rating;
  },

  userRatingLevel(state): TRatingLevel | undefined {
    if (state.entities.user.rating === undefined) {
      return undefined;
    }

    if (state.entities.user.rating < config.userRatingLowLine) {
      return 'low';
    }

    if (state.entities.user.rating >= config.userRatingHighLine) {
      return 'high';
    }

    return 'medium';
  },

  markedContactsIds(state): number[] {
    if (state.ui.buffer.markedContactsIds === undefined) {
      return [];
    }

    return state.ui.buffer.markedContactsIds;
  },

  selectedContact(state): TContact | undefined {
    const selectedContact = state.entities.contacts.selected;

    if (selectedContact === null) {
      return undefined;
    }

    // Преобразуем данные сущности стора в данные сущности представления
    let contactRequestStatus: TContactRequestStatus;

    switch (selectedContact.contactRequestStatus) {
      case EContactRequestStatus.NONE:
        contactRequestStatus = 'none';

        break;
      case EContactRequestStatus.ACCEPTED:
        contactRequestStatus = 'accepted';

        break;
      case EContactRequestStatus.RECEIVED:
        contactRequestStatus = 'received';

        break;
      case EContactRequestStatus.SENT:
        contactRequestStatus = 'sent';

        break;
      default:
        throw new Error('contact request status is unknown');
    }

    let role: TContactRole;

    switch (selectedContact.role) {
      case EContactRole.NONE:
        role = 'none';

        break;
      case EContactRole.UI_SUPPORT:
        role = 'ui-support';

        break;
      case EContactRole.SUPPORT:
        role = 'support';

        break;
      case EContactRole.UI_REGISTRATION:
        role = 'ui-registration';

        break;
      default:
        throw new Error(`unknown contact role: ${selectedContact.role}`);
    }

    let contactPromoRequestStatus: TContactPromoRequestStatus;

    switch (selectedContact.contactPromoRequestStatus) {
      case EContactPromoRequestStatus.NONE:
        contactPromoRequestStatus = 'none';

        break;
      case EContactPromoRequestStatus.SENT:
        contactPromoRequestStatus = 'sent';

        break;
      case EContactPromoRequestStatus.RECEIVED:
        contactPromoRequestStatus = 'received';

        break;
      default:
        throw new Error(`unknown contact promo request status: ${selectedContact.contactPromoRequestStatus}`);
    }

    let status: TContactStatus;

    switch (selectedContact.status) {
      case EContactStatus.NONE:
        status = 'none';

        break;
      case EContactStatus.DELETED_BY_CONTACT:
        status = 'deleted-by-contact';

        break;
      case EContactStatus.DELETED_CONTACT:
        status = 'deleted-contact';

        break;
      case EContactStatus.BLOCKED_CONTACT:
        status = 'blocked-contact';

        break;
      default:
        throw new Error(`unknown status: ${selectedContact.status}`);
    }

    // Формируем сущность представления
    return {
      contactRequestStatus,
      contactPromoRequestStatus,
      role,
      status,
      id: selectedContact.id,
      avatar: selectedContact.avatar !== null && !selectedContact.isAccountDeleted
        ? selectedContact.avatar.medium
        : undefined,
      name: selectedContact.name,
      isOnline: selectedContact.isOnline,
      isFavorite: selectedContact.isFavorite,
      isPremium: selectedContact.isPremium,
      isTyping: selectedContact.isTyping,
      isAccountDeleted: selectedContact.isAccountDeleted,
      unreadMessagesNumber: selectedContact.unreadMessagesNumber,
      promoRequestExpirationTime: selectedContact.promoRequestExpirationTime,
      note: selectedContact.note,
      hasStory: selectedContact.hasStory,
      isStoryViewed: selectedContact.isStoryViewed,
      infoMessage: selectedContact.infoMessage,
    };
  },

  contactForRemoval(state): Partial<TContact> | undefined {
    const { contactForRemovalId } = state.ui.buffer;

    if (contactForRemovalId === undefined) {
      return undefined;
    }

    const { contacts } = state.entities;

    const { selected: selectedContact } = contacts;

    let contact = contacts.list[contactForRemovalId];

    if (contact === undefined) {
      if (selectedContact !== null && selectedContact.id === contactForRemovalId) {
        contact = { ...selectedContact };
      } else {
        throw new Error('contact is not selected and not exists in list');
      }
    }

    let role: TContactRole;

    switch (contact.role) {
      case EContactRole.NONE:
        role = 'none';

        break;
      case EContactRole.UI_SUPPORT:
        role = 'ui-support';

        break;
      case EContactRole.SUPPORT:
        role = 'support';

        break;
      default:
        throw new Error(`unknown contact role: ${contact.role}`);
    }

    return {
      role,
      id: contact.id,
      avatar: contact.avatar !== null ? contact.avatar.medium : undefined,
      name: contact.name,
      isPremium: contact.isPremium,
    };
  },

  videochatCompanionVerificationDate(state): TRelativeDate | undefined {
    if (state.entities.videochat.companion === null) {
      throw new Error('videochat companion is null');
    }

    const timestamp = state.entities.videochat.companion.verificationTime;

    if (timestamp === undefined) {
      return undefined;
    }

    return getRelativeDate(timestamp);
  },

  isSearchFiltrationEnabled(state): boolean {
    return state.entities.settings.userMaleSearchFiltration === EUserMaleSearchFiltration.ONLY_BEST;
  },

  isVideochatCompanionRemainingMinutesDisplayed(state): boolean {
    const videochatCompanion = state.entities.videochat.companion;

    return (
      videochatCompanion !== null
      && videochatCompanion.id !== undefined
      && !config.companionsWithHiddenMaleRemainingMinutesIds.includes(videochatCompanion.id)
    );
  },

  videochatCompanionRemainingMillisecondsCount(state): number {
    const videochatCompanion = state.entities.videochat.companion;

    if (videochatCompanion === null) {
      throw new Error('videochat companion is null');
    }

    if (videochatCompanion.maleNumberOfMillisecondsRemaining === undefined) {
      return 0;
    }

    return videochatCompanion.maleNumberOfMillisecondsRemaining;
  },

  selectedContactLastActionDate(state): TRelativeDate | undefined {
    const selectedContact = state.entities.contacts.selected;

    if (selectedContact === null) {
      return undefined;
    }

    const lastMessage = (
      Object.values(selectedContact.messages.list)
        .sort((a, b) => b.id - a.id)[0]
    );

    return getRelativeDate(lastMessage.creationTime);
  },

  messengerState(state): TMessengerState {
    // Если контакт не выбран, показываем стартовую подсказку или приглашение подтвердить емейл
    const selectedContact = state.entities.contacts.selected;

    if (selectedContact === null) {
      const isEmailConfirmationDisplayed = state.entities.user.isNeedEmailConfirmation === true
        && state.ui.flags.isMessengerEmailConfirmationDisplayed;

      if (isEmailConfirmationDisplayed) {
        return 'email-confirmation';
      }

      return 'start';
    }

    // Обрабатываем варианты показа блоков системных сообщений
    if (selectedContact.id === config.supportCommonContactId) {
      return 'support-selection';
    }

    if (
      state.entities.user.gender === EGender.MALE
      && selectedContact.id === config.registrationContactId
    ) {
      return 'registration';
    }

    if (
      selectedContact.isAccountDeleted
      && selectedContact.contactRequestStatus === EContactRequestStatus.NONE
    ) {
      return 'account-deleted';
    }

    if (selectedContact.contactRequestStatus === EContactRequestStatus.NONE) {
      return 'referral-of-self-contact';
    }

    if (selectedContact.contactRequestStatus === EContactRequestStatus.RECEIVED) {
      if (
        state.entities.user.gender === EGender.MALE
        && !state.entities.user.isMalePremium
        && selectedContact.contactPromoRequestStatus === EContactPromoRequestStatus.RECEIVED
        && selectedContact.promoRequestExpirationTime !== undefined
        && selectedContact.promoRequestExpirationTime > Date.now()
      ) {
        return 'promo-request-received';
      }

      if (selectedContact.status === EContactStatus.DELETED_BY_CONTACT) {
        return 'messages-feed';
      }

      return 'request-received';
    }

    if (selectedContact.contactRequestStatus === EContactRequestStatus.SENT) {
      if (selectedContact.status === EContactStatus.DELETED_BY_CONTACT) {
        return 'request-declined';
      }

      if (
        state.entities.user.gender === EGender.FEMALE
        && selectedContact.contactPromoRequestStatus === EContactPromoRequestStatus.SENT
        && selectedContact.promoRequestExpirationTime !== undefined
        && selectedContact.promoRequestExpirationTime > Date.now()
      ) {
        return 'messages-feed';
      }

      return 'request-sent';
    }

    return 'messages-feed';
  },

  videochatCompanionContactStatus(state): TCompanionContactStatus {
    const videochatCompanion = state.entities.videochat.companion;

    if (videochatCompanion === null) {
      throw new Error('videochat companion is null');
    }

    if (videochatCompanion.contactStatus === undefined) {
      throw new Error('videochat companion contact status is undefined');
    }

    switch (videochatCompanion.contactStatus) {
      case ECompanionContactStatus.NONE:
        return 'none';
      case ECompanionContactStatus.REQUEST_SENT:
        return 'request-sent';
      case ECompanionContactStatus.REQUEST_RECEIVED:
        return 'request-received';
      case ECompanionContactStatus.REQUEST_ACCEPTED:
        return 'request-accepted';
      case ECompanionContactStatus.REQUEST_DECLINED:
        return 'request-declined';
      case ECompanionContactStatus.DELETED_CONTACT:
        return 'deleted-contact';
      case ECompanionContactStatus.DELETED_BY_CONTACT:
        return 'deleted-by-contact';
      case ECompanionContactStatus.BLOCKED_CONTACT:
        return 'blocked-contact';
      default:
        throw new Error(`unknown videochat companion contact status: ${videochatCompanion.contactStatus}`);
    }
  },

  isContactRequestAttentionDisplayed(state): boolean {
    return state.ui.flags.isContactRequestAttentionDisplayed;
  },

  selectedContactMessages(state): TMessengerFeedPeriod[] | undefined {
    const selectedContact = state.entities.contacts.selected;

    if (selectedContact === null) {
      return undefined;
    }

    // Получаем сообщения для выбранного контакта
    const selectedContactMessages: IMessageEntity[] = Object.values(
      selectedContact.messages.list,
    ).sort((a, b) => a.id - b.id);

    const result: TMessengerFeedPeriod[] = [];

    /**
     * В каждом фиде для Ж, кроме фидов с саппортами, должно присутствовать системное сообщение
     * о том, что за каждое входящее сообщение даются баллы. Для всех пользователей, которые
     * не подтвердили емейл должно присутствовать системное сообщение с просьбой его подтвердить.
     * Для всех пользователей должно присутствовать системное сообщение с информацией
     * о зашифрованных сообщениях. Дата всех этих системных сообщений должна быть сегодняшней
     */
    const todayBeginTimestamp = new Date().setHours(0, 0, 0, 0);

    const todayFeedPeriod: TMessengerFeedPeriod = {
      date: {
        isToday: true,
        isYesterday: false,
        timestamp: todayBeginTimestamp,
      },
      messages: [
        {
          type: 'encrypt',
        },
      ],
    };

    if (selectedContact.status === 'blocked-contact') {
      todayFeedPeriod.messages.push({
        type: 'blocked-contact',
      });
    }

    const isFemalePromoRequestSentActive = (
      state.entities.user.gender === EGender.FEMALE
      && selectedContact.role === EContactRole.NONE
      && selectedContact.contactPromoRequestStatus === EContactPromoRequestStatus.SENT
      && !selectedContact.isPremium
    );

    if (isFemalePromoRequestSentActive) {
      todayFeedPeriod.messages.push({
        type: 'female-points-promo',
      });
    }

    const isFemalePointsMessageRequired = (
      state.entities.user.gender === EGender.FEMALE
      && selectedContact.role === EContactRole.NONE
      && !isFemalePromoRequestSentActive
    );

    if (isFemalePointsMessageRequired) {
      todayFeedPeriod.messages.push({
        type: 'female-points',
      });
    }

    if (state.entities.user.isNeedEmailConfirmation) {
      todayFeedPeriod.messages.push({
        type: 'notifications',
      });
    }

    result.push(todayFeedPeriod);

    // Формируем массив сущностей представления
    selectedContactMessages.forEach((messageItem) => {
      // Формируем сообщение для представления
      let messageType: TMessageType;

      let messageData: TSystemMessageData | TUserMessageData | undefined;

      if (messageItem.type === EMessageType.SYSTEM) {
        if (messageItem.content === ESystemMessageText.PREMIUM_PURCHASE) {
          messageType = 'premium-purchase';
        } else {
          messageType = 'system';

          let messageVariant: TSystemMessageVariant;

          let messageText: string;

          let messageIcon: string | undefined;

          switch (messageItem.content) {
            case ESystemMessageText.CONTACT_FROM_VIDEOCHAT:
              messageVariant = 'success';
              messageText = 'SYS_MSG_1';

              break;
            case ESystemMessageText.CONTACT_DELETE:
              messageVariant = 'danger';
              messageText = messageItem.isIncoming ? 'SYS_MSG_2' : 'APP_CONTACT_REMOVED';

              break;
            case ESystemMessageText.REMOVED_CONTACT:
              messageVariant = 'danger';

              messageText = 'APP_YOU_REMOVED_CONTACT';

              break;
            case ESystemMessageText.BLOCKED_BY_CONTACT:
              messageVariant = 'danger';
              messageText = 'SYS_MSG_3';

              break;
            case ESystemMessageText.VIDEOMESSAGE:
              messageVariant = 'success';
              messageText = 'SYS_MSG_4';

              break;
            case ESystemMessageText.HAS_NOT_MESSAGES:
              messageVariant = 'success';
              messageText = 'SYS_MSG_5';

              break;
            case ESystemMessageText.CONTACT_REQUEST:
              messageVariant = 'success';
              messageText = messageItem.isIncoming
                ? 'APP_WANTS_TO_MAKE_FRIENDS'
                : 'SYS_MSG_6_OUT';

              break;
            case ESystemMessageText.CONTACT_REQUEST_ACCEPTED:
              messageVariant = 'success';
              messageText = 'SYS_MSG_7';

              break;
            case ESystemMessageText.CONTACT_REQUEST_DECLINED:
              messageVariant = 'danger';
              messageText = 'SYS_MSG_8';

              break;
            case ESystemMessageText.CONTACT_BOUGHT_PREMIUM:
              messageVariant = 'success';
              messageText = 'SYS_MSG_13';

              break;
            case ESystemMessageText.CONTACT_SUPPORT:
              messageVariant = 'success';
              messageText = 'SYS_MSG_14';

              break;
            case ESystemMessageText.SELF_CONTACT_REQUEST:
              messageVariant = 'success';
              messageText = selectedContact.id === state.entities.user.id
                ? 'S_ITS_YOU'
                : 'A_FRIEND_REQUEST';

              break;
            case ESystemMessageText.MISSED_CALL:
              messageVariant = 'danger';
              messageText = state.entities.user.gender === EGender.MALE
                ? 'LOGGER_CALL_MALE'
                : 'LOGGER_CALL_FEMALE';
              messageIcon = 'phone-crossed';

              break;
            case ESystemMessageText.WITHDRAWAL_REQUEST:
              messageVariant = 'danger';
              messageText = 'SYS_MSG_10';

              break;
            case ESystemMessageText.WITHDRAWAL_REQUEST_2:
              messageVariant = 'danger';
              messageText = 'SYS_MSG_15';

              break;
            default:
              throw new Error(`unknown system message type: ${messageItem.content}`);
          }

          messageData = {
            variant: messageVariant,
            text: messageText,
            creationTime: messageItem.creationTime,
            icon: messageIcon,
          };
        }
      } else {
        messageType = 'user';

        let messageDirection: TUserMessageDirection;

        let messageSatus: TUserMessageStatus | undefined;

        let points: number | undefined;

        if (messageItem.isIncoming) {
          messageDirection = 'incoming';
          points = messageItem.femalePoints;
        } else {
          messageDirection = 'outgoing';

          if (messageItem.isRead) {
            messageSatus = 'read';
          } else if (messageItem.sendingStatus !== undefined) {
            switch (messageItem.sendingStatus) {
              case EMessageSendingStatus.SENDING:
                messageSatus = 'sending';

                break;
              case EMessageSendingStatus.SENT:
                messageSatus = 'sent';

                break;
              case EMessageSendingStatus.ERROR:
                messageSatus = 'error';

                break;
              default:
                throw new Error(`unknown sending status: ${messageItem.sendingStatus}`);
            }
          }
        }

        let messageDataType: TUserMessageType;

        let messageContent: TUserMessageText
          | TUserMessagePhoto
          | TUserMessageVideo
          | TUserMessageGift
          | TSticker;

        if (messageItem.type === EMessageType.TEXT) {
          messageDataType = 'text';

          if (state.entities.user.isTranslationEnabled) {
            messageContent = {
              text: messageItem.translation !== undefined
                ? `${messageItem.translation}`
                : `${messageItem.content}`,
              original: messageItem.translation !== undefined
                ? `${messageItem.content}`
                : undefined,
            };
          } else {
            messageContent = {
              text: `${messageItem.content}`,
              original: undefined,
            };
          }
        } else if (messageItem.type === EMessageType.IMAGE) {
          messageDataType = 'photo';

          // Обрабатываем нештатные ситуации
          if (messageItem.content === undefined) {
            throw new Error('message content is undefined');
          }

          // Обрабатываем нештатные ситуации
          if (typeof messageItem.content === 'string') {
            throw new Error('message content is string');
          }

          /**
           * Обрабатываем нештатные ситуации
           * Проверяем является ли контент изображением по наличию свойства интерфейса изображения
           */
          if (typeof messageItem.content === 'number' || !('medium' in messageItem.content)) {
            throw new Error('medium is not found');
          }

          const medium = (
            !messageItem.isIncoming && messageItem.sendingStatus !== EMessageSendingStatus.SENT
          )
            ? `data:image/jpeg;base64,${messageItem.content.medium}`
            : messageItem.content.medium;

          const original = (
            !messageItem.isIncoming && messageItem.sendingStatus !== EMessageSendingStatus.SENT
          )
            ? `data:image/jpeg;base64,${messageItem.content.original}`
            : messageItem.content.original;

          messageContent = {
            medium,
            original,
          };
        } else if (messageItem.type === EMessageType.GIFT) {
          messageDataType = 'gift';

          if (typeof messageItem.content !== 'number') {
            throw new Error('messageItem.content must be number');
          }

          const gift = state.entities.gifts.list[messageItem.content];

          if (gift === undefined) {
            throw new Error('gift for messageItem.content in undefined');
          }

          messageContent = {
            type: getGiftTypeById(gift.id),
            price: gift.maleCostInMilliseconds,
            points: gift.femalePoints,
          };
        } else if (messageItem.type === EMessageType.VIDEO) {
          messageDataType = 'video';

          // Обрабатываем нештатные ситуации
          if (messageItem.content === undefined) {
            throw new Error('message content is undefined');
          }

          // Обрабатываем нештатные ситуации
          if (typeof messageItem.content === 'string') {
            throw new Error('message content is string');
          }

          /**
           * Обрабатываем нештатные ситуации
           * Проверяем является ли контент видео по наличию свойств интерфейса видео
           */
          if (
            typeof messageItem.content === 'number'
            || (!('webmUrl' in messageItem.content) && !('mp4Url' in messageItem.content))
          ) {
            throw new Error('webmUrl and mp4Url are not found');
          }

          const messageVideo: IVideoEntity = messageItem.content;

          let preview: string | undefined = messageVideo.isPreviewReady
            ? messageVideo.previewUrl
            : undefined;

          const webmVideo: string | undefined = messageVideo.isWebmReady
            ? messageVideo.webmUrl
            : undefined;

          let mp4Video: string | undefined = messageVideo.isMp4Ready
            ? messageVideo.mp4Url
            : undefined;

          if (!messageItem.isIncoming && messageItem.sendingStatus !== EMessageSendingStatus.SENT) {
            preview = `data:image/jpeg;base64,${preview}`;
            mp4Video = `data:video/mp4;base64,${mp4Video}`;
          }

          messageContent = {
            preview,
            mp4Video,
            webmVideo,
          };
        } else if (messageItem.type === EMessageType.STICKER) {
          messageDataType = 'sticker';

          messageContent = messageItem.content as TSticker;
        } else {
          throw new Error(`message type ${messageItem.type} is unknown`);
        }

        messageData = {
          points,
          direction: messageDirection,
          status: messageSatus,
          type: messageDataType,
          creationTime: messageItem.creationTime,
          content: messageContent,
        };
      }

      const message: TMessage = {
        id: messageItem.id,
        type: messageType,
        data: messageData,
      };

      // Определяем начало дня, к которому принадлежит дата создания сообщения
      const dayBeginTime: number = (
        new Date(messageItem.creationTime).setHours(0, 0, 0, 0)
      );

      /**
       * Если в фиде есть дата, соответствующая найденной, добавляем сообщение в массив сообщений,
       * принадлежащих к этой дате, в противном случае предварительно добавляем дату в фид
       */
      const existingFeedDateIndex = result.findIndex((item) => item.date.timestamp === dayBeginTime);

      if (existingFeedDateIndex === -1) {
        const messageDate = getRelativeDate(dayBeginTime);

        const feedPeriod: TMessengerFeedPeriod = {
          date: messageDate,
          messages: [
            message,
          ],
        };

        result.push(feedPeriod);
      } else {
        result[existingFeedDateIndex].messages.push(message);
      }
    });

    /**
     * Возвращаем массив, отсортированный в порядке дат написания сообщений,
     * от более поздних к более ранним
     */
    return result.sort((a, b) => a.date.timestamp - b.date.timestamp);
  },

  selectedContactLastMessage(state, { selectedContactMessages }): TMessage {
    const lastFeedPeriodMessages = (
      selectedContactMessages[selectedContactMessages.length - 1].messages
    );

    return lastFeedPeriodMessages[lastFeedPeriodMessages.length - 1];
  },

  isUserOutOfMessagesAttentionDisplayed(state) {
    return state.ui.flags.isUserOutOfMessagesAttentionDisplayed;
  },

  minMinutesCountForSearchFiltration(state): number {
    const { millisecondsForSearchFiltration } = state.entities.settings;

    if (millisecondsForSearchFiltration === undefined) {
      throw new Error('milliseconds for search filtration is undefined');
    }

    return Math.ceil(millisecondsForSearchFiltration / (60 * 1000));
  },

  isServerPingAttentionDisplayed(state): boolean {
    return state.ui.flags.isServerPingAttentionDisplayed;
  },

  isServerNetworkAttentionDisplayed(state): boolean {
    return state.ui.flags.isServerNetworkAttentionDisplayed;
  },

  isRemoveSelectedContactsMessagesAttentionDisplayed(state): boolean {
    return state.ui.flags.isRemoveSelectedContactsMessagesAttentionDisplayed;
  },

  isRemoveAllContactsMessagesAttentionDisplayed(state): boolean {
    return state.ui.flags.isRemoveAllContactsMessagesAttentionDisplayed;
  },

  isContactRemoveAttentionDisplayed(state) {
    return state.ui.flags.isContactRemoveAttentionDisplayed;
  },

  isSearchSettingsDisplayed(state) {
    return state.ui.flags.isSearchSettingsDisplayed;
  },

  isCompanionPervertAttentionDisplayed(state) {
    return state.ui.flags.isCompanionPervertAttentionDisplayed;
  },

  isImageSendAttentionDisplayed(state) {
    return state.ui.flags.isImageSendAttentionDisplayed;
  },

  userFemaleEarnedPoints(state): number | undefined {
    const videochatStartTariffId = state.ui.buffer.userFemalePreviousTariffId !== undefined
      ? state.ui.buffer.userFemalePreviousTariffId
      : state.entities.user.femaleCurrentTariffId;

    if (
      state.entities.videochat.duration === undefined
      || videochatStartTariffId === undefined
    ) {
      return undefined;
    }

    if (Object.keys(state.entities.user.femaleTariffs.list).length === 0) {
      throw new Error('user female tariffs is empty');
    }

    const tariff = state.entities.user.femaleTariffs.list[videochatStartTariffId];

    if (tariff === undefined) {
      throw new Error('tariff is undefined');
    }

    if (tariff.firstMinuteRate === undefined) {
      throw new Error('tariff first minute rate is undefined');
    }

    if (tariff.secondMinuteRate === undefined) {
      throw new Error('tariff second minute rate is undefined');
    }

    if (state.entities.videochat.duration === undefined) {
      throw new Error('videochat duration is undefined');
    }

    const minutes: number = Math.floor(state.entities.videochat.duration / (60 * 1000));

    if (minutes === 0) {
      return 0;
    }

    return tariff.firstMinuteRate + tariff.secondMinuteRate * (minutes - 1);
  },

  videochatDurationFormatted(state): TDurationFormat | undefined {
    if (state.entities.videochat.duration === undefined) {
      return undefined;
    }

    const seconds = state.entities.videochat.duration / 1000;

    return {
      hours: Math.floor(seconds / 3600),
      minutes: Math.floor((seconds % 3600) / 60),
      seconds: (seconds % 3600) % 60,
    };
  },

  isPhotoSendAttentionDisplayed(state) {
    return state.ui.flags.isPhotoSendAttentionDisplayed;
  },

  capturedPhoto(state): string | undefined {
    return state.entities.recorder.photo !== undefined
      ? `data:image/jpeg;base64,${state.entities.recorder.photo}`
      : undefined;
  },

  isRecorderSupported(): boolean {
    return RecorderRepository.getInstance().getIsSupported();
  },

  recorderVideoUrl(state): string | undefined {
    if (state.entities.recorder.video === null) {
      return undefined;
    }

    return window.URL.createObjectURL(state.entities.recorder.video);
  },

  isImageViewAttentionDisplayed(state): boolean {
    return state.ui.flags.isImageViewAttentionDisplayed;
  },

  isVideoViewAttentionDisplayed(state): boolean {
    return state.ui.flags.isVideoViewAttentionDisplayed;
  },

  mediaFileToView(state): string | undefined {
    return state.ui.buffer.mediaFileToView;
  },

  premiumExpirationDaysLeft(state): number | undefined {
    const { malePremiumExpirationTime } = state.entities.user;

    if (malePremiumExpirationTime === undefined) {
      return undefined;
    }

    const lastPremiumDay = new Date(malePremiumExpirationTime).setHours(0, 0, 0, 0);

    const today = new Date().setHours(0, 0, 0, 0);

    return Math.round((lastPremiumDay - today) / (24 * 60 * 60 * 1000));
  },

  userVerificationStatus(state): TUserVerificationStatus {
    if (state.entities.user.ban === null) {
      return 'checked';
    }

    switch (state.entities.user.ban.verificationStatus) {
      case EUserVerificationStatus.CHECKED:
        return 'checked';
      case EUserVerificationStatus.UNVERIFIED:
        return 'unverified';
      case EUserVerificationStatus.WAITING:
        return 'waiting';
      case EUserVerificationStatus.UPLOADED:
        return 'uploaded';
      case EUserVerificationStatus.CORRUPTED:
        return 'corrupted';
      case EUserVerificationStatus.FAKE_DOCUMENT:
        return 'fake-document';
      case EUserVerificationStatus.NO_DATE:
        return 'no-date';
      case EUserVerificationStatus.NO_FACE:
        return 'no-face';
      case EUserVerificationStatus.ALIEN_FACE:
        return 'alien-face';
      case EUserVerificationStatus.ALIEN_DOCUMENT:
        return 'alien-document';
      case EUserVerificationStatus.BAD_PHOTO:
        return 'bad-photo';
      case EUserVerificationStatus.UNDER_AGE:
        return 'under-age';
      case EUserVerificationStatus.ACCOUNT_EXIST:
        return 'account-exist';
      case EUserVerificationStatus.NO_DOCUMENT:
        return 'no-document';
      case EUserVerificationStatus.BAD_NAME:
        return 'bad-name';
      case EUserVerificationStatus.NO_QUALITY_FACE:
        return 'no-quality-face';
      case EUserVerificationStatus.ALIEN_IN_FRAME:
        return 'alien-in-frame';
      case EUserVerificationStatus.REMOVE_GLASSES:
        return 'remove-glasses';
      default:
        throw new Error(`unknown user verification status: ${state.entities.user.ban.verificationStatus}`);
    }
  },

  userVerificationStage(state): TUserVerificationStage | undefined {
    const { userVerificationStage } = state.ui.buffer;

    if (userVerificationStage === undefined) {
      return undefined;
    }

    switch (userVerificationStage) {
      case EUserVerificationStage.UNVERIFIED:
        return 'unverified';
      case EUserVerificationStage.WAITING:
        return 'waiting';
      case EUserVerificationStage.REJECTED:
        return 'rejected';
      default:
        throw new Error(`unknown user verification stage ${userVerificationStage}`);
    }
  },

  isVideoSendAttentionDisplayed(state) {
    return state.ui.flags.isVideoSendAttentionDisplayed;
  },

  isUserRoleModerator(state): boolean {
    return state.entities.user.role === EUserRole.MODERATOR;
  },

  isCameraNotSupportedAttentionDisplayed(state) {
    return state.ui.flags.isCameraNotSupportedAttentionDisplayed;
  },

  isCameraNotExistAttentionDisplayed(state) {
    return state.ui.flags.isCameraNotExistAttentionDisplayed;
  },

  isVideochatWithModerator(state): boolean {
    return (
      state.entities.videochat.companion !== null
      && state.entities.videochat.companion.role === ECompanionRole.MODERATOR
    );
  },

  isProcessVideoRecordActive(state): boolean {
    return state.entities.processes.isVideoRecordActive;
  },

  outgoingCallContact(state): TCallContact {
    const { outgoingCall } = state.entities;

    if (outgoingCall === null) {
      throw new Error('outgoing call is null');
    }

    return {
      id: outgoingCall.contact.id,
      name: outgoingCall.contact.name,
      avatar: (
        outgoingCall.contact.avatar === null
          ? undefined
          : outgoingCall.contact.avatar.medium
      ),
    };
  },

  incomingCallContact(state): TCallContact {
    const { incomingCall } = state.entities;

    if (incomingCall === null) {
      throw new Error('incoming call is null');
    }

    return {
      id: incomingCall.contact.id,
      name: incomingCall.contact.name,
      avatar: (
        incomingCall.contact.avatar === null
          ? undefined
          : incomingCall.contact.avatar.medium
      ),
    };
  },

  outgoingCallRejectionReason(state): TOutgoingCallRejectionReason | undefined {
    const { outgoingCall } = state.entities;

    if (outgoingCall === null) {
      throw new Error('outgoing call is null');
    }

    if (outgoingCall.rejectionReason === undefined) {
      return undefined;
    }

    switch (outgoingCall.rejectionReason) {
      case EOutgoingCallRejectionReason.DECLINED:
        return 'declined';
      case EOutgoingCallRejectionReason.OFFLINE:
        return 'offline';
      case EOutgoingCallRejectionReason.OUT_OF_MINUTES:
        return 'out-of-minutes';
      case EOutgoingCallRejectionReason.FAILED:
        return 'failed';
      case EOutgoingCallRejectionReason.UNAVAILABLE:
        return 'unavailable';
      default:
        throw new Error(`unknown outgoing reject reason: ${outgoingCall.rejectionReason}`);
    }
  },

  isIncomingCallAttentionDisplayed(state): boolean {
    return state.ui.flags.isIncomingCallAttentionDisplayed;
  },

  isVideochatStagePostProcess(state): boolean {
    return state.entities.videochat.stage === EVideochatStage.POST_PROCESS;
  },

  isProcessTimeOverLockActive(state): boolean {
    return state.entities.processes.isTimeOverLockActive;
  },

  userPointsForPremiumPurchase(state): number {
    const { pointsForPremiumPurchase: userPointsForPremiumPurchase } = state.entities.user;

    if (userPointsForPremiumPurchase === undefined) {
      throw new Error('user points for premium purchase is undefined');
    }

    return userPointsForPremiumPurchase;
  },

  promoCountdownDuration(state): number {
    const { promoDurationMinutes } = state.entities.settings;

    if (promoDurationMinutes === undefined) {
      throw new Error('promo duration minutes is undefined');
    }

    return promoDurationMinutes;
  },

  userPointsForTextMessage(state): number {
    const { pointsForTextMessage: userPointsForTextMessage } = state.entities.user;

    if (userPointsForTextMessage === undefined) {
      throw new Error('user points for text message is undefined');
    }

    return userPointsForTextMessage;
  },

  userPointsForPhotoMessage(state): number {
    const { pointsForPhotoMessage: userPointsForPhotoMessage } = state.entities.user;

    if (userPointsForPhotoMessage === undefined) {
      throw new Error('user points for photo message is undefined');
    }

    return userPointsForPhotoMessage;
  },

  userPointsForVideoMessage(state): number {
    const { pointsForVideoMessage: userPointsForVideoMessage } = state.entities.user;

    if (userPointsForVideoMessage === undefined) {
      throw new Error('user points for video message is undefined');
    }

    return userPointsForVideoMessage;
  },

  isCurrentCallDisplayed(state): boolean {
    const { videochat, outgoingCall } = state.entities;

    const isVideochatInProcessOrSearchActive = (
      videochat.stage === EVideochatStage.IN_PROCESS
      || videochat.stage === EVideochatStage.SEARCH
    );

    const isOutgoingCallActive = outgoingCall !== null && outgoingCall.rejectionReason === undefined;

    return isVideochatInProcessOrSearchActive || isOutgoingCallActive;
  },

  selectedContactMessageDraft(state): string | undefined {
    const selectedContact = state.entities.contacts.selected;

    if (selectedContact === null) {
      throw new Error('selected contact is null');
    }

    if (state.ui.buffer.messageDrafts === undefined) {
      return undefined;
    }

    return state.ui.buffer.messageDrafts[selectedContact.id];
  },

  isUnsecuredConnectionAttentionDisplayed(state): boolean {
    return state.ui.flags.isUnsecuredConnectionAttentionDisplayed;
  },

  mainAppLink(state, { isRusVideoDatingApp }): string {
    return isRusVideoDatingApp
      ? config.rusVideoDatingMainAppLink
      : config.coomeetMainAppLink;
  },

  usernameMaxLength(state): number | undefined {
    const { usernameMaxLength } = state.entities.settings;

    if (usernameMaxLength === undefined) {
      return undefined;
    }

    return usernameMaxLength;
  },

  emailMaxLength(state): number {
    const { emailMaxLength } = state.entities.settings;

    if (emailMaxLength === undefined) {
      throw new Error('email max length is undefined');
    }

    return emailMaxLength;
  },

  messageMaxLength(state): number {
    const { messageMaxLength } = state.entities.settings;

    if (messageMaxLength === undefined) {
      throw new Error('message max length is undefined');
    }

    return messageMaxLength;
  },

  isPromoBonusAttentionDisplayed(state) {
    return state.ui.flags.isPromoBonusAttentionDisplayed;
  },

  avatarValidationError(state): TUserAvatarValidationError | undefined {
    switch (state.ui.buffer.userAvatarValidationError) {
      case EUserAvatarValidationError.TOO_LARGE:
        return 'too-large';

      case EUserAvatarValidationError.READ_FAILED:
        return 'read-failed';

      default:
        return undefined;
    }
  },

  promoBonusContact(state): IContactEntity | undefined {
    if (state.ui.buffer.promoBonusContactId === undefined) {
      throw new Error('promo contact id is undefined');
    }

    const contact: IContactEntity = (
      state.entities.contacts.list[state.ui.buffer.promoBonusContactId]
    );

    // Обрабатываем нештатные ситуации
    if (contact === undefined) {
      throw new Error(`cant find promo contact by id: ${state.ui.buffer.promoBonusContactId}`);
    }

    return contact;
  },

  localStreamCoordinates(state): { x: number, y: number } {
    return state.entities.settings.localStreamCoordinates;
  },

  localStreamSize(state): TLocalStreamSize {
    const { localStreamSize } = state.entities.settings;

    switch (localStreamSize) {
      case ELocalStreamSize.SMALL:
        return 'small';
      case ELocalStreamSize.MEDIUM:
        return 'medium';
      case ELocalStreamSize.BIG:
        return 'big';
      default:
        throw new Error(`unknown local stream size: ${localStreamSize}`);
    }
  },

  selectedContactCallAvailabilityStatus(state): TOutgoingCallAvailabilityStatus | undefined {
    const {
      videochat,
      outgoingCall,
      incomingCall,
    } = state.entities;

    const isVideochatActive = videochat.stage !== undefined && videochat.stage !== EVideochatStage.FINISH;

    const isOutgoingCallActive = outgoingCall !== null && outgoingCall.rejectionReason === undefined;

    const isIncomingCallActive = incomingCall !== null;

    if (isVideochatActive || isOutgoingCallActive || isIncomingCallActive) {
      return 'unavailable-during-videochat-processes';
    }

    const selectedContact = state.entities.contacts.selected;

    if (selectedContact === null) {
      throw new Error('selected contact is null');
    }

    if (!selectedContact.isOnline) {
      return 'unavailable-with-offline-contact';
    }

    if (state.entities.user.gender === EGender.FEMALE && !selectedContact.isPremium) {
      return 'unavailable-with-not-premium-contact';
    }

    return 'available';
  },

  isMarkContactsMessagesAsReadButtonDisabled(state, { markedContactsIds }): boolean {
    return markedContactsIds.length === 0 && state.entities.contacts.unreadCount === 0;
  },

  isAuthorizationControlsDisabled(state) {
    return state.ui.flags.isAuthorizationControlsDisabled;
  },

  isPurchaseControlsDisabled(state): boolean {
    return state.ui.flags.isPurchaseControlsDisabled;
  },

  isContactAddDisabled(state) {
    return state.ui.flags.isContactAddDisabled;
  },

  isVideochatCompanionFaceHidden(state) {
    const videochatCompanion = state.entities.videochat.companion;

    return (
      state.entities.videochat.stage === EVideochatStage.IN_PROCESS
      && state.entities.user.gender === EGender.FEMALE
      && videochatCompanion !== null
      && !videochatCompanion.isPremium
      && !videochatCompanion.isFaceDetected
    );
  },

  isAppWaitingActive(state): boolean {
    return state.ui.flags.isAppWaitingActive;
  },

  isProcessConnectionActive(state): boolean {
    return state.entities.processes.isConnectionActive;
  },

  isStartWithdrawalAttentionDisplayed(state): boolean {
    return state.ui.flags.isStartWithdrawalAttentionDisplayed;
  },

  pointsCountToWithdrawal(state, { pointsCount }): number {
    const { withdrawalRequest } = state.entities;

    return withdrawalRequest !== null && withdrawalRequest.points !== undefined
      ? withdrawalRequest.points
      : pointsCount;
  },

  withdrawalRequestAmount(state): string {
    const { withdrawalRequest } = state.entities;

    if (
      withdrawalRequest !== null
      && withdrawalRequest.type === EPaymentSystemType.BITCOIN
      && withdrawalRequest.status !== EWithdrawalRequestStatus.SUCCESS
      && withdrawalRequest.status !== EWithdrawalRequestStatus.WAIT
    ) {
      return `${withdrawalRequest.amount} BTC*`;
    }

    let withdrawalRequestAmount: number;

    if (withdrawalRequest !== null && withdrawalRequest.points !== undefined) {
      withdrawalRequestAmount = withdrawalRequest.points;
    } else if (state.entities.user.femalePoints !== undefined) {
      withdrawalRequestAmount = state.entities.user.femalePoints;
    } else {
      withdrawalRequestAmount = 0;
    }

    if (withdrawalRequestAmount !== 0) {
      withdrawalRequestAmount = parseFloat((withdrawalRequestAmount / 100).toFixed(2));
    }

    if (withdrawalRequest !== null && withdrawalRequest.type === EPaymentSystemType.USDT) {
      return `${withdrawalRequestAmount} USDT*`;
    }

    return `$${withdrawalRequestAmount}`;
  },

  withdrawalRequestExchangeRate(state): number | undefined {
    const { withdrawalRequest } = state.entities;

    if (withdrawalRequest === null) {
      return undefined;
    }

    return withdrawalRequest.exchangeRate;
  },

  withdrawalRequestMethod(
    state,
    {
      withdrawalNewMethods,
      withdrawalSavedMethods,
    },
  ): TWithdrawalMethod | undefined {
    const { withdrawalRequest } = state.entities;

    if (withdrawalRequest !== null && withdrawalRequest.type !== undefined) {
      let paymentSystemType: TPaymentSystemType;

      switch (withdrawalRequest.type) {
        case EPaymentSystemType.CARD:
          paymentSystemType = 'card';

          break;
        case EPaymentSystemType.BANK:
          paymentSystemType = 'bank';

          break;
        case EPaymentSystemType.EPSWALLET:
          paymentSystemType = 'e-pay-service';

          break;
        case EPaymentSystemType.YANDEX:
          paymentSystemType = 'yandex-money';

          break;
        case EPaymentSystemType.WEBMONEY:
          paymentSystemType = 'web-money';

          break;
        case EPaymentSystemType.QIWI:
          paymentSystemType = 'qiwi';

          break;
        case EPaymentSystemType.BITCOIN:
          paymentSystemType = 'bitcoin';

          break;
        case EPaymentSystemType.USDT:
          paymentSystemType = 'usdt';

          break;
        default:
          throw new Error(`unknown payment system type: ${withdrawalRequest.type}`);
      }

      const newWithdrawalMethod: TWithdrawalMethod | undefined = withdrawalNewMethods.find(
        (item: TWithdrawalMethod) => item.type === paymentSystemType,
      );

      if (newWithdrawalMethod !== undefined) {
        if (withdrawalRequest.isSavedMethod && withdrawalSavedMethods !== undefined) {
          if (withdrawalRequest.requisites === null) {
            throw new Error('requisites are null');
          }

          const { number } = withdrawalRequest.requisites;

          if (number === undefined) {
            throw new Error('number is undefined');
          }

          return withdrawalSavedMethods.find(
            (item: TWithdrawalMethod) => (
              item.type === paymentSystemType
              && item.title !== undefined
              && item.title.slice(-4) === number.slice(-4)
            ),
          );
        }

        return newWithdrawalMethod;
      }
    }

    const paymentSystemCardIndex = withdrawalNewMethods.findIndex(
      (item: TWithdrawalMethod) => item.type === 'card',
    );

    if (paymentSystemCardIndex !== -1) {
      return withdrawalNewMethods[paymentSystemCardIndex];
    }

    return withdrawalNewMethods[0];
  },

  withdrawalRequestAvailabilityValidationError(
    state,
    {
      withdrawalRequestMethod,
      pointsCountToWithdrawal,
    },
  ): TWithdrawalRequestAvailabilityValidationError | undefined {
    const isWithdrawalRequestAvailable = withdrawalRequestMethod.minimumPoints !== undefined
      && pointsCountToWithdrawal >= withdrawalRequestMethod.minimumPoints;

    if (isWithdrawalRequestAvailable) {
      return undefined;
    }

    if (state.entities.user.isWithdrawalAvailableOnlyBySupport) {
      return 'limited';
    }

    return 'unavailable';
  },

  isPremiumRequiredForSearchBestAttentionDisplayed(state): boolean {
    return state.ui.flags.isPremiumRequiredForSearchBestAttentionDisplayed;
  },

  isMinutesRequiredForSearchBestAttentionDisplayed(state): boolean {
    return state.ui.flags.isMinutesRequiredForSearchBestAttentionDisplayed;
  },

  isWithdrawalYandexRequisitesAttentionDisplayed(state): boolean {
    return state.ui.flags.isWithdrawalYandexRequisitesAttentionDisplayed;
  },

  isWithdrawalBitcoinRequisitesAttentionDisplayed(state): boolean {
    return state.ui.flags.isWithdrawalBitcoinRequisitesAttentionDisplayed;
  },

  isWithdrawalEpayserviceRequisitesAttentionDisplayed(state): boolean {
    return state.ui.flags.isWithdrawalEpayserviceRequisitesAttentionDisplayed;
  },

  isWithdrawalBankFirstStepDisplayed(state): boolean {
    return state.ui.flags.isWithdrawalBankFirstStepDisplayed;
  },

  isWithdrawalBankSecondStepDisplayed(state): boolean {
    return state.ui.flags.isWithdrawalBankSecondStepDisplayed;
  },

  withdrawalRequestRequisitesYandexNumber(state): string | undefined {
    if (
      state.entities.withdrawalRequest === null
      || state.entities.withdrawalRequest.requisites === null
    ) {
      return undefined;
    }

    const requisites = (
      state.entities.withdrawalRequest.requisites as IWithdrawalRequestPaymentSystemYandex
    );

    return requisites.number;
  },

  withdrawalRequestYandexNumberValidationError(
    state,
  ): TWithdrawalRequestYandexNumberValidationError | undefined {
    if (
      state.ui.buffer.withdrawalRequestYandexNumberValidationError
      === EWithdrawalRequestYandexNumberValidationError.EMPTY
    ) {
      return 'empty';
    }

    if (
      state.ui.buffer.withdrawalRequestYandexNumberValidationError
      === EWithdrawalRequestYandexNumberValidationError.INCORRECT
    ) {
      return 'incorrect';
    }

    return undefined;
  },

  withdrawalRequestRequisitesBitcoinNumber(state): string | undefined {
    if (
      state.entities.withdrawalRequest === null
      || state.entities.withdrawalRequest.requisites === null
    ) {
      return undefined;
    }

    const requisites = (
      state.entities.withdrawalRequest.requisites as IWithdrawalRequestPaymentSystemBitcoin
    );

    return requisites.number;
  },

  withdrawalRequestBitcoinNumberValidationError(
    state,
  ): TWithdrawalRequestBitcoinNumberValidationError | undefined {
    if (
      state.ui.buffer.withdrawalRequestBitcoinNumberValidationError
      === EWithdrawalRequestBitcoinNumberValidationError.EMPTY
    ) {
      return 'empty';
    }

    if (
      state.ui.buffer.withdrawalRequestBitcoinNumberValidationError
      === EWithdrawalRequestBitcoinNumberValidationError.INCORRECT
    ) {
      return 'incorrect';
    }

    return undefined;
  },

  isWithdrawalUsdtRequisitesAttentionDisplayed(state): boolean {
    return state.ui.flags.isWithdrawalUsdtRequisitesAttentionDisplayed;
  },

  withdrawalRequestRequisitesUsdtNumber(state): string | undefined {
    if (
      state.entities.withdrawalRequest === null
      || state.entities.withdrawalRequest.requisites === null
    ) {
      return undefined;
    }

    const requisites = (
      state.entities.withdrawalRequest.requisites as IWithdrawalRequestPaymentSystemUsdt
    );

    return requisites.number;
  },

  withdrawalRequestUsdtNumberValidationError(
    state,
  ): TWithdrawalRequestUsdtNumberValidationError | undefined {
    if (
      state.ui.buffer.withdrawalRequestUsdtNumberValidationError
      === EWithdrawalRequestUsdtNumberValidationError.EMPTY
    ) {
      return 'empty';
    }

    if (
      state.ui.buffer.withdrawalRequestUsdtNumberValidationError
      === EWithdrawalRequestUsdtNumberValidationError.INCORRECT
    ) {
      return 'incorrect';
    }

    return undefined;
  },

  withdrawalRequestEpayserviceNumberValidationError(
    state,
  ): TWithdrawalRequestEpayserviceNumberValidationError | undefined {
    if (
      state.ui.buffer.withdrawalRequestEpayserviceNumberValidationError
      === EWithdrawalRequestEpayserviceNumberValidationError.EMPTY
    ) {
      return 'empty';
    }

    if (
      state.ui.buffer.withdrawalRequestEpayserviceNumberValidationError
      === EWithdrawalRequestEpayserviceNumberValidationError.INCORRECT
    ) {
      return 'incorrect';
    }

    return undefined;
  },

  isFinishWithdrawalAttentionDisplayed(state): boolean {
    return state.ui.flags.isFinishWithdrawalAttentionDisplayed;
  },

  isWithdrawalQiwiRequisitesAttentionDisplayed(state): boolean {
    return state.ui.flags.isWithdrawalQiwiRequisitesAttentionDisplayed;
  },

  isWithdrawalMethodRemovalAttentionDisplayed(state): boolean {
    return state.ui.flags.isWithdrawalMethodRemovalAttentionDisplayed;
  },

  withdrawalRequestRequisitesQiwiNumber(state): string | undefined {
    if (
      state.entities.withdrawalRequest === null
      || state.entities.withdrawalRequest.requisites === null
    ) {
      return undefined;
    }

    const requisites = (
      state.entities.withdrawalRequest.requisites as IWithdrawalRequestPaymentSystemQiwi
    );

    return requisites.number;
  },

  withdrawalRequestQiwiNumberValidationError(
    state,
  ): TWithdrawalRequestQiwiNumberValidationError | undefined {
    if (
      state.ui.buffer.withdrawalRequestQiwiNumberValidationError
      === EWithdrawalRequestQiwiNumberValidationError.EMPTY
    ) {
      return 'empty';
    }

    if (
      state.ui.buffer.withdrawalRequestQiwiNumberValidationError
      === EWithdrawalRequestQiwiNumberValidationError.INCORRECT
    ) {
      return 'incorrect';
    }

    return undefined;
  },

  isTariffsControlsDisabled(state): boolean {
    return state.ui.flags.isTariffsControlsDisabled;
  },

  isReferralsLoadingDisabled(state): boolean {
    return state.ui.flags.isReferralsLoadingDisabled;
  },

  isWithdrawalRequestLoadControlsDisabled(state): boolean {
    return state.ui.flags.isWithdrawalRequestLoadControlsDisabled;
  },

  isWithdrawalRequestRejectedAttentionDisplayed(state): boolean {
    return state.ui.flags.isWithdrawalRequestRejectedAttentionDisplayed;
  },

  isWithdrawalRequestRejected(state): boolean | undefined {
    const { withdrawalRequest } = state.entities;

    if (withdrawalRequest === null) {
      return undefined;
    }

    return withdrawalRequest.status === EWithdrawalRequestStatus.ERROR;
  },

  isWithdrawalRequestSendControlsDisabled(state): boolean {
    return state.ui.flags.isWithdrawalRequestSendControlsDisabled;
  },

  withdrawalRequestCardVendor(state): TAppIconPaymentName | undefined {
    const { withdrawalRequest } = state.entities;

    if (
      withdrawalRequest === null
      || withdrawalRequest.type !== EPaymentSystemType.CARD
      || withdrawalRequest.requisites === null
      || withdrawalRequest.requisites.number === undefined
    ) {
      return undefined;
    }

    const number = withdrawalRequest.requisites.number.replace(/\s+/g, '');

    if (config.cardVendorRegexes.visa.test(number)) {
      return 'visa';
    }

    if (config.cardVendorRegexes.masterCard.test(number)) {
      return 'master-card';
    }

    if (config.cardVendorRegexes.americanExpress.test(number)) {
      return 'american-express';
    }

    if (config.cardVendorRegexes.dinersClub.test(number)) {
      return 'diners-club';
    }

    if (config.cardVendorRegexes.discover.test(number)) {
      return 'discover';
    }

    if (config.cardVendorRegexes.jcb.test(number)) {
      return 'jcb';
    }

    if (config.cardVendorRegexes.maestro.test(number)) {
      return 'maestro';
    }

    if (config.cardVendorRegexes.mir.test(number)) {
      return 'mir';
    }

    return undefined;
  },

  isVideochatSearchNextControlsDisabled(state): boolean {
    return state.ui.flags.isVideochatSearchNextControlsDisabled;
  },

  isPurchaseUnavailableAttentionDisplayed(state): boolean {
    return state.ui.flags.isPurchaseUnavailableAttentionDisplayed;
  },

  isVideochatControlsHidden(state): boolean {
    return state.ui.flags.isVideochatControlsHidden;
  },

  isVideochatControlsAutoHidingEnabled(state): boolean {
    return state.ui.flags.isVideochatControlsAutoHidingEnabled;
  },

  isSettingsHighlighted(state): boolean | undefined {
    return !state.entities.user.isSettingsViewed;
  },

  isSettingsAttentionDisplayed(state): boolean {
    return state.ui.flags.isSettingsAttentionDisplayed;
  },

  currentLocale(state): TLocale {
    switch (state.entities.settings.locale) {
      case ELocale.EN:
        return {
          code: 'en',
          title: 'English',
        };
      case ELocale.RU:
        return {
          code: 'ru',
          title: 'Русский',
        };
      case ELocale.DE:
        return {
          code: 'de',
          title: 'Deutsch',
        };
      case ELocale.FR:
        return {
          code: 'fr',
          title: 'Français',
        };
      case ELocale.ES:
        return {
          code: 'es',
          title: 'Espanol',
        };
      case ELocale.IT:
        return {
          code: 'it',
          title: 'Italiano',
        };
      case ELocale.PL:
        return {
          code: 'pl',
          title: 'Polski',
        };
      case ELocale.RO:
        return {
          code: 'ro',
          title: 'Romana',
        };
      case ELocale.TR:
        return {
          code: 'tr',
          title: 'Turkce',
        };
      case ELocale.NL:
        return {
          code: 'nl',
          title: 'Nederlands',
        };
      case ELocale.FI:
        return {
          code: 'fi',
          title: 'Suomi',
        };
      case ELocale.UK:
        return {
          code: 'uk',
          title: 'Українська',
        };
      case ELocale.JA:
        return {
          code: 'ja',
          title: '日本語',
        };
      case ELocale.PT:
        return {
          code: 'pt',
          title: 'Português',
        };
      case ELocale.ZH:
        return {
          code: 'zh',
          title: '中文(简体)',
        };
      default:
        throw new Error(`unknown locale: ${state.entities.settings.locale}`);
    }
  },

  selectedContactLastSeenDate(state): TRelativeDate | undefined {
    const selectedContact = state.entities.contacts.selected;

    if (selectedContact === null) {
      return undefined;
    }

    if (selectedContact.lastSeenTime === undefined) {
      return undefined;
    }

    return getRelativeDate(selectedContact.lastSeenTime);
  },

  isEmailNotificationEnabled(state): boolean | undefined {
    if (state.entities.user.emailNotificationSettings === null) {
      return undefined;
    }

    return (
      !!state.entities.user.emailNotificationSettings.isNewMessageEnabled
      || !!state.entities.user.emailNotificationSettings.isInviteEnabled
      || (
        state.entities.user.malePremiumSubscription !== null
        && state.entities.user.malePremiumSubscription.isActive
        && state.entities.user.malePremiumSubscription.isNewScheme
        && !!state.entities.user.emailNotificationSettings.isBonusMinutesEnabled
      )
    );
  },

  isEmailNotificationNewMessageEnabled(state): boolean | undefined {
    if (state.entities.user.emailNotificationSettings === null) {
      return undefined;
    }

    return state.entities.user.emailNotificationSettings.isNewMessageEnabled;
  },

  isEmailNotificationInviteEnabled(state): boolean | undefined {
    if (state.entities.user.emailNotificationSettings === null) {
      return undefined;
    }

    return state.entities.user.emailNotificationSettings.isInviteEnabled;
  },

  isEmailNotificationBonusMinutesEnabled(state): boolean | undefined {
    if (state.entities.user.emailNotificationSettings === null) {
      return undefined;
    }

    return state.entities.user.emailNotificationSettings.isBonusMinutesEnabled;
  },

  isIncomingCallControlsDisabled(state): boolean {
    return state.ui.flags.isIncomingCallControlsDisabled;
  },

  isProcessLocalStreamStartActive(state): boolean {
    return state.entities.processes.isLocalStreamStartActive;
  },

  isSettingsAttentionControlsDisabled(state): boolean {
    return state.ui.flags.isSettingsAttentionControlsDisabled;
  },

  isPushNotificationSupported(): boolean {
    return PushNotificationRepository.getInstance().getIsSupported();
  },

  isPushNotificationEnabled(state): boolean {
    return (
      !!state.entities.settings.isPushNotificationOnClientEnabled
      && !!state.entities.user.pushNotificationSettings.isSubscribed
    );
  },

  isPushNotificationForClosedAppRequired(state): boolean {
    return !!state.entities.user.pushNotificationSettings.isForClosedAppRequired;
  },

  isPushNotificationMessageTextDisplayed(state): boolean {
    return !!state.entities.user.pushNotificationSettings.isMessageTextDisplayed;
  },

  isPushNotificationBonusMinutesEnabled(state): boolean {
    return !!state.entities.user.pushNotificationSettings.isBonusMinutesEnabled;
  },

  isPushNotificationCountdownLockEndEnabled(state): boolean {
    return !!state.entities.user.pushNotificationSettings.isCountdownLockEndNotificationEnabled;
  },

  pushNotificationSubscriptionState(state): TPushNotificationSubscriptionState | undefined {
    const { pushNotificationSubscriptionState } = state.ui.flags;

    if (pushNotificationSubscriptionState === undefined) {
      return undefined;
    }

    switch (pushNotificationSubscriptionState) {
      case EPushNotificationSubscriptionState.WAITING_PERMISSION:
        return 'waiting-permission';
      case EPushNotificationSubscriptionState.WAITING_SUBSCRIPTION:
        return 'waiting-subscription';
      case EPushNotificationSubscriptionState.SUCCESS:
        return 'success';
      case EPushNotificationSubscriptionState.ERROR:
        return 'error';
      default:
        throw new Error(`unknown push notification subscription state.entities ${pushNotificationSubscriptionState}`);
    }
  },

  isChangeAccountAttentionDisplayed(state): boolean {
    return state.ui.flags.isChangeAccountAttentionDisplayed;
  },

  isChangeAccountAttentionControlsDisabled(state): boolean {
    return state.ui.flags.isChangeAccountAttentionControlsDisabled;
  },

  isLocalStreamHidden(state): boolean {
    return state.ui.flags.isLocalStreamHidden;
  },

  isPartnerPaymentBlockAttentionDisplayed(state): boolean {
    return state.ui.flags.isPartnerPaymentBlockAttentionDisplayed;
  },

  paymentAllowedUrl(state): string | undefined {
    return state.ui.buffer.paymentAllowedUrl;
  },

  userMaleSelectedPremiumPackageId(state): number | undefined {
    if (
      Object.keys(state.entities.user.malePremiumPackages.list).length === 0
      || state.entities.user.maleSelectedPackageId === undefined
    ) {
      return undefined;
    }

    const selectedPremiumPackage = (
      state.entities.user.malePremiumPackages.list[state.entities.user.maleSelectedPackageId]
    );

    return selectedPremiumPackage !== undefined ? selectedPremiumPackage.id : undefined;
  },

  abuseReasons(state): TAbuseReason[] {
    const abuseReasons: TAbuseReason[] = ['spam', 'nude', 'involving-children', 'threats'];

    if (state.entities.user.gender === EGender.MALE) {
      abuseReasons.push('deceiving');
    } else {
      abuseReasons.push('minor-age');
    }

    return abuseReasons;
  },

  isCardConfirmationLockControlsDisabled(state): boolean {
    return state.ui.flags.isCardConfirmationLockControlsDisabled;
  },

  isContactRequestSentActionsDisabled(state): boolean {
    return state.ui.flags.isContactRequestSentActionsDisabled;
  },

  isContactsLoadingErrorDisplayed(state): boolean {
    return state.ui.flags.isContactsLoadingErrorDisplayed;
  },

  isStreamTuningFeatureEnabled(state): boolean {
    return config.features.isStreamTuningEnabled || state.entities.environment.features.includes('stream');
  },

  isWithdrawalToBankAccountFeatureEnabled(state): boolean {
    return config.features.isWithdrawalToBankAccountEnabled || state.entities.environment.features.includes('withdrawal-bank');
  },

  isVideochatStageInRecovery(state): boolean {
    return state.entities.videochat.stage === EVideochatStage.IN_RECOVERY;
  },

  isVideochatStageWaitCompanionRecovery(state): boolean {
    return state.entities.videochat.stage === EVideochatStage.WAIT_COMPANION_RECOVERY;
  },

  isVideochatRecoveryAttentionDisplayed(state): boolean {
    return state.ui.flags.isVideochatRecoveryAttentionDisplayed;
  },

  isVideochatRecoveryFailAttentionDisplayed(state): boolean {
    return state.ui.flags.isVideochatRecoveryFailAttentionDisplayed;
  },

  isVideochatRecoveryWaitingAttentionDisplayed(state): boolean {
    return state.ui.flags.isVideochatRecoveryWaitingAttentionDisplayed;
  },

  isActionImpossibleAttentionDisplayed(state): boolean {
    return state.ui.buffer.actionImpossibleReason !== undefined;
  },

  actionImpossibleReason(state): TActionImpossibleReason {
    switch (state.ui.buffer.actionImpossibleReason) {
      case EActionImpossibleReason.ACCOUNT_BLOCKED:
        return 'account-blocked';
      case EActionImpossibleReason.ACCOUNT_DELETED:
        return 'account-deleted';
      default:
        throw new Error(`unknown action impossible reason: ${state.ui.buffer.actionImpossibleReason}`);
    }
  },

  isUserStoppedVideochat(state): boolean {
    return !!state.ui.buffer.isUserStoppedVideochat;
  },

  isPageLoadErrorAttentionDisplayed(state): boolean {
    return state.ui.flags.isPageLoadErrorAttentionDisplayed;
  },

  videochatRecoveryWaitingDuration(state): number | undefined {
    return state.ui.buffer.videochatRecoveryWaitingDuration;
  },

  isContactRequestAcceptionErrorAttentionDisplayed(state): boolean {
    return state.ui.flags.isContactRequestAcceptionErrorAttentionDisplayed;
  },

  isMessengerFormAllActionsDisabled(state): boolean {
    const { selected: selectedContact } = state.entities.contacts;

    return (
      state.ui.flags.isMessagesSendingDisabled
      || !state.entities.processes.isConnectionActive
      || (
        selectedContact !== null
        && selectedContact.botCommands !== null
        && selectedContact.botCommands.shouldBlockMessagesSending
      )
    );
  },

  isMessengerFormMediaActionsDisabled(state): boolean {
    return (
      state.entities.videochat.stage !== undefined
      && state.entities.videochat.stage !== EVideochatStage.FINISH
    );
  },

  isMessengerFormStickersDisabled(state, { selectedContact }): boolean {
    const { videochat } = state.entities;

    return (
      videochat.stage === EVideochatStage.IN_PROCESS
      || videochat.stage === EVideochatStage.IN_RECOVERY
      || videochat.stage === EVideochatStage.WAIT_COMPANION_RECOVERY
    ) && (
      videochat.companion !== null
      && selectedContact.id === videochat.companion.id
    );
  },

  userReferralBonusPercent(state): number | undefined {
    const { referralBonusPercent: userReferralBonusPercent } = state.entities.user;

    if (userReferralBonusPercent === undefined) {
      return undefined;
    }

    return userReferralBonusPercent;
  },

  isMinutesAutoPurchaseUnavailableAttentionDisplayed(state): boolean {
    return state.ui.flags.isMinutesAutoPurchaseUnavailableAttentionDisplayed;
  },

  isMinutesAutoPurchaseFailedAttentionDisplayed(state): boolean {
    return state.ui.flags.isMinutesAutoPurchaseFailedAttentionDisplayed;
  },

  isMinutesAutoPurchaseTermsAttentionDisplayed(state): boolean {
    return state.ui.flags.isMinutesAutoPurchaseTermsAttentionDisplayed;
  },

  isPremiumSubscriptionRenewalAttentionDisplayed(state): boolean {
    return state.ui.flags.isPremiumSubscriptionRenewalAttentionDisplayed;
  },

  isPremiumSubscriptionExists(state): boolean {
    return state.entities.user.malePremiumSubscription !== null;
  },

  isPremiumSubscriptionActive(state): boolean {
    return (
      state.entities.user.malePremiumSubscription !== null
      && state.entities.user.malePremiumSubscription.isActive
    );
  },

  premiumSubscriptionCardLastNumbers(state): string | undefined {
    const { maleCards: userCards } = state.entities.user;

    if (userCards.premiumSubscription === null) {
      return undefined;
    }

    return userCards.premiumSubscription.lastNumbers;
  },

  userPremiumSubscriptionBonusMinutesTotal(state): number | undefined {
    const { malePremiumSubscription: userPremiumSubscription } = state.entities.user;

    if (userPremiumSubscription === null) {
      return undefined;
    }

    return userPremiumSubscription.bonusMinutesTotal;
  },

  userPremiumSubscriptionExpirationDate(state): number | undefined {
    return state.entities.user.malePremiumExpirationTime;
  },

  isCardChangeSuccessAttentionDisplayed(state): boolean {
    return state.ui.flags.isCardChangeSuccessAttentionDisplayed;
  },

  selectedMinutesPackage(state, { minutesPackages }): IMinutesPackageEntity {
    if (minutesPackages.length === 0) {
      throw new Error('user male minutes packages is empty');
    }

    // По умолчанию должен быть выбран третий пакет
    const { maleSelectedPackageId } = state.entities.user;

    if (maleSelectedPackageId === undefined) {
      return minutesPackages[2];
    }

    const selectedMinutesPackage = minutesPackages.find(
      (item: IMinutesPackageEntity) => item.id === maleSelectedPackageId,
    );

    if (selectedMinutesPackage === undefined) {
      throw new Error('selected minutes package is undefined');
    }

    return selectedMinutesPackage;
  },

  selectedPremiumPackage(state, { premiumPackages }): IPremiumPackageEntity {
    if (premiumPackages.length === 0) {
      throw new Error('user male premium packages are empty');
    }

    // Для премиума по умолчанию должен быть выбран первый пакет
    const { maleSelectedPackageId } = state.entities.user;

    if (maleSelectedPackageId === undefined) {
      return premiumPackages[0];
    }

    const selectedPremiumPackage = premiumPackages.find(
      (item: IPremiumPackageEntity) => item.id === maleSelectedPackageId,
    );

    if (selectedPremiumPackage === undefined) {
      throw new Error('selected premium package is undefined');
    }

    return selectedPremiumPackage;
  },

  isNotEnoughMinutesAttentionDisplayed(state): boolean {
    return state.ui.flags.isNotEnoughMinutesAttentionDisplayed;
  },

  isCardConfirmationWarningAttentionDisplayed(state): boolean {
    return state.ui.flags.isCardConfirmationWarningAttentionDisplayed;
  },

  cardConfirmationMinSum(): number {
    return config.cardConfirmationMinSum;
  },

  cardConfirmationMaxSum(): number {
    return config.cardConfirmationMaxSum;
  },

  videochatCompanionId(state): number | undefined {
    return state.entities.videochat.companion === null ? undefined : state.entities.videochat.companion.id;
  },

  videochatCompanionNote(state): string | undefined {
    return state.entities.videochat.companion === null ? undefined : state.entities.videochat.companion.note;
  },

  hasMinutesAutoPurchaseError(state): boolean {
    return state.entities.user.hasMaleMinutesAutoPurchaseError === true;
  },

  minutesAutoPurchasePackage(state): IMinutesPackageEntity | null {
    return state.entities.user.maleMinutesPackages.autoPurchase;
  },

  cardForMinutesAutoPurchase(state): TCard | null {
    const { maleCards } = state.entities.user;

    if (maleCards.list.length === 0) {
      return null;
    }

    let cardForAutoPurchase = maleCards.minutesAutoPurchase;

    if (cardForAutoPurchase === null) {
      ([cardForAutoPurchase] = maleCards.list);
    }

    let vendor: TAppIconPaymentName;

    switch (cardForAutoPurchase.vendor) {
      case ECardVendor.VISA:
        vendor = 'visa';

        break;
      case ECardVendor.MASTER_CARD:
        vendor = 'master-card';

        break;
      case ECardVendor.MAESTRO:
        vendor = 'maestro';

        break;
      case ECardVendor.JCB:
        vendor = 'jcb';

        break;
      case ECardVendor.DISCOVER:
        vendor = 'discover';

        break;
      case ECardVendor.AMERICAN_EXPRESS:
        vendor = 'american-express';

        break;
      case ECardVendor.DINERS_CLUB:
        vendor = 'diners-club';

        break;
      case ECardVendor.MIR:
        vendor = 'mir';

        break;
      case ECardVendor.UNKNOWN:
        vendor = 'unknown';

        break;
      default:
        throw new Error(`unknown card vendor: ${cardForAutoPurchase.vendor}`);
    }

    return {
      vendor,
      lastNumbers: cardForAutoPurchase.lastNumbers,
    };
  },

  isNotEnoughMinutesDisabled(state): boolean {
    return state.ui.flags.isNotEnoughMinutesDisabled;
  },

  minMinutesCountForAutoPurchaseActivation(state): number {
    const { minutesCountForAutoPurchaseActivation } = state.entities.settings;

    if (minutesCountForAutoPurchaseActivation === undefined) {
      throw new Error('minutes count for auto purchase activation is undefined');
    }

    return minutesCountForAutoPurchaseActivation;
  },

  firstAutoPurchaseAttemptMinutesCount(state): number {
    const { firstAutoPurchaseAttemptMinutesCount } = state.entities.settings;

    if (firstAutoPurchaseAttemptMinutesCount === undefined) {
      throw new Error('first auto purchase attempt minutes count is undefined');
    }

    return firstAutoPurchaseAttemptMinutesCount;
  },

  secondAutoPurchaseAttemptMinutesCount(state): number {
    const { secondAutoPurchaseAttemptMinutesCount } = state.entities.settings;

    if (secondAutoPurchaseAttemptMinutesCount === undefined) {
      throw new Error('second auto purchase attempt minutes count is undefined');
    }

    return secondAutoPurchaseAttemptMinutesCount;
  },

  isBrowserNotSupportedAttentionDisplayed(state): boolean {
    return state.ui.flags.isBrowserNotSupportedAttentionDisplayed;
  },

  isWebmPlaybackSupported(): boolean {
    let video: HTMLVideoElement | undefined = document.createElement('video');

    const isWebmPlaybackSupported = video.canPlayType(config.webmPlaybackMimeType) === 'probably';

    video = undefined;

    return isWebmPlaybackSupported;
  },

  isMp4PlaybackSupported(): boolean {
    let video: HTMLVideoElement | undefined = document.createElement('video');

    const isMp4PlaybackSupported = video.canPlayType(config.mp4PlaybackMimeType) === 'probably';

    video = undefined;

    return isMp4PlaybackSupported;
  },

  isPushNotificationBonusAttentionDisplayed(state): boolean {
    return state.ui.flags.isPushNotificationBonusAttentionDisplayed;
  },

  isUserPremiumSubscriptionEnablingCardSelectionDisplayed(state): boolean {
    return state.ui.flags.isUserPremiumSubscriptionEnablingCardSelectionDisplayed;
  },

  isUserPremiumSubscriptionEnablingCardRemovalConfirmDisplayed(state): boolean {
    return state.ui.flags.isUserPremiumSubscriptionEnablingCardRemovalConfirmDisplayed;
  },

  isUserPremiumSubscriptionChangingCardSelectionDisplayed(state): boolean {
    return state.ui.flags.isUserPremiumSubscriptionChangingCardSelectionDisplayed;
  },

  isUserPremiumSubscriptionChangingCardRemovalConfirmDisplayed(state): boolean {
    return state.ui.flags.isUserPremiumSubscriptionChangingCardRemovalConfirmDisplayed;
  },

  isCurrentWithdrawalRequestMethodAvailable(state): boolean {
    const { withdrawalRequest } = state.entities;

    if (withdrawalRequest === null) {
      throw new Error('withdrawal request is null');
    }

    return state.entities.user.withdrawalNewMethods.list
      .some((withdrawalMethod) => withdrawalMethod.type === withdrawalRequest.type);
  },

  pushNotificationBonusMinutes(state): number {
    return state.entities.user.pushNotificationBonusMinutes || 0;
  },

  imageMaxUploadSize(state): number {
    const { imageMaxUploadSize } = state.entities.settings;

    if (imageMaxUploadSize === undefined) {
      throw new Error('image max upload size is undefined');
    }

    return imageMaxUploadSize;
  },

  isBonusMinutesAppealAttentionDisplayed(state): boolean {
    return state.ui.flags.isBonusMinutesAppealAttentionDisplayed;
  },

  userEmailConfirmationBonusMinutes(state): number {
    const { emailConfirmationBonusMinutes: userEmailConfirmationBonusMinutes } = state.entities.user;

    if (userEmailConfirmationBonusMinutes === undefined) {
      throw new Error('user email confirmation bonus minutes is undefined');
    }

    return userEmailConfirmationBonusMinutes;
  },

  userOptionalEmailConfirmationBonusMinutes(state): number | undefined {
    return state.entities.user.emailConfirmationBonusMinutes;
  },

  isMessengerNotificationAppealDisplayed(
    state,
    {
      hasUserAuthorization,
      isPushNotificationSupported,
      isPushNotificationEnabled,
    },
  ): boolean {
    return (
      state.ui.flags.shouldShowMessengerNotificationAppeal
      && hasUserAuthorization
      && isPushNotificationSupported
      && !isPushNotificationEnabled
    );
  },

  isVideochatNotificationAppealDisplayed(
    state,
    {
      hasUserAuthorization,
      isPushNotificationSupported,
      isPushNotificationEnabled,
    },
  ): boolean {
    return (
      state.ui.flags.shouldShowVideochatNotificationAppeal
      && hasUserAuthorization
      && isPushNotificationSupported
      && !isPushNotificationEnabled
    );
  },

  isPremiumSubscriptionNotificationAppealDisplayed(
    state,
    {
      isPushNotificationSupported,
      isPushNotificationEnabled,
    },
  ): boolean {
    return isPushNotificationSupported && !isPushNotificationEnabled;
  },

  isBackToVideochatDisplayed(state): boolean {
    return state.ui.flags.isBackToVideochatDisplayed;
  },

  isCountdownLockDisplayed(state): boolean {
    return state.ui.flags.isCountdownLockDisplayed;
  },

  photogridStoryAuthorId(state): number | undefined {
    const { user } = state.entities;

    if (user === null) {
      return undefined;
    }

    if (user.gender === EGender.MALE) {
      const { preview: storyPreview } = state.entities.stories;

      if (storyPreview === null) {
        return undefined;
      }

      return storyPreview.authorId;
    }

    return user.id;
  },

  photogridStoryAuthorAvatarPath(state): string | undefined {
    const { avatar: userAvatar } = state.entities.user;

    if (userAvatar === null) {
      return undefined;
    }

    return userAvatar.small;
  },

  photogridStoryImagePath(state): string | undefined {
    const { user } = state.entities;

    if (user.gender === EGender.MALE) {
      const { preview: storyPreview } = state.entities.stories;

      if (storyPreview === null) {
        return undefined;
      }

      return storyPreview.imagePath;
    }

    const { recordedStory: userRecordedStory } = user;

    if (userRecordedStory === null || !userRecordedStory.isImageReady) {
      return undefined;
    }

    return userRecordedStory.imagePath;
  },

  isStoryLockDisplayed(state): boolean {
    return state.ui.flags.isStoryLockDisplayed;
  },

  storyLockBackgroundImage(state): string | undefined {
    const { active: activeStory } = state.entities.stories;

    if (activeStory === null || activeStory.authorAvatar === null) {
      return undefined;
    }

    return activeStory.authorAvatar.medium;
  },

  userStoryLockExpirationTime(state): number {
    const { storyLockExpirationTime: userStoryLockExpirationTime } = state.entities.user;

    if (userStoryLockExpirationTime === undefined) {
      throw new Error('user story lock expiration time is undefined');
    }

    return userStoryLockExpirationTime;
  },

  isStoryDisabled(state): boolean {
    return state.entities.stories.active === null;
  },

  storyAuthor(state): TStoryAuthor | null {
    const { active: activeStory } = state.entities.stories;

    if (activeStory === null) {
      return null;
    }

    let storyAuthorContactStatus: TStoryAuthorContactStatus;

    switch (activeStory.authorContactStatus) {
      case EStoryAuthorContactStatus.NONE:
        storyAuthorContactStatus = 'none';

        break;
      case EStoryAuthorContactStatus.REQUEST_SENT:
        storyAuthorContactStatus = 'request-sent';

        break;
      case EStoryAuthorContactStatus.REQUEST_RECEIVED:
        storyAuthorContactStatus = 'request-received';

        break;
      case EStoryAuthorContactStatus.REQUEST_ACCEPTED:
        storyAuthorContactStatus = 'request-accepted';

        break;
      case EStoryAuthorContactStatus.REQUEST_DECLINED:
        storyAuthorContactStatus = 'request-declined';

        break;
      default:
        throw new Error(`unknown story author contact status: ${activeStory.authorContactStatus}`);
    }

    return {
      id: activeStory.authorId,
      name: activeStory.authorName,
      avatarPath: activeStory.authorAvatar !== null ? activeStory.authorAvatar.small : undefined,
      contactStatus: storyAuthorContactStatus,
      isOnline: activeStory.isAuthorOnline,
    };
  },

  storyVideoPath(state, { isMp4PlaybackSupported }): string | undefined {
    const { active: activeStory } = state.entities.stories;

    if (activeStory === null) {
      return undefined;
    }

    if (isMp4PlaybackSupported) {
      return activeStory.mp4VideoPath;
    }

    return activeStory.webmVideoPath;
  },

  storyDate(state): TRelativeDate | undefined {
    const { active: activeStory } = state.entities.stories;

    if (activeStory === null) {
      return undefined;
    }

    return getRelativeDate(activeStory.publicationTime);
  },

  isStoryLiked(state): boolean | undefined {
    const { active: activeStory } = state.entities.stories;

    if (activeStory === null) {
      return undefined;
    }

    return activeStory.isLiked;
  },

  isStoryVolumeEnabled(state): boolean {
    return state.entities.settings.isStoryVolumeEnabled;
  },

  isStoriesViewDisabled(state): boolean {
    const {
      videochat,
      outgoingCall,
      incomingCall,
    } = state.entities;

    const isVideochatActive = videochat.stage !== undefined && videochat.stage !== EVideochatStage.FINISH;

    const isOutgoingCallActive = outgoingCall !== null && outgoingCall.rejectionReason === undefined;

    const isIncomingCallActive = incomingCall !== null;

    return isVideochatActive || isOutgoingCallActive || isIncomingCallActive;
  },

  minutesAutoPurchasePackageId(state): IMinutesPackageEntity['id'] {
    if (state.ui.buffer.userMaleMinutesAutoPurchasePackageId === undefined) {
      throw new Error('minutes auto purchase package id is undefined');
    }

    return state.ui.buffer.userMaleMinutesAutoPurchasePackageId;
  },

  minutesAutoPurchaseCardLastNumbers(state): ICardEntity['lastNumbers'] {
    if (state.ui.buffer.userMaleMinutesAutoPurchaseCardLastNumbers === undefined) {
      throw new Error('minutes auto purchase card last numbers is undefined');
    }

    return state.ui.buffer.userMaleMinutesAutoPurchaseCardLastNumbers;
  },

  cardLastNumbersForRemoval(state): TCard['lastNumbers'] | undefined {
    return state.ui.buffer.cardLastNumbersForRemoval;
  },

  videochatModerationSupportContactId(state): TContact['id'] {
    if (state.entities.contacts.supportIds === null) {
      throw new Error('support ids is null');
    }

    return state.entities.contacts.supportIds.videochatModeration;
  },

  financialQuestionsSupportContactId(state): TContact['id'] {
    if (state.entities.contacts.supportIds === null) {
      throw new Error('support ids is null');
    }

    return state.entities.contacts.supportIds.financialQuestions;
  },

  generalQuestionsSupportContactId(state): TContact['id'] {
    if (state.entities.contacts.supportIds === null) {
      throw new Error('support ids is null');
    }

    return state.entities.contacts.supportIds.generalQuestions;
  },

  photogridStoryStatus(state, { isWebmPlaybackSupported, isMp4PlaybackSupported }): TPhotoGridStoryStatus {
    if (state.ui.flags.isAppWaitingActive) {
      return 'loading';
    }

    if (state.entities.user.gender === EGender.MALE) {
      if (state.entities.stories.preview === null) {
        return 'not-exist';
      }

      return 'exist';
    }

    const { recordedStory: userRecordedStory } = state.entities.user;

    if (userRecordedStory === null) {
      return 'not-exist';
    }

    const isUserRecordedStoryPending = !(
      (userRecordedStory.isWebmVideoReady && isWebmPlaybackSupported)
      || (userRecordedStory.isMp4VideoReady && isMp4PlaybackSupported)
    );

    const isUserRecordedStoryNotModerated = userRecordedStory.moderationStatus === undefined;

    if (isUserRecordedStoryPending && isUserRecordedStoryNotModerated) {
      return 'loading';
    }

    return 'exist';
  },

  userPointsForRecordedStoryLike(state): number | undefined {
    return state.entities.user.pointsForRecordedStoryLike;
  },

  settingsRecordedStoryMaxDuration(state): number | undefined {
    return state.entities.settings.recordedStoryMaxDuration;
  },

  settingsRecordedStoryLifetime(state): number | undefined {
    return state.entities.settings.recordedStoryLifetime;
  },

  userRecordedStoryViewsCount(state): number | undefined {
    const { recordedStory: userRecordedStory } = state.entities.user;

    if (userRecordedStory === null) {
      return undefined;
    }

    return userRecordedStory.viewsCount;
  },

  userRecordedStoryEarnedPoints(state): number | undefined {
    const { recordedStory: userRecordedStory } = state.entities.user;

    if (userRecordedStory === null) {
      return undefined;
    }

    return userRecordedStory.earnedPoints;
  },

  userRecordedStoryLikesCount(state): number | undefined {
    const { recordedStory: userRecordedStory } = state.entities.user;

    if (userRecordedStory === null) {
      return undefined;
    }

    return userRecordedStory.likesCount;
  },

  userRecordedStoryModerationStatus(state): TRecordedStoryModerationStatus | undefined {
    const { recordedStory: userRecordedStory } = state.entities.user;

    if (userRecordedStory === null || userRecordedStory.moderationStatus === undefined) {
      return undefined;
    }

    switch (userRecordedStory.moderationStatus) {
      case ERecordedStoryModerationStatus.APPROVED:
        return 'approved';
      case ERecordedStoryModerationStatus.REJECTED_PORN:
        return 'rejected-porn';
      case ERecordedStoryModerationStatus.REJECTED_ADVERTISING:
        return 'rejected-advertising';
      case ERecordedStoryModerationStatus.REJECTED_AGE:
        return 'rejected-age';
      case ERecordedStoryModerationStatus.REJECTED_CHILD:
        return 'rejected-child';
      case ERecordedStoryModerationStatus.REJECTED_GENDER:
        return 'rejected-gender';
      case ERecordedStoryModerationStatus.REJECTED_PHOTO:
        return 'rejected-photo';
      case ERecordedStoryModerationStatus.REJECTED_RULES:
        return 'rejected-rules';
      case ERecordedStoryModerationStatus.REJECTED_LOADING_ERROR:
        return 'rejected-loading-error';
      default:
        throw new Error(`unknown user recorded story moderation status: ${userRecordedStory.moderationStatus}`);
    }
  },

  isUserRecordedStoryOnModeration(state): boolean {
    const { recordedStory: userRecordedStory } = state.entities.user;

    return userRecordedStory !== null && userRecordedStory.moderationStatus === undefined;
  },

  userRecordedStoryVideoPath(state, { isWebmPlaybackSupported, isMp4PlaybackSupported }): string | undefined {
    const { recordedStory: userRecordedStory } = state.entities.user;

    if (userRecordedStory !== null && userRecordedStory.moderationStatus === undefined) {
      if (userRecordedStory.isWebmVideoReady && isWebmPlaybackSupported) {
        return userRecordedStory.webmVideoPath;
      }

      if (userRecordedStory.isMp4VideoReady && isMp4PlaybackSupported) {
        return userRecordedStory.mp4VideoPath;
      }
    }

    const { video: recorderVideo } = state.entities.recorder;

    return recorderVideo === null ? undefined : window.URL.createObjectURL(recorderVideo);
  },

  isUserEmailConfirmationBonusAvailable(state): boolean {
    return !!state.entities.user.isEmailConfirmationBonusAvailable;
  },

  userPremiumSubscriptionPackage(state): IPremiumPackageEntity | undefined {
    const { malePremiumPackages: userPremiumPackages } = state.entities.user;

    return Object.values(userPremiumPackages.list).find((item) => item.isSubscription);
  },

  userPremiumSubscriptionPackageRelativePrice(
    state,
    { userPremiumSubscriptionPackage },
  ): IPremiumPackageEntity['relativePrice'] {
    if (userPremiumSubscriptionPackage === undefined) {
      throw new Error('user premium subscription package is undefined');
    }

    return userPremiumSubscriptionPackage.relativePrice;
  },

  userPremiumSubscriptionPackageBonusMinutesTotal(
    state,
    { userPremiumSubscriptionPackage },
  ): IPremiumPackageEntity['bonusMinutesTotal'] {
    if (userPremiumSubscriptionPackage === undefined) {
      throw new Error('user premium subscription package is undefined');
    }

    return userPremiumSubscriptionPackage.bonusMinutesTotal;
  },

  premiumSubscriptionPurchaseGateway(state): IPurchaseGatewayEntity | null {
    return state.ui.buffer.premiumSubscriptionPurchaseGateway;
  },

  purchasedPackageId(state): IMinutesPackageEntity['id'] | IPremiumPackageEntity['id'] | undefined {
    return state.ui.buffer.purchasedPackageId;
  },

  cardLastNumbersForPremiumSubscription(state): TCard['lastNumbers'] | undefined {
    return state.ui.buffer.cardLastNumbersForPremiumSubscription;
  },

  purchaseType(state): TPurchaseType | undefined {
    const { purchaseType } = state.ui.buffer;

    switch (purchaseType) {
      case EPurchaseType.PREMIUM:
        return 'premium';
      case EPurchaseType.MINUTES:
        return 'minutes';
      case undefined:
        return undefined;
      default:
        throw new Error(`unknown purchase type ${purchaseType}`);
    }
  },

  storyContactId(state): number | undefined {
    const { active: activeStory } = state.entities.stories;

    if (activeStory === null || activeStory.authorContactStatus === EStoryAuthorContactStatus.NONE) {
      return undefined;
    }

    return activeStory.authorId;
  },

  storyContactName(state): string {
    const { active: activeStory } = state.entities.stories;

    if (activeStory === null) {
      throw new Error('story is null');
    }

    return activeStory.authorName;
  },

  storyContactAvatarPath(state): string | undefined {
    const { active: activeStory } = state.entities.stories;

    if (activeStory === null) {
      throw new Error('story is null');
    }

    if (activeStory.authorAvatar === null) {
      return undefined;
    }

    return activeStory.authorAvatar.medium;
  },

  minutesPackagesForPendingGift(state, { minutesPackages }): IMinutesPackageEntity[] {
    if (minutesPackages.length === 0) {
      throw new Error('minutes packages are empty');
    }

    const { gifts } = state.entities;

    if (gifts.pending === null) {
      return minutesPackages;
    }

    const pendingGift = gifts.list[gifts.pending.id];

    if (pendingGift === undefined) {
      throw new Error('pending gift is undefined');
    }

    const lastGiftPrice = Math.round(pendingGift.maleCostInMilliseconds / (60 * 1000));

    return minutesPackages.filter((item: IMinutesPackageEntity) => item.minutes >= lastGiftPrice);
  },

  selectedMinutesPackageForPendingGift(state, { minutesPackagesForPendingGift }): IMinutesPackageEntity {
    if (minutesPackagesForPendingGift.length === 0) {
      throw new Error('minutes packages for pending gift are empty');
    }

    return minutesPackagesForPendingGift[minutesPackagesForPendingGift.length - 1];
  },

  isStoryRemovalConfirmDisplayed(state): boolean {
    return state.ui.flags.isStoryRemovalConfirmDisplayed;
  },

  isStoryRecordCloseDisplayed(state): boolean {
    return state.ui.flags.isStoryRecordCloseDisplayed;
  },

  isFunctionalUnavailableDisplayed(state): boolean {
    return state.ui.flags.isFunctionalUnavailableDisplayed;
  },

  isStoryNavigationDisplayed(state): boolean {
    return state.ui.flags.shouldShowStoryNavigation && state.entities.user.gender === EGender.MALE;
  },

  prevStoryAuthorId(state): number | undefined {
    const { stories } = state.entities;

    if (stories.active === null) {
      return undefined;
    }

    const prevStoryIndex = stories.list.indexOf(stories.active.authorId) - 1;

    if (prevStoryIndex === -1) {
      if (stories.list.length > 1) {
        return stories.list[stories.list.length - 1];
      }

      return undefined;
    }

    return stories.list[prevStoryIndex];
  },

  nextStoryAuthorId(state): number | undefined {
    const { stories } = state.entities;

    if (stories.active === null) {
      return undefined;
    }

    return stories.list[stories.list.indexOf(stories.active.authorId) + 1];
  },

  selectedContactId(state): TContact['id'] | undefined {
    const selectedContact = state.entities.contacts.selected;

    if (selectedContact === null) {
      return undefined;
    }

    return selectedContact.id;
  },

  isStoryEarnedPointsDisplayed(state): boolean {
    return state.ui.flags.isStoryEarnedPointsDisplayed;
  },

  isUserAccessDeniedAttentionDisplayed(state): boolean {
    return state.ui.flags.isUserAccessDeniedAttentionDisplayed;
  },

  userStoryEarnedPoints(state): number {
    const { storyEarnedPoints: userStoryEarnedPoints } = state.entities.user;

    if (userStoryEarnedPoints === undefined) {
      throw new Error('user story earned points is undefined');
    }

    return userStoryEarnedPoints;
  },

  contactsScrollPosition(state): number | undefined {
    return state.ui.buffer.contactsScrollPosition;
  },

  videochatStartTime(state): number | undefined {
    return state.entities.videochat.startTime;
  },

  userPremiumSubscriptionPackageBonusMinutesInstant(state, { userPremiumSubscriptionPackage }): number {
    if (userPremiumSubscriptionPackage === undefined) {
      throw new Error('user premium subscription package is undefined');
    }

    if (userPremiumSubscriptionPackage.bonusMinutesForInterval === undefined) {
      throw new Error('user premium subscription package bonus minutes for interval is undefined');
    }

    return userPremiumSubscriptionPackage.bonusMinutesForInterval;
  },

  userPremiumSubscriptionPackageBonusMinutesFuture(
    state,
    {
      userPremiumSubscriptionPackageBonusMinutesTotal,
      userPremiumSubscriptionPackageBonusMinutesInstant,
    },
  ): number {
    return userPremiumSubscriptionPackageBonusMinutesTotal - userPremiumSubscriptionPackageBonusMinutesInstant;
  },

  userPremiumSubscriptionBonusMinutesFuture(state): number {
    const { malePremiumSubscription: userPremiumSubscription } = state.entities.user;

    if (userPremiumSubscription === null) {
      throw new Error('user premium subscription is null');
    }

    return userPremiumSubscription.bonusMinutesTotal - userPremiumSubscription.bonusMinutesForInterval;
  },

  userPremiumSubscriptionBonusMinutesNextAdditionDate(state): number | undefined {
    const { malePremiumSubscription: userPremiumSubscription } = state.entities.user;

    if (userPremiumSubscription === null) {
      throw new Error('user premium subscription is null');
    }

    return userPremiumSubscription.bonusMinutesNextAdditionDate;
  },

  userPremiumSubscriptionBonusMinutesToAdd(state): number {
    const { malePremiumSubscription: userPremiumSubscription } = state.entities.user;

    if (userPremiumSubscription === null) {
      return 0;
    }

    return userPremiumSubscription.bonusMinutesToAdd;
  },

  userPremiumSubscriptionBonusMinutesRemaining(state): number {
    const { malePremiumSubscription: userPremiumSubscription } = state.entities.user;

    if (userPremiumSubscription === null) {
      return 0;
    }

    return Math.floor(userPremiumSubscription.bonusMillisecondsRemaining / (60 * 1000));
  },

  userPremiumSubscriptionPurchasedMinutesRemaining(
    state,
    {
      remainingMinutesCount,
      userPremiumSubscriptionBonusMinutesRemaining,
    },
  ): number {
    return Math.floor(remainingMinutesCount - userPremiumSubscriptionBonusMinutesRemaining);
  },

  isUserPremiumSubscriptionPushNotificationEnabled(state): boolean {
    const { isBonusMinutesEnabled: IsUserPushNotificationSettingsBonusMinutesEnabled } = (
      state.entities.user.pushNotificationSettings
    );

    return IsUserPushNotificationSettingsBonusMinutesEnabled === true;
  },

  userPremiumSubscriptionNextAvailabilityDate(state): number | undefined {
    const { malePremiumSubscription: userPremiumSubscription } = state.entities.user;

    if (userPremiumSubscription === null || userPremiumSubscription.nextAvailabilityDate === undefined) {
      return undefined;
    }

    return userPremiumSubscription.nextAvailabilityDate;
  },

  isPremiumSubscriptionBonusMinutesRenewalDisplayed(state): boolean {
    return state.ui.flags.isPremiumSubscriptionBonusMinutesRenewalDisplayed;
  },

  userPremiumSubscriptionBonusMinutesForInterval(state): number {
    const { malePremiumSubscription: userPremiumSubscription } = state.entities.user;

    if (userPremiumSubscription === null) {
      throw new Error('user premium subscription is null');
    }

    return userPremiumSubscription.bonusMinutesForInterval;
  },

  isPremiumSubscriptionDetailsButtonDisplayed(state, { userPremiumSubscriptionPackage }): boolean {
    const { malePremiumSubscription: userPremiumSubscription } = state.entities.user;

    return (
      userPremiumSubscriptionPackage !== undefined
      || (
        userPremiumSubscription !== null
        && userPremiumSubscription.isNewScheme
        && (
          userPremiumSubscription.isActive
          || (
            userPremiumSubscription.nextAvailabilityDate !== undefined
            && userPremiumSubscription.nextAvailabilityDate > new Date().setHours(0, 0, 0, 0)
          )
        )
      )
    );
  },

  isUserPremiumSubscriptionNewScheme(state): boolean {
    return (
      state.entities.user.malePremiumSubscription !== null
      && state.entities.user.malePremiumSubscription.isNewScheme
    );
  },

  userPremiumSubscriptionBonusMinutesAdditionDate(state): TRelativeDate {
    const { user } = state.entities;

    if (user.isMalePremium) {
      const { malePremiumSubscription: userPremiumSubscription } = user;

      if (
        userPremiumSubscription === null
        || userPremiumSubscription.bonusMinutesToAdd === userPremiumSubscription.bonusMinutesTotal
      ) {
        if (user.malePremiumExpirationTime === undefined) {
          throw new Error('user male premium expiration time is undefined');
        }

        return {
          isToday: false,
          isYesterday: false,
          timestamp: user.malePremiumExpirationTime,
        };
      }
    }

    return {
      isToday: true,
      isYesterday: false,
      timestamp: Date.now(),
    };
  },

  isPremiumSubscriptionCardRemovalTextDisplayed(state): boolean {
    const { malePremiumSubscription: userPremiumSubscription, maleCards: userCards } = state.entities.user;

    if (userPremiumSubscription === null) {
      return false;
    }

    return (
      userPremiumSubscription.isActive
      && userPremiumSubscription.nextAvailabilityDate !== undefined
      && userCards.premiumSubscription !== null
      && userCards.premiumSubscription.lastNumbers === state.ui.buffer.cardLastNumbersForRemoval
    );
  },

  videochatQueuePosition(state): number | undefined {
    return state.entities.videochat.queuePosition;
  },

  isVideochatStopDisabled(state): boolean {
    return (
      state.entities.videochat.stage === EVideochatStage.POST_PROCESS
      || state.entities.videochat.stage === EVideochatStage.IN_RECOVERY
      || state.ui.flags.isVideochatStopDisabled
    );
  },

  isVideochatModerationDisabled(state): boolean {
    return (
      state.entities.videochat.stage !== EVideochatStage.IN_PROCESS
      || state.ui.flags.isVideochatModerationDisabled
    );
  },

  isNavigationPremiumSubscriptionButtonDisplayed(state, { userPremiumSubscriptionPackage }): boolean {
    return userPremiumSubscriptionPackage !== undefined;
  },

  isPersonalOfferDisplayed(state): boolean {
    return state.ui.flags.isPersonalOfferDisplayed;
  },

  isActivePremiumSubscriptionDisplayed(state): boolean {
    return state.ui.flags.isActivePremiumSubscriptionDisplayed;
  },

  isInactivePremiumSubscriptionDisplayed(state): boolean {
    return state.ui.flags.isInactivePremiumSubscriptionDisplayed;
  },

  isUserPremiumSubscriptionEnabled(state): boolean {
    const { malePremiumSubscription: userPremiumSubscription } = state.entities.user;

    return userPremiumSubscription !== null && userPremiumSubscription.isActive;
  },

  isPremiumSubscriptionPurchasedDisplayed(state): boolean {
    return state.ui.flags.isPremiumSubscriptionPurchasedDisplayed;
  },

  isPremiumSubscriptionExplanationDisplayed(state): boolean {
    return state.ui.flags.isPremiumSubscriptionExplanationDisplayed;
  },

  isPremiumSubscriptionDeactivateDisplayed(state): boolean {
    return state.ui.flags.isPremiumSubscriptionDeactivateDisplayed;
  },

  isPremiumSubscriptionCardSelectionDisplayed(state): boolean {
    return state.ui.flags.isPremiumSubscriptionCardSelectionDisplayed;
  },

  isPremiumSubscriptionCardRemovalDisplayed(state): boolean {
    return state.ui.flags.isPremiumSubscriptionCardRemovalDisplayed;
  },

  isPremiumSubscriptionPurchaseErrorDisplayed(state): boolean {
    return state.ui.flags.isPremiumSubscriptionPurchaseErrorDisplayed;
  },

  userPremiumSubscriptionRelativePrice(state): number {
    const { malePremiumSubscription: userPremiumSubscription } = state.entities.user;

    if (userPremiumSubscription === null) {
      throw new Error('user premium subscription is null');
    }

    return userPremiumSubscription.price;
  },

  isUserPremiumBySubscription(state): boolean {
    const { user } = state.entities;

    return (
      user.isMalePremium === true
      && user.malePremiumSubscription !== null
      && user.malePremiumSubscription.isActive
    );
  },

  userPremiumSubscriptionBonusMinutesAdded(state): number {
    const { malePremiumSubscription: userPremiumSubscription } = state.entities.user;

    if (userPremiumSubscription === null) {
      throw new Error('user premium subscription is null');
    }

    return userPremiumSubscription.bonusMinutesTotal - userPremiumSubscription.bonusMinutesToAdd;
  },

  isVideochatStartViewStoryControlDisabled(state): boolean {
    return (
      state.ui.flags.isAppWaitingActive
      || state.entities.processes.isLocalStreamStartActive
      || state.entities.stories.preview === null
    );
  },

  storiesPreviewAuthorId(state): number | undefined {
    const { preview: storyPreview } = state.entities.stories;

    if (storyPreview === null) {
      throw new Error('story preview is null');
    }

    return storyPreview.authorId;
  },

  isNewVideochatStartFeatureEnabled(state): boolean {
    return (
      config.features.isNewVideochatStartEnabled
      || state.entities.environment.features.includes('new-videochat-start')
    );
  },

  isStoryUrlCopyFeatureEnabled(state): boolean {
    return (
      config.features.isStoryUrlCopyEnabled
      || state.entities.environment.features.includes('story-url-copy')
    );
  },

  contactNameValidationError(state): TContactNameValidationError | undefined {
    if (state.ui.buffer.contactNameValidationError === EContactNameValidationError.EMPTY) {
      return 'empty';
    }

    if (state.ui.buffer.contactNameValidationError === EContactNameValidationError.SHORT) {
      return 'short';
    }

    if (
      state.ui.buffer.contactNameValidationError === EContactNameValidationError.CONTAINS_EMOJI
    ) {
      return 'contains-emoji';
    }

    return undefined;
  },

  isContactNameEditingDisplayed(state): boolean {
    return state.ui.flags.isContactNameEditingDisplayed;
  },

  isContactNameEditingAfterContactAcceptingDisplayed(state): boolean {
    return state.ui.flags.isContactNameEditingAfterContactAcceptingDisplayed;
  },

  isUnavailableSearchAttentionDisplayed(state): boolean {
    return state.ui.flags.isUnavailableSearchAttentionDisplayed;
  },

  isStoryAbuseAttentionDisplayed(state): boolean {
    return state.ui.flags.isStoryAbuseAttentionDisplayed;
  },

  storyAbuseReasons(): TStoryAbuseReason[] {
    return ['nude', 'minor-age', 'spam', 'hateful', 'violent', 'copyright'];
  },

  selectedContactRequestGift(state): TGiftType | undefined {
    const { selected: selectedContact } = state.entities.contacts;

    if (selectedContact === null) {
      throw new Error('selected contact is null');
    }

    return selectedContact.contactRequestGift !== undefined
      ? getGiftTypeById(selectedContact.contactRequestGift)
      : undefined;
  },

  isUserTranslationEnabled(state): boolean {
    return !!state.entities.user.isTranslationEnabled;
  },

  userTranslationLangs(): TTranslationLang[] {
    return [
      {
        code: 'en',
        title: 'English',
      },
      {
        code: 'ru',
        title: 'Русский',
      },
      {
        code: 'de',
        title: 'Deutsch',
      },
      {
        code: 'fr',
        title: 'Français',
      },
      {
        code: 'es',
        title: 'Español',
      },
      {
        code: 'sq',
        title: 'Shqip',
      },
      {
        code: 'hy',
        title: 'Hայերէն',
      },
      {
        code: 'be',
        title: 'Беларуская',
      },
      {
        code: 'bs',
        title: 'Bosanski',
      },
      {
        code: 'bg',
        title: 'Български',
      },
      {
        code: 'zh',
        title: '中文(简体)',
      },
      {
        code: 'hr',
        title: 'Hrvatski',
      },
      {
        code: 'cs',
        title: 'čeština',
      },
      {
        code: 'da',
        title: 'Dansk',
      },
      {
        code: 'fi',
        title: 'Suomi',
      },
      {
        code: 'el',
        title: 'Ελληνικά',
      },
      {
        code: 'hu',
        title: 'Magyar',
      },
      {
        code: 'hi',
        title: 'हिन्दी',
      },
      {
        code: 'id',
        title: 'Bahasa',
      },
      {
        code: 'ga',
        title: 'Gaeilge',
      },
      {
        code: 'he',
        title: 'עִבְרִית',
      },
      {
        code: 'it',
        title: 'Italiano',
      },
      {
        code: 'ja',
        title: '日本語',
      },
      {
        code: 'kk',
        title: 'қазақ тілі',
      },
      {
        code: 'lv',
        title: 'Latviešu',
      },
      {
        code: 'lb',
        title: 'Lëtzebuergesch',
      },
      {
        code: 'mk',
        title: 'Македонски',
      },
      {
        code: 'ms',
        title: 'Melayu',
      },
      {
        code: 'nl',
        title: 'Nederlands',
      },
      {
        code: 'no',
        title: 'Norsk',
      },
      {
        code: 'pl',
        title: 'Polski',
      },
      {
        code: 'pt',
        title: 'Português',
      },
      {
        code: 'af',
        title: 'Afrikaans',
      },
      {
        code: 'ko',
        title: '한국어',
      },
      {
        code: 'ro',
        title: 'Românesc',
      },
      {
        code: 'sr',
        title: 'Српски',
      },
      {
        code: 'sk',
        title: 'Slovenský',
      },
      {
        code: 'sl',
        title: 'Slovenski',
      },
      {
        code: 'sv',
        title: 'Svenska',
      },
      {
        code: 'th',
        title: 'ไทย',
      },
      {
        code: 'tr',
        title: 'Türkçe',
      },
      {
        code: 'uk',
        title: 'Українська',
      },
    ];
  },

  userTranslationLang(state): TTranslationLang {
    let translationLang: TTranslationLang;

    switch (state.entities.user.translationLang) {
      case ETranslationLangCode.EN:
        translationLang = {
          code: 'en',
          title: 'English',
        };

        break;
      case ETranslationLangCode.RU:
        translationLang = {
          code: 'ru',
          title: 'Русский',
        };

        break;
      case ETranslationLangCode.DE:
        translationLang = {
          code: 'de',
          title: 'Deutsch',
        };

        break;
      case ETranslationLangCode.FR:
        translationLang = {
          code: 'fr',
          title: 'Français',
        };

        break;
      case ETranslationLangCode.ES:
        translationLang = {
          code: 'es',
          title: 'Español',
        };

        break;
      case ETranslationLangCode.SQ:
        translationLang = {
          code: 'sq',
          title: 'Shqip',
        };

        break;
      case ETranslationLangCode.HY:
        translationLang = {
          code: 'hy',
          title: 'Hայերէն',
        };

        break;
      case ETranslationLangCode.BE:
        translationLang = {
          code: 'be',
          title: 'Беларуская',
        };

        break;
      case ETranslationLangCode.BS:
        translationLang = {
          code: 'bs',
          title: 'Bosanski',
        };

        break;
      case ETranslationLangCode.BG:
        translationLang = {
          code: 'bg',
          title: 'Български',
        };

        break;
      case ETranslationLangCode.ZH:
        translationLang = {
          code: 'zh',
          title: '漢語',
        };

        break;
      case ETranslationLangCode.HR:
        translationLang = {
          code: 'hr',
          title: 'Hrvatski',
        };

        break;
      case ETranslationLangCode.CS:
        translationLang = {
          code: 'cs',
          title: 'čeština',
        };

        break;
      case ETranslationLangCode.DA:
        translationLang = {
          code: 'da',
          title: 'Dansk',
        };

        break;
      case ETranslationLangCode.FI:
        translationLang = {
          code: 'fi',
          title: 'Suomi',
        };

        break;
      case ETranslationLangCode.EL:
        translationLang = {
          code: 'el',
          title: 'Ελληνικά',
        };

        break;
      case ETranslationLangCode.HU:
        translationLang = {
          code: 'hu',
          title: 'Magyar',
        };

        break;
      case ETranslationLangCode.HI:
        translationLang = {
          code: 'hi',
          title: 'हिन्दी',
        };

        break;
      case ETranslationLangCode.ID:
        translationLang = {
          code: 'id',
          title: 'Bahasa',
        };

        break;
      case ETranslationLangCode.GA:
        translationLang = {
          code: 'ga',
          title: 'Gaeilge',
        };

        break;
      case ETranslationLangCode.HE:
        translationLang = {
          code: 'he',
          title: 'עִבְרִית',
        };

        break;
      case ETranslationLangCode.IT:
        translationLang = {
          code: 'it',
          title: 'Italiano',
        };

        break;
      case ETranslationLangCode.JA:
        translationLang = {
          code: 'ja',
          title: '日本語',
        };

        break;
      case ETranslationLangCode.KK:
        translationLang = {
          code: 'kk',
          title: 'қазақ тілі',
        };

        break;
      case ETranslationLangCode.LV:
        translationLang = {
          code: 'lv',
          title: 'Latviešu',
        };

        break;
      case ETranslationLangCode.LB:
        translationLang = {
          code: 'lb',
          title: 'Lëtzebuergesch',
        };

        break;
      case ETranslationLangCode.MK:
        translationLang = {
          code: 'mk',
          title: 'Македонски',
        };

        break;
      case ETranslationLangCode.MS:
        translationLang = {
          code: 'ms',
          title: 'Melayu',
        };

        break;
      case ETranslationLangCode.NL:
        translationLang = {
          code: 'nl',
          title: 'Nederlands',
        };

        break;
      case ETranslationLangCode.NO:
        translationLang = {
          code: 'no',
          title: 'Norsk',
        };

        break;
      case ETranslationLangCode.PL:
        translationLang = {
          code: 'pl',
          title: 'Polski',
        };

        break;
      case ETranslationLangCode.PT:
        translationLang = {
          code: 'pt',
          title: 'Português',
        };

        break;
      case ETranslationLangCode.AF:
        translationLang = {
          code: 'af',
          title: 'Afrikaans',
        };

        break;
      case ETranslationLangCode.KO:
        translationLang = {
          code: 'ko',
          title: '한국어',
        };

        break;
      case ETranslationLangCode.RO:
        translationLang = {
          code: 'ro',
          title: 'Românesc',
        };

        break;
      case ETranslationLangCode.SR:
        translationLang = {
          code: 'sr',
          title: 'Српски',
        };

        break;
      case ETranslationLangCode.SK:
        translationLang = {
          code: 'sk',
          title: 'Slovenský',
        };

        break;
      case ETranslationLangCode.SL:
        translationLang = {
          code: 'sl',
          title: 'Slovenski',
        };

        break;
      case ETranslationLangCode.SV:
        translationLang = {
          code: 'sv',
          title: 'Svenska',
        };

        break;
      case ETranslationLangCode.TH:
        translationLang = {
          code: 'th',
          title: 'ไทย',
        };

        break;
      case ETranslationLangCode.TR:
        translationLang = {
          code: 'tr',
          title: 'Türkçe',
        };

        break;
      case ETranslationLangCode.UK:
        translationLang = {
          code: 'uk',
          title: 'Українська',
        };

        break;
      default:
        throw new Error(`unknown translation language: ${state.entities.user.translationLang}`);
    }

    return translationLang;
  },

  withdrawalRequestRejectionReason(state): TWithdrawalRequestRejectionReason | undefined {
    const { withdrawalRequestRejectionReason } = state.ui.buffer;

    if (withdrawalRequestRejectionReason === undefined) {
      return undefined;
    }

    return withdrawalRequestRejectionReason as TWithdrawalRequestRejectionReason;
  },

  isUsageRulesDisplayedAfterAuthorization(state): boolean {
    return !!state.ui.buffer.isUsageRulesDisplayedAfterAuthorization;
  },

  isUserPremiumSubscriptionCardChangeBlocked(state): boolean {
    const { malePremiumSubscription: userPremiumSubscription } = state.entities.user;

    if (userPremiumSubscription === null) {
      throw new Error('user premium subscription is null');
    }

    return userPremiumSubscription.isCardChangeBlocked;
  },

  isContactBlockConfirmationAttentionDisplayed(state): boolean {
    return state.ui.flags.isContactBlockConfirmationAttentionDisplayed;
  },

  hasOnlyPremiumSubscriptionPackage(state): boolean {
    const { malePremiumPackages } = state.entities.user;

    const malePremiumPackagesList = Object.values(malePremiumPackages.list);

    return malePremiumPackagesList.length === 1 && malePremiumPackagesList[0].isSubscription;
  },

  isContactBotCommandsDisplayed(state): boolean {
    const { selected: selectedContact } = state.entities.contacts;

    return selectedContact !== null && selectedContact.botCommands !== null;
  },

  contactBotCommandsMessage(state): IMessengerBotCommandsEntity['message'] {
    const { selected: selectedContact } = state.entities.contacts;

    if (selectedContact === null || selectedContact.botCommands === null) {
      return undefined;
    }

    return selectedContact.botCommands.message;
  },

  contactBotCommandsList(state): IMessengerBotCommandsEntity['list'] {
    const { selected: selectedContact } = state.entities.contacts;

    if (selectedContact === null || selectedContact.botCommands === null) {
      return [];
    }

    return selectedContact.botCommands.list;
  },

  isBotCommandsDisabled(state): boolean {
    return state.ui.flags.isMessagesSendingDisabled;
  },

  isWithdrawalConfirmationAttentionDisplayed(state): boolean {
    return state.ui.flags.isWithdrawalConfirmationAttentionDisplayed;
  },

  isWithdrawalCancelledAttentionDisplayed(state): boolean {
    return state.ui.flags.isWithdrawalCancelledAttentionDisplayed;
  },

  withdrawalConfirmationCode(state): string {
    if (state.entities.withdrawalRequest === null) {
      throw new Error('withdrawal request is null');
    }

    if (state.entities.withdrawalRequest.confirmationCode === undefined) {
      throw new Error('confirmation code is undefined');
    }

    return state.entities.withdrawalRequest.confirmationCode;
  },

  widthdrawalConfirmationValidationError(state): TWithdrawalConfirmationValidationError | undefined {
    switch (state.ui.buffer.withdrawalConfirmationValidationError) {
      case EWithdrawalConfirmationValidationError.EMPTY:
        return 'empty';
      case EWithdrawalConfirmationValidationError.SHORT:
        return 'short';
      case EWithdrawalConfirmationValidationError.INCORRECT:
        return 'incorrect';
      default:
        return undefined;
    }
  },

  isWithdrawalConfirmationDisabled(state): boolean {
    return state.ui.buffer.isWithdrawalConfirmationDisabled;
  },

  isEmailConfirmationForFreebieAttentionDisplayed(state): boolean {
    return state.ui.flags.isEmailConfirmationForFreebieAttentionDisplayed;
  },

  emailConfirmationForFreebieMinutes(state): number {
    const { emailConfirmationForFreebieMinutes } = state.entities.user;

    if (emailConfirmationForFreebieMinutes === undefined) {
      throw new Error('user email confirmation for premium minutes is undefined');
    }

    return emailConfirmationForFreebieMinutes;
  },

  emailConfirmationForFreebieEmail(state): string {
    const { userEmailConfirmationForFreebieEmail } = state.ui.buffer;

    if (userEmailConfirmationForFreebieEmail === undefined) {
      const { email: userEmail } = state.entities.user;

      if (userEmail === undefined) {
        throw new Error('user email is undefined');
      }

      return userEmail;
    }

    return userEmailConfirmationForFreebieEmail;
  },

  emailConfirmationCodeValidationError(state): TEmailConfirmationCodeValidationError | undefined {
    const { userEmailConfirmationCodeValidationError } = state.ui.buffer;

    switch (userEmailConfirmationCodeValidationError) {
      case EEmailConfirmationCodeValidationError.EMPTY:
        return 'empty';
      case EEmailConfirmationCodeValidationError.SHORT:
        return 'short';
      case EEmailConfirmationCodeValidationError.INCORRECT:
        return 'incorrect';
      default:
        return undefined;
    }
  },

  isSendEmailConfirmationCodeDisabled(state): boolean {
    return state.ui.buffer.isSendEmailConfirmationCodeDisabled;
  },

  isSearchStartWarningDisplayed(state): boolean {
    return state.ui.flags.isSearchStartWarningDisplayed;
  },

  isCompanionCommunicationRestrictionWarningDisplayed(state): boolean {
    const { companion: videochatCompanion } = state.entities.videochat;

    return (
      state.entities.videochat.stage === EVideochatStage.IN_PROCESS
      && videochatCompanion !== null
      && videochatCompanion.isCommunicationRestrictionWarningRequired === true
    );
  },

  isUserMalePurchaseVatIncluded(state): boolean {
    if (state.entities.user.registrationStatus !== ERegistrationStatus.AUTHORIZED) {
      return true;
    }

    if (state.entities.user.isMalePurchaseVatIncluded === undefined) {
      throw new Error('is male purchase VAT included is undefined');
    }

    return state.entities.user.isMalePurchaseVatIncluded;
  },

  supportEmail(): string {
    return config.supportEmail;
  },

  isStoryRecordingUnavailable(state): boolean {
    return (
      state.entities.user.gender === EGender.FEMALE
      && state.entities.user.registrationStatus === ERegistrationStatus.AUTHORIZED
      && state.entities.user.femaleCurrentTariffId !== undefined
      && state.entities.user.femaleMinTariffIdWithAvailableStoryRecording !== undefined
      /**
       * Идентификаторы тарифов идут в порядке возрастания от топового (первый по порядку)
       * до начального (последний по порядку). Так сделано на бэке
       */
      && state.entities.user.femaleCurrentTariffId > state.entities.user.femaleMinTariffIdWithAvailableStoryRecording
    );
  },

  minTariffNameWithAvailableStoryRecording(state): string | undefined {
    if (
      state.entities.user.gender === EGender.MALE
      || state.entities.user.registrationStatus !== ERegistrationStatus.AUTHORIZED
      || state.entities.user.femaleMinTariffIdWithAvailableStoryRecording === undefined
    ) {
      return undefined;
    }

    const tariff = (
      state.entities.user.femaleTariffs.list[state.entities.user.femaleMinTariffIdWithAvailableStoryRecording]
    );

    if (tariff === undefined) {
      throw new Error('tariff is undefined');
    }

    return `${tariff.firstMinuteRate}/${tariff.secondMinuteRate}`;
  },

  isAccountDeletionDisabled(state): boolean {
    return state.ui.buffer.isAccountDeletionDisabled;
  },

  isNewVersionButtonDisplayed(state): boolean {
    const isFemaleUnregisteredUser = (
      state.entities.user.gender === EGender.FEMALE
      && state.entities.user.registrationStatus === ERegistrationStatus.NONE
    );

    const isFeatureEnabled = (
      config.features.isNewVersionButtonEnabled
      || state.entities.environment.features.includes('version')
    );

    return isFeatureEnabled && !isFemaleUnregisteredUser;
  },

  isNewVersionButtonHighlighted(state): boolean {
    return state.ui.flags.isNewVersionButtonHighlighted;
  },
};

export default getters;
