import { createI18n, LocaleMessages } from 'vue-i18n';
import config from '@/config';
import { ELocale } from '@/enums';
// eslint-disable-next-line import/no-cycle
import SettingsViewmodel from '@/core/viewmodels/settings.viewmodel';
import AjaxRepository from '@/repositories/ajax-repository';
import LocaleRepository from '@/repositories/locale-repository';
import datetimeFormats from '@/i18n/date-time-formats';
import {
  choiceRussianIndex,
  choiceFrenchIndex,
  choiceTurkishIndex,
  choicePolishIndex,
  choiceRomanianIndex,
  choiceUkrainianIndex,
} from '@/i18n/plural-forms-choice';

function loadPreloadedLocaleMessages(): LocaleMessages {
  const locales = require.context('@/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);

  const messages: LocaleMessages = {};

  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);

    if (matched && matched.length > 1) {
      const locale = matched[1];

      messages[locale] = locales(key).i18n;
    }
  });

  return messages;
}

// Создаем инстанс локализации
const i18n = createI18n({
  datetimeFormats,
  locale: config.defaultLocale,
  fallbackLocale: config.defaultLocale,
  // @ts-ignore
  messages: loadPreloadedLocaleMessages(),
  legacy: false,
  globalInjection: true,
  pluralRules: {
    [ELocale.RU]: choiceRussianIndex,
    [ELocale.FR]: choiceFrenchIndex,
    [ELocale.TR]: choiceTurkishIndex,
    [ELocale.PL]: choicePolishIndex,
    [ELocale.RO]: choiceRomanianIndex,
    [ELocale.UK]: choiceUkrainianIndex,
  },
});

// Инициализируем локаль
LocaleRepository.getInstance().asyncGetLocale()
  .then(async (locale) => {
    // Определяем локаль
    let activeLocale: ELocale | undefined = locale;

    if (activeLocale === undefined || !Object.values(ELocale).includes(activeLocale)) {
      activeLocale = config.defaultLocale;
    }

    // Загружаем пакет переводов для выбранной локали, если ее нет в предзагруженных локалях
    if (!i18n.global.availableLocales.includes(activeLocale)) {
      const response = await AjaxRepository.getInstance().asyncGetLocaleMessages(activeLocale);

      i18n.global.setLocaleMessage(activeLocale, response.localeMessages.list);
    }

    // Устанавливаем локаль
    // @ts-ignore
    i18n.global.locale.value = activeLocale;

    SettingsViewmodel.getInstance().setLocale(activeLocale);
  })
  .catch((e) => {
    throw e;
  });

export default i18n;
