import ProcedureEmitter from '@/packages/procedure-emitter';
import { ELocale } from '@/enums';
import IEnvironmentEntity from '@/core/entities/environment.entity';
import { IWindowConfigRepository } from '@/core/interactors/types/repositories';

class WindowConfigRepository extends ProcedureEmitter {
  private static instance: WindowConfigRepository;

  // eslint-disable-next-line no-useless-constructor, no-empty-function, @typescript-eslint/no-empty-function
  private constructor() {
    super('WindowConfigRepository');
  }

  static getInstance(): WindowConfigRepository {
    try {
      if (WindowConfigRepository.instance === undefined) {
        WindowConfigRepository.instance = new WindowConfigRepository();
      }

      return WindowConfigRepository.instance;
    } catch (error) {
      throw error;
    }
  }

  asyncGetPartnerId: IWindowConfigRepository['asyncGetPartnerId'] = async (): Promise<{
    environment: Pick<IEnvironmentEntity, 'partnerId'>,
  }> => {
    try {
      if (!this.isAgent) {
        return await this.sendToAgent({ procedure: 'asyncGetPartnerId' });
      }

      return {
        environment: {
          // eslint-disable-next-line no-underscore-dangle
          partnerId: window._coomeet !== undefined ? `${window._coomeet.PartnerId}` : undefined,
        },
      };
    } catch (error) {
      throw error;
    }
  }

  asyncGetPartnerChannel: IWindowConfigRepository['asyncGetPartnerChannel'] = async (): Promise<{
    environment: Pick<IEnvironmentEntity, 'partnerChannel'>,
  }> => {
    try {
      if (!this.isAgent) {
        return await this.sendToAgent({ procedure: 'asyncGetPartnerChannel' });
      }

      return {
        environment: {
          // eslint-disable-next-line no-underscore-dangle
          partnerChannel: window._coomeet !== undefined ? window._coomeet.ChannelName : undefined,
        },
      };
    } catch (error) {
      throw error;
    }
  }

  asyncGetRestoreToken: IWindowConfigRepository['asyncGetRestoreToken'] = async (): Promise<{
    environment: Pick<IEnvironmentEntity, 'restoreToken'>,
  }> => {
    try {
      if (!this.isAgent) {
        return await this.sendToAgent({ procedure: 'asyncGetRestoreToken' });
      }

      return {
        environment: {
          // eslint-disable-next-line no-underscore-dangle
          restoreToken: window._coomeet !== undefined ? window._coomeet.restore : undefined,
        },
      };
    } catch (error) {
      throw error;
    }
  }

  asyncGetLocale: IWindowConfigRepository['asyncGetLocale'] = async (): Promise<ELocale | undefined> => {
    try {
      if (!this.isAgent) {
        return await this.sendToAgent({ procedure: 'asyncGetLocale' });
      }

      // eslint-disable-next-line no-underscore-dangle
      if (window._coomeet === undefined) {
        return undefined;
      }

      // eslint-disable-next-line no-underscore-dangle
      const locale = window._coomeet.site_lang;

      // Для СЕО требуется установить кастомную локаль для определенных случаев
      switch (locale) {
        case 'hi':
        case 'sv':
          return ELocale.EN;
        case 'no':
          return ELocale.DE;
        default:
          return locale as ELocale;
      }
    } catch (error) {
      throw error;
    }
  }

  asyncGetIsMalePurchasePremiumShowRequired: IWindowConfigRepository['asyncGetIsMalePurchasePremiumShowRequired'] =
    async (): Promise<{
      environment: Pick<IEnvironmentEntity, 'isMalePurchasePremiumShowRequired'>,
    }> => {
      try {
        if (!this.isAgent) {
          return await this.sendToAgent({ procedure: 'asyncGetIsMalePurchasePremiumShowRequired' });
        }

        // eslint-disable-next-line no-underscore-dangle
        if (window._coomeet === undefined || window._coomeet.modalOpen === undefined) {
          return { environment: { isMalePurchasePremiumShowRequired: false } };
        }

        // eslint-disable-next-line no-underscore-dangle
        return { environment: { isMalePurchasePremiumShowRequired: window._coomeet.modalOpen === 'premium' } };
      } catch (error) {
        throw error;
      }
    }

  asyncGetIsMalePurchaseMinutesShowRequired: IWindowConfigRepository['asyncGetIsMalePurchaseMinutesShowRequired'] =
    async (): Promise<{
      environment: Pick<IEnvironmentEntity, 'isMalePurchaseMinutesShowRequired'>,
    }> => {
      try {
        if (!this.isAgent) {
          return await this.sendToAgent({ procedure: 'asyncGetIsMalePurchaseMinutesShowRequired' });
        }

        // eslint-disable-next-line no-underscore-dangle
        if (window._coomeet === undefined || window._coomeet.modalOpen === undefined) {
          return { environment: { isMalePurchaseMinutesShowRequired: false } };
        }

        // eslint-disable-next-line no-underscore-dangle
        return { environment: { isMalePurchaseMinutesShowRequired: window._coomeet.modalOpen === 'minutes' } };
      } catch (error) {
        throw error;
      }
    }

  asyncGetSelectedPurchasePackageId: IWindowConfigRepository['asyncGetSelectedPurchasePackageId'] = async (): Promise<{
    environment: Pick<IEnvironmentEntity, 'selectedPurchasePackageId'>,
  }> => {
    try {
      if (!this.isAgent) {
        return await this.sendToAgent({ procedure: 'asyncGetSelectedPurchasePackageId' });
      }

      return {
        environment: {
          // eslint-disable-next-line no-underscore-dangle
          selectedPurchasePackageId: window._coomeet !== undefined ? window._coomeet.offerId : undefined,
        },
      };
    } catch (error) {
      throw error;
    }
  }
}

export default WindowConfigRepository;
