export enum EGender {
  FEMALE,
  MALE,
}

export enum ELocale {
  EN = 'en',
  RU = 'ru',
  DE = 'de',
  FR = 'fr',
  ES = 'es',
  IT = 'it',
  PL = 'pl',
  RO = 'ro',
  TR = 'tr',
  NL = 'nl',
  FI = 'fi',
  UK = 'uk',
  JA = 'ja',
  PT = 'pt',
  ZH = 'zh',
}

export enum ERegistrationStatus {
  NONE,
  IN_PROCESS,
  AUTHORIZED,
}

export enum ECompanionRate {
  ONE = 1,
  TWO = 2,
  THREE = 3,
  FOUR = 4,
  FIVE = 5,
}

export enum ECompanionReviewReason {
  RUDENESS = 1,
  DECEPTION = 2,
  CHILD = 3,
  OTHER = 4,
}

export enum EVideochatPageStage {
  START,
  PROCESS,
  FINISH,
  CALL,
}

export enum EVideochatHistorySorting {
  DURATION = 'duration',
  RECENT = 'recent',
  ONLINE = 'online',
}

export enum EAbuseReason {
  SPAM = 1,
  NUDE = 2,
  INVOLVING_CHILDREN = 3,
  THREATS = 4,
  DECEIVING = 5,
  MINOR_AGE = 6,
}

export enum EStoryAbuseReason {
  NUDE = 1,
  MINOR_AGE = 2,
  SPAM = 3,
  HATEFUL = 4,
  VIOLENT = 5,
  COPYRIGHT = 6,
}

export enum EStoryLoadError {
  NOT_FOUND = 1,
}

export enum EPurchaseType {
  PREMIUM = 'premium',
  MINUTES = 'minutes',
}

export enum EPremiumDurationUnit {
  HOUR = 'hour',
  DAY = 'day',
  MONTH = 'month',
  YEAR = 'year',
}

export enum EMinutesOfferDiscountUnit {
  MINUTES = 'minutes',
}

export enum EMessageSendError {
  LOW_MINUTES = 0,
  FORMAT_DENIED = 113,
  FILE_CORRUPTED = 421,
}

export enum EUserAvatarUploadError {
  LONG_REQUEST = 144,
  FORMAT_DENIED = 113,
  CORRUPTED = 141,
}

export enum EUserAvatarValidationError {
  TOO_LARGE,
  READ_FAILED,
}

export enum EPurchaseError {
  NO_FUNDS = 824,
  PAYMENT_FAILED = 829,
  NO_FUNDS_BUY_CHEAPER = 834,
  TRIAL_UNAVAILABLE = 835,
  NO_CARD = 863,
  VERIFICATION_ERROR = 868,
  SUBSCRIPTION_FAILED = 880,
  PAYMENT_RESTRICTED = 425,
}

export enum EUserEmailConfirmationStatus {
  INITIAL,
  UNAVAILABLE_ERROR,
  ALREADY_EXISTS_ERROR,
  UNKNOWN_ERROR,
  LOADING,
  SUCCESS,
}

export enum EUserEmailConfirmationError {
  UNAVAILABLE,
  ALREADY_EXISTS,
  UNKNOWN,
}

export enum EBanStatus {
  NONE = 0,
  PORN = 1,
  ADVERTISING = 4,
  PHOTO = 5,
  AGE = 6,
  GENDER = 7,
  LIFETIME = 10,
  CHILD = 66,
  TEN_DAYS = 67,
  RULES = 8,
  PAYPAL_DISPUTE = -1,
}

export enum EUserVerificationStatus {
  CHECKED,
  UNVERIFIED,
  WAITING,
  UPLOADED,
  CORRUPTED,
  FAKE_DOCUMENT,
  NO_DATE,
  NO_FACE,
  ALIEN_FACE,
  ALIEN_DOCUMENT,
  BAD_PHOTO,
  UNDER_AGE,
  ACCOUNT_EXIST,
  NO_DOCUMENT,
  BAD_NAME,
  NO_QUALITY_FACE,
  ALIEN_IN_FRAME,
  REMOVE_GLASSES,
}

export enum EUserAgeRange {
  YOUNG = 'young',
  GROWN = 'grown',
  MIDDLE = 'middle',
  MATURE = 'mature',
}

export enum EAccountTransactionActivityType {
  MALE_GOT_DAILY_MINUTES = 'male-got-daily-minutes',
  MALE_WON_GAME = 'male-won-game',
  MALE_SENT_GIFT = 'male-sent-gift',
  MALE_RECEIVED_GIFT = 'male-received-gift',
  MALE_SENT_MESSAGES = 'male-sent-messages',
  MALE_SPENT_ACCOUNT_FUNDS = 'male-spent-account-funds',
  MALE_REFILLED_ACCOUNT = 'male-refilled-account',
  MALE_RETURNED_FUNDS = 'male-returned-funds',

  FEMALE_GOT_BONUS = 'female-got-bonus',
  FEMALE_CANCELED_SALARY = 'female-canceled-salary',
  FEMALE_CONFIRMED_EMAIL = 'female-confirmed-email',
  FEMALE_WITHDREW_FUNDS = 'female-withdrew-funds',
  FEMALE_RECEIVED_MESSAGES = 'female-received-messages',
  FEMALE_GOT_PREMIUM_BONUS = 'female-got-premium-bonus',
  FEMALE_RECEIVED_GIFT = 'female-received-gift',
  FEMALE_EARNED_REFERRAL_POINTS = 'female-earned-referral-points',
  FEMALE_RETURNED_POINTS = 'female-returned-points',
  FEMALE_POINTS_TAKEN_BY_ADMIN = 'female-points-taken-by-admin',
  FEMALE_POINTS_FOR_STORY_LIKES = 'female-points-for-story-likes',
  FEMALE_REDUCED_POINTS_FOR_REFERRAL = 'female-reduced-points-for-referral',
  FEMALE_REDUCED_POINTS_FOR_PROMO = 'female-reduced-points-for-promo',

  GOT_CHAT_POINTS_PENALTY = 'got-chat-points-penalty',
  SPENT_TIME_IN_CHAT = 'spent-time-in-chat',
}

export enum EAuthorizationError {
  USER_AUTHORIZED = 111,
  EMPTY_EMAIL = 401,
  WRONG_CREDENTIALS = 403,
  INCORRECT_PASSWORD = 405,
  INCORRECT_EMAIL = 411,
  USER_EXISTS = 412,
}

export enum EUserEmailValidationError {
  EMPTY,
  INCORRECT,
  EXISTS,
  WRONG_CREDENTIALS,
  FORBIDDEN,
}

export enum EUserEmailValidationResult {
  SUCCESS,
  ERROR_EMPTY,
  ERROR_INCORRECT,
  ERROR_EXISTS,
  ERROR_WRONG_CREDENTIALS,
}

export enum EUserPasswordValidationError {
  EMPTY,
  SHORT,
  NO_MATCH,
}

export enum EUserNameValidationError {
  EMPTY,
  SHORT,
  CONTAINS_EMOJI,
}

export enum EUserAvatarEditState {
  MAKE,
  MAKED,
  SELECTED,
}

export enum ESocial {
  MAILRU = 'mailru',
  VKONTAKTE = 'vk',
  YANDEX = 'ya',
  GOOGLE = 'google',
  FACEBOOK = 'fb',
  MSN = 'msn',
}

export enum EReferralListType {
  MALES,
  FEMALES,
}

export enum EUserAccountDeletionCodeValidationError {
  EMPTY,
  SHORT,
  INCORRECT,
}

export enum EUserAccountDeletionReasonValidationError {
  EMPTY,
}

export enum EMessageType {
  TEXT = 0,
  IMAGE = 1,
  VIDEO = 2,
  SYSTEM = 3,
  GIFT = 5,
  STICKER = 6,
}

export enum ETranslationLangCode {
  EN = 'en',
  RU = 'ru',
  DE = 'de',
  FR = 'fr',
  ES = 'es',
  SQ = 'sq',
  HY = 'hy',
  BE = 'be',
  BS = 'bs',
  BG = 'bg',
  ZH = 'zh',
  HR = 'hr',
  CS = 'cs',
  DA = 'da',
  FI = 'fi',
  EL = 'el',
  HU = 'hu',
  HI = 'hi',
  ID = 'id',
  GA = 'ga',
  HE = 'he',
  IT = 'it',
  JA = 'ja',
  KK = 'kk',
  LV = 'lv',
  LB = 'lb',
  MK = 'mk',
  MS = 'ms',
  NL = 'nl',
  NO = 'no',
  PL = 'pl',
  PT = 'pt',
  AF = 'af',
  KO = 'ko',
  RO = 'ro',
  SR = 'sr',
  SK = 'sk',
  SL = 'sl',
  SV = 'sv',
  TH = 'th',
  TR = 'tr',
  UK = 'uk',
}

export enum ETopChangeDirection {
  DOWN = -1,
  NO_CHANGE = 0,
  UP = 1,
}

export enum ETopPeriod {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
}

export enum EPurchaseAvailableCardVendor {
  VISA = 'Visa',
  MASTER_CARD = 'MasterCard',
  MAESTRO = 'Maestro',
  MIR = 'Mir',
  JCB = 'JCB',
  DISCOVER = 'Discover',
  AMERICAN_EXPRESS = 'American Express',
  DINERS_CLUB = 'Diners Club',
}

export enum EWithdrawalAvailableCardVendor {
  VISA = 'Visa',
  MASTER_CARD = 'MasterCard',
}

export enum EWithdrawalAvailableUsdtFormat {
  TRC20 = 'trc-20',
  ERC20 = 'erc-20',
}

export enum ECardVendor {
  VISA = 'Visa',
  MASTER_CARD = 'MasterCard',
  MAESTRO = 'Maestro',
  JCB = 'JCB',
  DISCOVER = 'Discover',
  AMERICAN_EXPRESS = 'American Express',
  DINERS_CLUB = 'Diners Club',
  UNKNOWN = 'Unknown',
  MIR = 'Mir',
}

export enum EUserCardConfirmationValidationError {
  WRONG_SUM,
  NO_ATTEMPTS_LEFT,
  INVALID_SUM,
}

export enum ECardConfirmationStatus {
  NONE = 'none',
  WAITING = 'waite',
  COMPLETE = 'wait_confirm',
}

export enum ECardConfirmationError {
  NO_ATTEMPTS_LEFT = 488,
  WRONG_SUM = 490,
  NO_REQUEST = 492,
  IN_PROCESS = 602,
}

export enum EGiftType {
  DONUT = 'donut',
  BOUQUET = 'bouquet',
  CAT = 'cat',
  BRILLIANT = 'brilliant',
  DIADEM = 'diadem',
  STRAWBERRY = 'strawberry',
}

export enum EPaymentSystemType {
  CARD = 'card',
  PAYPAL = 'paypal',
  EPSWALLET = 'epswallete',
  YANDEX = 'yandex',
  WEBMONEY = 'webmoney',
  QIWI = 'qiwi',
  BANK = 'bank_account',
  BITCOIN = 'bitcoin',
  SBP = 'sbp',
  USDT = 'usdt',
}

export enum EWithdrawalRequestStatus {
  SUCCESS = 'pay',
  WAIT = 'wait',
  CONFIRMATION = 'confirm',
  ERROR = 'bad_requisits',
}

export enum EWithdrawalRequestRejectionReason {
  INVALID_BANK_CODE = 'invalid-bank-code',
  EMAIL_USED_BY_ANOTHER_BENEFICIARY = 'email-used-by-another-beneficiary',
  PHONE_USED_BY_ANOTHER_BENEFICIARY = 'phone-used-by-another-beneficiary',
  REQUEST_CANCELLED = 'request-cancelled',
  WRONG_CARD_EXPIRY_MONTH = 'wrong-card-expiry-month',
  CARD_COUNTRY_NOT_SUPPORTED = 'card-country-not-supported',
  INVALID_ACCOUNT_NUMBER = 'invalid-account-number',
  INVALID_CARD_NUMBER = 'invalid-card-number',
  INVALID_CARD_TYPE = 'invalid-card-type',
  INVALID_BANK_ACCOUNT_NOT_CC = 'invalid-bank-account-not-cc',
  RECIPIENT_IN_STOP_LIST = 'recipient-in-stop-list',
  TRY_LATER = 'try-later',
  PURSE_LIMIT = 'purse-limit',
  INVALID_BANK_ACCOUNT = 'invalid-bank-account',
  BANK_ACCOUNT_CLOSED = 'bank-account-closed',
  INVALID_AMOUNT = 'invalid-amount',
  INVALID_BANK_BRANCH = 'invalid-bank-branch',
  INVALID_BENEFICIARY_DOCUMENT = 'invalid-beneficiary-document',
  INVALID_BENEFICIARY_NAME = 'invalid-beneficiary-name',
  REJECTED_BY_BANK = 'rejected-by-bank',
  EXPIRED = 'expired',
  BENEFICIARY_LIMIT_EXCEEDED = 'beneficiary-limit-exceeded',
  RISK_POLICY = 'risk-policy',
  BLOCKED_FROZEN_ACCOUNT = 'blocked-frozen-account',
  DOCUMENT_ACCOUNT_MISMATCH = 'document-account-mismatch',
  INVALID_PIX_KEY = 'invalid-pix-key',
  INVALID_IFSC_CODE = 'invalid-ifsc-code',
  INVALID_BANK_ACCOUNT_OR_IFSC_CODE = 'invalid-bank-account-or-ifsc-code',
  INVALID_NBIN = 'invalid-nbin',
  UNABLE_RECEIVE_TRANSFERS = 'unable-receive-transfers',
  INVALID_BANK_ACCOUNT_TYPE = 'invalid-bank-account-type',
  REJECTED_BY_MERCHANT_REQUEST = 'rejected-by-merchant-request',
  INCOME_DISABLED = 'income-disabled',
  INVALID_WALLET_NUMBER = 'invalid-wallet-number',
  DECLINED_BY_ISSUER = 'declined-by-issuer',
  CARD_EXPIRED = 'card-expired',
  CORRUPT_CARD_DATA = 'corrupt-card-data',
  CARD_LIMIT = 'card-limit',
  SUSPENDED = 'suspended',
  UNVERIFIED_REQUISITES = 'unverified-requisites',
  UNKNOWN = 'unknown',
}

export enum EWithdrawalRequestCardNumberValidationError {
  EMPTY,
  INCORRECT,
  WRONG_EMITTER_COUNTRY,
}

export enum EWithdrawalRequestCardExpirationValidationError {
  EMPTY,
  INCORRECT,
}

export enum EWithdrawalRequestCardCurrencyValidationError {
  EMPTY,
  INCORRECT,
}

export enum EWithdrawalRequestCardHolderFirstNameValidationError {
  EMPTY,
  INCORRECT,
}

export enum EWithdrawalRequestCardHolderLastNameValidationError {
  EMPTY,
  INCORRECT,
}

export enum EWithdrawalRequestCardHolderBirthdayValidationError {
  EMPTY,
  INCORRECT,
  YOUNG,
}

export enum EWithdrawalRequestCardAddressStreetValidationError {
  EMPTY,
}

export enum EWithdrawalRequestCardAddressCityValidationError {
  EMPTY,
}

export enum EWithdrawalRequestWebmoneyNumberValidationError {
  EMPTY,
  INCORRECT,
}

export enum EWithdrawalRequestBankAccountHolderDocumentNumberValidationError {
  EMPTY,
  INCORRECT,
}

export enum EWithdrawalRequestBankAccountHolderFirstNameValidationError {
  EMPTY,
  INCORRECT,
}

export enum EWithdrawalRequestBankAccountHolderLastNameValidationError {
  EMPTY,
  INCORRECT,
}

export enum EWithdrawalRequestBankAccountHolderAddressValidationError {
  EMPTY,
  INCORRECT,
}

export enum EWithdrawalRequestBankAccountHolderPhoneValidationError {
  EMPTY,
  INCORRECT,
}

export enum EWithdrawalRequestBankAccountHolderEmailValidationError {
  EMPTY,
  INCORRECT,
}

export enum EWithdrawalRequestBankBranchCodeValidationError {
  EMPTY,
  INCORRECT,
}

export enum EWithdrawalRequestBankAccountNumberValidationError {
  EMPTY,
  INCORRECT,
}

export enum EContactRequestStatus {
  NONE = 2,
  SENT = -1,
  RECEIVED = 0,
  ACCEPTED = 1,
}

export enum EWithdrawalRequestError {
  GUEST_ACTION = 110,
  ACCESS_RESTRICTED = 132,
  NO_ACTIVE_REQUEST = 134,
  REQUEST_EXISTS = 135,
  LOW_POINTS = 475,
  DATABASE_API_ERROR = 141,
  WRONG_DATA = 302,
  WRONG_GENDER = 474,
  BILLING_ERROR = 494,
  MANUAL_ONLY = 495,
  SERVER_ERROR = 517,
  GENERAL_ERROR = 555,
}

export enum EContactStatus {
  NONE = 'none',
  DELETED_BY_CONTACT = 'deleted-by-contact',
  DELETED_CONTACT = 'deleted-contact',
  BLOCKED_CONTACT = 'blocked-contact',
}

export enum ECompanionContactStatus {
  NONE = 'none',
  REQUEST_SENT = 'request-sent',
  REQUEST_RECEIVED = 'request-received',
  REQUEST_ACCEPTED = 'request-accepted',
  REQUEST_DECLINED = 'request-declined',
  DELETED_CONTACT = 'deleted-contact',
  DELETED_BY_CONTACT = 'deleted-by-contact',
  BLOCKED_CONTACT = 'blocked-contact',
}

export enum ECompanionModerationStatus {
  NONE,
  APPROVED,
  REJECTED,
}

export enum EMessageSendingStatus {
  SENDING = 'sending',
  SENT = 'sent',
  ERROR = 'error',
}

export enum ESystemMessageText {
  CONTACT_FROM_VIDEOCHAT = 'SYS_MSG_1',
  CONTACT_DELETE = 'SYS_MSG_2',
  BLOCKED_BY_CONTACT = 'SYS_MSG_3',
  VIDEOMESSAGE = 'SYS_MSG_4',
  HAS_NOT_MESSAGES = 'SYS_MSG_5',
  CONTACT_REQUEST = 'SYS_MSG_6',
  CONTACT_REQUEST_ACCEPTED = 'SYS_MSG_7',
  CONTACT_REQUEST_DECLINED = 'SYS_MSG_8',
  WITHDRAWAL_REQUEST = 'SYS_MSG_10',
  CONTACT_BOUGHT_PREMIUM = 'SYS_MSG_13',
  CONTACT_SUPPORT = 'SYS_MSG_14',
  WITHDRAWAL_REQUEST_2 = 'SYS_MSG_15',
  SELF_CONTACT_REQUEST = 'SYS_MSG_16',
  PREMIUM_PURCHASE = 'SYS_MSG_21',
  MISSED_CALL = 'LOGGER_CALL',
  REMOVED_CONTACT = 'APP_YOU_REMOVED_CONTACT',
}

export enum EContactsFiltration {
  NONE = 'none',
  RECENT = 'recent',
  ONLINE = 'online',
  FAVORITE = 'favorite',
  UNREAD = 'unread',
  BLOCKED = 'blocked',
}

export enum EContactsDisplayMode {
  DEFAULT = 'default',
  SEARCH = 'search',
  EDIT = 'edit',
}

export enum EUserMaleSearchFiltration {
  ALL,
  ONLY_BEST,
}

export enum EContactRole {
  NONE = 'none',
  UI_REGISTRATION = 'ui-registration',
  UI_SUPPORT = 'ui-support',
  SUPPORT = 'support',
}

export enum EUserVerificationStage {
  UNVERIFIED,
  WAITING,
  REJECTED,
}

export enum EUserRole {
  USER,
  MODERATOR,
}

export enum ECompanionRole {
  NONE,
  MODERATOR,
}

export enum EOutgoingCallRejectionReason {
  DECLINED = 'declined',
  OUT_OF_MINUTES = 'out-of-minutes',
  OFFLINE = 'offline',
  FAILED = 'failed',
  UNAVAILABLE = 'unavailable',
}

export enum EContactPromoRequestStatus {
  NONE = 'none',
  SENT = 'sent',
  RECEIVED = 'received',
}

export enum ELocalStreamSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  BIG = 'big',
}

export enum EWithdrawalRequestYandexNumberValidationError {
  EMPTY,
  INCORRECT,
}

export enum EWithdrawalRequestBitcoinNumberValidationError {
  EMPTY,
  INCORRECT,
}

export enum EWithdrawalRequestUsdtNumberValidationError {
  EMPTY,
  INCORRECT,
}

export enum EWithdrawalRequestQiwiNumberValidationError {
  EMPTY,
  INCORRECT,
}

export enum EWithdrawalRequestEpayserviceNumberValidationError {
  EMPTY,
  INCORRECT,
}

export enum ECountry {
  AF = 'AF',
  AL = 'AL',
  DZ = 'DZ',
  AD = 'AD',
  AO = 'AO',
  AI = 'AI',
  AG = 'AG',
  AR = 'AR',
  AM = 'AM',
  AW = 'AW',
  AU = 'AU',
  AT = 'AT',
  AZ = 'AZ',
  BS = 'BS',
  BH = 'BH',
  BD = 'BD',
  BB = 'BB',
  BY = 'BY',
  BE = 'BE',
  BZ = 'BZ',
  BJ = 'BJ',
  BM = 'BM',
  BT = 'BT',
  BO = 'BO',
  BA = 'BA',
  BW = 'BW',
  BR = 'BR',
  VG = 'VG',
  BN = 'BN',
  BG = 'BG',
  BF = 'BF',
  BI = 'BI',
  KH = 'KH',
  CM = 'CM',
  CA = 'CA',
  CV = 'CV',
  KY = 'KY',
  CF = 'CF',
  TD = 'TD',
  CL = 'CL',
  CN = 'CN',
  CO = 'CO',
  KM = 'KM',
  CG = 'CG',
  CD = 'CD',
  CK = 'CK',
  CR = 'CR',
  CI = 'CI',
  HR = 'HR',
  CU = 'CU',
  CW = 'CW',
  CY = 'CY',
  CZ = 'CZ',
  DK = 'DK',
  DJ = 'DJ',
  DM = 'DM',
  DO = 'DO',
  TL = 'TL',
  EC = 'EC',
  EG = 'EG',
  SV = 'SV',
  GQ = 'GQ',
  ER = 'ER',
  EE = 'EE',
  ET = 'ET',
  FK = 'FK',
  FO = 'FO',
  FM = 'FM',
  FJ = 'FJ',
  FI = 'FI',
  FR = 'FR',
  GF = 'GF',
  PF = 'PF',
  GA = 'GA',
  GM = 'GM',
  GE = 'GE',
  DE = 'DE',
  GH = 'GH',
  GI = 'GI',
  GR = 'GR',
  GL = 'GL',
  GD = 'GD',
  GP = 'GP',
  GU = 'GU',
  GT = 'GT',
  GG = 'GG',
  GN = 'GN',
  GW = 'GW',
  GY = 'GY',
  HT = 'HT',
  HN = 'HN',
  HK = 'HK',
  HU = 'HU',
  IS = 'IS',
  IN = 'IN',
  ID = 'ID',
  IR = 'IR',
  IQ = 'IQ',
  IE = 'IE',
  IM = 'IM',
  IL = 'IL',
  IT = 'IT',
  JM = 'JM',
  JP = 'JP',
  JE = 'JE',
  JO = 'JO',
  KZ = 'KZ',
  KE = 'KE',
  KI = 'KI',
  XK = 'XK',
  KW = 'KW',
  KG = 'KG',
  LA = 'LA',
  LV = 'LV',
  LB = 'LB',
  LS = 'LS',
  LR = 'LR',
  LY = 'LY',
  LI = 'LI',
  LT = 'LT',
  LU = 'LU',
  MO = 'MO',
  MK = 'MK',
  MG = 'MG',
  MW = 'MW',
  MY = 'MY',
  MV = 'MV',
  ML = 'ML',
  MT = 'MT',
  MH = 'MH',
  MQ = 'MQ',
  MR = 'MR',
  MU = 'MU',
  YT = 'YT',
  MX = 'MX',
  MD = 'MD',
  MC = 'MC',
  MN = 'MN',
  ME = 'ME',
  MA = 'MA',
  MZ = 'MZ',
  MM = 'MM',
  NA = 'NA',
  NR = 'NR',
  NP = 'NP',
  NL = 'NL',
  NC = 'NC',
  NZ = 'NZ',
  NI = 'NI',
  NE = 'NE',
  NG = 'NG',
  NF = 'NF',
  KP = 'KP',
  MP = 'MP',
  NO = 'NO',
  OM = 'OM',
  PK = 'PK',
  PW = 'PW',
  PA = 'PA',
  PG = 'PG',
  PY = 'PY',
  PE = 'PE',
  PH = 'PH',
  PN = 'PN',
  PL = 'PL',
  PT = 'PT',
  ZZ = 'ZZ',
  PR = 'PR',
  QA = 'QA',
  TT = 'TT',
  RE = 'RE',
  RO = 'RO',
  RU = 'RU',
  RW = 'RW',
  BL = 'BL',
  SH = 'SH',
  KN = 'KN',
  LC = 'LC',
  MF = 'MF',
  PM = 'PM',
  VC = 'VC',
  WS = 'WS',
  SM = 'SM',
  ST = 'ST',
  SA = 'SA',
  SN = 'SN',
  RS = 'RS',
  SC = 'SC',
  SL = 'SL',
  SG = 'SG',
  SK = 'SK',
  SI = 'SI',
  SB = 'SB',
  SO = 'SO',
  ZA = 'ZA',
  KR = 'KR',
  ES = 'ES',
  LK = 'LK',
  SD = 'SD',
  SR = 'SR',
  SZ = 'SZ',
  SE = 'SE',
  CH = 'CH',
  SY = 'SY',
  TW = 'TW',
  TJ = 'TJ',
  TZ = 'TZ',
  TH = 'TH',
  TG = 'TG',
  TK = 'TK',
  TO = 'TO',
  TN = 'TN',
  TR = 'TR',
  TM = 'TM',
  TC = 'TC',
  TV = 'TV',
  UG = 'UG',
  UA = 'UA',
  AE = 'AE',
  GB = 'GB',
  US = 'US',
  VI = 'VI',
  XX = 'XX',
  UY = 'UY',
  UZ = 'UZ',
  VU = 'VU',
  VE = 'VE',
  VN = 'VN',
  WF = 'WF',
  EH = 'EH',
  YE = 'YE',
  ZM = 'ZM',
  ZW = 'ZW',
}

export enum ECurrency {
  USD = 'USD',
  AED = 'AED',
  AFN = 'AFN',
  ALL = 'ALL',
  AMD = 'AMD',
  ANG = 'ANG',
  AOA = 'AOA',
  ARS = 'ARS',
  AUD = 'AUD',
  AWG = 'AWG',
  AZN = 'AZN',
  BAM = 'BAM',
  BBD = 'BBD',
  BDT = 'BDT',
  BGN = 'BGN',
  BHD = 'BHD',
  BIF = 'BIF',
  BMD = 'BMD',
  BND = 'BND',
  BOB = 'BOB',
  BRL = 'BRL',
  BSD = 'BSD',
  BTC = 'BTC',
  BTN = 'BTN',
  BWP = 'BWP',
  BYN = 'BYN',
  BZD = 'BZD',
  CAD = 'CAD',
  CDF = 'CDF',
  CHF = 'CHF',
  CLF = 'CLF',
  CLP = 'CLP',
  CNH = 'CNH',
  CNY = 'CNY',
  COP = 'COP',
  CRC = 'CRC',
  CUC = 'CUC',
  CUP = 'CUP',
  CVE = 'CVE',
  CZK = 'CZK',
  DJF = 'DJF',
  DKK = 'DKK',
  DOP = 'DOP',
  DZD = 'DZD',
  EGP = 'EGP',
  ERN = 'ERN',
  ETB = 'ETB',
  EUR = 'EUR',
  FJD = 'FJD',
  FKP = 'FKP',
  GBP = 'GBP',
  GEL = 'GEL',
  GGP = 'GGP',
  GHS = 'GHS',
  GIP = 'GIP',
  GMD = 'GMD',
  GNF = 'GNF',
  GTQ = 'GTQ',
  GYD = 'GYD',
  HKD = 'HKD',
  HNL = 'HNL',
  HRK = 'HRK',
  HTG = 'HTG',
  HUF = 'HUF',
  IDR = 'IDR',
  ILS = 'ILS',
  IMP = 'IMP',
  INR = 'INR',
  IQD = 'IQD',
  IRR = 'IRR',
  ISK = 'ISK',
  JEP = 'JEP',
  JMD = 'JMD',
  JOD = 'JOD',
  JPY = 'JPY',
  KES = 'KES',
  KGS = 'KGS',
  KHR = 'KHR',
  KMF = 'KMF',
  KPW = 'KPW',
  KRW = 'KRW',
  KWD = 'KWD',
  KYD = 'KYD',
  KZT = 'KZT',
  LAK = 'LAK',
  LBP = 'LBP',
  LKR = 'LKR',
  LRD = 'LRD',
  LSL = 'LSL',
  LYD = 'LYD',
  MAD = 'MAD',
  MDL = 'MDL',
  MGA = 'MGA',
  MKD = 'MKD',
  MMK = 'MMK',
  MNT = 'MNT',
  MOP = 'MOP',
  MRO = 'MRO',
  MRU = 'MRU',
  MUR = 'MUR',
  MVR = 'MVR',
  MWK = 'MWK',
  MXN = 'MXN',
  MYR = 'MYR',
  MZN = 'MZN',
  NAD = 'NAD',
  NGN = 'NGN',
  NIO = 'NIO',
  NOK = 'NOK',
  NPR = 'NPR',
  NZD = 'NZD',
  OMR = 'OMR',
  PAB = 'PAB',
  PEN = 'PEN',
  PGK = 'PGK',
  PHP = 'PHP',
  PKR = 'PKR',
  PLN = 'PLN',
  PYG = 'PYG',
  QAR = 'QAR',
  RON = 'RON',
  RSD = 'RSD',
  RUB = 'RUB',
  RWF = 'RWF',
  SAR = 'SAR',
  SBD = 'SBD',
  SCR = 'SCR',
  SDG = 'SDG',
  SEK = 'SEK',
  SGD = 'SGD',
  SHP = 'SHP',
  SLL = 'SLL',
  SOS = 'SOS',
  SRD = 'SRD',
  SSP = 'SSP',
  STD = 'STD',
  STN = 'STN',
  SVC = 'SVC',
  SYP = 'SYP',
  SZL = 'SZL',
  THB = 'THB',
  TJS = 'TJS',
  TMT = 'TMT',
  TND = 'TND',
  TOP = 'TOP',
  TRY = 'TRY',
  TTD = 'TTD',
  TWD = 'TWD',
  TZS = 'TZS',
  UAH = 'UAH',
  UGX = 'UGX',
  UYU = 'UYU',
  UZS = 'UZS',
  VEF = 'VEF',
  VES = 'VES',
  VND = 'VND',
  VUV = 'VUV',
  WST = 'WST',
  XAF = 'XAF',
  XAG = 'XAG',
  XAU = 'XAU',
  XCD = 'XCD',
  XDR = 'XDR',
  XOF = 'XOF',
  XPD = 'XPD',
  XPF = 'XPF',
  XPT = 'XPT',
  YER = 'YER',
  ZAR = 'ZAR',
  ZMW = 'ZMW',
  ZWL = 'ZWL',
}

export enum EGiftSendError {
  LOW_MINUTES,
  PREVIOUS_SEND_NOT_COMPLETE,
}

export enum EGiftSendSource {
  VIDEOCHAT,
  COMPANION_RATE,
  MESSENGER,
  STORY,
}

export enum EPushNotificationSubscriptionState {
  WAITING_PERMISSION = 'waiting-permission',
  WAITING_SUBSCRIPTION = 'waiting-subscription',
  SUCCESS = 'success',
  ERROR = 'error',
}

export enum EVideochatStopReason {
  TIME_IS_OVER = 'TimeIsOver',
  USER_OUT = 'UserOut',
  PING = 'Ping',
  STOP_CHAT = 'StopChat',
  SEARCH_NEXT = 'SearchNext',
  SET_BAN = 'SetBan',
  ABUSE = 'Abuse',
  SCORE_REFUNDED = 'ScoreRefunded',
  VIDEOCALL_ACCEPT = 'VideoCallAccept',
  MODERATION_GOOD = 'ModerationGood',
  MODERATION_BAD = 'ModerationBad',
  SEARCH_START = 'SearchStart',
  USER_CARD_CHECK = 'UserCardCheck',
  WAIT_RESTORE = 'WaitRestore',
}

export enum EMediaDeviceType {
  AUDIO = 'audio',
  VIDEO = 'video',
}

export enum EInviteError {
  ACCOUNT_DELETED = 416,
  ALREADY_IN_CONTACTS = 118,
  ACCOUNT_BLOCKED = 480,
}

export enum EAbuseError {
  ACCOUNT_DELETED = 416,
}

export enum EVideochatStopError {
  VIDEOCALL_NOT_EXISTS = 125,
}

export enum ESticker {
  STICKER_1 = 'sticker-1',
  STICKER_2 = 'sticker-2',
  STICKER_3 = 'sticker-3',
  STICKER_4 = 'sticker-4',
  STICKER_5 = 'sticker-5',
  STICKER_6 = 'sticker-6',
  STICKER_7 = 'sticker-7',
  STICKER_8 = 'sticker-8',
  STICKER_9 = 'sticker-9',
  STICKER_10 = 'sticker-10',
  STICKER_11 = 'sticker-11',
  STICKER_12 = 'sticker-12',
  STICKER_13 = 'sticker-13',
  STICKER_14 = 'sticker-14',
  STICKER_15 = 'sticker-15',
  STICKER_16 = 'sticker-16',
  STICKER_17 = 'sticker-17',
  STICKER_18 = 'sticker-18',
  STICKER_19 = 'sticker-19',
  STICKER_20 = 'sticker-20',
  STICKER_21 = 'sticker-21',
  STICKER_22 = 'sticker-22',
  STICKER_23 = 'sticker-23',
  STICKER_24 = 'sticker-24',
  STICKER_25 = 'sticker-25',
  STICKER_26 = 'sticker-26',
  STICKER_27 = 'sticker-27',
  STICKER_28 = 'sticker-28',
  STICKER_29 = 'sticker-29',
  STICKER_30 = 'sticker-30',
  STICKER_31 = 'sticker-31',
  STICKER_32 = 'sticker-32',
  STICKER_33 = 'sticker-33',
  STICKER_34 = 'sticker-34',
  STICKER_35 = 'sticker-35',
  STICKER_36 = 'sticker-36',
  STICKER_37 = 'sticker-37',
  STICKER_38 = 'sticker-38',
  STICKER_39 = 'sticker-39',
  STICKER_40 = 'sticker-40',
  STICKER_41 = 'sticker-41',
  STICKER_42 = 'sticker-42',
  STICKER_43 = 'sticker-43',
  STICKER_44 = 'sticker-44',
  STICKER_45 = 'sticker-45',
  STICKER_46 = 'sticker-46',
  STICKER_47 = 'sticker-47',
  STICKER_48 = 'sticker-48',
  STICKER_49 = 'sticker-49',
}

export enum EReferralValidationError {
  INVALID,
  INCORRECT_ID,
}

export enum EStoryAuthorContactStatus {
  NONE = 'none',
  REQUEST_SENT = 'sent',
  REQUEST_RECEIVED = 'received',
  REQUEST_ACCEPTED = 'accepted',
  REQUEST_DECLINED = 'declined',
}

export enum EOutgoingCallSource {
  VIDEOCHAT,
  MESSENGER,
  STORY,
}

export enum ERecordedStoryModerationStatus {
  APPROVED = 'approved',
  REJECTED_PORN = 'porn',
  REJECTED_ADVERTISING = 'advertising',
  REJECTED_PHOTO = 'photo',
  REJECTED_AGE = 'age',
  REJECTED_GENDER = 'gender',
  REJECTED_CHILD = 'child',
  REJECTED_RULES = 'rules',
  REJECTED_LOADING_ERROR = 'loading-error',
}

export enum EPage {
  GENDER,
  SUBSCRIPTION,
  FORBIDDEN,
  VIDEOCHAT,
  MESSAGES,
  TOP,
  PROFILE,
  STORIES,
  STORY_RECORD,
  NOT_FOUND,
}

export enum EVideochatStage {
  SEARCH,
  IN_PROCESS,
  POST_PROCESS,
  FINISH,
  IN_RECOVERY,
  WAIT_COMPANION_RECOVERY,
  NEED_CONFIRM_RECOVERY,
}

export enum EActionImpossibleReason {
  ACCOUNT_DELETED,
  ACCOUNT_BLOCKED,
}

export enum ETooltipPlacement {
  TOP = 'top',
  BOTTOM = 'bottom',
  LEFT = 'left',
  RIGHT = 'right',
}

export enum EPushNotificationSubscribeSource {
  QUICK_SETTINGS = 1,
  VIDEOCHAT_LOCK = 2,
  STORIES_LOCK = 3,
  MESSENGER = 4,
  PREMIUM_SUBSCRIPTION = 5,
}

export enum EContactNameValidationError {
  EMPTY,
  SHORT,
  CONTAINS_EMOJI,
}

export enum EWithdrawalConfirmationValidationError {
  EMPTY,
  SHORT,
  INCORRECT,
}

export enum EWithdrawalMethodDuration {
  SHORT = 'short',
  MEDIUM = 'medium',
  TWO_DAYS = 'two-days',
}

export enum EEmailConfirmationCodeValidationError {
  EMPTY,
  SHORT,
  INCORRECT,
}
