import { TrackJS, TrackJSConfigureOptions } from 'trackjs';
import config from '@/config';
import { ITrackerRepository } from '@/core/interactors/types/repositories';

class TrackerRepository {
  private static instance: TrackerRepository;

  // eslint-disable-next-line no-useless-constructor, no-empty-function, @typescript-eslint/no-empty-function
  private constructor() {}

  static getInstance(): TrackerRepository {
    try {
      if (TrackerRepository.instance === undefined) {
        TrackerRepository.instance = new TrackerRepository();
      }

      return TrackerRepository.instance;
    } catch (error) {
      throw error;
    }
  }

  setup: ITrackerRepository['setup'] = (): void => {
    try {
      TrackJS.install({
        token: config.tracker.token,
        application: config.tracker.application,
        version: config.app.build,
        forwardingDomain: config.tracker.forwardingDomain,
      });
    } catch (error) {
      throw error;
    }
  }

  /**
   * @param payload конфигурация TrackJS
   */
  configure: ITrackerRepository['configure'] = (payload: TrackJSConfigureOptions): void => {
    try {
      TrackJS.configure(payload);
    } catch (error) {
      throw error;
    }
  }

  /**
   * @param payload массив данных для логирования
   */
  error: ITrackerRepository['error'] = (...payload: any[]): void => {
    try {
      TrackJS.console.error(...payload);
    } catch (error) {
      throw error;
    }
  }

  /**
   * @param payload массив данных для логирования
   */
  log: ITrackerRepository['log'] = (...payload: any[]): void => {
    try {
      TrackJS.console.log(...payload);
    } catch (error) {
      throw error;
    }
  }
}

export default TrackerRepository;
