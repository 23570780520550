import UserViewmodel from '@/core/viewmodels/user.viewmodel';
import StorageRepository from '@/repositories/storage-repository';
import SettingsViewmodel from '@/core/viewmodels/settings.viewmodel';
import WatchAppDataInteractor from '@/core/interactors/watch-app-data.interactor';

class WatchAppDataActionController {
  static execute(): void {
    try {
      const interactor = new WatchAppDataInteractor({
        viewmodels: {
          user: {
            subscribeToGenderChange: UserViewmodel.getInstance().subscribeToGenderChange,
            subscribeToLockExpirationTimeChange: UserViewmodel.getInstance().subscribeToLockExpirationTimeChange,
            subscribeToHashChange: UserViewmodel.getInstance().subscribeToHashChange,
          },
          settings: {
            subscribeToVolumeChange: SettingsViewmodel.getInstance().subscribeToVolumeChange,
            subscribeToMicrophoneChange: SettingsViewmodel.getInstance().subscribeToMicrophoneChange,
          },
        },
        repositories: {
          storage: {
            asyncSetUserGender: StorageRepository.getInstance().asyncSetUserGender,
            asyncSetUserLockExpirationTime: StorageRepository.getInstance().asyncSetUserLockExpirationTime,
            asyncSetUserHash: StorageRepository.getInstance().asyncSetUserHash,
            asyncSetVolumeEnabled: StorageRepository.getInstance().asyncSetVolumeEnabled,
            asyncSetMicrophoneEnabled: StorageRepository.getInstance().asyncSetMicrophoneEnabled,
          },
        },
      });

      interactor.execute();
    } catch (error) {
      throw error;
    }
  }
}

export default WatchAppDataActionController;
