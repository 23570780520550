function toggleElementDisplay(payload: {
  element: HTMLElement,
  comment: Comment,
}): void {
  try {
    const isElementEmpty = (
      payload.element.children.length === 0
      && payload.element.textContent === ''
    );

    const isElementDisplayed = payload.element.parentNode !== null;

    // Если элемент пустой и отображается, скрываем его, заменяя на комментарий, и прерываем выполнение
    if (isElementEmpty && isElementDisplayed) {
      payload.element.replaceWith(payload.comment);

      return;
    }

    // Если элемент непустой и скрыт, показываем его, заменяя комментарий данным элементом
    if (!isElementEmpty && !isElementDisplayed) {
      payload.comment.replaceWith(payload.element);
    }
  } catch (error) {
    throw error;
  }
}

export default {
  mounted(element: HTMLElement): void {
    try {
      const comment = document.createComment('');

      const mutationObserver = new MutationObserver((): void => {
        toggleElementDisplay({ element, comment });
      });

      mutationObserver.observe(element, { childList: true });

      /**
       * В beforeUnmount нужно отключать обсервер, но он создается в хуке mounted и в другом хуке он недоступен.
       * По этому в beforeUnmount генерируется кастомное событие, обработчик которого создается и вешается на элемент
       * в том же хуке где и создается сам обсервер.
       */
      const beforeUnmountHandler = (): void => {
        mutationObserver.disconnect();

        element.removeEventListener('before-unmount', beforeUnmountHandler);
      };

      element.addEventListener('before-unmount', beforeUnmountHandler);

      toggleElementDisplay({ element, comment });
    } catch (error) {
      throw error;
    }
  },
  beforeUnmount(element: HTMLElement): void {
    try {
      const beforeUnmountEvent = new CustomEvent('before-unmount');

      element.dispatchEvent(beforeUnmountEvent);
    } catch (error) {
      throw error;
    }
  },
};
