import { MutationTree } from 'vuex';
import IViewmodel from '@/core/interactors/types/viewmodels';

/* eslint-disable no-param-reassign */
const mutations: MutationTree<IViewmodel> = {
  updateAccountTransactions(state, payload: IViewmodel['entities']['accountTransactions']) {
    try {
      state.entities.accountTransactions = payload;
    } catch (error) {
      throw error;
    }
  },

  updatePromoMessageTemplates(state, payload: IViewmodel['entities']['promoMessageTemplates']) {
    try {
      state.entities.promoMessageTemplates = payload;
    } catch (error) {
      throw error;
    }
  },

  updateUi(state, payload: IViewmodel['ui']) {
    try {
      state.ui = payload;
    } catch (error) {
      throw error;
    }
  },

  updateUiOld(state, payload: Partial<IViewmodel['ui']['flags']>) {
    try {
      state.ui.flags = Object.freeze({
        ...state.ui.flags,
        ...payload,
      });
    } catch (error) {
      throw error;
    }
  },

  updateUser(state, payload: IViewmodel['entities']['user']) {
    try {
      state.entities.user = payload;
    } catch (error) {
      throw error;
    }
  },

  updateWithdrawalRequest(state, payload: IViewmodel['entities']['withdrawalRequest'] | null) {
    try {
      state.entities.withdrawalRequest = payload;
    } catch (error) {
      throw error;
    }
  },

  updateDevices(state, payload: Partial<IViewmodel['entities']['devices']>) {
    try {
      state.entities.devices = Object.freeze({
        ...state.entities.devices,
        ...payload,
      });
    } catch (error) {
      throw error;
    }
  },

  updateRecorder(state, payload: IViewmodel['entities']['recorder']) {
    try {
      state.entities.recorder = payload;
    } catch (error) {
      throw error;
    }
  },

  updateStreams(state, payload: IViewmodel['entities']['streams']) {
    try {
      state.entities.streams = payload;
    } catch (error) {
      throw error;
    }
  },

  updateSettings(state, payload: IViewmodel['entities']['settings']) {
    try {
      state.entities.settings = payload;
    } catch (error) {
      throw error;
    }
  },

  updateBuffer(state, payload: Partial<IViewmodel['ui']['buffer']>) {
    try {
      state.ui.buffer = Object.freeze({
        ...state.ui.buffer,
        ...payload,
      });
    } catch (error) {
      throw error;
    }
  },

  updateVideochat(state, payload: IViewmodel['entities']['videochat']) {
    try {
      state.entities.videochat = payload;
    } catch (error) {
      throw error;
    }
  },

  updateVideochatHistory(state, payload: IViewmodel['entities']['videochatHistory']) {
    try {
      state.entities.videochatHistory = payload;
    } catch (error) {
      throw error;
    }
  },

  updateTop(state, payload: IViewmodel['entities']['top']) {
    try {
      state.entities.top = payload;
    } catch (error) {
      throw error;
    }
  },

  updateProcesses(state, payload: IViewmodel['entities']['processes']) {
    try {
      state.entities.processes = payload;
    } catch (error) {
      throw error;
    }
  },

  updateReferrals(state, payload: IViewmodel['entities']['referrals']) {
    try {
      state.entities.referrals = Object.freeze(payload);
    } catch (error) {
      throw error;
    }
  },

  updateGifts(state, payload: IViewmodel['entities']['gifts']) {
    try {
      state.entities.gifts = payload;
    } catch (error) {
      throw error;
    }
  },

  updateContacts(state, payload: IViewmodel['entities']['contacts']) {
    try {
      state.entities.contacts = payload;
    } catch (error) {
      throw error;
    }
  },

  updateEnvironment(state, payload: IViewmodel['entities']['environment']) {
    try {
      state.entities.environment = payload;
    } catch (error) {
      throw error;
    }
  },

  updateStories(state, payload: IViewmodel['entities']['stories']) {
    try {
      state.entities.stories = payload;
    } catch (error) {
      throw error;
    }
  },

  updateOutgoingCall(state, payload: IViewmodel['entities']['outgoingCall']): void {
    try {
      state.entities.outgoingCall = payload;
    } catch (error) {
      throw error;
    }
  },

  updateIncomingCall(state, payload: IViewmodel['entities']['incomingCall']): void {
    try {
      state.entities.incomingCall = payload;
    } catch (error) {
      throw error;
    }
  },
};
/* eslint-enable no-param-reassign */

export default mutations;
