import { IProcedureEmitterMessage } from '@/packages/procedure-emitter/types';

/**
 * Предоставляет механизм для общения ифрейма с хостом через PostMessage
 * Состоит из 2-з базовых частей: клиента (ифрейма) и агента (хоста)
 * Инициализация клиента происходит через конструктор, для агента есть метод setAgent
 * Важно указать одинаковые namespace в этих местах, чтобы эвенты корректно верифицировались
 * Использовать constructor.name вместо явно указываемого namespace нельзя из-за особенностей сборки
 */
class ProcedureEmitter {
  private client?: Window;

  private agent?: Window;

  private namespace?: string;

  private subscribers: { [key: string]: (value: MessageEvent) => void } = {};

  readonly isAgent: boolean = window.self === window.parent;

  clientContainer?: HTMLIFrameElement;

  constructor(payload: string) {
    this.setAgent(payload);
  }

  private setAgent(payload: string) {
    try {
      this.namespace = payload;
      this.agent = window.parent;
    } catch (error) {
      throw error;
    }
  }

  setClient(payload: { selector: string, namespace: string }): void {
    try {
      // Пробрасываем неймспейс
      this.namespace = payload.namespace;

      // Ищем и запоминаем окно
      const container = document.querySelector(payload.selector);

      if (container === null || !(container instanceof HTMLIFrameElement)) {
        throw new Error('client container is incorrect');
      }

      this.clientContainer = container;

      const content = this.clientContainer.contentWindow;

      if (content === null) {
        throw new Error('client container has not content');
      }

      this.client = content;

      // Подписываемся
      window.addEventListener('message', async (event) => {
        const {
          context: messageContext,
          procedure: messageProcedure,
          payload: messagePayload,
        }: IProcedureEmitterMessage = event.data;

        /**
         * Проверяем, что сообщение отправлено конкретно данному инстансу ProcedureEmitter
         * Выходим без ошибок ибо сообщения могут приходить и от других источников, а эвент один
         */
        if (messageContext === undefined || messageContext !== this.namespace) {
          return;
        }

        /**
         * Результат выполнения процедуры передается клиенту через postMessage,
         * в котором данные сериализуются, но сложные объекты вроде window и подобные
         * не могут быть преобразованы и это приводит к ошибке.
         * Поэтому нужно учитывать это.
         */
        // @ts-ignore
        const result = await this[messageProcedure].call(this, messagePayload);

        const message: IProcedureEmitterMessage = {
          context: messageContext,
          procedure: messageProcedure,
          payload: result,
        };

        if (this.client === undefined) {
          throw new Error('client is undefined');
        }

        this.client.postMessage(message, '*');
      }, false);
    } catch (error) {
      throw error;
    }
  }

  // TODO добавить таймаут ожидания ответа
  async sendToAgent(payload: {
    procedure: string,
    payload?: any,
  }): Promise<any> {
    try {
      return await new Promise((resolve) => {
        // Обрабатываем нештатные ситуации
        if (this.agent === undefined || this.namespace === undefined) {
          throw new Error('agent or namespace is undefined');
        }

        const {
          procedure: messageProcedure,
          payload: messagePayload,
        } = payload;
        const context = this.namespace;

        // Подписываемся на ответ агента
        window.addEventListener('message', function handler(event) {
          const {
            context: agentContext,
            procedure: agentProcedure,
            payload: agentPayload,
          }: IProcedureEmitterMessage = event.data;

          /**
           * Проверяем, что сообщение отправлено конкретно данному инстансу ProcedureEmitter
           * Выходим без ошибок ибо сообщения могут приходить и от других источников, а эвент один
           */
          if (agentContext !== context || agentProcedure !== messageProcedure) {
            return;
          }

          window.removeEventListener('message', handler, false);
          resolve(agentPayload);
        }, false);

        // Отправляем агенту сообщение
        const message: IProcedureEmitterMessage = {
          context,
          procedure: messageProcedure,
          payload: messagePayload,
        };

        this.agent.postMessage(message, '*');
      });
    } catch (error) {
      throw error;
    }
  }

  sendToClient(payload: { procedure: string, payload?: any }): void {
    try {
      // Обрабатываем нештатные ситуации
      if (this.client === undefined || this.namespace === undefined) {
        throw new Error('client or namespace is undefined');
      }

      // Отправляем клиенту сообщение
      const {
        procedure: messageProcedure,
        payload: messagePayload,
      } = payload;
      const message: IProcedureEmitterMessage = {
        procedure: messageProcedure,
        payload: messagePayload,
        context: this.namespace,
      };

      this.client.postMessage(message, '*');
    } catch (error) {
      throw error;
    }
  }

  subscribeToAgent(payload: string): void {
    try {
      // Обрабатываем нештатные ситуации
      if (this.agent === undefined || this.namespace === undefined) {
        throw new Error('agent or namespace is undefined');
      }

      // Может быть только одна подписка на указанную процедуру
      if (this.subscribers[payload] !== undefined) {
        throw new Error('subscriber is defined');
      }

      const context = this.namespace;

      this.subscribers[payload] = (event: MessageEvent) => {
        const {
          context: agentContext,
          procedure: agentProcedure,
          payload: agentPayload,
        }: IProcedureEmitterMessage = event.data;

        /**
         * Проверяем, что сообщение отправлено конкретно данному инстансу ProcedureEmitter
         * Выходим без ошибок ибо сообщения могут приходить и от других источников, а эвент один
         */
        if (agentContext !== context || agentProcedure !== payload) {
          return;
        }

        // @ts-ignore
        this[payload].call(this, agentPayload);
      };

      // Подписываемся на ответ агента
      window.addEventListener('message', this.subscribers[payload], false);
    } catch (error) {
      throw error;
    }
  }

  unsubscribeToAgent(payload: string): void {
    try {
      // Обрабатываем нештатные ситуации
      if (this.agent === undefined || this.namespace === undefined) {
        throw new Error('agent or namespace is undefined');
      }

      if (this.subscribers[payload] === undefined) {
        throw new Error('subscriber is undefined');
      }

      window.removeEventListener('message', this.subscribers[payload], false);

      delete this.subscribers[payload];
    } catch (error) {
      throw error;
    }
  }
}

export default ProcedureEmitter;
