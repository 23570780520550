import ILocaleMessagesEntity from '@/core/entities/locale-messages.entity';
// eslint-disable-next-line import/no-cycle
import i18n from '@/i18n';
// eslint-disable-next-line import/no-cycle
import store from '@/store';
import { NonNullablePick } from '@/core/types';
import IViewmodel, { ISettingsViewmodel } from '@/core/interactors/types/viewmodels';
import ISettingsEntity from '@/core/entities/settings.entity';
import { EContactsDisplayMode, EContactsFiltration } from '@/enums';

class SettingsViewmodel {
  private store = store;

  private static instance: SettingsViewmodel;

  // eslint-disable-next-line no-useless-constructor, no-empty-function, @typescript-eslint/no-empty-function
  private constructor() {}

  static getInstance(): SettingsViewmodel {
    try {
      if (SettingsViewmodel.instance === undefined) {
        SettingsViewmodel.instance = new SettingsViewmodel();
      }

      return SettingsViewmodel.instance;
    } catch (error) {
      throw error;
    }
  }

  private setProperty<
    Property extends keyof ISettingsEntity,
    Payload extends ISettingsEntity[Property],
  >(
    property: Property,
    payload: Payload,
  ): void {
    try {
      this.store.commit(
        'updateSettings',
        Object.freeze({
          ...this.store.state.entities.settings,
          [property]: payload,
        }),
      );
    } catch (error) {
      throw error;
    }
  }

  private watchProperty<Property extends keyof ISettingsEntity>(
    property: Property,
    callback: (payload: ISettingsEntity[Property]) => void,
  ): void {
    try {
      this.store.watch((state) => state.entities.settings[property], callback);
    } catch (error) {
      throw error;
    }
  }

  get: ISettingsViewmodel['get'] = (): IViewmodel['entities']['settings'] => {
    try {
      return this.store.state.entities.settings;
    } catch (error) {
      throw error;
    }
  }

  set: ISettingsViewmodel['set'] = (payload: IViewmodel['entities']['settings']): void => {
    try {
      this.store.commit('updateSettings', Object.freeze({ ...payload }));
    } catch (error) {
      throw error;
    }
  }

  setIsMicrophoneEnabled: ISettingsViewmodel['setIsMicrophoneEnabled'] = (
    payload: ISettingsEntity['isMicrophoneEnabled'],
  ): void => {
    try {
      this.setProperty('isMicrophoneEnabled', payload);
    } catch (error) {
      throw error;
    }
  }

  setIsVolumeEnabled: ISettingsViewmodel['setIsVolumeEnabled'] = (payload: ISettingsEntity['isVolumeEnabled']): void => {
    try {
      this.setProperty('isVolumeEnabled', payload);
    } catch (error) {
      throw error;
    }
  }

  setMicrophoneAndVolumeEnabled: ISettingsViewmodel['setMicrophoneAndVolumeEnabled'] = (payload: {
    isMicrophoneEnabled: ISettingsEntity['isMicrophoneEnabled'],
    isVolumeEnabled: ISettingsEntity['isVolumeEnabled'],
  }): void => {
    try {
      this.store.commit(
        'updateSettings',
        Object.freeze({
          ...this.store.state.entities.settings,
          isMicrophoneEnabled: payload.isMicrophoneEnabled,
          isVolumeEnabled: payload.isVolumeEnabled,
        }),
      );
    } catch (error) {
      throw error;
    }
  }

  setContactsFiltration: ISettingsViewmodel['setContactsFiltration'] = (
    payload: ISettingsEntity['contactsFiltration'],
  ): void => {
    try {
      this.setProperty('contactsFiltration', payload);
    } catch (error) {
      throw error;
    }
  }

  setIsMessagesAutoReadEnabled: ISettingsViewmodel['setIsMessagesAutoReadEnabled'] = (
    payload: ISettingsEntity['isMessagesAutoReadEnabled'],
  ): void => {
    try {
      this.setProperty('isMessagesAutoReadEnabled', payload);
    } catch (error) {
      throw error;
    }
  }

  setVideochatHistorySorting: ISettingsViewmodel['setVideochatHistorySorting'] = (
    payload: ISettingsEntity['videochatHistorySorting'],
  ): void => {
    try {
      this.setProperty('videochatHistorySorting', payload);
    } catch (error) {
      throw error;
    }
  }

  setContactsDisplayMode: ISettingsViewmodel['setContactsDisplayMode'] = (
    payload: ISettingsEntity['contactsDisplayMode'],
  ): void => {
    try {
      this.setProperty('contactsDisplayMode', payload);
    } catch (error) {
      throw error;
    }
  }

  setIsSafeSearchEnabled: ISettingsViewmodel['setIsSafeSearchEnabled'] = (
    payload: ISettingsEntity['isSafeSearchEnabled'],
  ): void => {
    try {
      this.setProperty('isSafeSearchEnabled', payload);
    } catch (error) {
      throw error;
    }
  }

  setUserMaleSearchFiltration: ISettingsViewmodel['setUserMaleSearchFiltration'] = (
    payload: ISettingsEntity['userMaleSearchFiltration'],
  ): void => {
    try {
      this.setProperty('userMaleSearchFiltration', payload);
    } catch (error) {
      throw error;
    }
  }

  setIsPushNotificationOnClientEnabled: ISettingsViewmodel['setIsPushNotificationOnClientEnabled'] = (
    payload: ISettingsEntity['isPushNotificationOnClientEnabled'],
  ): void => {
    try {
      this.setProperty('isPushNotificationOnClientEnabled', payload);
    } catch (error) {
      throw error;
    }
  }

  setLocalStreamSize: ISettingsViewmodel['setLocalStreamSize'] = (payload: ISettingsEntity['localStreamSize']): void => {
    try {
      this.setProperty('localStreamSize', payload);
    } catch (error) {
      throw error;
    }
  }

  setLocalStreamCoordinates: ISettingsViewmodel['setLocalStreamCoordinates'] = (
    payload: ISettingsEntity['localStreamCoordinates'],
  ): void => {
    try {
      this.setProperty('localStreamCoordinates', payload);
    } catch (error) {
      throw error;
    }
  }

  subscribeToVolumeChange: ISettingsViewmodel['subscribeToVolumeChange'] = (
    payload: (value: ISettingsEntity['isVolumeEnabled']) => void,
  ): void => {
    try {
      this.watchProperty('isVolumeEnabled', payload);
    } catch (error) {
      throw error;
    }
  }

  subscribeToMicrophoneChange: ISettingsViewmodel['subscribeToMicrophoneChange'] = (
    payload: (value: ISettingsEntity['isMicrophoneEnabled']) => void,
  ): void => {
    try {
      this.watchProperty('isMicrophoneEnabled', payload);
    } catch (error) {
      throw error;
    }
  }

  subscribeOnceToLocaleChange: ISettingsViewmodel['subscribeOnceToLocaleChange'] = (
    payload: (value: ISettingsEntity['locale']) => void,
  ): void => {
    try {
      const unwatch = this.store.watch(
        (state) => state.entities.settings.locale,
        (locale) => {
          payload(locale);

          unwatch();
        },
      );
    } catch (error) {
      throw error;
    }
  }

  setIsStoryVolumeEnabled: ISettingsViewmodel['setIsStoryVolumeEnabled'] = (
    payload: ISettingsEntity['isStoryVolumeEnabled'],
  ): void => {
    try {
      this.setProperty('isStoryVolumeEnabled', payload);
    } catch (error) {
      throw error;
    }
  }

  setLocale: ISettingsViewmodel['setLocale'] = (payload: ISettingsEntity['locale']): void => {
    try {
      this.setProperty('locale', payload);
    } catch (error) {
      throw error;
    }
  }

  setScreenshotCaptureDelay: ISettingsViewmodel['setScreenshotCaptureDelay'] = (payload: ISettingsEntity['screenshotCaptureDelay']): void => {
    try {
      this.setProperty('screenshotCaptureDelay', payload);
    } catch (error) {
      throw error;
    }
  }

  updateAfterLogout: ISettingsViewmodel['updateAfterLogout'] = (
    payload: NonNullablePick<
      ISettingsEntity,
      'screenshotCaptureInterval'
      | 'millisecondsForSearchFiltration'
      | 'promoDurationMinutes'
      | 'usernameMaxLength'
      | 'emailMaxLength'
      | 'messageMaxLength'
      | 'imageMaxUploadSize'
      | 'minutesCountForAutoPurchaseActivation'
      | 'firstAutoPurchaseAttemptMinutesCount'
      | 'secondAutoPurchaseAttemptMinutesCount'
      | 'recordedStoryMaxDuration'
      | 'recordedStoryLifetime'
      | 'isSafeSearchEnabled'
    >,
  ): void => {
    try {
      this.store.commit(
        'updateSettings',
        Object.freeze({
          ...this.store.state.entities.settings,
          ...payload,
          contactsDisplayMode: EContactsDisplayMode.DEFAULT,
          contactsFiltration: EContactsFiltration.RECENT,
        }),
      );
    } catch (error) {
      throw error;
    }
  }

  asyncSetLocaleData: ISettingsViewmodel['asyncSetLocaleData'] = async (payload: {
    localeValue: NonNullable<ISettingsEntity['locale']>,
    localeMessages: ILocaleMessagesEntity,
  }): Promise<void> => {
    try {
      // Устанавливаем словарь, если такой локали еще нет
      if (!i18n.global.availableLocales.includes(payload.localeValue)) {
        i18n.global.setLocaleMessage(payload.localeValue, payload.localeMessages.list);
      }

      // Устанавливаем локаль в модуль переводов
      // @ts-ignore
      i18n.global.locale.value = payload.localeValue;

      // Обновляем значение локали в ВМ
      this.setProperty('locale', payload.localeValue);
    } catch (error) {
      throw error;
    }
  }
}

export default SettingsViewmodel;
