import { ELocale } from '@/enums';

const defaultFormats = {
  'date-with-weekday': {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  },

  'date-time': {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
  },

  time: {
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
  },

  date: {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  },

  'date-numeric': {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  },

  'day-month': {
    day: 'numeric',
    month: 'numeric',
  },

  'day-month-gmt': {
    day: 'numeric',
    month: 'numeric',
    timeZone: 'GMT',
  },

  'day-month-long': {
    day: 'numeric',
    month: 'long',
  },
};

/**
 * Генерируем форматы дат и времени для всех языков,
 * потому что vue-i18n требует указание форматов для каждого языка
 */
const datetimeFormats = Object.values(ELocale).reduce(
  (result, code) => {
    let localeFormats = { ...defaultFormats };

    // Мануально выставляем 12 часовой формат для английской локали
    if (code === ELocale.EN) {
      localeFormats = {
        ...localeFormats,
        'date-time': {
          ...defaultFormats['date-time'],
          hour12: true,
        },
        time: {
          ...defaultFormats.time,
          hour12: true,
        },
      };
    }

    return {
      ...result,
      [code]: localeFormats,
    };
  },
  {},
);

export default datetimeFormats;
