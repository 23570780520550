import SyncAppCriticalDataInteractor from '@/core/interactors/sync-app-critical-data.interactor';
import UserViewmodel from '@/core/viewmodels/user.viewmodel';
import StorageRepository from '@/repositories/storage-repository';
import LocationRepository from '@/repositories/location-repository';

class SyncAppCriticalDataActionController {
  static async asyncExecute(): Promise<void> {
    try {
      const interactor = new SyncAppCriticalDataInteractor({
        viewmodels: {
          user: {
            setGender: UserViewmodel.getInstance().setGender,
          },
        },
        repositories: {
          storage: {
            asyncGetUserGender: StorageRepository.getInstance().asyncGetUserGender,
          },
          location: {
            asyncGetUtmSource: LocationRepository.getInstance().asyncGetUtmSource,
          },
        },
      });

      await interactor.asyncExecute();
    } catch (error) {
      throw error;
    }
  }
}

export default SyncAppCriticalDataActionController;
