import { ISettingsViewmodel, IUserViewmodel } from '@/core/interactors/types/viewmodels';
import { IStorageRepository } from '@/core/interactors/types/repositories';
import { EGender } from '@/enums';
import VideochatViewmodel from '@/core/viewmodels/videochat.viewmodel';
import IVideochatEntity from '@/core/entities/videochat.entity';
import TrackerRepository from '@/repositories/tracker-repository';

interface IWatchAppDataInteractorBoundaries {
  readonly viewmodels: {
    user: Pick<
      IUserViewmodel,
      'subscribeToGenderChange'
      | 'subscribeToLockExpirationTimeChange'
      | 'subscribeToHashChange'
    >,
    settings: Pick<ISettingsViewmodel, 'subscribeToVolumeChange' | 'subscribeToMicrophoneChange'>,
  },
  readonly repositories: {
    storage: Pick<
      IStorageRepository,
      'asyncSetUserGender'
      | 'asyncSetUserLockExpirationTime'
      | 'asyncSetUserHash'
      | 'asyncSetVolumeEnabled'
      | 'asyncSetMicrophoneEnabled'
    >,
  },
}

class WatchAppDataInteractor {
  private boundaries: IWatchAppDataInteractorBoundaries;

  constructor(payload: IWatchAppDataInteractorBoundaries) {
    this.boundaries = { ...payload };
  }

  execute(): void {
    try {
      /**
       * TODO заменить когда будут нормальные креаторы
       * Сейчас поле может быть сброшено из-за кривого креатора
       */
      this.boundaries.viewmodels.user.subscribeToGenderChange(
        async (value: EGender | undefined): Promise<void> => {
          try {
            if (value !== undefined) {
              await this.boundaries.repositories.storage.asyncSetUserGender(value);
            }
          } catch (error) {
            throw error;
          }
        },
      );

      /**
       * TODO заменить когда будут нормальные креаторы
       * Сейчас поле может быть сброшено из-за кривого креатора
       */
      this.boundaries.viewmodels.user.subscribeToLockExpirationTimeChange(
        async (value: number | undefined): Promise<void> => {
          try {
            if (value !== undefined) {
              await this.boundaries.repositories.storage.asyncSetUserLockExpirationTime(value);
            }
          } catch (error) {
            throw error;
          }
        },
      );

      /**
       * TODO заменить когда будут нормальные креаторы
       * Сейчас поле может быть сброшено из-за кривого креатора
       */
      this.boundaries.viewmodels.user.subscribeToHashChange(
        async (value: string | undefined): Promise<void> => {
          try {
            try {
              if (value !== undefined) {
                await this.boundaries.repositories.storage.asyncSetUserHash(value);
              }
            } catch (error) {
              throw error;
            }
          } catch (error) {
            throw error;
          }
        },
      );

      this.boundaries.viewmodels.settings.subscribeToVolumeChange(
        async (value: boolean | undefined): Promise<void> => {
          try {
            if (value !== undefined) {
              await this.boundaries.repositories.storage.asyncSetVolumeEnabled(value);
            }
          } catch (error) {
            throw error;
          }
        },
      );

      this.boundaries.viewmodels.settings.subscribeToMicrophoneChange(
        async (value: boolean | undefined): Promise<void> => {
          try {
            if (value !== undefined) {
              await this.boundaries.repositories.storage.asyncSetMicrophoneEnabled(value);
            }
          } catch (error) {
            throw error;
          }
        },
      );

      VideochatViewmodel.getInstance().subscribeToStageChange(
        async (value: IVideochatEntity['stage']): Promise<void> => {
          try {
            TrackerRepository.getInstance().log(`videochat stage changed to ${value}`);
          } catch (error) {
            throw error;
          }
        },
      );
    } catch (error) {
      throw error;
    }
  }
}

export default WatchAppDataInteractor;
