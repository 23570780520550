import { IUserViewmodel } from '@/core/interactors/types/viewmodels';
import { ILocationRepository, IStorageRepository } from '@/core/interactors/types/repositories';
import { EGender } from '@/enums';

interface ISyncAppCriticalDataInteractorBoundaries {
  readonly viewmodels: {
    user: Pick<IUserViewmodel, 'setGender'>,
  },
  readonly repositories: {
    storage: Pick<IStorageRepository, 'asyncGetUserGender'>,
    location: Pick<ILocationRepository, 'asyncGetUtmSource'>,
  },
}

class SyncAppCriticalDataInteractor {
  private boundaries: ISyncAppCriticalDataInteractorBoundaries;

  constructor(payload: ISyncAppCriticalDataInteractorBoundaries) {
    this.boundaries = { ...payload };
  }

  async asyncExecute(): Promise<void> {
    try {
      /**
       * Выставляем пол за пользователя (М выбран как дефолтный),
       * если он не был установлен ранее и переход был по ссылке из письма.
       * UTM метки используются в ссылках из писем эл.почты.
       * Метка `utm_source` будет присутствовать во всех ссылках из писем,
       * поэтому ориентируемся на ее наличие
       */
      const { user } = await this.boundaries.repositories.storage.asyncGetUserGender();

      let userGender = user.gender;

      if (userGender === undefined) {
        const { utmSource } = (await this.boundaries.repositories.location.asyncGetUtmSource()).environment;

        if (utmSource !== undefined) {
          userGender = EGender.MALE;
        }
      }

      this.boundaries.viewmodels.user.setGender(userGender);
    } catch (error) {
      throw error;
    }
  }
}

export default SyncAppCriticalDataInteractor;
