
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'Iframe',

    mounted(): void {
      try {
        // eslint-disable-next-line no-underscore-dangle
        window._coomeet = {
          PartnerId: 15567042862715,
          ChannelName: 'DevTeam',
        };

        const coomeetScript = document.createElement('script');

        coomeetScript.type = 'text/javascript';

        coomeetScript.async = true;

        coomeetScript.src = `${process.env.VUE_APP_ORIGIN || window.origin}/js/code.js?${Date.now()}`;

        (this.$refs.coomeetContainer as HTMLElement).appendChild(coomeetScript);
      } catch (error) {
        throw error;
      }
    },
  });
