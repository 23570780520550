import { createApp, h } from 'vue';
import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';
// @ts-ignore
import VueSvgInlinePlugin from 'vue-svg-inline-plugin';
import App from '@/app.vue';
import Iframe from '@/iframe.vue';
import router from '@/router/index';
import store from '@/store/index';
import i18n from '@/i18n/index';
import config from '@/config';
import TrackerRepository from '@/repositories/tracker-repository';
import UserViewmodel from '@/core/viewmodels/user.viewmodel';
import ifNotEmpty from '@/directives/if-not-empty';
import SyncAppCriticalDataActionController from '@/core/controllers/sync-app-critical-data-action.controller';
import WatchAppDataActionController from '@/core/controllers/watch-app-data-action.controller';

if (config.tracker.isEnabled) {
  TrackerRepository.getInstance().setup();
}

const isPartnerSimulationActive = (
  process.env.VUE_APP_IFRAME_ENABLED && window.self === window.parent
);

/**
 * Грязное место!
 *
 * Определение целевой страницы должно быть сделано до запуска App ибо нельзя
 * использовать хуки App в качестве места заполнения стора и перехода на нужную страницу: пока идет
 * синхронизация стора с внешними данными в каком-то App хуке, App компонент рендерит
 * template-секцию и начинается выполнение роута и хуков ответственного за роут компонента.
 * Следом заканчивает выполнение хук App компонента, который прерывает редиректом инит компонентента
 * роута. В итоге лезет некорректное поведение логики и ложные срабатывания хуков того же main.vue
 * с неприятными последствиями
 *
 * Делаем проверки, заполняем стор внешними данными, запускаем логику стартовых переходов страниц
 * Хук роутера срабатывает при каждом переходе, поэтому минимизируем стоимость вычислений
 */
router.beforeEach(async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  // Если данные уже синхронизированы и пол есть, либо страница имитации партнера - выходим
  const user = UserViewmodel.getInstance().get();

  if (isPartnerSimulationActive || user.gender !== undefined) {
    return next();
  }

  /**
   * Синхронизируем данные при первом запуске, делаем редирект если:
   * - предыдущая страница отсутствует
   * - следующая страница не страница выбора пола
   * - следующая страница не страница подписки
   */
  const isPrevPageNotExist = from.name === null || from.name === undefined;

  const isNextPageNotGender = to.name !== 'gender';

  const isNextPageNotSubscription = to.name !== 'subscription';

  if (
    isPrevPageNotExist
    && isNextPageNotGender
    && isNextPageNotSubscription
  ) {
    await SyncAppCriticalDataActionController.asyncExecute();

    const userAfterSync = UserViewmodel.getInstance().get();

    if (userAfterSync.gender === undefined) {
      return next({ name: 'gender' });
    }

    return next();
  }

  return next();
});

createApp({
  created() {
    try {
      WatchAppDataActionController.execute();
    } catch (error) {
      throw error;
    }
  },
  render() {
    if (isPartnerSimulationActive) {
      return h(Iframe);
    }

    return h(App);
  },
})
  .use(store)
  .use(router)
  .use(i18n)
  .use(VueSvgInlinePlugin, {
    attributes: {
      remove: ['style', 'focusable', 'style', 'alt', 'role', 'tabindex'],
    },
    cache: {
      persistent: false,
      removeRevisions: false,
    },
  })
  .directive('if-not-empty', ifNotEmpty)
  .mount('body');
