export function choiceRussianIndex(choice: number): number {
  if (choice % 10 === 1 && choice % 100 !== 11) {
    return 0;
  }

  if (choice % 10 >= 2 && choice % 10 <= 4 && (choice % 100 < 10 || choice % 100 >= 20)) {
    return 1;
  }

  return 2;
}

export function choiceFrenchIndex(choice: number): number {
  if (choice <= 1) {
    return 0;
  }

  return 1;
}

export function choicePolishIndex(choice: number): number {
  if (choice === 1) {
    return 0;
  }

  if (choice % 10 >= 2 && choice % 10 <= 4 && (choice % 100 < 10 || choice % 100 >= 20)) {
    return 1;
  }

  return 2;
}

export function choiceRomanianIndex(choice: number): number {
  if (choice === 1) {
    return 0;
  }

  if (choice === 0 || (choice % 100 > 0 && choice % 100 < 20)) {
    return 1;
  }

  return 2;
}

export function choiceTurkishIndex(choice: number, choicesLength: number): number {
  /**
   * Для турецкого языка в случаях когда число стоит перед словом используется только одна форма
   * По этому для подобных кейсов в текстах будет только одна форма
   * Для остальных случаев оставляем определение согласно http://docs.translatehouse.org/projects/localization-guide/en/latest/l10n/pluralforms.html
   */
  if (choicesLength === 1) {
    return 0;
  }

  if (choice <= 1) {
    return 0;
  }

  return 1;
}

export function choiceUkrainianIndex(choice: number): number {
  if (choice % 10 === 1 && choice % 100 !== 11) {
    return 0;
  }

  if (choice % 10 >= 2 && choice % 10 <= 4 && (choice % 100 < 10 || choice % 100 >= 20)) {
    return 1;
  }

  return 2;
}
