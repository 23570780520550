import ShowPageLoadErrorInteractor from '@/core/interactors/show-page-load-error.interactor';
// eslint-disable-next-line import/no-cycle
import UiViewmodel from '@/core/viewmodels/ui.viewmodel';

class ShowPageLoadErrorAttentionActionController {
  static execute(): void {
    try {
      const interactor = new ShowPageLoadErrorInteractor({
        viewmodels: {
          ui: {
            showPageLoadingError: UiViewmodel.getInstance().showPageLoadingError,
          },
        },
      });

      interactor.execute();
    } catch (error) {
      throw error;
    }
  }
}

export default ShowPageLoadErrorAttentionActionController;
