import { IUiViewmodel } from '@/core/interactors/types/viewmodels';

interface IShowPageLoadErrorInteractorBoundaries {
  readonly viewmodels: {
    ui: Pick<IUiViewmodel, 'showPageLoadingError'>,
  },
}

class ShowPageLoadErrorInteractor {
  private boundaries: IShowPageLoadErrorInteractorBoundaries;

  constructor(payload: IShowPageLoadErrorInteractorBoundaries) {
    this.boundaries = { ...payload };
  }

  execute(): void {
    try {
      // Показываем ошибку
      this.boundaries.viewmodels.ui.showPageLoadingError();
    } catch (error) {
      throw error;
    }
  }
}

export default ShowPageLoadErrorInteractor;
