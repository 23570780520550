import { Md5 } from 'md5-typescript';
import config from '@/config';
import {
  IPushNotificationMessage,
  EPushNotificationType,
  IPushNotificationPingClient,
} from '@/repositories/push-notification-repository/types';
import IUserEntity from '@/core/entities/user.entity';
import IContactEntity from '@/core/entities/contact.entity';
import { IPushNotificationRepository } from '@/core/interactors/types/repositories';
import ISettingsEntity from '@/core/entities/settings.entity';
import IPushNotificationSubscriptionEntity from '@/core/entities/push-notification-subscription.entity';

class PushNotificationRepository {
  private static instance: PushNotificationRepository;

  private static context = 'PushNotificationRepository';

  private handlers: { [key: string]: (payload: IPushNotificationMessage['payload']) => void } = {};

  private constructor() {
    this.processMessage = this.processMessage.bind(this);
  }

  static getInstance(): PushNotificationRepository {
    try {
      if (PushNotificationRepository.instance === undefined) {
        PushNotificationRepository.instance = new PushNotificationRepository();
      }

      return PushNotificationRepository.instance;
    } catch (error) {
      throw error;
    }
  }

  /**
   * @param payload содержимое пуш-нотификации
   */
  private executeHandler(payload: IPushNotificationMessage) {
    try {
      const {
        procedure,
        payload: messagePayload,
      }: IPushNotificationMessage = payload;

      // Если нет подписки, прерываем выполнение метода
      if (this.handlers[procedure] === undefined) {
        return;
      }

      // Выполняем обработчик сообщения
      this.handlers[procedure](messagePayload);
    } catch (error) {
      throw error;
    }
  }

  /**
   * @param payload событие получения сообщения
   */
  private processMessage(payload: MessageEvent) {
    try {
      const {
        context,
        procedure,
        payload: messagePayload,
      }: IPushNotificationMessage = payload.data;

      // Выходим без обработки, если сообщение от стороннего сервиса
      if (context !== PushNotificationRepository.context) {
        return;
      }

      // Проверяем валидность данных сообщения
      if (!Object.values(EPushNotificationType).includes(procedure)) {
        throw new Error('message data is not valid');
      }

      // Запускаем обработчик
      this.executeHandler({
        context,
        procedure,
        payload: messagePayload,
      });
    } catch (error) {
      throw error;
    }
  }

  private activateSubscription() {
    try {
      // Добавляем слушателя пост-сообщений от сервис-воркера
      const { serviceWorker } = window.navigator;

      if (serviceWorker === undefined) {
        throw new Error('service worker is undefined');
      }

      serviceWorker.addEventListener('message', this.processMessage, false);
    } catch (error) {
      throw error;
    }
  }

  private deactivateSubscription() {
    try {
      // Удаляем слушателя пост-сообщений от сервис-воркера
      const { serviceWorker } = window.navigator;

      if (serviceWorker === undefined) {
        throw new Error('service worker is undefined');
      }

      serviceWorker.removeEventListener('message', this.processMessage, false);
    } catch (error) {
      throw error;
    }
  }

  /**
   * @param payload.procedure тип пуш-нотификации
   * @param payload.handler обработчик
   */
  private addHandler(payload: {
    procedure: EPushNotificationType,
    handler: (payload: IPushNotificationMessage['payload']) => void,
  }): void {
    try {
      // Добавляем обработчик в список
      if (this.handlers[payload.procedure] !== undefined) {
        throw new Error('handler is defined');
      }

      this.handlers[payload.procedure] = payload.handler;

      // Если нужно инициализируем слушателей
      if (Object.keys(this.handlers).length === 1) {
        this.activateSubscription();
      }
    } catch (error) {
      throw error;
    }
  }

  /**
   * @param payload тип пуш-нотификации
   */
  private removeHandler(payload: EPushNotificationType): void {
    try {
      // Удаляем обработчик из списка
      if (this.handlers[payload] === undefined) {
        throw new Error('handler is undefined');
      }

      delete this.handlers[payload];

      // Если нужно, убиваем слушателей
      if (Object.keys(this.handlers).length === 0) {
        this.deactivateSubscription();
      }
    } catch (error) {
      throw error;
    }
  }

  /**
   * @param payload.procedure тип пуш-нотификации
   * @param payload.payload данные для передачи в нотификации
   */
  private static send(payload: {
    procedure: EPushNotificationType,
    payload?: any,
  }): { status: 'success' } | { status: 'error', message: string } {
    try {
      // Отправляем данные сервис-воркеру
      const { serviceWorker } = window.navigator;

      if (serviceWorker === undefined) {
        return { status: 'error', message: 'service worker is undefined' };
      }

      if (serviceWorker.controller === null) {
        return { status: 'error', message: 'service worker controller is null' };
      }

      serviceWorker.controller.postMessage({
        context: PushNotificationRepository.context,
        procedure: payload.procedure,
        payload: payload.payload,
      });

      return { status: 'success' };
    } catch (error) {
      throw error;
    }
  }

  /**
   * @param payload base64-строка для публичного ключа
   */
  private static convertBase64ToUint8Array(payload: string) {
    try {
      const padding = '='.repeat((4 - (payload.length % 4)) % 4);

      const base64 = (payload + padding).replace(/-/g, '+').replace(/_/g, '/');

      const rawData = window.atob(base64);

      const result = new Uint8Array(rawData.length);

      for (let i = 0; i < rawData.length; i += 1) {
        result[i] = rawData.charCodeAt(i);
      }

      return result;
    } catch (error) {
      throw error;
    }
  }

  private static async asyncGetRegistration(): Promise<{
    data: ServiceWorkerRegistration | undefined
  } | {
    error: Error,
  }> {
    try {
      // Получаем и возвращаем данные регистрации воркера
      const { serviceWorker } = window.navigator;

      if (serviceWorker === undefined) {
        throw new Error('service worker is undefined');
      }

      try {
        const registration = await serviceWorker.getRegistration();

        return {
          data: registration,
        };
      } catch (e) {
        // Возвращаем ошибку получения данных регистрации сервис-воркера
        return {
          error: e,
        };
      }
    } catch (error) {
      throw error;
    }
  }

  getIsSupported: IPushNotificationRepository['getIsSupported'] = (): boolean => {
    try {
      /**
       * Костыль! В Safari и Firefox из-за партификации просматриваемого в iframe контекста получается ситуация когда
       * сервис-воркер после открытия нового окна и подписки на уведомления не может получить доступ к партифицированным
       * копиям приложения как к клиентам, а следовательно не может полноценно обработать клик пользователя
       * по уведомлению. Поэтому для этих браузеров проверка должна возвращать что пуши в них недоступны
       */
      if (
        /Version\/([0-9._]+).*Safari/.test(navigator.userAgent)
        || /Firefox\/([0-9.]+)(?:\s|$)/.test(navigator.userAgent)
      ) {
        return false;
      }

      return (
        'Notification' in window
        && window.PushManager !== undefined
        && window.navigator.serviceWorker !== undefined
      );
    } catch (error) {
      throw error;
    }
  }

  getIsAllowed: IPushNotificationRepository['getIsAllowed'] = (): boolean => {
    try {
      return window.Notification.permission === 'granted';
    } catch (error) {
      throw error;
    }
  }

  asyncRequestPermission: IPushNotificationRepository['asyncRequestPermission'] = async (): Promise<boolean> => {
    try {
      // Запрашиваем разрешение на показ уведомлений
      const permission = await window.Notification.requestPermission();

      if (permission === 'granted') {
        return true;
      }

      return false;
    } catch (error) {
      throw error;
    }
  }

  asyncRegister: IPushNotificationRepository['asyncRegister'] = async (): Promise<null | { error: Error }> => {
    try {
      const { serviceWorker } = window.navigator;

      if (serviceWorker === undefined) {
        throw new Error('service worker is undefined');
      }

      try {
        await serviceWorker.register(config.serviceWorkerUrl);

        return null;
      } catch (err) {
        return { error: err };
      }
    } catch (error) {
      throw error;
    }
  }

  /**
   * @param payload base64-строка для публичного ключа
   */
  asyncSubscribe: IPushNotificationRepository['asyncSubscribe'] = async (payload: string): Promise<null | { error: Error }> => {
    try {
      // Получаем данные регистрации сервис-воркера
      const { serviceWorker } = window.navigator;

      if (serviceWorker === undefined) {
        throw new Error('service worker is undefined');
      }

      const registration = await serviceWorker.ready;

      /**
       * Chrome не разрешает использование base64-строки для публичного ключа, поэтому
       * конвертируем ключ в Uint8Array, который принимается всеми браузерами
       */
      const convertedServerKey = PushNotificationRepository.convertBase64ToUint8Array(payload);

      // Подписываем сервис-воркер на получение данных от сервера
      try {
        await registration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: convertedServerKey,
        });

        return null;
      } catch (err) {
        return { error: err };
      }
    } catch (error) {
      throw error;
    }
  }

  asyncGetDecryptedSubscription: IPushNotificationRepository['asyncGetDecryptedSubscription'] = async (): Promise<{
    pushNotificationSubscription: Pick<IPushNotificationSubscriptionEntity, 'decrypted'>,
  }> => {
    try {
      // Получаем данные регистрации воркера
      const response = await PushNotificationRepository.asyncGetRegistration();

      if ('error' in response || response.data === undefined) {
        return {
          pushNotificationSubscription: {
            decrypted: null,
          },
        };
      }

      // Получаем и возвращаем данные подписки
      const pushNotificationSubscription = await response.data.pushManager.getSubscription();

      return {
        pushNotificationSubscription: {
          decrypted: pushNotificationSubscription,
        },
      };
    } catch (error) {
      throw error;
    }
  }

  asyncGetEncryptedSubscription: IPushNotificationRepository['asyncGetEncryptedSubscription'] = async (): Promise<{
    pushNotificationSubscription: Pick<IPushNotificationSubscriptionEntity, 'encrypted'>,
  }> => {
    try {
      // Получаем данные подписки
      const response = await this.asyncGetDecryptedSubscription();

      // Если подписки нет, возвращаем пустой результат
      if (response.pushNotificationSubscription.decrypted === null) {
        return {
          pushNotificationSubscription: {
            encrypted: undefined,
          },
        };
      }

      // Возвращаем md5-хэш данных подписки
      return {
        pushNotificationSubscription: {
          encrypted: Md5.init(JSON.stringify(response.pushNotificationSubscription.decrypted)),
        },
      };
    } catch (error) {
      throw error;
    }
  }

  /**
   * @param payload колбек
   */
  subscribeToPingClient: IPushNotificationRepository['subscribeToPingClient'] = (
    payload: (
      value: {
        contactId: IContactEntity['id'] | undefined,
        userId: IUserEntity['id'],
      },
    ) => void,
  ): void => {
    try {
      this.addHandler({
        procedure: EPushNotificationType.PingClient,
        handler: (response: IPushNotificationPingClient): void => {
          payload({
            contactId: response.contactId !== undefined ? parseInt(response.contactId, 10) : undefined,
            userId: parseInt(response.receiverId, 10),
          });
        },
      });
    } catch (error) {
      throw error;
    }
  }

  sendPingClientComplete: IPushNotificationRepository['sendPingClientComplete'] = (): { status: 'success' } | { status: 'error', message: string } => {
    try {
      return PushNotificationRepository.send({ procedure: EPushNotificationType.PingClientComplete });
    } catch (error) {
      throw error;
    }
  }

  sendAppLoadComplete: IPushNotificationRepository['sendAppLoadComplete'] = (): { status: 'success' } | { status: 'error', message: string } => {
    try {
      return PushNotificationRepository.send({ procedure: EPushNotificationType.AppLoadComplete });
    } catch (error) {
      throw error;
    }
  }

  asyncGetIsPushNotificationOnClientEnabled: IPushNotificationRepository['asyncGetIsPushNotificationOnClientEnabled'] = async (): Promise<{
    settings: Pick<ISettingsEntity, 'isPushNotificationOnClientEnabled'>,
  }> => {
    try {
      const response = await this.asyncGetDecryptedSubscription();

      return {
        settings: {
          isPushNotificationOnClientEnabled: (
            this.getIsAllowed() && response.pushNotificationSubscription.decrypted !== null
          ),
        },
      };
    } catch (error) {
      throw error;
    }
  }
}

export default PushNotificationRepository;
