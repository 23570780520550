import store from '@/store';
import IViewmodel, { IUiViewmodel } from '@/core/interactors/types/viewmodels';
import {
  EPurchaseError,
  EGiftSendError,
  ETopPeriod,
  EUserAvatarUploadError,
  EWithdrawalRequestCardHolderFirstNameValidationError,
  EWithdrawalRequestCardHolderLastNameValidationError,
  EWithdrawalRequestCardHolderBirthdayValidationError,
  EVideochatPageStage,
  EUserAvatarEditState,
  EUserVerificationStatus,
  EUserVerificationStage,
  ECardConfirmationError,
  EUserCardConfirmationValidationError,
  EAuthorizationError,
  EUserEmailValidationError,
  EUserPasswordValidationError,
  EUserNameValidationError,
  EWithdrawalRequestCardAddressCityValidationError,
  EWithdrawalRequestCardAddressStreetValidationError,
  EPaymentSystemType,
  EUserAccountDeletionCodeValidationError,
  EWithdrawalRequestCardNumberValidationError,
  EWithdrawalRequestCardExpirationValidationError,
  EWithdrawalRequestCardCurrencyValidationError,
  EWithdrawalRequestYandexNumberValidationError,
  EWithdrawalRequestBitcoinNumberValidationError,
  EWithdrawalRequestWebmoneyNumberValidationError,
  EWithdrawalRequestQiwiNumberValidationError,
  EWithdrawalRequestEpayserviceNumberValidationError,
  EUserEmailConfirmationStatus,
  EOutgoingCallSource,
  EBanStatus,
  EUserAvatarValidationError,
  EActionImpossibleReason,
  EInviteError,
  EPage,
  EWithdrawalRequestBankAccountHolderDocumentNumberValidationError,
  EWithdrawalRequestBankAccountNumberValidationError,
  EWithdrawalRequestBankBranchCodeValidationError,
  EWithdrawalRequestBankAccountHolderFirstNameValidationError,
  EWithdrawalRequestBankAccountHolderLastNameValidationError,
  EWithdrawalRequestBankAccountHolderPhoneValidationError,
  EWithdrawalRequestBankAccountHolderEmailValidationError,
  EWithdrawalRequestBankAccountHolderAddressValidationError,
  EContactNameValidationError,
  EWithdrawalRequestUsdtNumberValidationError,
  EWithdrawalConfirmationValidationError,
  EEmailConfirmationCodeValidationError,
} from '@/enums';
import IBanEntity from '@/core/entities/ban.entity';
import ICardEntity from '@/core/entities/card.entity';
import ICompanionEntity from '@/core/entities/companion.entity';
import IContactEntity from '@/core/entities/contact.entity';
import IErrorEntity from '@/core/entities/error.entity';
import IWithdrawalRequestEntity, { IWithdrawalRequestPaymentSystems } from '@/core/entities/withdrawal-request.entity';
import IUserEntity from '@/core/entities/user.entity';
import IPurchaseGatewayEntity from '@/core/entities/purchase-gateway.entity';

// TODO подумать как убрать эту зависимость (инвертировать)
// eslint-disable-next-line import/no-cycle
import router from '@/router/index';

class UiViewmodel {
  private store = store;

  private static instance: UiViewmodel;

  private cameraNotSupportedResolver: ((payload: unknown) => void) | null = null;

  private cameraNotExistResolver: ((payload: void) => void) | null = null;

  private cameraNotAvailableResolver: ((payload: boolean) => void) | null = null;

  // eslint-disable-next-line no-useless-constructor, no-empty-function, @typescript-eslint/no-empty-function
  private constructor() {}

  static getInstance(): UiViewmodel {
    try {
      if (UiViewmodel.instance === undefined) {
        UiViewmodel.instance = new UiViewmodel();
      }

      return UiViewmodel.instance;
    } catch (error) {
      throw error;
    }
  }

  private setFlagProperty<
    Property extends keyof IViewmodel['ui']['flags'],
    Payload extends IViewmodel['ui']['flags'][Property],
  >(
    property: Property,
    payload: Payload,
  ): void {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            [property]: payload,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  private watchFlagProperty<Property extends keyof IViewmodel['ui']['flags']>(
    property: Property,
    callback: (payload: IViewmodel['ui']['flags'][Property]) => void,
  ): void {
    try {
      this.store.watch((state) => state.ui.flags[property], callback);
    } catch (error) {
      throw error;
    }
  }

  private setBufferProperty<
    Property extends keyof IViewmodel['ui']['buffer'],
    Payload extends IViewmodel['ui']['buffer'][Property],
  >(
    property: Property,
    payload: Payload,
  ): void {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          buffer: Object.freeze({
            ...this.store.state.ui.buffer,
            [property]: payload,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  private watchBufferProperty<Property extends keyof IViewmodel['ui']['buffer']>(
    property: Property,
    callback: (payload: IViewmodel['ui']['buffer'][Property]) => void,
  ): void {
    try {
      this.store.watch((state) => state.ui.buffer[property], callback);
    } catch (error) {
      throw error;
    }
  }

  get: IUiViewmodel['get'] = (): IViewmodel['ui'] => {
    try {
      return this.store.state.ui;
    } catch (error) {
      throw error;
    }
  }

  set: IUiViewmodel['set'] = (payload: IViewmodel['ui']): void => {
    try {
      this.store.commit('updateUi', Object.freeze({ ...payload }));
    } catch (error) {
      throw error;
    }
  }

  updateCardLastNumbersForPremiumPurchase: IUiViewmodel['updateCardLastNumbersForPremiumPurchase'] = (
    payload: ICardEntity['lastNumbers'],
  ): void => {
    try {
      this.setBufferProperty('cardLastNumbersForPurchase', payload);
    } catch (error) {
      throw error;
    }
  }

  showBrowserNotSupported: IUiViewmodel['showBrowserNotSupported'] = (): void => {
    try {
      this.setFlagProperty('isBrowserNotSupportedAttentionDisplayed', true);
    } catch (error) {
      throw error;
    }
  }

  hideCardConfirmationWarning: IUiViewmodel['hideCardConfirmationWarning'] = (): void => {
    try {
      this.setFlagProperty('isCardConfirmationWarningAttentionDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  hideUserPremiumSubscriptionChangingCardSelection: IUiViewmodel['hideUserPremiumSubscriptionChangingCardSelection'] = (): void => {
    try {
      this.setFlagProperty('isUserPremiumSubscriptionChangingCardSelectionDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  showPremiumSubscriptionRenewal: IUiViewmodel['showPremiumSubscriptionRenewal'] = (): void => {
    try {
      this.setFlagProperty('isPremiumSubscriptionRenewalAttentionDisplayed', true);
    } catch (error) {
      throw error;
    }
  }

  showUserPremiumSubscriptionChangingCardSelectionInsteadRemovalConfirm: IUiViewmodel['showUserPremiumSubscriptionChangingCardSelectionInsteadRemovalConfirm'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isUserPremiumSubscriptionChangingCardSelectionDisplayed: true,
            isUserPremiumSubscriptionChangingCardRemovalConfirmDisplayed: false,
          }),
          buffer: Object.freeze({
            ...this.store.state.ui.buffer,
            cardLastNumbersForRemoval: undefined,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showUserPremiumSubscriptionChangingCardRemovalConfirmInsteadSelection: IUiViewmodel['showUserPremiumSubscriptionChangingCardRemovalConfirmInsteadSelection'] = (payload: ICardEntity['lastNumbers']): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isUserPremiumSubscriptionChangingCardRemovalConfirmDisplayed: true,
            isUserPremiumSubscriptionChangingCardSelectionDisplayed: false,
          }),
          buffer: Object.freeze({
            ...this.store.state.ui.buffer,
            cardLastNumbersForRemoval: payload,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  hideUserPremiumSubscriptionEnablingCardSelection: IUiViewmodel['hideUserPremiumSubscriptionEnablingCardSelection'] = (): void => {
    try {
      this.setFlagProperty('isUserPremiumSubscriptionEnablingCardSelectionDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  showCardChangeSuccess: IUiViewmodel['showCardChangeSuccess'] = (): void => {
    try {
      this.setFlagProperty('isCardChangeSuccessAttentionDisplayed', true);
    } catch (error) {
      throw error;
    }
  }

  showCardChangeSuccessInsteadPremiumSubscriptionCardSelection:
    IUiViewmodel['showCardChangeSuccessInsteadPremiumSubscriptionCardSelection'] = (): void => {
      try {
        this.store.commit(
          'updateUi',
          {
            ...this.store.state.ui,
            flags: Object.freeze({
              ...this.store.state.ui.flags,
              isUserPremiumSubscriptionChangingCardSelectionDisplayed: false,
              isCardChangeSuccessAttentionDisplayed: true,
            }),
          },
        );
      } catch (error) {
        throw error;
      }
    }

  hideMessengerNotificationAppeal: IUiViewmodel['hideMessengerNotificationAppeal'] = (): void => {
    try {
      this.setFlagProperty('shouldShowMessengerNotificationAppeal', false);
    } catch (error) {
      throw error;
    }
  }

  hideVideochatNotificationAppeal: IUiViewmodel['hideVideochatNotificationAppeal'] = (): void => {
    try {
      this.setFlagProperty('shouldShowVideochatNotificationAppeal', false);
    } catch (error) {
      throw error;
    }
  }

  showUserPremiumSubscriptionPurchaseError: IUiViewmodel['showUserPremiumSubscriptionPurchaseError'] = (
    payload: IErrorEntity,
  ): void => {
    try {
      switch (payload.code) {
        case EPurchaseError.SUBSCRIPTION_FAILED:
        case EPurchaseError.NO_CARD:
        case EPurchaseError.PAYMENT_RESTRICTED:
          this.store.commit(
            'updateUi',
            {
              flags: Object.freeze({
                ...this.store.state.ui.flags,
                isPurchaseErrorAttentionDisplayed: true,
              }),
              buffer: Object.freeze({
                ...this.store.state.ui.buffer,
                purchaseError: payload.code,
              }),
            },
          );

          break;
        default:
          throw new Error(`unknown error code: ${payload.code}`);
      }
    } catch (error) {
      throw error;
    }
  }

  showPurchaseErrorInsteadPremiumSubscriptionCardSelection: IUiViewmodel['showPurchaseErrorInsteadPremiumSubscriptionCardSelection'] = (
    payload: IErrorEntity,
  ): void => {
    try {
      switch (payload.code) {
        case EPurchaseError.NO_CARD:
          this.store.commit(
            'updateUi',
            {
              flags: Object.freeze({
                ...this.store.state.ui.flags,
                isUserPremiumSubscriptionChangingCardSelectionDisplayed: false,
                isPurchaseErrorAttentionDisplayed: true,
              }),
              buffer: Object.freeze({
                ...this.store.state.ui.buffer,
                purchaseError: payload.code,
              }),
            },
          );

          break;
        default:
          throw new Error(`unknown error code: ${payload.code}`);
      }
    } catch (error) {
      throw error;
    }
  }

  showPushNotificationBonus: IUiViewmodel['showPushNotificationBonus'] = (): void => {
    try {
      this.setFlagProperty('isPushNotificationBonusAttentionDisplayed', true);
    } catch (error) {
      throw error;
    }
  }

  hidePushNotificationBonus: IUiViewmodel['hidePushNotificationBonus'] = (): void => {
    try {
      this.setFlagProperty('isPushNotificationBonusAttentionDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  showWithdrawalStartInsteadRejection: IUiViewmodel['showWithdrawalStartInsteadRejection'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isWithdrawalRequestRejectedAttentionDisplayed: false,
            isStartWithdrawalAttentionDisplayed: true,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showCardConfirmationWarningInsteadPremiumSubscriptionCardSelection:
    IUiViewmodel['showCardConfirmationWarningInsteadPremiumSubscriptionCardSelection'] = (): void => {
      try {
        this.store.commit(
          'updateUi',
          {
            ...this.store.state.ui,
            flags: Object.freeze({
              ...this.store.state.ui.flags,
              isCardConfirmationWarningAttentionDisplayed: true,
              isUserPremiumSubscriptionChangingCardSelectionDisplayed: false,
              isUserPremiumSubscriptionEnablingCardSelectionDisplayed: false,
            }),
          },
        );
      } catch (error) {
        throw error;
      }
    }

  showUserPremiumSubscriptionEnablingCardRemovalConfirmInsteadSelection: IUiViewmodel['showUserPremiumSubscriptionEnablingCardRemovalConfirmInsteadSelection'] = (payload: ICardEntity['lastNumbers']): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isUserPremiumSubscriptionEnablingCardRemovalConfirmDisplayed: true,
            isUserPremiumSubscriptionEnablingCardSelectionDisplayed: false,
          }),
          buffer: Object.freeze({
            ...this.store.state.ui.buffer,
            cardLastNumbersForRemoval: payload,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showUserPremiumSubscriptionEnablingCardSelectionInsteadRemovalConfirm: IUiViewmodel['showUserPremiumSubscriptionEnablingCardSelectionInsteadRemovalConfirm'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isUserPremiumSubscriptionEnablingCardSelectionDisplayed: true,
            isUserPremiumSubscriptionEnablingCardRemovalConfirmDisplayed: false,
          }),
          buffer: Object.freeze({
            ...this.store.state.ui.buffer,
            cardLastNumbersForRemoval: undefined,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  hideUserPremiumSubscriptionEnablingCardRemovalConfirm: IUiViewmodel['hideUserPremiumSubscriptionEnablingCardRemovalConfirm'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isUserPremiumSubscriptionEnablingCardRemovalConfirmDisplayed: false,
          }),
          buffer: Object.freeze({
            ...this.store.state.ui.buffer,
            cardLastNumbersForRemoval: undefined,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  hideUserPremiumSubscriptionChangingCardRemovalConfirm: IUiViewmodel['hideUserPremiumSubscriptionChangingCardRemovalConfirm'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isUserPremiumSubscriptionChangingCardRemovalConfirmDisplayed: false,
          }),
          buffer: Object.freeze({
            ...this.store.state.ui.buffer,
            cardLastNumbersForRemoval: undefined,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showUserPurchaseCardRemovalConfirm: IUiViewmodel['showUserPurchaseCardRemovalConfirm'] = (payload: ICardEntity['lastNumbers']): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isUserPurchaseCardRemovalConfirmDisplayed: true,
          }),
          buffer: Object.freeze({
            ...this.store.state.ui.buffer,
            cardLastNumbersForRemoval: payload,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  hideUserPurchaseCardRemovalConfirm: IUiViewmodel['hideUserPurchaseCardRemovalConfirm'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isUserPurchaseCardRemovalConfirmDisplayed: false,
          }),
          buffer: Object.freeze({
            ...this.store.state.ui.buffer,
            cardLastNumbersForRemoval: undefined,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  removeCardLastNumbers: IUiViewmodel['removeCardLastNumbers'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          buffer: Object.freeze({
            ...this.store.state.ui.buffer,
            cardLastNumbersForRemoval: undefined,
            cardLastNumbersForPremiumSubscription: undefined,
            cardLastNumbersForChange: undefined,
            cardLastNumbersForPurchase: undefined,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  updateUserAvatarEditState: IUiViewmodel['updateUserAvatarEditState'] = (
    payload: IViewmodel['ui']['buffer']['userAvatarEditState'],
  ): void => {
    try {
      this.setBufferProperty('userAvatarEditState', payload);
    } catch (error) {
      throw error;
    }
  }

  updateMessageDrafts: IUiViewmodel['updateMessageDrafts'] = (payload: {
    contactId: IContactEntity['id'],
    message: string,
  }): void => {
    try {
      const messageDrafts = { ...this.store.state.ui.buffer.messageDrafts };

      const shouldSetMessageDraft = (
        payload.message.length > 0
        && messageDrafts[payload.contactId] !== payload.message
      );

      const shouldUnsetMessageDraft = (
        payload.message.length === 0
        && messageDrafts[payload.contactId] !== undefined
      );

      if (shouldSetMessageDraft) {
        messageDrafts[payload.contactId] = payload.message;
      } else if (shouldUnsetMessageDraft) {
        delete messageDrafts[payload.contactId];
      }

      if (shouldSetMessageDraft || shouldUnsetMessageDraft) {
        this.setBufferProperty('messageDrafts', Object.keys(messageDrafts).length > 0 ? messageDrafts : undefined);
      }
    } catch (error) {
      throw error;
    }
  }

  removeOutgoingCallSource: IUiViewmodel['removeOutgoingCallSource'] = (): void => {
    try {
      this.setBufferProperty('outgoingCallSource', undefined);
    } catch (error) {
      throw error;
    }
  }

  hideContactRequest: IUiViewmodel['hideContactRequest'] = (): void => {
    try {
      this.setFlagProperty('isContactRequestAttentionDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  hideVideochatHistoryClearing: IUiViewmodel['hideVideochatHistoryClearing'] = (): void => {
    try {
      this.setFlagProperty('isVideochatHistoryClearAttentionDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  hideVideochatRecovery: IUiViewmodel['hideVideochatRecovery'] = (): void => {
    try {
      this.setFlagProperty('isVideochatRecoveryAttentionDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  showSignUp: IUiViewmodel['showSignUp'] = (): void => {
    try {
      this.setFlagProperty('isSignUpAttentionDisplayed', true);
    } catch (error) {
      throw error;
    }
  }

  showPremiumPurchase: IUiViewmodel['showPremiumPurchase'] = (): void => {
    try {
      this.setFlagProperty('isPremiumPurchaseAttentionDisplayed', true);
    } catch (error) {
      throw error;
    }
  }

  showMinutesPurchase: IUiViewmodel['showMinutesPurchase'] = (): void => {
    try {
      this.setFlagProperty('isMinutesPurchaseAttentionDisplayed', true);
    } catch (error) {
      throw error;
    }
  }

  showUsageRulesConfirmation: IUiViewmodel['showUsageRulesConfirmation'] = (
    payload: NonNullable<IViewmodel['ui']['buffer']['isUsageRulesDisplayedAfterAuthorization']>,
  ): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isUsageRulesAttentionDisplayed: true,
          }),
          buffer: Object.freeze({
            ...this.store.state.ui.buffer,
            isUsageRulesDisplayedAfterAuthorization: payload,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  hideUsageRulesConfirmation: IUiViewmodel['hideUsageRulesConfirmation'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isUsageRulesAttentionDisplayed: false,
          }),
          buffer: Object.freeze({
            ...this.store.state.ui.buffer,
            isUsageRulesDisplayedAfterAuthorization: undefined,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showCardConfirmation: IUiViewmodel['showCardConfirmation'] = (): void => {
    try {
      this.setFlagProperty('isCardConfirmationLockDisplayed', true);
    } catch (error) {
      throw error;
    }
  }

  hideUserOutOfMessages: IUiViewmodel['hideUserOutOfMessages'] = (): void => {
    try {
      this.setFlagProperty('isUserOutOfMessagesAttentionDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  disableAppWaiting: IUiViewmodel['disableAppWaiting'] = (): void => {
    try {
      this.setFlagProperty('isAppWaitingActive', false);
    } catch (error) {
      throw error;
    }
  }

  disableAccountChange: IUiViewmodel['disableAccountChange'] = (): void => {
    try {
      this.setFlagProperty('isChangeAccountAttentionControlsDisabled', true);
    } catch (error) {
      throw error;
    }
  }

  disableSettingsActions: IUiViewmodel['disableSettingsActions'] = (): void => {
    try {
      this.setFlagProperty('isSettingsAttentionControlsDisabled', true);
    } catch (error) {
      throw error;
    }
  }

  enableSettingsActions: IUiViewmodel['enableSettingsActions'] = (): void => {
    try {
      this.setFlagProperty('isSettingsAttentionControlsDisabled', false);
    } catch (error) {
      throw error;
    }
  }

  disableContactRequestSentActions: IUiViewmodel['disableContactRequestSentActions'] = (): void => {
    try {
      this.setFlagProperty('isContactRequestSentActionsDisabled', true);
    } catch (error) {
      throw error;
    }
  }

  enableContactRequestSentActions: IUiViewmodel['enableContactRequestSentActions'] = (): void => {
    try {
      this.setFlagProperty('isContactRequestSentActionsDisabled', false);
    } catch (error) {
      throw error;
    }
  }

  disableContactRequest: IUiViewmodel['disableContactRequest'] = (): void => {
    try {
      this.setFlagProperty('isContactRequestDisabled', true);
    } catch (error) {
      throw error;
    }
  }

  enableContactRequest: IUiViewmodel['enableContactRequest'] = (): void => {
    try {
      this.setFlagProperty('isContactRequestDisabled', false);
    } catch (error) {
      throw error;
    }
  }

  hideMediaSending: IUiViewmodel['hideMediaSending'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isImageSendAttentionDisplayed: false,
            isPhotoSendAttentionDisplayed: false,
            isVideoSendAttentionDisplayed: false,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  removeTemporaryAvatarAndShowValidationError: IUiViewmodel['removeTemporaryAvatarAndShowValidationError'] = (
    payload: IErrorEntity,
  ): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          buffer: Object.freeze({
            ...this.store.state.ui.buffer,
            userTemporaryAvatar: undefined,
            userAvatarEditState: undefined,
            userAvatarValidationError: payload.code,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  removeTemporaryAvatarAndShowReadFailedError: IUiViewmodel['removeTemporaryAvatarAndShowReadFailedError'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          buffer: Object.freeze({
            ...this.store.state.ui.buffer,
            userTemporaryAvatar: undefined,
            userAvatarEditState: undefined,
            userAvatarValidationError: EUserAvatarValidationError.READ_FAILED,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  removeTemporaryAvatarAndHideValidationError:
    IUiViewmodel['removeTemporaryAvatarAndHideValidationError'] = (): void => {
      try {
        this.store.commit(
          'updateUi',
          {
            ...this.store.state.ui,
            buffer: Object.freeze({
              ...this.store.state.ui.buffer,
              userTemporaryAvatar: undefined,
              userAvatarEditState: undefined,
              userAvatarValidationError: undefined,
            }),
          },
        );
      } catch (error) {
        throw error;
      }
    }

  showSignUpInsteadSettings: IUiViewmodel['showSignUpInsteadSettings'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isSettingsAttentionDisplayed: false,
            isSignUpAttentionDisplayed: true,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showSignUpAppealInsteadSettings: IUiViewmodel['showSignUpAppealInsteadSettings'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isSettingsAttentionDisplayed: false,
            isSignUpAppealAttentionDisplayed: true,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showSignInInsteadChangeAccount: IUiViewmodel['showSignInInsteadChangeAccount'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isChangeAccountAttentionDisplayed: false,
            isChangeAccountAttentionControlsDisabled: false,
            isSignInAttentionDisplayed: true,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showWithdrawalCardHolderInsteadCardAddress: IUiViewmodel['showWithdrawalCardHolderInsteadCardAddress'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isWithdrawalCardAddressAttentionDisplayed: false,
            isWithdrawalCardHolderAttentionDisplayed: true,
          }),
          buffer: Object.freeze({
            ...this.store.state.ui.buffer,
            withdrawalRequestCardAddressStreetValidationError: undefined,
            withdrawalRequestCardAddressCityValidationError: undefined,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showWithdrawalCardRequisitesInsteadCardHolder: IUiViewmodel['showWithdrawalCardRequisitesInsteadCardHolder'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isWithdrawalCardHolderAttentionDisplayed: false,
            isWithdrawalCardRequisitesAttentionDisplayed: true,
          }),
          buffer: Object.freeze({
            ...this.store.state.ui.buffer,
            withdrawalRequestCardHolderFirstNameValidationError: undefined,
            withdrawalRequestCardHolderLastNameValidationError: undefined,
            withdrawalRequestCardHolderBirthdayValidationError: undefined,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showWithdrawalStartInsteadCardRequisites: IUiViewmodel['showWithdrawalStartInsteadCardRequisites'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isWithdrawalCardRequisitesAttentionDisplayed: false,
            isStartWithdrawalAttentionDisplayed: true,
          }),
          buffer: Object.freeze({
            ...this.store.state.ui.buffer,
            withdrawalRequestCardNumberValidationError: undefined,
            withdrawalRequestCardExpirationValidationError: undefined,
            withdrawalRequestCardCurrencyValidationError: undefined,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showWithdrawalStartInsteadEpayserviceRequisites:
    IUiViewmodel['showWithdrawalStartInsteadEpayserviceRequisites'] = (): void => {
      try {
        this.store.commit(
          'updateUi',
          {
            flags: Object.freeze({
              ...this.store.state.ui.flags,
              isWithdrawalEpayserviceRequisitesAttentionDisplayed: false,
              isStartWithdrawalAttentionDisplayed: true,
            }),
            buffer: Object.freeze({
              ...this.store.state.ui.buffer,
              withdrawalRequestEpayserviceNumberValidationError: undefined,
            }),
          },
        );
      } catch (error) {
        throw error;
      }
    }

  showWithdrawalStartInsteadQiwiRequisites: IUiViewmodel['showWithdrawalStartInsteadQiwiRequisites'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isWithdrawalQiwiRequisitesAttentionDisplayed: false,
            isStartWithdrawalAttentionDisplayed: true,
          }),
          buffer: Object.freeze({
            ...this.store.state.ui.buffer,
            withdrawalRequestQiwiNumberValidationError: undefined,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showWithdrawalStartInsteadWebmoneyRequisites: IUiViewmodel['showWithdrawalStartInsteadWebmoneyRequisites'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isWithdrawalWebmoneyRequisitesAttentionDisplayed: false,
            isStartWithdrawalAttentionDisplayed: true,
          }),
          buffer: Object.freeze({
            ...this.store.state.ui.buffer,
            withdrawalRequestWebmoneyNumberValidationError: undefined,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showWithdrawalStartInsteadYandexRequisites: IUiViewmodel['showWithdrawalStartInsteadYandexRequisites'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isWithdrawalYandexRequisitesAttentionDisplayed: false,
            isStartWithdrawalAttentionDisplayed: true,
          }),
          buffer: Object.freeze({
            ...this.store.state.ui.buffer,
            withdrawalRequestYandexNumberValidationError: undefined,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showWithdrawalStartInsteadBitcoinRequisites: IUiViewmodel['showWithdrawalStartInsteadBitcoinRequisites'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isWithdrawalBitcoinRequisitesAttentionDisplayed: false,
            isStartWithdrawalAttentionDisplayed: true,
          }),
          buffer: Object.freeze({
            ...this.store.state.ui.buffer,
            withdrawalRequestBitcoinNumberValidationError: undefined,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showSignInInsteadPasswordRecovery: IUiViewmodel['showSignInInsteadPasswordRecovery'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isPasswordRecoveryAttentionDisplayed: false,
            isSignInAttentionDisplayed: true,
          }),
          buffer: Object.freeze({
            ...this.store.state.ui.buffer,
            passwordRecoveryEmailValidationError: undefined,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showSignUpAppeal: IUiViewmodel['showSignUpAppeal'] = (): void => {
    try {
      this.setFlagProperty('isSignUpAppealAttentionDisplayed', true);
    } catch (error) {
      throw error;
    }
  }

  showPremiumRequirementForSearchBest: IUiViewmodel['showPremiumRequirementForSearchBest'] = (): void => {
    try {
      this.setFlagProperty('isPremiumRequiredForSearchBestAttentionDisplayed', true);
    } catch (error) {
      throw error;
    }
  }

  showMinutesRequirementForSearchBest: IUiViewmodel['showMinutesRequirementForSearchBest'] = (): void => {
    try {
      this.setFlagProperty('isMinutesRequiredForSearchBestAttentionDisplayed', true);
    } catch (error) {
      throw error;
    }
  }

  hideUserNameEditForm: IUiViewmodel['hideUserNameEditForm'] = (): void => {
    try {
      this.setFlagProperty('isUserNameEditFormDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  hideMicrophoneNotification: IUiViewmodel['hideMicrophoneNotification'] = (): void => {
    try {
      this.setFlagProperty('isMicrophoneNoticeDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  hideSignUpAppeal: IUiViewmodel['hideSignUpAppeal'] = (): void => {
    try {
      this.setFlagProperty('isSignUpAppealAttentionDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  hideFaceNotDetected: IUiViewmodel['hideFaceNotDetected'] = (): void => {
    try {
      this.setFlagProperty('shouldShowFaceNotDetectedAttention', false);
    } catch (error) {
      throw error;
    }
  }

  showDoubleEntry: IUiViewmodel['showDoubleEntry'] = (): void => {
    try {
      this.setFlagProperty('isDoubleEntryAttentionDisplayed', true);
    } catch (error) {
      throw error;
    }
  }

  enableAppWaiting: IUiViewmodel['enableAppWaiting'] = (): void => {
    try {
      this.setFlagProperty('isAppWaitingActive', true);
    } catch (error) {
      throw error;
    }
  }

  hideSwitchPageDuringVideochat: IUiViewmodel['hideSwitchPageDuringVideochat'] = (): void => {
    try {
      this.setFlagProperty('isSwitchPageDuringVideochatAttentionDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  hideCompanionAbuse: IUiViewmodel['hideCompanionAbuse'] = (): void => {
    try {
      this.setFlagProperty('isCompanionAbuseAttentionDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  hideAccountDeletion: IUiViewmodel['hideAccountDeletion'] = (): void => {
    try {
      this.setFlagProperty('isAccountDeletionAttentionDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  hideAccountDeletionConfirmation: IUiViewmodel['hideAccountDeletionConfirmation'] = (): void => {
    try {
      this.setFlagProperty('isAccountDeletionConfirmationAttentionDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  hideAccountDeletionReason: IUiViewmodel['hideAccountDeletionReason'] = (): void => {
    try {
      this.setFlagProperty('isAccountDeletionReasonAttentionDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  hideAccountDeletionSuccess: IUiViewmodel['hideAccountDeletionSuccess'] = (): void => {
    try {
      this.setFlagProperty('isAccountDeletionSuccessAttentionDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  hideAccountTransactions: IUiViewmodel['hideAccountTransactions'] = (): void => {
    try {
      this.setFlagProperty('isAccountTransactionsAttentionDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  hideAvatarUploadError: IUiViewmodel['hideAvatarUploadError'] = (): void => {
    try {
      this.setFlagProperty('isAvatarUploadErrorAttentionDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  hideBrowserNotSupported: IUiViewmodel['hideBrowserNotSupported'] = (): void => {
    try {
      this.setFlagProperty('isBrowserNotSupportedAttentionDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  showUserEmailConfirmation: IUiViewmodel['showUserEmailConfirmation'] = (): void => {
    try {
      this.setFlagProperty('isUserEmailConfirmationAttentionDisplayed', true);
    } catch (error) {
      throw error;
    }
  }

  hideAllContactsMessagesRemoval: IUiViewmodel['hideAllContactsMessagesRemoval'] = (): void => {
    try {
      this.setFlagProperty('isRemoveAllContactsMessagesAttentionDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  hideSelectedContactsMessagesRemoval: IUiViewmodel['hideSelectedContactsMessagesRemoval'] = (): void => {
    try {
      this.setFlagProperty('isRemoveSelectedContactsMessagesAttentionDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  hideWithdrawalStart: IUiViewmodel['hideWithdrawalStart'] = (): void => {
    try {
      this.setFlagProperty('isStartWithdrawalAttentionDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  removeMarkedContactsIds: IUiViewmodel['removeMarkedContactsIds'] = (): void => {
    try {
      this.setBufferProperty('markedContactsIds', undefined);
    } catch (error) {
      throw error;
    }
  }

  showAccountDeletionConfirmationInsteadDeletion:
    IUiViewmodel['showAccountDeletionConfirmationInsteadDeletion'] = (): void => {
      try {
        this.store.commit(
          'updateUi',
          {
            ...this.store.state.ui,
            flags: Object.freeze({
              ...this.store.state.ui.flags,
              isAccountDeletionAttentionDisplayed: false,
              isAccountDeletionConfirmationAttentionDisplayed: true,
            }),
          },
        );
      } catch (error) {
        throw error;
      }
    }

  showVideochatStartInsteadCompanionReview: IUiViewmodel['showVideochatStartInsteadCompanionReview'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isCompanionReviewLockDisplayed: false,
            videochatPageStage: EVideochatPageStage.START,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showVideochatStartInsteadCompanionRate: IUiViewmodel['showVideochatStartInsteadCompanionRate'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isCompanionRateLockDisplayed: false,
            videochatPageStage: EVideochatPageStage.START,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showVideochatStartInsteadCompanionReviewSent: IUiViewmodel['showVideochatStartInsteadCompanionReviewSent'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isCompanionReviewSentLockDisplayed: false,
            videochatPageStage: EVideochatPageStage.START,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showVideochatStartInsteadCompanionGift: IUiViewmodel['showVideochatStartInsteadCompanionGift'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isCompanionGiftLockDisplayed: false,
            videochatPageStage: EVideochatPageStage.START,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showWithdrawalRequisitesInsteadStart: IUiViewmodel['showWithdrawalRequisitesInsteadStart'] = (
    payload: EPaymentSystemType,
  ): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isStartWithdrawalAttentionDisplayed: false,
            isWithdrawalYandexRequisitesAttentionDisplayed: payload === EPaymentSystemType.YANDEX,
            isWithdrawalWebmoneyRequisitesAttentionDisplayed: payload === EPaymentSystemType.WEBMONEY,
            isWithdrawalQiwiRequisitesAttentionDisplayed: payload === EPaymentSystemType.QIWI,
            isWithdrawalCardRequisitesAttentionDisplayed: payload === EPaymentSystemType.CARD,
            isWithdrawalEpayserviceRequisitesAttentionDisplayed: payload === EPaymentSystemType.EPSWALLET,
            isWithdrawalBitcoinRequisitesAttentionDisplayed: payload === EPaymentSystemType.BITCOIN,
            isWithdrawalUsdtRequisitesAttentionDisplayed: payload === EPaymentSystemType.USDT,
            isWithdrawalBankFirstStepDisplayed: payload === EPaymentSystemType.BANK,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  hidePasswordRecovery: IUiViewmodel['hidePasswordRecovery'] = (): void => {
    try {
      this.setFlagProperty('isPasswordRecoveryAttentionDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  hidePasswordRecoverySuccess: IUiViewmodel['hidePasswordRecoverySuccess'] = (): void => {
    try {
      this.setFlagProperty('isPasswordRecoverySuccessAttentionDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  hidePremiumAdded: IUiViewmodel['hidePremiumAdded'] = (): void => {
    try {
      this.setFlagProperty('isPremiumAddedAttentionDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  hideMinutesAdded: IUiViewmodel['hideMinutesAdded'] = (): void => {
    try {
      this.setFlagProperty('isMinutesAddedAttentionDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  hideMinutesReduced: IUiViewmodel['hideMinutesReduced'] = (): void => {
    try {
      this.setFlagProperty('isMinutesReducedAttentionDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  hideMinutesRequirementForSearchBest: IUiViewmodel['hideMinutesRequirementForSearchBest'] = (): void => {
    try {
      this.setFlagProperty('isMinutesRequiredForSearchBestAttentionDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  hideMinutesAutoPurchaseFailed: IUiViewmodel['hideMinutesAutoPurchaseFailed'] = (): void => {
    try {
      this.setFlagProperty('isMinutesAutoPurchaseFailedAttentionDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  hideMinutesAutoPurchaseUnavailable: IUiViewmodel['hideMinutesAutoPurchaseUnavailable'] = (): void => {
    try {
      this.setFlagProperty('isMinutesAutoPurchaseUnavailableAttentionDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  hideMinutesPurchase: IUiViewmodel['hideMinutesPurchase'] = (): void => {
    try {
      this.setFlagProperty('isMinutesPurchaseAttentionDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  hidePremiumPurchase: IUiViewmodel['hidePremiumPurchase'] = (): void => {
    try {
      this.setFlagProperty('isPremiumPurchaseAttentionDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  hidePageLoadingError: IUiViewmodel['hidePageLoadingError'] = (): void => {
    try {
      this.setFlagProperty('isPageLoadErrorAttentionDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  hideMessageSendingError: IUiViewmodel['hideMessageSendingError'] = (): void => {
    try {
      this.setFlagProperty('isMessageSendErrorAttentionDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  hideCardChangeSuccess: IUiViewmodel['hideCardChangeSuccess'] = (): void => {
    try {
      this.setFlagProperty('isCardChangeSuccessAttentionDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  hideLocalStream: IUiViewmodel['hideLocalStream'] = (): void => {
    try {
      this.setFlagProperty('isLocalStreamHidden', true);
    } catch (error) {
      throw error;
    }
  }

  hideLogout: IUiViewmodel['hideLogout'] = (): void => {
    try {
      this.setFlagProperty('isUserLogoutAttentionDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  hidePhotoSending: IUiViewmodel['hidePhotoSending'] = (): void => {
    try {
      this.setFlagProperty('isPhotoSendAttentionDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  hideImageSending: IUiViewmodel['hideImageSending'] = (): void => {
    try {
      this.setFlagProperty('isImageSendAttentionDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  hideImageView: IUiViewmodel['hideImageView'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isImageViewAttentionDisplayed: false,
          }),
          buffer: Object.freeze({
            ...this.store.state.ui.buffer,
            mediaFileToView: undefined,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  hidePartnerPaymentBlock: IUiViewmodel['hidePartnerPaymentBlock'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isPartnerPaymentBlockAttentionDisplayed: false,
          }),
          buffer: Object.freeze({
            ...this.store.state.ui.buffer,
            paymentAllowedUrl: undefined,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  hideGiftError: IUiViewmodel['hideGiftError'] = (): void => {
    try {
      this.setFlagProperty('isGiftErrorAttentionDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  hideCompanionNote: IUiViewmodel['hideCompanionNote'] = (): void => {
    try {
      this.setFlagProperty('isCompanionNoteAttentionDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  hideContactNote: IUiViewmodel['hideContactNote'] = (): void => {
    try {
      this.setFlagProperty('isContactNoteAttentionDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  hideCompanionPervert: IUiViewmodel['hideCompanionPervert'] = (): void => {
    try {
      this.setFlagProperty('isCompanionPervertAttentionDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  hideContactRequestAcceptionError: IUiViewmodel['hideContactRequestAcceptionError'] = (): void => {
    try {
      this.setFlagProperty('isContactRequestAcceptionErrorAttentionDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  hideActionImpossible: IUiViewmodel['hideActionImpossible'] = (): void => {
    try {
      this.setBufferProperty('actionImpossibleReason', undefined);
    } catch (error) {
      throw error;
    }
  }

  hideWithdrawalFinish: IUiViewmodel['hideWithdrawalFinish'] = (): void => {
    try {
      this.setFlagProperty('isFinishWithdrawalAttentionDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  hideMessengerEmailConfirmation: IUiViewmodel['hideMessengerEmailConfirmation'] = (): void => {
    try {
      this.setFlagProperty('isMessengerEmailConfirmationDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  hideEmailConfirmation: IUiViewmodel['hideEmailConfirmation'] = (): void => {
    try {
      this.setFlagProperty('isUserEmailConfirmationAttentionDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  hideEmailConfirmationReminder: IUiViewmodel['hideEmailConfirmationReminder'] = (): void => {
    try {
      this.setFlagProperty('isUserEmailConfirmationReminderAttentionDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  hideChangedEmailConfirmation: IUiViewmodel['hideChangedEmailConfirmation'] = (): void => {
    try {
      this.setFlagProperty('isUserEmailHasChangedAttentionDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  hideAccountChange: IUiViewmodel['hideAccountChange'] = (): void => {
    try {
      this.setFlagProperty('isChangeAccountAttentionDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  hideCompanionBannedVideocallLock: IUiViewmodel['hideCompanionBannedVideocallLock'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isCompanionBannedVideocallLockDisplayed: false,
            videochatPageStage: EVideochatPageStage.START,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  hideCompanionDisconnectVideocallLock: IUiViewmodel['hideCompanionDisconnectVideocallLock'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isCompanionDisconnectVideocallLockDisplayed: false,
            videochatPageStage: EVideochatPageStage.START,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  hideCompanionLeaveVideocallLock: IUiViewmodel['hideCompanionLeaveVideocallLock'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isCompanionLeaveVideocallLockDisplayed: false,
            videochatPageStage: EVideochatPageStage.START,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  hideContactRemoval: IUiViewmodel['hideContactRemoval'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isContactRemoveAttentionDisplayed: false,
          }),
          buffer: Object.freeze({
            ...this.store.state.ui.buffer,
            contactForRemovalId: undefined,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  hideCardConfirmation: IUiViewmodel['hideCardConfirmation'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isCardConfirmationLockDisplayed: false,
          }),
          buffer: Object.freeze({
            ...this.store.state.ui.buffer,
            userCardConfirmationValidationError: undefined,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showAccountDeletionReasonValidationError: IUiViewmodel['showAccountDeletionReasonValidationError'] = (
    payload: IErrorEntity,
  ): void => {
    try {
      this.setBufferProperty('accountDeletionReasonValidationError', payload.code);
    } catch (error) {
      throw error;
    }
  }

  hideAccountDeletionReasonValidationError: IUiViewmodel['hideAccountDeletionReasonValidationError'] = (): void => {
    try {
      this.setBufferProperty('accountDeletionReasonValidationError', undefined);
    } catch (error) {
      throw error;
    }
  }

  showMicrophoneNotice: IUiViewmodel['showMicrophoneNotice'] = (): void => {
    try {
      this.setFlagProperty('isMicrophoneNoticeDisplayed', true);
    } catch (error) {
      throw error;
    }
  }

  showCameraNotSupported: IUiViewmodel['showCameraNotSupported'] = (): void => {
    try {
      this.setFlagProperty('isCameraNotSupportedAttentionDisplayed', true);
    } catch (error) {
      throw error;
    }
  }

  hideCameraNotSupported: IUiViewmodel['hideCameraNotSupported'] = (): void => {
    try {
      this.setFlagProperty('isCameraNotSupportedAttentionDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  showCameraNotExist: IUiViewmodel['showCameraNotExist'] = (): void => {
    try {
      this.setFlagProperty('isCameraNotExistAttentionDisplayed', true);
    } catch (error) {
      throw error;
    }
  }

  hideCameraNotExist: IUiViewmodel['hideCameraNotExist'] = (): void => {
    try {
      this.setFlagProperty('isCameraNotExistAttentionDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  showCameraNotAvailable: IUiViewmodel['showCameraNotAvailable'] = (): void => {
    try {
      this.setFlagProperty('isCameraNotAvailableAttentionDisplayed', true);
    } catch (error) {
      throw error;
    }
  }

  hideCameraNotAvailable: IUiViewmodel['hideCameraNotAvailable'] = (): void => {
    try {
      this.setFlagProperty('isCameraNotAvailableAttentionDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  showCameraNeedPermission: IUiViewmodel['showCameraNeedPermission'] = (): void => {
    try {
      this.setFlagProperty('isCameraNeedPermissionAttentionDisplayed', true);
    } catch (error) {
      throw error;
    }
  }

  hideCameraNeedPermission: IUiViewmodel['hideCameraNeedPermission'] = (): void => {
    try {
      this.setFlagProperty('isCameraNeedPermissionAttentionDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  enablePurchase: IUiViewmodel['enablePurchase'] = (): void => {
    try {
      this.setFlagProperty('isPurchaseControlsDisabled', false);
    } catch (error) {
      throw error;
    }
  }

  disablePurchase: IUiViewmodel['disablePurchase'] = (): void => {
    try {
      this.setFlagProperty('isPurchaseControlsDisabled', true);
    } catch (error) {
      throw error;
    }
  }

  showPurchaseOneClickError: IUiViewmodel['showPurchaseOneClickError'] = (payload: IErrorEntity): void => {
    try {
      switch (payload.code) {
        case EPurchaseError.NO_FUNDS:
        case EPurchaseError.PAYMENT_FAILED:
        case EPurchaseError.NO_FUNDS_BUY_CHEAPER:
        case EPurchaseError.TRIAL_UNAVAILABLE:
        case EPurchaseError.NO_CARD:
          this.store.commit(
            'updateUi',
            {
              flags: Object.freeze({
                ...this.store.state.ui.flags,
                isMinutesPurchaseAttentionDisplayed: false,
                isPremiumPurchaseAttentionDisplayed: false,
                isPurchaseErrorAttentionDisplayed: true,
              }),
              buffer: Object.freeze({
                ...this.store.state.ui.buffer,
                purchaseError: payload.code,
              }),
            },
          );

          break;
        default:
          /*
           * Нижеследующие коды ошибок не обрабатываются,
           * просто сообщаем в консоль, утверждено с VK:
           * 602, 866 - Одновременная попытка оплатить несколько раз
           * 867 - Попытка оплатить картой, которая не прошла верификацию
           */
          throw new Error(`unknown error: ${payload}`);
      }
    } catch (error) {
      throw error;
    }
  }

  updatePushNotificationSubscriptionState: IUiViewmodel['updatePushNotificationSubscriptionState'] = (
    payload: IViewmodel['ui']['flags']['pushNotificationSubscriptionState'],
  ): void => {
    try {
      this.setFlagProperty('pushNotificationSubscriptionState', payload);
    } catch (error) {
      throw error;
    }
  }

  showServerPing: IUiViewmodel['showServerPing'] = (): void => {
    try {
      this.setFlagProperty('isServerPingAttentionDisplayed', true);
    } catch (error) {
      throw error;
    }
  }

  hideServerPing: IUiViewmodel['hideServerPing'] = (): void => {
    try {
      this.setFlagProperty('isServerPingAttentionDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  showServerNetwork: IUiViewmodel['showServerNetwork'] = (): void => {
    try {
      this.setFlagProperty('isServerNetworkAttentionDisplayed', true);
    } catch (error) {
      throw error;
    }
  }

  hideServerNetwork: IUiViewmodel['hideServerNetwork'] = (): void => {
    try {
      this.setFlagProperty('isServerNetworkAttentionDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  updateSocketConnectionEstablishTime: IUiViewmodel['updateSocketConnectionEstablishTime'] = (
    payload: IViewmodel['ui']['buffer']['socketConnectionEstablishTime'],
  ): void => {
    try {
      this.setBufferProperty('socketConnectionEstablishTime', payload);
    } catch (error) {
      throw error;
    }
  }

  showAppForbidden: IUiViewmodel['showAppForbidden'] = (): void => {
    try {
      this.setFlagProperty('isAppForbidden', true);
    } catch (error) {
      throw error;
    }
  }

  showContactRequestAcceptionError: IUiViewmodel['showContactRequestAcceptionError'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isContactRequestAttentionDisplayed: false,
            isContactRequestAcceptionErrorAttentionDisplayed: true,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  enableContactOutgoingRequestSending: IUiViewmodel['enableContactOutgoingRequestSending'] = (): void => {
    try {
      this.setFlagProperty('isContactAddDisabled', false);
    } catch (error) {
      throw error;
    }
  }

  disableContactOutgoingRequestSending: IUiViewmodel['disableContactOutgoingRequestSending'] = (): void => {
    try {
      this.setFlagProperty('isContactAddDisabled', true);
    } catch (error) {
      throw error;
    }
  }

  showContactOutgoingRequestError: IUiViewmodel['showContactOutgoingRequestError'] = (payload: IErrorEntity): void => {
    try {
      switch (payload.code) {
        case EInviteError.ACCOUNT_DELETED:
        case EInviteError.ACCOUNT_BLOCKED:
          this.store.commit(
            'updateUi',
            {
              flags: Object.freeze({
                ...this.store.state.ui.flags,
                isContactAddDisabled: false,
              }),
              buffer: Object.freeze({
                ...this.store.state.ui.buffer,
                actionImpossibleReason: payload.code === EInviteError.ACCOUNT_DELETED
                  ? EActionImpossibleReason.ACCOUNT_DELETED
                  : EActionImpossibleReason.ACCOUNT_BLOCKED,
              }),
            },
          );

          break;
        case EInviteError.ALREADY_IN_CONTACTS:
          /**
           * Ошибка может прийти в результате гонки сигналов, при одновременном добавлении
           * в друзья. В данном случае все отрабатывает корректно, поэтому только снимаем блокировку с контролов
           * добавления в контакты
           */
          this.setFlagProperty('isContactAddDisabled', false);

          break;
        default:
          throw new Error(`unknown error code: ${payload.code}`);
      }
    } catch (error) {
      throw error;
    }
  }

  showUserOutOfMessages: IUiViewmodel['showUserOutOfMessages'] = (): void => {
    try {
      this.setFlagProperty('isUserOutOfMessagesAttentionDisplayed', true);
    } catch (error) {
      throw error;
    }
  }

  showGift: IUiViewmodel['showGift'] = (): void => {
    try {
      this.setFlagProperty('isGiftAttentionDisplayed', true);
    } catch (error) {
      throw error;
    }
  }

  hideGift: IUiViewmodel['hideGift'] = (): void => {
    try {
      this.setFlagProperty('isGiftAttentionDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  showGiftSendingError: IUiViewmodel['showGiftSendingError'] = (payload: {
    error: IErrorEntity,
    isMinutesAutoPurchaseEnabled: boolean,
  }): void => {
    try {
      switch (payload.error.code) {
        case EGiftSendError.LOW_MINUTES:
          /**
           * Если включена фича автоматической покупки минут, и у юзера включена автоматическая
           * покупка минут, показываем окно с призывом пополнить минуты, в противном случае
           * показываем модалку покупки минут
           */
          if (payload.isMinutesAutoPurchaseEnabled) {
            this.setFlagProperty('isNotEnoughMinutesAttentionDisplayed', true);
          } else {
            this.setFlagProperty('isMinutesPurchaseAttentionDisplayed', true);
          }

          break;
        case EGiftSendError.PREVIOUS_SEND_NOT_COMPLETE:
          // Показываем модальное окно с ошибкой отправки подарка
          this.setFlagProperty('isGiftErrorAttentionDisplayed', true);

          break;
        default:
          throw new Error(`unknown error code: ${payload.error.code}`);
      }
    } catch (error) {
      throw error;
    }
  }

  updateContactsLoadingAvailable: IUiViewmodel['updateContactsLoadingAvailable'] = (
    payload: IViewmodel['ui']['buffer']['isContactsLoadingAvailable'],
  ): void => {
    try {
      this.setBufferProperty('isContactsLoadingAvailable', payload);
    } catch (error) {
      throw error;
    }
  }

  showContactsLoadingError: IUiViewmodel['showContactsLoadingError'] = (): void => {
    try {
      this.setFlagProperty('isContactsLoadingErrorDisplayed', true);
    } catch (error) {
      throw error;
    }
  }

  hideContactsLoadingError: IUiViewmodel['hideContactsLoadingError'] = (): void => {
    try {
      this.setFlagProperty('isContactsLoadingErrorDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  updatePaymentAllowedUrl: IUiViewmodel['updatePaymentAllowedUrl'] = (
    payload: IViewmodel['ui']['buffer']['paymentAllowedUrl'],
  ): void => {
    try {
      this.setBufferProperty('paymentAllowedUrl', payload);
    } catch (error) {
      throw error;
    }
  }

  hidePremiumRequirementForSearchBest: IUiViewmodel['hidePremiumRequirementForSearchBest'] = (): void => {
    try {
      this.setFlagProperty('isPremiumRequiredForSearchBestAttentionDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  showContactRequest: IUiViewmodel['showContactRequest'] = (): void => {
    try {
      this.setFlagProperty('isContactRequestAttentionDisplayed', true);
    } catch (error) {
      throw error;
    }
  }

  hideVideochatRecoveryWaiting: IUiViewmodel['hideVideochatRecoveryWaiting'] = (): void => {
    try {
      this.setFlagProperty('isVideochatRecoveryWaitingAttentionDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  hidePremiumSubscriptionRenewal: IUiViewmodel['hidePremiumSubscriptionRenewal'] = (): void => {
    try {
      this.setFlagProperty('isPremiumSubscriptionRenewalAttentionDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  showPurchaseError: IUiViewmodel['showPurchaseError'] = (): void => {
    try {
      this.setFlagProperty('isPurchaseErrorAttentionDisplayed', true);
    } catch (error) {
      throw error;
    }
  }

  hidePurchaseError: IUiViewmodel['hidePurchaseError'] = (): void => {
    try {
      this.setFlagProperty('isPurchaseErrorAttentionDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  hidePurchaseRetry: IUiViewmodel['hidePurchaseRetry'] = (): void => {
    try {
      this.setFlagProperty('isPurchaseRetryAttentionDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  hidePurchaseSuccess: IUiViewmodel['hidePurchaseSuccess'] = (): void => {
    try {
      this.setFlagProperty('isPurchaseSuccessAttentionDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  hidePurchaseUnavailable: IUiViewmodel['hidePurchaseUnavailable'] = (): void => {
    try {
      this.setFlagProperty('isPurchaseUnavailableAttentionDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  hideSearchSettings: IUiViewmodel['hideSearchSettings'] = (): void => {
    try {
      this.setFlagProperty('isSearchSettingsDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  toggleSearchSettings: IUiViewmodel['toggleSearchSettings'] = (): void => {
    try {
      this.setFlagProperty('isSearchSettingsDisplayed', !this.store.state.ui.flags.isSearchSettingsDisplayed);
    } catch (error) {
      throw error;
    }
  }

  hideSettings: IUiViewmodel['hideSettings'] = (): void => {
    try {
      this.setFlagProperty('isSettingsAttentionDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  hideWithdrawalRejection: IUiViewmodel['hideWithdrawalRejection'] = (): void => {
    try {
      this.setFlagProperty('isWithdrawalRequestRejectedAttentionDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  hideTariffsAndStatistics: IUiViewmodel['hideTariffsAndStatistics'] = (): void => {
    try {
      this.setFlagProperty('isTariffsAndStatisticsAttentionDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  hideTopDayLeader: IUiViewmodel['hideTopDayLeader'] = (): void => {
    try {
      this.setFlagProperty('isTopDayLeaderAttentionDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  hideTopMonthLeader: IUiViewmodel['hideTopMonthLeader'] = (): void => {
    try {
      this.setFlagProperty('isTopMonthLeaderAttentionDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  hideTopWeekLeader: IUiViewmodel['hideTopWeekLeader'] = (): void => {
    try {
      this.setFlagProperty('isTopWeekLeaderAttentionDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  hideReferrals: IUiViewmodel['hideReferrals'] = (): void => {
    try {
      this.setFlagProperty('isReferralAttentionDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  hideVideoSending: IUiViewmodel['hideVideoSending'] = (): void => {
    try {
      this.setFlagProperty('isVideoSendAttentionDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  hideWithdrawalError: IUiViewmodel['hideWithdrawalError'] = (): void => {
    try {
      this.setFlagProperty('isWithdrawalRequestErrorAttentionDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  showUnsecuredConnection: IUiViewmodel['showUnsecuredConnection'] = (): void => {
    try {
      this.setFlagProperty('isUnsecuredConnectionAttentionDisplayed', true);
    } catch (error) {
      throw error;
    }
  }

  hideVideochatControlsAndDisableAutoHiding: IUiViewmodel['hideVideochatControlsAndDisableAutoHiding'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isVideochatControlsHidden: true,
            isVideochatControlsAutoHidingEnabled: false,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  hideVideochatControlsDuringActiveAutoHiding: IUiViewmodel['hideVideochatControlsDuringActiveAutoHiding'] = (): void => {
    try {
      const { flags } = this.store.state.ui;

      if (flags.isVideochatControlsAutoHidingEnabled) {
        this.store.commit(
          'updateUi',
          {
            ...this.store.state.ui,
            flags: Object.freeze({
              ...this.store.state.ui.flags,
              isVideochatControlsHidden: true,
              isVideochatControlsAutoHidingEnabled: false,
            }),
          },
        );
      }
    } catch (error) {
      throw error;
    }
  }

  showVideochatStartInsteadRecoveryFail: IUiViewmodel['showVideochatStartInsteadRecoveryFail'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            videochatPageStage: EVideochatPageStage.START,
            isVideochatRecoveryFailAttentionDisplayed: false,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showCompanionRateInsteadVideochatRecovery: IUiViewmodel['showCompanionRateInsteadVideochatRecovery'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            videochatPageStage: EVideochatPageStage.FINISH,
            isCompanionRateLockDisplayed: true,
          }),
          buffer: Object.freeze({
            ...this.store.state.ui.buffer,
            isCompanionRateAfterRecoveryRequired: undefined,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  asyncShowCompanionRateInsteadVideochatRecovery: IUiViewmodel['asyncShowCompanionRateInsteadVideochatRecovery'] = async (): Promise<void> => {
    try {
      this.store.commit(
        'updateUi',
        {
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            videochatPageStage: EVideochatPageStage.FINISH,
            isCompanionRateLockDisplayed: true,
          }),
          buffer: Object.freeze({
            ...this.store.state.ui.buffer,
            isCompanionRateAfterRecoveryRequired: undefined,
          }),
        },
      );

      if (router.currentRoute.value.name !== 'videochat') {
        await router.push({ name: 'videochat' });
      }
    } catch (error) {
      throw error;
    }
  }

  hideWithdrawalCardAddress: IUiViewmodel['hideWithdrawalCardAddress'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isWithdrawalCardAddressAttentionDisplayed: false,
          }),
          buffer: Object.freeze({
            ...this.store.state.ui.buffer,
            withdrawalRequestCardAddressStreetValidationError: undefined,
            withdrawalRequestCardAddressCityValidationError: undefined,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  hideWithdrawalCardHolder: IUiViewmodel['hideWithdrawalCardHolder'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isWithdrawalCardHolderAttentionDisplayed: false,
          }),
          buffer: Object.freeze({
            ...this.store.state.ui.buffer,
            withdrawalRequestCardHolderFirstNameValidationError: undefined,
            withdrawalRequestCardHolderLastNameValidationError: undefined,
            withdrawalRequestCardHolderBirthdayValidationError: undefined,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  hideWithdrawalCardRequisites: IUiViewmodel['hideWithdrawalCardRequisites'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isWithdrawalCardRequisitesAttentionDisplayed: false,
          }),
          buffer: Object.freeze({
            ...this.store.state.ui.buffer,
            withdrawalRequestCardNumberValidationError: undefined,
            withdrawalRequestCardExpirationValidationError: undefined,
            withdrawalRequestCardCurrencyValidationError: undefined,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  hideWithdrawalEpayserviceRequisites: IUiViewmodel['hideWithdrawalEpayserviceRequisites'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isWithdrawalEpayserviceRequisitesAttentionDisplayed: false,
          }),
          buffer: Object.freeze({
            ...this.store.state.ui.buffer,
            withdrawalRequestEpayserviceNumberValidationError: undefined,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  hideWithdrawalWebmoneyRequisites: IUiViewmodel['hideWithdrawalWebmoneyRequisites'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isWithdrawalWebmoneyRequisitesAttentionDisplayed: false,
          }),
          buffer: Object.freeze({
            ...this.store.state.ui.buffer,
            withdrawalRequestWebmoneyNumberValidationError: undefined,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  hideWithdrawalYandexRequisites: IUiViewmodel['hideWithdrawalYandexRequisites'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isWithdrawalYandexRequisitesAttentionDisplayed: false,
          }),
          buffer: Object.freeze({
            ...this.store.state.ui.buffer,
            withdrawalRequestYandexNumberValidationError: undefined,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  hideWithdrawalBitcoinRequisites: IUiViewmodel['hideWithdrawalBitcoinRequisites'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isWithdrawalBitcoinRequisitesAttentionDisplayed: false,
          }),
          buffer: Object.freeze({
            ...this.store.state.ui.buffer,
            withdrawalRequestBitcoinNumberValidationError: undefined,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showWithdrawalStartInsteadUsdtRequisites: IUiViewmodel['showWithdrawalStartInsteadUsdtRequisites'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isWithdrawalUsdtRequisitesAttentionDisplayed: false,
            isStartWithdrawalAttentionDisplayed: true,
          }),
          buffer: Object.freeze({
            ...this.store.state.ui.buffer,
            withdrawalRequestUsdtNumberValidationError: undefined,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  hideWithdrawalUsdtRequisites: IUiViewmodel['hideWithdrawalUsdtRequisites'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isWithdrawalUsdtRequisitesAttentionDisplayed: false,
          }),
          buffer: Object.freeze({
            ...this.store.state.ui.buffer,
            withdrawalRequestUsdtNumberValidationError: undefined,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  hideWithdrawalUsdtNumberValidationError: IUiViewmodel['hideWithdrawalUsdtNumberValidationError'] = (): void => {
    try {
      this.setBufferProperty('withdrawalRequestUsdtNumberValidationError', undefined);
    } catch (error) {
      throw error;
    }
  }

  showWithdrawalUsdtNumberClientValidationError: IUiViewmodel['showWithdrawalUsdtNumberClientValidationError'] = (
    payload: IErrorEntity,
  ): void => {
    try {
      this.setBufferProperty('withdrawalRequestUsdtNumberValidationError', payload.code);
    } catch (error) {
      throw error;
    }
  }

  showWithdrawalUsdtNumberServerValidationError: IUiViewmodel['showWithdrawalUsdtNumberServerValidationError'] = (
    payload: IErrorEntity,
  ): void => {
    try {
      switch (payload.code) {
        case 302:
          this.setBufferProperty(
            'withdrawalRequestUsdtNumberValidationError',
            EWithdrawalRequestUsdtNumberValidationError.INCORRECT,
          );

          break;
        case 303:
          this.setBufferProperty(
            'withdrawalRequestUsdtNumberValidationError',
            EWithdrawalRequestUsdtNumberValidationError.EMPTY,
          );

          break;
        default:
          throw new Error(`unknown error code: ${payload.code}`);
      }
    } catch (error) {
      throw error;
    }
  }

  showWithdrawalConfirmationInsteadUsdtRequisites: IUiViewmodel['showWithdrawalConfirmationInsteadUsdtRequisites'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isWithdrawalRequestSendControlsDisabled: false,
            isWithdrawalUsdtRequisitesAttentionDisplayed: false,
            isWithdrawalConfirmationAttentionDisplayed: true,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showWithdrawalFinishInsteadUsdtRequisites: IUiViewmodel['showWithdrawalFinishInsteadUsdtRequisites'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isWithdrawalRequestSendControlsDisabled: false,
            isFinishWithdrawalAttentionDisplayed: true,
            isWithdrawalUsdtRequisitesAttentionDisplayed: false,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  hideWithdrawalMethodRemoval: IUiViewmodel['hideWithdrawalMethodRemoval'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isWithdrawalMethodRemovalAttentionDisplayed: false,
          }),
          buffer: Object.freeze({
            ...this.store.state.ui.buffer,
            withdrawalMethodForRemoval: undefined,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  hideWithdrawalQiwiRequisites: IUiViewmodel['hideWithdrawalQiwiRequisites'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isWithdrawalQiwiRequisitesAttentionDisplayed: false,
          }),
          buffer: Object.freeze({
            ...this.store.state.ui.buffer,
            withdrawalRequestQiwiNumberValidationError: undefined,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  hideReceivedPromoBonus: IUiViewmodel['hideReceivedPromoBonus'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isPromoBonusAttentionDisplayed: false,
          }),
          buffer: Object.freeze({
            ...this.store.state.ui.buffer,
            promoBonusContactId: undefined,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  hideSignInAndSignUp: IUiViewmodel['hideSignInAndSignUp'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isSignUpAttentionDisplayed: false,
            isSignInAttentionDisplayed: false,
            isAuthorizationControlsDisabled: false,
          }),
          buffer: Object.freeze({
            ...this.store.state.ui.buffer,
            userSignUpEmailValidationError: undefined,
            userSignUpPasswordValidationError: undefined,
            userSignInEmailValidationError: undefined,
            userSignInPasswordValidationError: undefined,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  hideVideoViewer: IUiViewmodel['hideVideoViewer'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isVideoViewAttentionDisplayed: false,
          }),
          buffer: Object.freeze({
            ...this.store.state.ui.buffer,
            mediaFileToView: undefined,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showSignUpCompleteAndVideochatStart: IUiViewmodel['showSignUpCompleteAndVideochatStart'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isCompleteSignUpAttentionDisplayed: true,
            videochatPageStage: EVideochatPageStage.START,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showSignUpCompleteAfterEmailConfirmationForFreebie: IUiViewmodel['showSignUpCompleteAfterEmailConfirmationForFreebie'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isEmailConfirmationForFreebieAttentionDisplayed: false,
            isCompleteSignUpAttentionDisplayed: true,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showVideochatStart: IUiViewmodel['showVideochatStart'] = (): void => {
    try {
      this.setFlagProperty('videochatPageStage', EVideochatPageStage.START);
    } catch (error) {
      throw error;
    }
  }

  showVideochatCall: IUiViewmodel['showVideochatCall'] = (): void => {
    try {
      this.setFlagProperty('videochatPageStage', EVideochatPageStage.CALL);
    } catch (error) {
      throw error;
    }
  }

  showVideochatFinish: IUiViewmodel['showVideochatFinish'] = (): void => {
    try {
      this.setFlagProperty('videochatPageStage', EVideochatPageStage.FINISH);
    } catch (error) {
      throw error;
    }
  }

  showServerUnavailable: IUiViewmodel['showServerUnavailable'] = (): void => {
    try {
      this.setFlagProperty('isServerUnavailableAttentionDisplayed', true);
    } catch (error) {
      throw error;
    }
  }

  unmarkContact: IUiViewmodel['unmarkContact'] = (payload: IContactEntity['id']): void => {
    try {
      const { markedContactsIds } = this.store.state.ui.buffer;

      if (markedContactsIds === undefined) {
        throw new Error('marked contacts ids are undefined');
      }

      const idIndex = markedContactsIds.indexOf(payload);

      if (idIndex === -1) {
        throw new Error('id index is negative');
      }

      const newIds: IContactEntity['id'][] = [...markedContactsIds];

      newIds.splice(idIndex, 1);

      this.setBufferProperty('markedContactsIds', newIds);
    } catch (error) {
      throw error;
    }
  }

  markContact: IUiViewmodel['markContact'] = (payload: IContactEntity['id']): void => {
    try {
      const { markedContactsIds } = this.store.state.ui.buffer;

      if (markedContactsIds === undefined) {
        this.setBufferProperty('markedContactsIds', [payload]);

        return;
      }

      this.setBufferProperty('markedContactsIds', [
        ...markedContactsIds,
        payload,
      ]);
    } catch (error) {
      throw error;
    }
  }

  hideVideochatRecoveryFail: IUiViewmodel['hideVideochatRecoveryFail'] = (): void => {
    try {
      this.setFlagProperty('isVideochatRecoveryFailAttentionDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  hideCompanionBannedDuringVideoCall: IUiViewmodel['hideCompanionBannedDuringVideoCall'] = (): void => {
    try {
      this.setFlagProperty('isCompanionBannedVideocallLockDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  hideCompanionGift: IUiViewmodel['hideCompanionGift'] = (): void => {
    try {
      this.setFlagProperty('isCompanionGiftLockDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  hideCompanionHasAnotherVideoCall: IUiViewmodel['hideCompanionHasAnotherVideoCall'] = (): void => {
    try {
      this.setFlagProperty('isCompanionHasAnotherVideocallLockDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  hideCompanionRate: IUiViewmodel['hideCompanionRate'] = (): void => {
    try {
      this.setFlagProperty('isCompanionRateLockDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  hideCompanionReview: IUiViewmodel['hideCompanionReview'] = (): void => {
    try {
      this.setFlagProperty('isCompanionReviewLockDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  hideCompanionReviewSent: IUiViewmodel['hideCompanionReviewSent'] = (): void => {
    try {
      this.setFlagProperty('isCompanionReviewSentLockDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  hideFemalePromo: IUiViewmodel['hideFemalePromo'] = (): void => {
    try {
      this.setFlagProperty('isFemalePromoVideocallLockDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  hideCompanionOutOfMinutes: IUiViewmodel['hideCompanionOutOfMinutes'] = (): void => {
    try {
      this.setFlagProperty('isCompanionOutOfMinutesLockDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  showEmailConfirmationReminder: IUiViewmodel['showEmailConfirmationReminder'] = (): void => {
    try {
      this.setFlagProperty('isUserEmailConfirmationReminderAttentionDisplayed', true);
    } catch (error) {
      throw error;
    }
  }

  showSignInInsteadSignUpAppeal: IUiViewmodel['showSignInInsteadSignUpAppeal'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isSignUpAppealAttentionDisplayed: false,
            isSignInAttentionDisplayed: true,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showSignUpInsteadAppeal: IUiViewmodel['showSignUpInsteadAppeal'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isSignUpAppealAttentionDisplayed: false,
            isSignUpAttentionDisplayed: true,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showWithdrawalConfirmationInsteadStart: IUiViewmodel['showWithdrawalConfirmationInsteadStart'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isWithdrawalRequestSendControlsDisabled: false,
            isStartWithdrawalAttentionDisplayed: false,
            isWithdrawalConfirmationAttentionDisplayed: true,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showWithdrawalFinishInsteadStart: IUiViewmodel['showWithdrawalFinishInsteadStart'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isWithdrawalRequestSendControlsDisabled: false,
            isStartWithdrawalAttentionDisplayed: false,
            isFinishWithdrawalAttentionDisplayed: true,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showCardConfirmationInsteadPurchaseSuccess: IUiViewmodel['showCardConfirmationInsteadPurchaseSuccess'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isCardConfirmationLockDisplayed: true,
            isPurchaseSuccessAttentionDisplayed: false,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showPurchaseSuccess: IUiViewmodel['showPurchaseSuccess'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isPurchaseSuccessAttentionDisplayed: true,
            isMinutesAddedAttentionDisplayed: false,
            isMinutesReducedAttentionDisplayed: false,
            isPremiumAddedAttentionDisplayed: false,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showMinutesAdded: IUiViewmodel['showMinutesAdded'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isPurchaseSuccessAttentionDisplayed: false,
            isMinutesAddedAttentionDisplayed: true,
            isMinutesReducedAttentionDisplayed: false,
            isPremiumAddedAttentionDisplayed: false,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showMinutesReduced: IUiViewmodel['showMinutesReduced'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isPurchaseSuccessAttentionDisplayed: false,
            isMinutesAddedAttentionDisplayed: false,
            isMinutesReducedAttentionDisplayed: true,
            isPremiumAddedAttentionDisplayed: false,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showPremiumAdded: IUiViewmodel['showPremiumAdded'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isPurchaseSuccessAttentionDisplayed: false,
            isMinutesAddedAttentionDisplayed: false,
            isMinutesReducedAttentionDisplayed: false,
            isPremiumAddedAttentionDisplayed: true,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  hidePurchases: IUiViewmodel['hidePurchases'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isMinutesPurchaseAttentionDisplayed: false,
            isPremiumPurchaseAttentionDisplayed: false,
            isPurchaseRetryAttentionDisplayed: false,
            isNotEnoughMinutesAttentionDisplayed: false,
            isNotEnoughMinutesDisabled: false,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showWithdrawalMethodRemoval: IUiViewmodel['showWithdrawalMethodRemoval'] = (
    payload: IViewmodel['ui']['buffer']['withdrawalMethodForRemoval'],
  ): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isWithdrawalMethodRemovalAttentionDisplayed: true,
          }),
          buffer: Object.freeze({
            ...this.store.state.ui.buffer,
            withdrawalMethodForRemoval: payload,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showPasswordRecoveryInsteadSignUpOrSignIn: IUiViewmodel['showPasswordRecoveryInsteadSignUpOrSignIn'] = (payload: string): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isSignInAttentionDisplayed: false,
            isSignUpAttentionDisplayed: false,
            isPasswordRecoveryAttentionDisplayed: true,
          }),
          buffer: Object.freeze({
            ...this.store.state.ui.buffer,
            userSignUpEmailValidationError: undefined,
            userSignUpPasswordValidationError: undefined,
            userSignInEmailValidationError: undefined,
            userSignInPasswordValidationError: undefined,
            passwordRecoveryEmail: payload,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showWithdrawalBankAccountError: IUiViewmodel['showWithdrawalBankAccountError'] = (payload: IErrorEntity): void => {
    try {
      switch (payload.code) {
        case 401:
        case 474:
        case 517:
        case 475:
        case 110:
        case 135:
        case 132:
        case 494:
        case 495:
          this.store.commit(
            'updateUi',
            {
              flags: Object.freeze({
                ...this.store.state.ui.flags,
                isWithdrawalRequestSendControlsDisabled: false,
                isWithdrawalRequestErrorAttentionDisplayed: true,
              }),
              buffer: Object.freeze({
                ...this.store.state.ui.buffer,
                withdrawalRequestError: payload.code,
              }),
            },
          );

          break;
        case 319:
          this.store.commit(
            'updateUi',
            {
              flags: Object.freeze({
                ...this.store.state.ui.flags,
                isWithdrawalRequestSendControlsDisabled: false,
                isWithdrawalBankFirstStepDisplayed: true,
                isWithdrawalBankSecondStepDisplayed: false,
              }),
              buffer: Object.freeze({
                ...this.store.state.ui.buffer,
                withdrawalRequestBankAccountHolderDocumentNumberValidationError:
                  EWithdrawalRequestBankAccountHolderDocumentNumberValidationError.INCORRECT,
              }),
            },
          );

          break;
        case 324:
          this.store.commit(
            'updateUi',
            {
              flags: Object.freeze({
                ...this.store.state.ui.flags,
                isWithdrawalRequestSendControlsDisabled: false,
                isWithdrawalBankFirstStepDisplayed: true,
                isWithdrawalBankSecondStepDisplayed: false,
              }),
              buffer: Object.freeze({
                ...this.store.state.ui.buffer,
                withdrawalRequestBankAccountHolderFirstNameValidationError:
                  EWithdrawalRequestBankAccountHolderFirstNameValidationError.INCORRECT,
              }),
            },
          );

          break;
        case 325:
          this.store.commit(
            'updateUi',
            {
              flags: Object.freeze({
                ...this.store.state.ui.flags,
                isWithdrawalRequestSendControlsDisabled: false,
                isWithdrawalBankFirstStepDisplayed: true,
                isWithdrawalBankSecondStepDisplayed: false,
              }),
              buffer: Object.freeze({
                ...this.store.state.ui.buffer,
                withdrawalRequestBankAccountHolderLastNameValidationError:
                  EWithdrawalRequestBankAccountHolderLastNameValidationError.INCORRECT,
              }),
            },
          );

          break;
        case 313:
          this.store.commit(
            'updateUi',
            {
              flags: Object.freeze({
                ...this.store.state.ui.flags,
                isWithdrawalRequestSendControlsDisabled: false,
                isWithdrawalBankFirstStepDisplayed: true,
                isWithdrawalBankSecondStepDisplayed: false,
              }),
              buffer: Object.freeze({
                ...this.store.state.ui.buffer,
                withdrawalRequestBankAccountHolderAddressValidationError:
                  EWithdrawalRequestBankAccountHolderAddressValidationError.INCORRECT,
              }),
            },
          );

          break;
        case 302:
          this.store.commit(
            'updateUi',
            {
              flags: Object.freeze({
                ...this.store.state.ui.flags,
                isWithdrawalRequestSendControlsDisabled: false,
              }),
              buffer: Object.freeze({
                ...this.store.state.ui.buffer,
                withdrawalRequestBankAccountNumberValidationError:
                  EWithdrawalRequestBankAccountNumberValidationError.INCORRECT,
              }),
            },
          );

          break;
        case 322:
          this.store.commit(
            'updateUi',
            {
              flags: Object.freeze({
                ...this.store.state.ui.flags,
                isWithdrawalRequestSendControlsDisabled: false,
              }),
              buffer: Object.freeze({
                ...this.store.state.ui.buffer,
                withdrawalRequestBankBranchCodeValidationError:
                  EWithdrawalRequestBankBranchCodeValidationError.INCORRECT,
              }),
            },
          );

          break;
        case 326:
          this.store.commit(
            'updateUi',
            {
              flags: Object.freeze({
                ...this.store.state.ui.flags,
                isWithdrawalRequestSendControlsDisabled: false,
              }),
              buffer: Object.freeze({
                ...this.store.state.ui.buffer,
                withdrawalRequestBankAccountHolderPhoneValidationError:
                  EWithdrawalRequestBankAccountHolderPhoneValidationError.INCORRECT,
              }),
            },
          );

          break;
        case 327:
          this.store.commit(
            'updateUi',
            {
              flags: Object.freeze({
                ...this.store.state.ui.flags,
                isWithdrawalRequestSendControlsDisabled: false,
              }),
              buffer: Object.freeze({
                ...this.store.state.ui.buffer,
                withdrawalRequestBankAccountHolderEmailValidationError:
                  EWithdrawalRequestBankAccountHolderEmailValidationError.INCORRECT,
              }),
            },
          );

          break;
        default:
          /*
          * Нижеследующие коды ошибок не обрабатываются,
          * просто сообщаем в консоль об ошибке, утверждено с VK:
          * 499 - не нашли заявку для повторной оплаты после смены реквизитов
          * 801 - заявка не в том статусе чтоб можно было снова отправлять на выплату
          */
          throw new Error(`unknown error code: ${payload.code}`);
      }
    } catch (error) {
      throw error;
    }
  }

  showWithdrawalCommonError: IUiViewmodel['showWithdrawalCommonError'] = (payload: IErrorEntity): void => {
    try {
      switch (payload.code) {
        case 401:
        case 474:
        case 517:
        case 110:
        case 135:
        case 132:
        case 494:
        case 495:
        case 302:
          this.store.commit(
            'updateUi',
            {
              flags: Object.freeze({
                ...this.store.state.ui.flags,
                isWithdrawalRequestSendControlsDisabled: false,
                isWithdrawalRequestErrorAttentionDisplayed: true,
              }),
              buffer: Object.freeze({
                ...this.store.state.ui.buffer,
                withdrawalRequestError: payload.code,
              }),
            },
          );

          break;
        case 475: {
          this.store.commit(
            'updateUi',
            {
              flags: Object.freeze({
                ...this.store.state.ui.flags,
                isWithdrawalRequestSendControlsDisabled: false,
                isWithdrawalRequestErrorAttentionDisplayed: true,
              }),
              buffer: Object.freeze({
                ...this.store.state.ui.buffer,
                withdrawalRequestError: payload.code,
                withdrawalRequestMinPoints: payload.comment,
              }),
            },
          );

          break;
        }
        default:
          /*
          * Нижеследующие коды ошибок не обрабатываются,
          * просто сообщаем в консоль об ошибке, утверждено с VK:
          * 499 - не нашли заявку для повторной оплаты после смены реквизитов
          * 801 - заявка не в том статусе чтоб можно было снова отправлять на выплату
          */
          throw new Error(`unknown error code: ${payload.code}`);
      }
    } catch (error) {
      throw error;
    }
  }

  showLocalStream: IUiViewmodel['showLocalStream'] = (): void => {
    try {
      this.setFlagProperty('isLocalStreamHidden', false);
    } catch (error) {
      throw error;
    }
  }

  showVideochatControls: IUiViewmodel['showVideochatControls'] = (): void => {
    try {
      this.setFlagProperty('isVideochatControlsHidden', false);
    } catch (error) {
      throw error;
    }
  }

  showVideochatHistoryClearing: IUiViewmodel['showVideochatHistoryClearing'] = (): void => {
    try {
      this.setFlagProperty('isVideochatHistoryClearAttentionDisplayed', true);
    } catch (error) {
      throw error;
    }
  }

  showReferrals: IUiViewmodel['showReferrals'] = (): void => {
    try {
      this.setFlagProperty('isReferralAttentionDisplayed', true);
    } catch (error) {
      throw error;
    }
  }

  showVideoSending: IUiViewmodel['showVideoSending'] = (): void => {
    try {
      this.setFlagProperty('isVideoSendAttentionDisplayed', true);
    } catch (error) {
      throw error;
    }
  }

  showImageSending: IUiViewmodel['showImageSending'] = (): void => {
    try {
      this.setFlagProperty('isImageSendAttentionDisplayed', true);
    } catch (error) {
      throw error;
    }
  }

  showPhotoSending: IUiViewmodel['showPhotoSending'] = (): void => {
    try {
      this.setFlagProperty('isPhotoSendAttentionDisplayed', true);
    } catch (error) {
      throw error;
    }
  }

  showAccountDeletion: IUiViewmodel['showAccountDeletion'] = (): void => {
    try {
      this.setFlagProperty('isAccountDeletionAttentionDisplayed', true);
    } catch (error) {
      throw error;
    }
  }

  showAccountTransactions: IUiViewmodel['showAccountTransactions'] = (): void => {
    try {
      this.setFlagProperty('isAccountTransactionsAttentionDisplayed', true);
    } catch (error) {
      throw error;
    }
  }

  showUserNameEditForm: IUiViewmodel['showUserNameEditForm'] = (): void => {
    try {
      this.setFlagProperty('isUserNameEditFormDisplayed', true);
    } catch (error) {
      throw error;
    }
  }

  hideUserNameEditFormAndValidationError: IUiViewmodel['hideUserNameEditFormAndValidationError'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isUserNameEditFormDisplayed: false,
          }),
          buffer: Object.freeze({
            ...this.store.state.ui.buffer,
            userProfileNameValidationError: undefined,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showSignIn: IUiViewmodel['showSignIn'] = (): void => {
    try {
      this.setFlagProperty('isSignInAttentionDisplayed', true);
    } catch (error) {
      throw error;
    }
  }

  showCompanionNote: IUiViewmodel['showCompanionNote'] = (): void => {
    try {
      this.setFlagProperty('isCompanionNoteAttentionDisplayed', true);
    } catch (error) {
      throw error;
    }
  }

  showContactNote: IUiViewmodel['showContactNote'] = (): void => {
    try {
      this.setFlagProperty('isContactNoteAttentionDisplayed', true);
    } catch (error) {
      throw error;
    }
  }

  showAccountChanging: IUiViewmodel['showAccountChanging'] = (): void => {
    try {
      this.setFlagProperty('isChangeAccountAttentionDisplayed', true);
    } catch (error) {
      throw error;
    }
  }

  showLogout: IUiViewmodel['showLogout'] = (): void => {
    try {
      this.setFlagProperty('isUserLogoutAttentionDisplayed', true);
    } catch (error) {
      throw error;
    }
  }

  showSettings: IUiViewmodel['showSettings'] = (): void => {
    try {
      this.setFlagProperty('isSettingsAttentionDisplayed', true);
    } catch (error) {
      throw error;
    }
  }

  showPageLoadingError: IUiViewmodel['showPageLoadingError'] = (): void => {
    try {
      this.setFlagProperty('isPageLoadErrorAttentionDisplayed', true);
    } catch (error) {
      throw error;
    }
  }

  showWithdrawalStartAndEnableWithdrawalLoading: IUiViewmodel['showWithdrawalStartAndEnableWithdrawalLoading'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isStartWithdrawalAttentionDisplayed: true,
            isWithdrawalRequestLoadControlsDisabled: false,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showWithdrawalConfirmation: IUiViewmodel['showWithdrawalConfirmation'] = (): void => {
    try {
      this.setFlagProperty('isWithdrawalConfirmationAttentionDisplayed', true);
    } catch (error) {
      throw error;
    }
  }

  hideWithdrawalConfirmation: IUiViewmodel['hideWithdrawalConfirmation'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isWithdrawalConfirmationAttentionDisplayed: false,
          }),
          buffer: Object.freeze({
            ...this.store.state.ui.buffer,
            withdrawalConfirmationValidationError: undefined,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  disableWithdrawalConfirmation: IUiViewmodel['disableWithdrawalConfirmation'] = (): void => {
    try {
      this.setBufferProperty('isWithdrawalConfirmationDisabled', true);
    } catch (error) {
      throw error;
    }
  }

  enableWithdrawalConfirmation: IUiViewmodel['enableWithdrawalConfirmation'] = (): void => {
    try {
      this.setBufferProperty('isWithdrawalConfirmationDisabled', false);
    } catch (error) {
      throw error;
    }
  }

  enableWithdrawalConfirmationAndShowCancelledWithdrawal: IUiViewmodel['enableWithdrawalConfirmationAndShowCancelledWithdrawal'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isWithdrawalConfirmationAttentionDisplayed: false,
            isWithdrawalCancelledAttentionDisplayed: true,
          }),
          buffer: Object.freeze({
            ...this.store.state.ui.buffer,
            isWithdrawalConfirmationDisabled: false,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  hideCancelledWithdrawal: IUiViewmodel['hideCancelledWithdrawal'] = (): void => {
    try {
      this.setFlagProperty('isWithdrawalCancelledAttentionDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  hideWithdrawalConfirmationValidationError: IUiViewmodel['hideWithdrawalConfirmationValidationError'] = (): void => {
    try {
      this.setBufferProperty('withdrawalConfirmationValidationError', undefined);
    } catch (error) {
      throw error;
    }
  }

  showWithdrawalConfirmationValidationError: IUiViewmodel['showWithdrawalConfirmationValidationError'] = (payload: IErrorEntity): void => {
    try {
      switch (payload.code) {
        case 0:
          this.setBufferProperty('withdrawalConfirmationValidationError', EWithdrawalConfirmationValidationError.EMPTY);

          break;
        case 1:
          this.setBufferProperty('withdrawalConfirmationValidationError', EWithdrawalConfirmationValidationError.SHORT);

          break;
        case 2:
        case 885:
          this.setBufferProperty('withdrawalConfirmationValidationError', EWithdrawalConfirmationValidationError.INCORRECT);

          break;
        default:
          throw new Error(`unknown error code: ${payload.code}`);
      }
    } catch (error) {
      throw error;
    }
  }

  showWithdrawalConfirmationAndEnableWithdrawalLoading: IUiViewmodel['showWithdrawalConfirmationAndEnableWithdrawalLoading'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isWithdrawalRequestLoadControlsDisabled: false,
            isWithdrawalConfirmationAttentionDisplayed: true,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showWithdrawalFinishAndEnableWithdrawalLoading: IUiViewmodel['showWithdrawalFinishAndEnableWithdrawalLoading'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isFinishWithdrawalAttentionDisplayed: true,
            isWithdrawalRequestLoadControlsDisabled: false,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showWithdrawalRejectionAndEnableWithdrawalLoading: IUiViewmodel['showWithdrawalRejectionAndEnableWithdrawalLoading'] = (
    payload: IViewmodel['ui']['buffer']['withdrawalRequestRejectionReason'],
  ): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isWithdrawalRequestRejectedAttentionDisplayed: true,
            isWithdrawalRequestLoadControlsDisabled: false,
          }),
          buffer: Object.freeze({
            ...this.store.state.ui.buffer,
            withdrawalRequestRejectionReason: payload,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  disableWithdrawalLoading: IUiViewmodel['disableWithdrawalLoading'] = (): void => {
    try {
      this.setFlagProperty('isWithdrawalRequestLoadControlsDisabled', true);
    } catch (error) {
      throw error;
    }
  }

  showMinutesPurchaseInsteadAutoPurchaseFail: IUiViewmodel['showMinutesPurchaseInsteadAutoPurchaseFail'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isMinutesAutoPurchaseFailedAttentionDisplayed: false,
            isMinutesPurchaseAttentionDisplayed: true,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showMinutesPurchaseInsteadRequirementForSearchBest:
    IUiViewmodel['showMinutesPurchaseInsteadRequirementForSearchBest'] = (): void => {
      try {
        this.store.commit(
          'updateUi',
          {
            ...this.store.state.ui,
            flags: Object.freeze({
              ...this.store.state.ui.flags,
              isMinutesRequiredForSearchBestAttentionDisplayed: false,
              isMinutesPurchaseAttentionDisplayed: true,
            }),
          },
        );
      } catch (error) {
        throw error;
      }
    }

  showPremiumPurchaseInsteadRequirementForSearchBest:
    IUiViewmodel['showPremiumPurchaseInsteadRequirementForSearchBest'] = (): void => {
      try {
        this.store.commit(
          'updateUi',
          {
            ...this.store.state.ui,
            flags: Object.freeze({
              ...this.store.state.ui.flags,
              isPremiumRequiredForSearchBestAttentionDisplayed: false,
              isPremiumPurchaseAttentionDisplayed: true,
            }),
          },
        );
      } catch (error) {
        throw error;
      }
    }

    showPremiumPurchaseInsteadEmailConfirmationForFreebie: IUiViewmodel['showPremiumPurchaseInsteadEmailConfirmationForFreebie'] = (): void => {
      try {
        this.store.commit(
          'updateUi',
          {
            ...this.store.state.ui,
            flags: Object.freeze({
              ...this.store.state.ui.flags,
              isEmailConfirmationForFreebieAttentionDisplayed: false,
              isPremiumPurchaseAttentionDisplayed: true,
            }),
          },
        );
      } catch (error) {
        throw error;
      }
    }

  showEmailConfirmationInsteadReminder: IUiViewmodel['showEmailConfirmationInsteadReminder'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isUserEmailConfirmationReminderAttentionDisplayed: false,
            isUserEmailConfirmationAttentionDisplayed: true,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showEmailConfirmationForFreebieInsteadReminder: IUiViewmodel['showEmailConfirmationForFreebieInsteadReminder'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isUserEmailConfirmationReminderAttentionDisplayed: false,
            isEmailConfirmationForFreebieAttentionDisplayed: true,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showSettingsInsteadPushNotificationBonus: IUiViewmodel['showSettingsInsteadPushNotificationBonus'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isPushNotificationBonusAttentionDisplayed: false,
            isSettingsAttentionDisplayed: true,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showCompanionAbuse: IUiViewmodel['showCompanionAbuse'] = (payload: ICompanionEntity['id']): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isCompanionAbuseAttentionDisplayed: true,
          }),
          buffer: Object.freeze({
            ...this.store.state.ui.buffer,
            companionAbuseId: payload,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showContactRemoval: IUiViewmodel['showContactRemoval'] = (payload: IContactEntity['id']): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isContactRemoveAttentionDisplayed: true,
          }),
          buffer: Object.freeze({
            ...this.store.state.ui.buffer,
            contactForRemovalId: payload,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showSelectedContactsMessagesRemoval: IUiViewmodel['showSelectedContactsMessagesRemoval'] = (
    payload: IContactEntity['id'][],
  ): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isRemoveSelectedContactsMessagesAttentionDisplayed: true,
          }),
          buffer: Object.freeze({
            ...this.store.state.ui.buffer,
            markedContactsIds: payload,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showImageView: IUiViewmodel['showImageView'] = (payload: IViewmodel['ui']['buffer']['mediaFileToView']): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isImageViewAttentionDisplayed: true,
          }),
          buffer: Object.freeze({
            ...this.store.state.ui.buffer,
            mediaFileToView: payload,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showVideoMessage: IUiViewmodel['showVideoMessage'] = (payload: string): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isVideoViewAttentionDisplayed: true,
          }),
          buffer: Object.freeze({
            ...this.store.state.ui.buffer,
            mediaFileToView: payload,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showMinutesAutoPurchaseInsteadPurchase: IUiViewmodel['showMinutesAutoPurchaseInsteadPurchase'] = (payload: {
    userMaleMinutesAutoPurchasePackageId: IViewmodel['ui']['buffer']['userMaleMinutesAutoPurchasePackageId'],
    userMaleMinutesAutoPurchaseCardLastNumbers: IViewmodel['ui']['buffer']['userMaleMinutesAutoPurchaseCardLastNumbers'],
  }): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isMinutesPurchaseAttentionDisplayed: false,
            isMinutesAutoPurchaseTermsAttentionDisplayed: true,
          }),
          buffer: Object.freeze({
            ...this.store.state.ui.buffer,
            userMaleMinutesAutoPurchasePackageId: payload.userMaleMinutesAutoPurchasePackageId,
            userMaleMinutesAutoPurchaseCardLastNumbers: payload.userMaleMinutesAutoPurchaseCardLastNumbers,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showWithdrawalLoadingError: IUiViewmodel['showWithdrawalLoadingError'] = (payload: IErrorEntity): void => {
    try {
      switch (payload.code) {
        case 132:
        case 110:
        case 141:
          this.store.commit(
            'updateUi',
            {
              flags: Object.freeze({
                ...this.store.state.ui.flags,
                isWithdrawalRequestErrorAttentionDisplayed: true,
                isWithdrawalRequestLoadControlsDisabled: false,
              }),
              buffer: Object.freeze({
                ...this.store.state.ui.buffer,
                withdrawalRequestError: payload.code,
              }),
            },
          );

          break;
        default:
          throw new Error(`unknown error code: ${payload.code}`);
      }
    } catch (error) {
      throw error;
    }
  }

  showPurchaseUnavailable: IUiViewmodel['showPurchaseUnavailable'] = (): void => {
    try {
      this.setFlagProperty('isPurchaseUnavailableAttentionDisplayed', true);
    } catch (error) {
      throw error;
    }
  }

  showPartnerPaymentBlocking: IUiViewmodel['showPartnerPaymentBlocking'] = (): void => {
    try {
      this.setFlagProperty('isPartnerPaymentBlockAttentionDisplayed', true);
    } catch (error) {
      throw error;
    }
  }

  showAllContactsMessagesRemoval: IUiViewmodel['showAllContactsMessagesRemoval'] = (): void => {
    try {
      this.setFlagProperty('isRemoveAllContactsMessagesAttentionDisplayed', true);
    } catch (error) {
      throw error;
    }
  }

  showWithdrawalEpayserviceNumberServerValidationError: IUiViewmodel['showWithdrawalEpayserviceNumberServerValidationError'] = (
    payload: IErrorEntity,
  ): void => {
    try {
      switch (payload.code) {
        case 302:
          this.setBufferProperty(
            'withdrawalRequestEpayserviceNumberValidationError',
            EWithdrawalRequestEpayserviceNumberValidationError.INCORRECT,
          );

          break;
        case 303:
          this.setBufferProperty(
            'withdrawalRequestEpayserviceNumberValidationError',
            EWithdrawalRequestEpayserviceNumberValidationError.EMPTY,
          );

          break;
        default:
          throw new Error(`unknown error code: ${payload.code}`);
      }
    } catch (error) {
      throw error;
    }
  }

  showWithdrawalEpayserviceNumberClientValidationError: IUiViewmodel['showWithdrawalEpayserviceNumberClientValidationError'] = (
    payload: IErrorEntity,
  ): void => {
    try {
      this.setBufferProperty('withdrawalRequestEpayserviceNumberValidationError', payload.code);
    } catch (error) {
      throw error;
    }
  }

  hideWithdrawalEpayserviceNumberValidationError:
    IUiViewmodel['hideWithdrawalEpayserviceNumberValidationError'] = (): void => {
      try {
        this.setBufferProperty('withdrawalRequestEpayserviceNumberValidationError', undefined);
      } catch (error) {
        throw error;
      }
    }

  showWithdrawalYandexNumberServerValidationError: IUiViewmodel['showWithdrawalYandexNumberServerValidationError'] = (
    payload: IErrorEntity,
  ): void => {
    try {
      switch (payload.code) {
        case 302:
          this.setBufferProperty(
            'withdrawalRequestYandexNumberValidationError',
            EWithdrawalRequestYandexNumberValidationError.INCORRECT,
          );

          break;
        case 303:
          this.setBufferProperty(
            'withdrawalRequestYandexNumberValidationError',
            EWithdrawalRequestYandexNumberValidationError.EMPTY,
          );

          break;
        default:
          throw new Error(`unknown error code: ${payload.code}`);
      }
    } catch (error) {
      throw error;
    }
  }

  showWithdrawalBitcoinNumberServerValidationError: IUiViewmodel['showWithdrawalBitcoinNumberServerValidationError'] = (
    payload: IErrorEntity,
  ): void => {
    try {
      switch (payload.code) {
        case 302:
          this.setBufferProperty(
            'withdrawalRequestBitcoinNumberValidationError',
            EWithdrawalRequestBitcoinNumberValidationError.INCORRECT,
          );

          break;
        case 303:
          this.setBufferProperty(
            'withdrawalRequestBitcoinNumberValidationError',
            EWithdrawalRequestBitcoinNumberValidationError.EMPTY,
          );

          break;
        default:
          throw new Error(`unknown error code: ${payload.code}`);
      }
    } catch (error) {
      throw error;
    }
  }

  showWithdrawalYandexNumberClientValidationError: IUiViewmodel['showWithdrawalYandexNumberClientValidationError'] = (
    payload: IErrorEntity,
  ): void => {
    try {
      this.setBufferProperty('withdrawalRequestYandexNumberValidationError', payload.code);
    } catch (error) {
      throw error;
    }
  }

  showWithdrawalBitcoinNumberClientValidationError: IUiViewmodel['showWithdrawalBitcoinNumberClientValidationError'] = (
    payload: IErrorEntity,
  ): void => {
    try {
      this.setBufferProperty('withdrawalRequestBitcoinNumberValidationError', payload.code);
    } catch (error) {
      throw error;
    }
  }

  hideWithdrawalYandexNumberValidationError: IUiViewmodel['hideWithdrawalYandexNumberValidationError'] = (): void => {
    try {
      this.setBufferProperty('withdrawalRequestYandexNumberValidationError', undefined);
    } catch (error) {
      throw error;
    }
  }

  hideWithdrawalBitcoinNumberValidationError: IUiViewmodel['hideWithdrawalBitcoinNumberValidationError'] = (): void => {
    try {
      this.setBufferProperty('withdrawalRequestBitcoinNumberValidationError', undefined);
    } catch (error) {
      throw error;
    }
  }

  showWithdrawalWebmoneyNumberServerValidationError: IUiViewmodel['showWithdrawalWebmoneyNumberServerValidationError'] = (
    payload: IErrorEntity,
  ): void => {
    try {
      switch (payload.code) {
        case 302:
          this.setBufferProperty(
            'withdrawalRequestWebmoneyNumberValidationError',
            EWithdrawalRequestWebmoneyNumberValidationError.INCORRECT,
          );

          break;
        case 303:
          this.setBufferProperty(
            'withdrawalRequestWebmoneyNumberValidationError',
            EWithdrawalRequestWebmoneyNumberValidationError.EMPTY,
          );

          break;
        default:
          throw new Error(`unknown error code: ${payload.code}`);
      }
    } catch (error) {
      throw error;
    }
  }

  showWithdrawalWebmoneyNumberClientValidationError:
    IUiViewmodel['showWithdrawalWebmoneyNumberClientValidationError'] = (payload: IErrorEntity): void => {
      try {
        this.setBufferProperty('withdrawalRequestWebmoneyNumberValidationError', payload.code);
      } catch (error) {
        throw error;
      }
    }

  hideWithdrawalWebmoneyNumberValidationError:
    IUiViewmodel['hideWithdrawalWebmoneyNumberValidationError'] = (): void => {
      try {
        this.setBufferProperty('withdrawalRequestWebmoneyNumberValidationError', undefined);
      } catch (error) {
        throw error;
      }
    }

  showWithdrawalQiwiNumberServerValidationError: IUiViewmodel['showWithdrawalQiwiNumberServerValidationError'] = (
    payload: IErrorEntity,
  ): void => {
    try {
      switch (payload.code) {
        case 302:
          this.setBufferProperty(
            'withdrawalRequestQiwiNumberValidationError',
            EWithdrawalRequestQiwiNumberValidationError.INCORRECT,
          );

          break;
        case 303:
          this.setBufferProperty(
            'withdrawalRequestQiwiNumberValidationError',
            EWithdrawalRequestQiwiNumberValidationError.EMPTY,
          );

          break;
        default:
          throw new Error(`unknown error code: ${payload.code}`);
      }
    } catch (error) {
      throw error;
    }
  }

  showWithdrawalQiwiNumberClientValidationError: IUiViewmodel['showWithdrawalQiwiNumberClientValidationError'] = (
    payload: IErrorEntity,
  ): void => {
    try {
      this.setBufferProperty('withdrawalRequestQiwiNumberValidationError', payload.code);
    } catch (error) {
      throw error;
    }
  }

  hideWithdrawalQiwiNumberValidationError: IUiViewmodel['hideWithdrawalQiwiNumberValidationError'] = (): void => {
    try {
      this.setBufferProperty('withdrawalRequestQiwiNumberValidationError', undefined);
    } catch (error) {
      throw error;
    }
  }

  showWithdrawalCardServerValidationError: IUiViewmodel['showWithdrawalCardServerValidationError'] = (
    payload: IErrorEntity,
  ): void => {
    try {
      switch (payload.code) {
        case 302:
          this.setBufferProperty(
            'withdrawalRequestCardNumberValidationError',
            EWithdrawalRequestCardNumberValidationError.INCORRECT,
          );

          break;
        case 303:
          this.setBufferProperty(
            'withdrawalRequestCardNumberValidationError',
            EWithdrawalRequestCardNumberValidationError.EMPTY,
          );

          break;
        case 315:
          this.setBufferProperty(
            'withdrawalRequestCardNumberValidationError',
            EWithdrawalRequestCardNumberValidationError.WRONG_EMITTER_COUNTRY,
          );

          break;
        case 304:
        case 305:
          this.setBufferProperty(
            'withdrawalRequestCardExpirationValidationError',
            EWithdrawalRequestCardExpirationValidationError.INCORRECT,
          );

          break;
        case 306:
          this.setBufferProperty(
            'withdrawalRequestCardCurrencyValidationError',
            EWithdrawalRequestCardCurrencyValidationError.INCORRECT,
          );

          break;
        default:
          throw new Error(`unknown error code: ${payload.code}`);
      }
    } catch (error) {
      throw error;
    }
  }

  showWithdrawalCardNumberClientValidationError: IUiViewmodel['showWithdrawalCardNumberClientValidationError'] = (
    payload: IErrorEntity,
  ): void => {
    try {
      this.setBufferProperty('withdrawalRequestCardNumberValidationError', payload.code);
    } catch (error) {
      throw error;
    }
  }

  showWithdrawalCardExpirationClientValidationError:
    IUiViewmodel['showWithdrawalCardExpirationClientValidationError'] = (payload: IErrorEntity): void => {
      try {
        this.setBufferProperty('withdrawalRequestCardExpirationValidationError', payload.code);
      } catch (error) {
        throw error;
      }
    }

  showWithdrawalCardCurrencyClientValidationError: IUiViewmodel['showWithdrawalCardCurrencyClientValidationError'] = (
    payload: IErrorEntity,
  ): void => {
    try {
      this.setBufferProperty('withdrawalRequestCardCurrencyValidationError', payload.code);
    } catch (error) {
      throw error;
    }
  }

  hideWithdrawalCardValidationError: IUiViewmodel['hideWithdrawalCardValidationError'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          buffer: Object.freeze({
            ...this.store.state.ui.buffer,
            withdrawalRequestCardNumberValidationError: undefined,
            withdrawalRequestCardExpirationValidationError: undefined,
            withdrawalRequestCardCurrencyValidationError: undefined,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  hideWithdrawalBankFirstStepValidationErrors: IUiViewmodel['hideWithdrawalBankFirstStepValidationErrors'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          buffer: Object.freeze({
            ...this.store.state.ui.buffer,
            withdrawalRequestBankAccountHolderDocumentNumberValidationError: undefined,
            withdrawalRequestBankAccountHolderFirstNameValidationError: undefined,
            withdrawalRequestBankAccountHolderLastNameValidationError: undefined,
            withdrawalRequestBankAccountHolderAddressValidationError: undefined,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  hideWithdrawalBankSecondStepValidationErrors: IUiViewmodel['hideWithdrawalBankSecondStepValidationErrors'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          buffer: Object.freeze({
            ...this.store.state.ui.buffer,
            withdrawalRequestBankBranchCodeValidationError: undefined,
            withdrawalRequestBankAccountNumberValidationError: undefined,
            withdrawalRequestBankAccountHolderPhoneValidationError: undefined,
            withdrawalRequestBankAccountHolderEmailValidationError: undefined,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showWithdrawalRequestBankAccountHolderDocumentNumberValidationError: IUiViewmodel['showWithdrawalRequestBankAccountHolderDocumentNumberValidationError'] = (
    payload: IErrorEntity,
  ): void => {
    try {
      this.setBufferProperty('withdrawalRequestBankAccountHolderDocumentNumberValidationError', payload.code);
    } catch (error) {
      throw error;
    }
  }

  showWithdrawalRequestBankAccountHolderFirstNameValidationError: IUiViewmodel['showWithdrawalRequestBankAccountHolderFirstNameValidationError'] = (
    payload: IErrorEntity,
  ): void => {
    try {
      this.setBufferProperty('withdrawalRequestBankAccountHolderFirstNameValidationError', payload.code);
    } catch (error) {
      throw error;
    }
  }

  showWithdrawalRequestBankAccountHolderLastNameValidationError: IUiViewmodel['showWithdrawalRequestBankAccountHolderLastNameValidationError'] = (
    payload: IErrorEntity,
  ): void => {
    try {
      this.setBufferProperty('withdrawalRequestBankAccountHolderLastNameValidationError', payload.code);
    } catch (error) {
      throw error;
    }
  }

  showWithdrawalRequestBankAccountHolderAddressValidationError: IUiViewmodel['showWithdrawalRequestBankAccountHolderAddressValidationError'] = (
    payload: IErrorEntity,
  ): void => {
    try {
      this.setBufferProperty('withdrawalRequestBankAccountHolderAddressValidationError', payload.code);
    } catch (error) {
      throw error;
    }
  }

  showWithdrawalRequestBankBranchCodeValidationError: IUiViewmodel['showWithdrawalRequestBankBranchCodeValidationError'] = (
    payload: IErrorEntity,
  ): void => {
    try {
      this.setBufferProperty('withdrawalRequestBankBranchCodeValidationError', payload.code);
    } catch (error) {
      throw error;
    }
  }

  showWithdrawalRequestBankAccountNumberValidationError: IUiViewmodel['showWithdrawalRequestBankAccountNumberValidationError'] = (
    payload: IErrorEntity,
  ): void => {
    try {
      this.setBufferProperty('withdrawalRequestBankAccountNumberValidationError', payload.code);
    } catch (error) {
      throw error;
    }
  }

  showWithdrawalRequestBankAccountHolderPhoneValidationError: IUiViewmodel['showWithdrawalRequestBankAccountHolderPhoneValidationError'] = (
    payload: IErrorEntity,
  ): void => {
    try {
      this.setBufferProperty('withdrawalRequestBankAccountHolderPhoneValidationError', payload.code);
    } catch (error) {
      throw error;
    }
  }

  showWithdrawalRequestBankAccountHolderEmailValidationError: IUiViewmodel['showWithdrawalRequestBankAccountHolderEmailValidationError'] = (
    payload: IErrorEntity,
  ): void => {
    try {
      this.setBufferProperty('withdrawalRequestBankAccountHolderEmailValidationError', payload.code);
    } catch (error) {
      throw error;
    }
  }

  showWithdrawalBankSecondStepInsteadFirst: IUiViewmodel['showWithdrawalBankSecondStepInsteadFirst'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isWithdrawalBankFirstStepDisplayed: false,
            isWithdrawalBankSecondStepDisplayed: true,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  hideWithdrawalBankFirstStep: IUiViewmodel['hideWithdrawalBankFirstStep'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isWithdrawalBankFirstStepDisplayed: false,
          }),
          buffer: Object.freeze({
            ...this.store.state.ui.buffer,
            withdrawalRequestBankAccountHolderDocumentNumberValidationError: undefined,
            withdrawalRequestBankAccountHolderFirstNameValidationError: undefined,
            withdrawalRequestBankAccountHolderLastNameValidationError: undefined,
            withdrawalRequestBankAccountHolderAddressValidationError: undefined,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  hideWithdrawalBankSecondStep: IUiViewmodel['hideWithdrawalBankSecondStep'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isWithdrawalBankSecondStepDisplayed: false,
          }),
          buffer: Object.freeze({
            ...this.store.state.ui.buffer,
            withdrawalRequestBankBranchCodeValidationError: undefined,
            withdrawalRequestBankAccountNumberValidationError: undefined,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showWithdrawalStartInsteadFirstStep: IUiViewmodel['showWithdrawalStartInsteadFirstStep'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isWithdrawalBankFirstStepDisplayed: false,
            isStartWithdrawalAttentionDisplayed: true,
          }),
          buffer: Object.freeze({
            ...this.store.state.ui.buffer,
            withdrawalRequestBankAccountHolderDocumentNumberValidationError: undefined,
            withdrawalRequestBankAccountHolderFirstNameValidationError: undefined,
            withdrawalRequestBankAccountHolderLastNameValidationError: undefined,
            withdrawalRequestBankAccountHolderAddressValidationError: undefined,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showWithdrawalFirstStepInsteadSecond: IUiViewmodel['showWithdrawalFirstStepInsteadSecond'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isWithdrawalBankSecondStepDisplayed: false,
            isWithdrawalBankFirstStepDisplayed: true,
          }),
          buffer: Object.freeze({
            ...this.store.state.ui.buffer,
            withdrawalRequestBankBranchCodeValidationError: undefined,
            withdrawalRequestBankAccountNumberValidationError: undefined,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showWithdrawalConfirmationInsteadBankSecondStep: IUiViewmodel['showWithdrawalConfirmationInsteadBankSecondStep'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isWithdrawalBankSecondStepDisplayed: false,
            isWithdrawalRequestSendControlsDisabled: false,
            isWithdrawalConfirmationAttentionDisplayed: true,
          }),
          buffer: Object.freeze({
            ...this.store.state.ui.buffer,
            withdrawalRequestBankBranchCodeValidationError: undefined,
            withdrawalRequestBankAccountNumberValidationError: undefined,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showWithdrawalFinishInsteadBankSecondStep: IUiViewmodel['showWithdrawalFinishInsteadBankSecondStep'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isWithdrawalBankSecondStepDisplayed: false,
            isWithdrawalRequestSendControlsDisabled: false,
            isFinishWithdrawalAttentionDisplayed: true,
          }),
          buffer: Object.freeze({
            ...this.store.state.ui.buffer,
            withdrawalRequestBankBranchCodeValidationError: undefined,
            withdrawalRequestBankAccountNumberValidationError: undefined,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showWithdrawalConfirmationInsteadCardAddress: IUiViewmodel['showWithdrawalConfirmationInsteadCardAddress'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isWithdrawalRequestSendControlsDisabled: false,
            isWithdrawalCardAddressAttentionDisplayed: false,
            isWithdrawalConfirmationAttentionDisplayed: true,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showWithdrawalFinishInsteadCardAddress: IUiViewmodel['showWithdrawalFinishInsteadCardAddress'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isWithdrawalRequestSendControlsDisabled: false,
            isWithdrawalCardAddressAttentionDisplayed: false,
            isFinishWithdrawalAttentionDisplayed: true,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showWithdrawalFinishInsteadCardRequisites: IUiViewmodel['showWithdrawalFinishInsteadCardRequisites'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isWithdrawalRequestSendControlsDisabled: false,
            isWithdrawalCardRequisitesAttentionDisplayed: false,
            isFinishWithdrawalAttentionDisplayed: true,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showWithdrawalConfirmationInsteadEpayserviceRequisites: IUiViewmodel['showWithdrawalConfirmationInsteadEpayserviceRequisites'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isWithdrawalRequestSendControlsDisabled: false,
            isWithdrawalEpayserviceRequisitesAttentionDisplayed: false,
            isWithdrawalConfirmationAttentionDisplayed: true,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showWithdrawalFinishInsteadEpayserviceRequisites: IUiViewmodel['showWithdrawalFinishInsteadEpayserviceRequisites'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isWithdrawalRequestSendControlsDisabled: false,
            isFinishWithdrawalAttentionDisplayed: true,
            isWithdrawalEpayserviceRequisitesAttentionDisplayed: false,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showWithdrawalConfirmationInsteadQiwiRequisites: IUiViewmodel['showWithdrawalConfirmationInsteadQiwiRequisites'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isWithdrawalRequestSendControlsDisabled: false,
            isWithdrawalQiwiRequisitesAttentionDisplayed: false,
            isWithdrawalConfirmationAttentionDisplayed: true,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showWithdrawalFinishInsteadQiwiRequisites: IUiViewmodel['showWithdrawalFinishInsteadQiwiRequisites'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isWithdrawalRequestSendControlsDisabled: false,
            isFinishWithdrawalAttentionDisplayed: true,
            isWithdrawalQiwiRequisitesAttentionDisplayed: false,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showWithdrawalFinishInsteadYandexRequisites: IUiViewmodel['showWithdrawalFinishInsteadYandexRequisites'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isWithdrawalRequestSendControlsDisabled: false,
            isFinishWithdrawalAttentionDisplayed: true,
            isWithdrawalYandexRequisitesAttentionDisplayed: false,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showWithdrawalConfirmationInsteadYandexRequisites: IUiViewmodel['showWithdrawalConfirmationInsteadYandexRequisites'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isWithdrawalRequestSendControlsDisabled: false,
            isWithdrawalYandexRequisitesAttentionDisplayed: false,
            isWithdrawalConfirmationAttentionDisplayed: true,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showWithdrawalConfirmationInsteadBitcoinRequisites: IUiViewmodel['showWithdrawalConfirmationInsteadBitcoinRequisites'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isWithdrawalRequestSendControlsDisabled: false,
            isWithdrawalBitcoinRequisitesAttentionDisplayed: false,
            isWithdrawalConfirmationAttentionDisplayed: true,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showWithdrawalFinishInsteadBitcoinRequisites: IUiViewmodel['showWithdrawalFinishInsteadBitcoinRequisites'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isWithdrawalRequestSendControlsDisabled: false,
            isFinishWithdrawalAttentionDisplayed: true,
            isWithdrawalBitcoinRequisitesAttentionDisplayed: false,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showWithdrawalConfirmationInsteadWebmoneyRequisites: IUiViewmodel['showWithdrawalConfirmationInsteadWebmoneyRequisites'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isWithdrawalRequestSendControlsDisabled: false,
            isWithdrawalWebmoneyRequisitesAttentionDisplayed: false,
            isWithdrawalConfirmationAttentionDisplayed: true,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showWithdrawalFinishInsteadWebmoneyRequisites: IUiViewmodel['showWithdrawalFinishInsteadWebmoneyRequisites'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isWithdrawalRequestSendControlsDisabled: false,
            isFinishWithdrawalAttentionDisplayed: true,
            isWithdrawalWebmoneyRequisitesAttentionDisplayed: false,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showWithdrawalCardAddressInsteadHolder: IUiViewmodel['showWithdrawalCardAddressInsteadHolder'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isWithdrawalCardHolderAttentionDisplayed: false,
            isWithdrawalCardAddressAttentionDisplayed: true,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showWithdrawalCardHolderInsteadRequisites: IUiViewmodel['showWithdrawalCardHolderInsteadRequisites'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isWithdrawalCardRequisitesAttentionDisplayed: false,
            isWithdrawalCardHolderAttentionDisplayed: true,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showPartnerPaymentBlockingInsteadPurchases: IUiViewmodel['showPartnerPaymentBlockingInsteadPurchases'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isMinutesPurchaseAttentionDisplayed: false,
            isPremiumPurchaseAttentionDisplayed: false,
            isPartnerPaymentBlockAttentionDisplayed: true,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showPartnerPaymentBlockingInsteadPremiumPurchase:
    IUiViewmodel['showPartnerPaymentBlockingInsteadPremiumPurchase'] = (): void => {
      try {
        this.store.commit(
          'updateUi',
          {
            ...this.store.state.ui,
            flags: Object.freeze({
              ...this.store.state.ui.flags,
              isPremiumPurchaseAttentionDisplayed: false,
              isPartnerPaymentBlockAttentionDisplayed: true,
            }),
          },
        );
      } catch (error) {
        throw error;
      }
    }

  showMinutesPurchaseInsteadAutoPurchaseUnavailable:
    IUiViewmodel['showMinutesPurchaseInsteadAutoPurchaseUnavailable'] = (): void => {
      try {
        this.store.commit(
          'updateUi',
          {
            ...this.store.state.ui,
            flags: Object.freeze({
              ...this.store.state.ui.flags,
              isMinutesAutoPurchaseUnavailableAttentionDisplayed: false,
              isMinutesPurchaseAttentionDisplayed: true,
            }),
          },
        );
      } catch (error) {
        throw error;
      }
    }

  showPremiumPurchaseInsteadContactRemoval: IUiViewmodel['showPremiumPurchaseInsteadContactRemoval'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isPremiumPurchaseAttentionDisplayed: true,
            isContactRemoveAttentionDisplayed: false,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showTopLeader: IUiViewmodel['showTopLeader'] = (payload: ETopPeriod): void => {
    try {
      switch (payload) {
        case ETopPeriod.DAY:
          this.setFlagProperty('isTopDayLeaderAttentionDisplayed', true);

          break;
        case ETopPeriod.WEEK:
          this.setFlagProperty('isTopWeekLeaderAttentionDisplayed', true);

          break;
        case ETopPeriod.MONTH:
          this.setFlagProperty('isTopMonthLeaderAttentionDisplayed', true);

          break;
        default:
          throw new Error(`unknown top period: ${payload}`);
      }
    } catch (error) {
      throw error;
    }
  }

  showCapturedAvatar: IUiViewmodel['showCapturedAvatar'] = (
    payload: IViewmodel['ui']['buffer']['userTemporaryAvatar'],
  ): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          buffer: Object.freeze({
            ...this.store.state.ui.buffer,
            userTemporaryAvatar: payload,
            userAvatarEditState: EUserAvatarEditState.MAKED,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showSelectedAvatarAndHideValidationError: IUiViewmodel['showSelectedAvatarAndHideValidationError'] = (
    payload: IViewmodel['ui']['buffer']['userTemporaryAvatar'],
  ): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          buffer: Object.freeze({
            ...this.store.state.ui.buffer,
            userTemporaryAvatar: payload,
            userAvatarEditState: EUserAvatarEditState.SELECTED,
            userAvatarValidationError: undefined,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showAvatarSavingError: IUiViewmodel['showAvatarSavingError'] = (
    payload: IErrorEntity,
  ): void => {
    try {
      switch (payload.code) {
        case EUserAvatarUploadError.LONG_REQUEST:
        case EUserAvatarUploadError.FORMAT_DENIED:
        case EUserAvatarUploadError.CORRUPTED:
          this.store.commit(
            'updateUi',
            {
              flags: Object.freeze({
                ...this.store.state.ui.flags,
                isAvatarUploadErrorAttentionDisplayed: true,
              }),
              buffer: Object.freeze({
                ...this.store.state.ui.buffer,
                userTemporaryAvatar: undefined,
                userAvatarEditState: undefined,
                userAvatarUploadError: payload.code,
              }),
            },
          );

          break;
        default:
          throw new Error(`unknown error code: ${payload.code}`);
      }
    } catch (error) {
      throw error;
    }
  }

  showWithdrawalCardHolderServerValidationError: IUiViewmodel['showWithdrawalCardHolderServerValidationError'] = (
    payload: IErrorEntity,
  ): void => {
    try {
      switch (payload.code) {
        case 307:
          this.setBufferProperty(
            'withdrawalRequestCardHolderFirstNameValidationError',
            EWithdrawalRequestCardHolderFirstNameValidationError.INCORRECT,
          );

          break;
        case 308:
          this.setBufferProperty(
            'withdrawalRequestCardHolderLastNameValidationError',
            EWithdrawalRequestCardHolderLastNameValidationError.INCORRECT,
          );

          break;
        case 309:
          this.setBufferProperty(
            'withdrawalRequestCardHolderBirthdayValidationError',
            EWithdrawalRequestCardHolderBirthdayValidationError.INCORRECT,
          );

          break;
        case 310:
          this.setBufferProperty(
            'withdrawalRequestCardHolderBirthdayValidationError',
            EWithdrawalRequestCardHolderBirthdayValidationError.YOUNG,
          );

          break;
        default:
          throw new Error(`unknown error code: ${payload.code}`);
      }
    } catch (error) {
      throw error;
    }
  }

  showWithdrawalCardHolderFirstNameClientValidationError:
    IUiViewmodel['showWithdrawalCardHolderFirstNameClientValidationError'] = (payload: IErrorEntity): void => {
      try {
        this.setBufferProperty('withdrawalRequestCardHolderFirstNameValidationError', payload.code);
      } catch (error) {
        throw error;
      }
    }

  showWithdrawalCardHolderLastNameClientValidationError:
    IUiViewmodel['showWithdrawalCardHolderLastNameClientValidationError'] = (payload: IErrorEntity): void => {
      try {
        this.setBufferProperty('withdrawalRequestCardHolderLastNameValidationError', payload.code);
      } catch (error) {
        throw error;
      }
    }

  showWithdrawalCardHolderBirthdayClientValidationError:
    IUiViewmodel['showWithdrawalCardHolderBirthdayClientValidationError'] = (payload: IErrorEntity): void => {
      try {
        this.setBufferProperty('withdrawalRequestCardHolderBirthdayValidationError', payload.code);
      } catch (error) {
        throw error;
      }
    }

  hideWithdrawalCardHolderValidationError: IUiViewmodel['hideWithdrawalCardHolderValidationError'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          buffer: Object.freeze({
            ...this.store.state.ui.buffer,
            withdrawalRequestCardHolderFirstNameValidationError: undefined,
            withdrawalRequestCardHolderLastNameValidationError: undefined,
            withdrawalRequestCardHolderBirthdayValidationError: undefined,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  disableWithdrawalSendingAndRemoveError: IUiViewmodel['disableWithdrawalSendingAndRemoveError'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isWithdrawalRequestSendControlsDisabled: true,
          }),
          buffer: Object.freeze({
            ...this.store.state.ui.buffer,
            withdrawalRequestError: undefined,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  asyncShowBan: IUiViewmodel['asyncShowBan'] = async (payload: IBanEntity): Promise<void> => {
    try {
      if (payload.status === EBanStatus.AGE) {
        let userVerificationStage;

        switch (payload.verificationStatus) {
          case EUserVerificationStatus.UNVERIFIED:
            userVerificationStage = EUserVerificationStage.UNVERIFIED;

            break;
          case EUserVerificationStatus.WAITING:
            userVerificationStage = EUserVerificationStage.WAITING;

            break;
          case EUserVerificationStatus.CORRUPTED:
          case EUserVerificationStatus.FAKE_DOCUMENT:
          case EUserVerificationStatus.NO_DATE:
          case EUserVerificationStatus.NO_FACE:
          case EUserVerificationStatus.NO_DOCUMENT:
          case EUserVerificationStatus.ALIEN_FACE:
          case EUserVerificationStatus.ALIEN_DOCUMENT:
          case EUserVerificationStatus.BAD_PHOTO:
          case EUserVerificationStatus.UNDER_AGE:
          case EUserVerificationStatus.ACCOUNT_EXIST:
          case EUserVerificationStatus.BAD_NAME:
          case EUserVerificationStatus.NO_QUALITY_FACE:
          case EUserVerificationStatus.ALIEN_IN_FRAME:
          case EUserVerificationStatus.REMOVE_GLASSES:
            userVerificationStage = EUserVerificationStage.REJECTED;

            break;
          default:
            throw new Error(`unknown verification status: ${payload.verificationStatus}`);
        }

        this.store.commit(
          'updateUi',
          {
            flags: Object.freeze({
              ...this.store.state.ui.flags,
              videochatPageStage: EVideochatPageStage.FINISH,
              isUserVerificationLockDisplayed: true,
            }),
            buffer: Object.freeze({
              ...this.store.state.ui.buffer,
              userVerificationStage,
            }),
          },
        );

        return;
      }

      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            videochatPageStage: EVideochatPageStage.FINISH,
            isUserBanAttentionDisplayed: true,
          }),
        },
      );

      if (
        payload.isStrict
        && router.currentRoute.value.name !== 'videochat'
      ) {
        await router.push({ name: 'videochat' });
      }
    } catch (error) {
      throw error;
    }
  }

  hideBan: IUiViewmodel['hideBan'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            videochatPageStage: EVideochatPageStage.START,
            isUserVerificationLockDisplayed: false,
            isUserBanAttentionDisplayed: false,
          }),
          buffer: Object.freeze({
            ...this.store.state.ui.buffer,
            userVerificationStage: undefined,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  updateUserStoppedVideochat: IUiViewmodel['updateUserStoppedVideochat'] = (
    payload: IViewmodel['ui']['buffer']['isUserStoppedVideochat'],
  ): void => {
    try {
      this.setBufferProperty('isUserStoppedVideochat', payload);
    } catch (error) {
      throw error;
    }
  }

  setVideochatSearchPageStage: IUiViewmodel['setVideochatSearchPageStage'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            videochatPageStage: EVideochatPageStage.PROCESS,
            isVideochatSearchNextControlsDisabled: true,
          }),
          buffer: Object.freeze({
            ...this.store.state.ui.buffer,
            isUserStoppedVideochat: undefined,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showCompanionOutOfMinutes: IUiViewmodel['showCompanionOutOfMinutes'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            videochatPageStage: EVideochatPageStage.FINISH,
            isCompanionOutOfMinutesLockDisplayed: true,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showCompanionLeaveVideocall: IUiViewmodel['showCompanionLeaveVideocall'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            videochatPageStage: EVideochatPageStage.FINISH,
            isCompanionLeaveVideocallLockDisplayed: true,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showCompanionDisconnectVideocall: IUiViewmodel['showCompanionDisconnectVideocall'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            videochatPageStage: EVideochatPageStage.FINISH,
            isCompanionDisconnectVideocallLockDisplayed: true,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showCompanionBannedVideocall: IUiViewmodel['showCompanionBannedVideocall'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            videochatPageStage: EVideochatPageStage.FINISH,
            isCompanionBannedVideocallLockDisplayed: true,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showCompanionHasAnotherVideocall: IUiViewmodel['showCompanionHasAnotherVideocall'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            videochatPageStage: EVideochatPageStage.FINISH,
            isCompanionHasAnotherVideocallLockDisplayed: true,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showCompanionPays: IUiViewmodel['showCompanionPays'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            videochatPageStage: EVideochatPageStage.FINISH,
            isCompanionPaysLockDisplayed: true,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showCompanionRate: IUiViewmodel['showCompanionRate'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            videochatPageStage: EVideochatPageStage.FINISH,
            isCompanionRateLockDisplayed: true,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showFemalePromoVideocall: IUiViewmodel['showFemalePromoVideocall'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            videochatPageStage: EVideochatPageStage.FINISH,
            isFemalePromoVideocallLockDisplayed: true,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showVideochatRecovery: IUiViewmodel['showVideochatRecovery'] = (): void => {
    try {
      this.setFlagProperty('isVideochatRecoveryAttentionDisplayed', true);
    } catch (error) {
      throw error;
    }
  }

  showVideochatRecoveryWaiting: IUiViewmodel['showVideochatRecoveryWaiting'] = (
    payload: IViewmodel['ui']['buffer']['isCompanionRateAfterRecoveryRequired'],
  ): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            videochatPageStage: EVideochatPageStage.START,
            isVideochatRecoveryWaitingAttentionDisplayed: true,
          }),
          buffer: Object.freeze({
            ...this.store.state.ui.buffer,
            isCompanionRateAfterRecoveryRequired: payload,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showVideochatStartAndShowMinutesPurchase: IUiViewmodel['showVideochatStartAndShowMinutesPurchase'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            videochatPageStage: EVideochatPageStage.START,
            isMinutesPurchaseAttentionDisplayed: true,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  updateUserFemalePreviousTariffId: IUiViewmodel['updateUserFemalePreviousTariffId'] = (
    payload: IViewmodel['ui']['buffer']['userFemalePreviousTariffId'],
  ): void => {
    try {
      this.setBufferProperty('userFemalePreviousTariffId', payload);
    } catch (error) {
      throw error;
    }
  }

  showCompanionPervert: IUiViewmodel['showCompanionPervert'] = (): void => {
    try {
      this.setFlagProperty('isCompanionPervertAttentionDisplayed', true);
    } catch (error) {
      throw error;
    }
  }

  enableVideochatControlsAutoHiding: IUiViewmodel['enableVideochatControlsAutoHiding'] = (): void => {
    try {
      this.setFlagProperty('isVideochatControlsAutoHidingEnabled', true);
    } catch (error) {
      throw error;
    }
  }

  disableVideochatSearchNext: IUiViewmodel['disableVideochatSearchNext'] = (): void => {
    try {
      this.setFlagProperty('isVideochatSearchNextControlsDisabled', true);
    } catch (error) {
      throw error;
    }
  }

  showIncomingCall: IUiViewmodel['showIncomingCall'] = (): void => {
    try {
      this.setFlagProperty('isIncomingCallAttentionDisplayed', true);
    } catch (error) {
      throw error;
    }
  }

  hideIncomingCall: IUiViewmodel['hideIncomingCall'] = (): void => {
    try {
      this.setFlagProperty('isIncomingCallAttentionDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  disableIncomingCall: IUiViewmodel['disableIncomingCall'] = (): void => {
    try {
      this.setFlagProperty('isIncomingCallControlsDisabled', true);
    } catch (error) {
      throw error;
    }
  }

  showVideochatRecoveryFail: IUiViewmodel['showVideochatRecoveryFail'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            videochatPageStage: EVideochatPageStage.START,
            isVideochatRecoveryAttentionDisplayed: false,
            isVideochatRecoveryFailAttentionDisplayed: true,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showFaceNotDetected: IUiViewmodel['showFaceNotDetected'] = (): void => {
    try {
      this.setFlagProperty('shouldShowFaceNotDetectedAttention', true);
    } catch (error) {
      throw error;
    }
  }

  hideSignInAndSignUpAndSignUpAppeal: IUiViewmodel['hideSignInAndSignUpAndSignUpAppeal'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isSignInAttentionDisplayed: false,
            isSignUpAttentionDisplayed: false,
            isSignUpAppealAttentionDisplayed: false,
          }),
          buffer: Object.freeze({
            ...this.store.state.ui.buffer,
            userSignUpEmailValidationError: undefined,
            userSignUpPasswordValidationError: undefined,
            userSignInEmailValidationError: undefined,
            userSignInPasswordValidationError: undefined,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  enableReferralsLoading: IUiViewmodel['enableReferralsLoading'] = (): void => {
    try {
      this.setFlagProperty('isReferralsLoadingDisabled', false);
    } catch (error) {
      throw error;
    }
  }

  disableReferralsLoading: IUiViewmodel['disableReferralsLoading'] = (): void => {
    try {
      this.setFlagProperty('isReferralsLoadingDisabled', true);
    } catch (error) {
      throw error;
    }
  }

  showTariffsAndStatisticsAndDisable: IUiViewmodel['showTariffsAndStatisticsAndDisable'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isTariffsAndStatisticsAttentionDisplayed: true,
            isTariffsControlsDisabled: true,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  enableTariffs: IUiViewmodel['enableTariffs'] = (): void => {
    try {
      this.setFlagProperty('isTariffsControlsDisabled', false);
    } catch (error) {
      throw error;
    }
  }

  showSignUpComplete: IUiViewmodel['showSignUpComplete'] = (): void => {
    try {
      this.setFlagProperty('isCompleteSignUpAttentionDisplayed', true);
    } catch (error) {
      throw error;
    }
  }

  hidePreviousVideochatEnding: IUiViewmodel['hidePreviousVideochatEnding'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isCompanionLeaveVideocallLockDisplayed: false,
            isCompanionBannedVideocallLockDisplayed: false,
            isCompanionOutOfMinutesLockDisplayed: false,
            isCompanionRateLockDisplayed: false,
            isCompanionHasAnotherVideocallLockDisplayed: false,
            isCompanionReviewLockDisplayed: false,
            isCompanionReviewSentLockDisplayed: false,
            isCompanionGiftLockDisplayed: false,
            isFemalePromoVideocallLockDisplayed: false,
            isCompanionPaysLockDisplayed: false,
            isVideochatRecoveryAttentionDisplayed: false,
            isVideochatRecoveryFailAttentionDisplayed: false,
            isVideochatRecoveryWaitingAttentionDisplayed: false,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showChangedEmailConfirmation: IUiViewmodel['showChangedEmailConfirmation'] = (): void => {
    try {
      this.setFlagProperty('isUserEmailHasChangedAttentionDisplayed', true);
    } catch (error) {
      throw error;
    }
  }

  showPromoBonus: IUiViewmodel['showPromoBonus'] = (
    payload: IViewmodel['ui']['buffer']['promoBonusContactId'],
  ): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isPromoBonusAttentionDisplayed: true,
          }),
          buffer: Object.freeze({
            ...this.store.state.ui.buffer,
            promoBonusContactId: payload,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showMinutesPurchaseInsteadAutoPurchaseTerms: IUiViewmodel['showMinutesPurchaseInsteadAutoPurchaseTerms'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isMinutesAutoPurchaseTermsAttentionDisplayed: false,
            isMinutesPurchaseAttentionDisplayed: true,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showMinutesPurchaseInsteadEnabledAutoPurchase: IUiViewmodel['showMinutesPurchaseInsteadEnabledAutoPurchase'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isMinutesAutoPurchaseTermsAttentionDisplayed: false,
            isMinutesPurchaseAttentionDisplayed: true,
          }),
          buffer: Object.freeze({
            ...this.store.state.ui.buffer,
            userMaleMinutesAutoPurchasePackageId: undefined,
            userMaleMinutesAutoPurchaseCardLastNumbers: undefined,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  hideMinutesPurchaseAndAutoPurchaseTerms: IUiViewmodel['hideMinutesPurchaseAndAutoPurchaseTerms'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isMinutesAutoPurchaseTermsAttentionDisplayed: false,
            isMinutesPurchaseAttentionDisplayed: false,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showMinutesAutoPurchaseUnavailable: IUiViewmodel['showMinutesAutoPurchaseUnavailable'] = (): void => {
    try {
      this.setFlagProperty('isMinutesAutoPurchaseUnavailableAttentionDisplayed', true);
    } catch (error) {
      throw error;
    }
  }

  showMinutesAutoPurchaseFailed: IUiViewmodel['showMinutesAutoPurchaseFailed'] = (): void => {
    try {
      if (this.store.state.ui.flags.isMinutesPurchaseAttentionDisplayed) {
        return;
      }

      this.setFlagProperty('isMinutesAutoPurchaseFailedAttentionDisplayed', true);
    } catch (error) {
      throw error;
    }
  }

  hideNotEnoughMinutes: IUiViewmodel['hideNotEnoughMinutes'] = (): void => {
    try {
      this.setFlagProperty('isNotEnoughMinutesAttentionDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  hideNotEnoughMinutesAndEnable: IUiViewmodel['hideNotEnoughMinutesAndEnable'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isNotEnoughMinutesAttentionDisplayed: false,
            isNotEnoughMinutesDisabled: false,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  enableNotEnoughMinutes: IUiViewmodel['enableNotEnoughMinutes'] = (): void => {
    try {
      this.setFlagProperty('isNotEnoughMinutesDisabled', false);
    } catch (error) {
      throw error;
    }
  }

  disableNotEnoughMinutes: IUiViewmodel['disableNotEnoughMinutes'] = (): void => {
    try {
      this.setFlagProperty('isNotEnoughMinutesDisabled', true);
    } catch (error) {
      throw error;
    }
  }

  enableAuthorization: IUiViewmodel['enableAuthorization'] = (): void => {
    try {
      this.setFlagProperty('isAuthorizationControlsDisabled', false);
    } catch (error) {
      throw error;
    }
  }

  disableAuthorization: IUiViewmodel['disableAuthorization'] = (): void => {
    try {
      this.setFlagProperty('isAuthorizationControlsDisabled', true);
    } catch (error) {
      throw error;
    }
  }

  showInitialProfileSettings: IUiViewmodel['showInitialProfileSettings'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isUserProfileSettingsFormInInitialState: true,
          }),
          buffer: Object.freeze({
            ...this.store.state.ui.buffer,
            userProfilePasswordValidationError: undefined,
            userProfileEmailValidationError: undefined,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showPasswordRecoverySuccess: IUiViewmodel['showPasswordRecoverySuccess'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isPasswordRecoveryAttentionDisplayed: false,
            isPasswordRecoverySuccessAttentionDisplayed: true,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  hideUnacceptedIncomingCall: IUiViewmodel['hideUnacceptedIncomingCall'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            videochatPageStage: EVideochatPageStage.START,
            isIncomingCallAttentionDisplayed: false,
            isIncomingCallControlsDisabled: false,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  hideAcceptedIncomingCall: IUiViewmodel['hideAcceptedIncomingCall'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            videochatPageStage: EVideochatPageStage.PROCESS,
            isIncomingCallAttentionDisplayed: false,
            isIncomingCallControlsDisabled: false,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showVideochatProgressAndEnableVideochatSearchNext:
    IUiViewmodel['showVideochatProgressAndEnableVideochatSearchNext'] = (): void => {
      try {
        this.store.commit(
          'updateUi',
          {
            ...this.store.state.ui,
            flags: Object.freeze({
              ...this.store.state.ui.flags,
              videochatPageStage: EVideochatPageStage.PROCESS,
              isVideochatSearchNextControlsDisabled: false,
            }),
          },
        );
      } catch (error) {
        throw error;
      }
    }

  removePreviousTariffId: IUiViewmodel['removePreviousTariffId'] = (): void => {
    try {
      this.setBufferProperty('userFemalePreviousTariffId', undefined);
    } catch (error) {
      throw error;
    }
  }

  removeCompanionRateAfterRecoveryRequired: IUiViewmodel['removeCompanionRateAfterRecoveryRequired'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          buffer: Object.freeze({
            ...this.store.state.ui.buffer,
            isCompanionRateAfterRecoveryRequired: undefined,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showCardConfirmationServerValidationError: IUiViewmodel['showCardConfirmationServerValidationError'] = (
    payload: IErrorEntity,
  ): void => {
    try {
      switch (payload.code) {
        case ECardConfirmationError.NO_ATTEMPTS_LEFT:
          this.setBufferProperty(
            'userCardConfirmationValidationError',
            EUserCardConfirmationValidationError.NO_ATTEMPTS_LEFT,
          );

          break;
        case ECardConfirmationError.WRONG_SUM:
          this.setBufferProperty(
            'userCardConfirmationValidationError',
            EUserCardConfirmationValidationError.WRONG_SUM,
          );

          break;
        case ECardConfirmationError.NO_REQUEST:
          this.setFlagProperty('isCardConfirmationLockDisplayed', false);

          break;
        case ECardConfirmationError.IN_PROCESS:
          // Ничего не делаем, процесс идёт
          break;
        default:
          throw new Error(`unknown error code: ${payload.code}`);
      }
    } catch (error) {
      throw error;
    }
  }

  showCardConfirmationClientValidationError: IUiViewmodel['showCardConfirmationClientValidationError'] = (
    payload: IErrorEntity,
  ): void => {
    try {
      this.setBufferProperty('userCardConfirmationValidationError', payload.code);
    } catch (error) {
      throw error;
    }
  }

  showSignUpValidationError: IUiViewmodel['showSignUpValidationError'] = (
    payload: IErrorEntity,
  ): void => {
    try {
      switch (payload.code) {
        case 411:
          this.setBufferProperty('userSignUpEmailValidationError', EUserEmailValidationError.INCORRECT);

          break;
        case 405:
          this.setBufferProperty('userSignUpPasswordValidationError', EUserPasswordValidationError.SHORT);

          break;
        case 401:
          this.setBufferProperty('userSignUpEmailValidationError', EUserEmailValidationError.EMPTY);

          break;
        case 412:
          this.setBufferProperty('userSignUpEmailValidationError', EUserEmailValidationError.EXISTS);

          break;
        case 498:
          this.setBufferProperty('userSignUpEmailValidationError', EUserEmailValidationError.FORBIDDEN);

          break;
        default:
          throw new Error(`unknown error code: ${payload.code}`);
      }
    } catch (error) {
      throw error;
    }
  }

  showSignUpEmailValidationError: IUiViewmodel['showSignUpEmailValidationError'] = (
    payload: IErrorEntity,
  ): void => {
    try {
      this.setBufferProperty('userSignUpEmailValidationError', payload.code);
    } catch (error) {
      throw error;
    }
  }

  showSignUpPasswordValidationError: IUiViewmodel['showSignUpPasswordValidationError'] = (
    payload: IErrorEntity,
  ): void => {
    try {
      this.setBufferProperty('userSignUpPasswordValidationError', payload.code);
    } catch (error) {
      throw error;
    }
  }

  hideSignUpValidationError: IUiViewmodel['hideSignUpValidationError'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          buffer: Object.freeze({
            ...this.store.state.ui.buffer,
            userSignUpEmailValidationError: undefined,
            userSignUpPasswordValidationError: undefined,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showSignInValidationError: IUiViewmodel['showSignInValidationError'] = (payload: IErrorEntity): void => {
    try {
      switch (payload.code) {
        case EAuthorizationError.INCORRECT_EMAIL:
          this.setBufferProperty('userSignInEmailValidationError', EUserEmailValidationError.INCORRECT);

          break;
        case EAuthorizationError.INCORRECT_PASSWORD:
          this.setBufferProperty('userSignInPasswordValidationError', EUserPasswordValidationError.SHORT);

          break;
        case EAuthorizationError.WRONG_CREDENTIALS:
          this.setBufferProperty('userSignInEmailValidationError', EUserEmailValidationError.WRONG_CREDENTIALS);

          break;
        case EAuthorizationError.USER_AUTHORIZED:
          this.store.commit(
            'updateUi',
            {
              flags: Object.freeze({
                ...this.store.state.ui.flags,
                isSignInAttentionDisplayed: false,
                isSignUpAttentionDisplayed: false,
              }),
              buffer: Object.freeze({
                ...this.store.state.ui.buffer,
                userSignUpEmailValidationError: undefined,
                userSignUpPasswordValidationError: undefined,
                userSignInEmailValidationError: undefined,
                userSignInPasswordValidationError: undefined,
              }),
            },
          );

          break;
        default:
          throw new Error(`unknown error code: ${payload.code}`);
      }
    } catch (error) {
      throw error;
    }
  }

  showSignInEmailValidationError: IUiViewmodel['showSignInEmailValidationError'] = (
    payload: IErrorEntity,
  ): void => {
    try {
      this.setBufferProperty('userSignInEmailValidationError', payload.code);
    } catch (error) {
      throw error;
    }
  }

  showSignInPasswordValidationError: IUiViewmodel['showSignInPasswordValidationError'] = (
    payload: IErrorEntity,
  ): void => {
    try {
      this.setBufferProperty('userSignInPasswordValidationError', payload.code);
    } catch (error) {
      throw error;
    }
  }

  hideSignInValidationError: IUiViewmodel['hideSignInValidationError'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          buffer: Object.freeze({
            ...this.store.state.ui.buffer,
            userSignInEmailValidationError: undefined,
            userSignInPasswordValidationError: undefined,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showSignUpCompleteValidationError: IUiViewmodel['showSignUpCompleteValidationError'] = (payload: IErrorEntity): void => {
    try {
      let userSignUpNameValidationError;

      switch (payload.code) {
        case 401:
          userSignUpNameValidationError = EUserNameValidationError.EMPTY;

          break;
        case 402:
          userSignUpNameValidationError = EUserNameValidationError.SHORT;

          break;
        case 317:
          userSignUpNameValidationError = EUserNameValidationError.CONTAINS_EMOJI;

          break;
        default:
          throw new Error(`unknown error code: ${payload.code}`);
      }

      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isAuthorizationControlsDisabled: false,
          }),
          buffer: Object.freeze({
            ...this.store.state.ui.buffer,
            userSignUpNameValidationError,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showSignUpNameValidationError: IUiViewmodel['showSignUpNameValidationError'] = (payload: IErrorEntity): void => {
    try {
      this.setBufferProperty('userSignUpNameValidationError', payload.code);
    } catch (error) {
      throw error;
    }
  }

  hideSignUpNameValidationError: IUiViewmodel['hideSignUpNameValidationError'] = (): void => {
    try {
      this.setBufferProperty('userSignUpNameValidationError', undefined);
    } catch (error) {
      throw error;
    }
  }

  showSignUpSuccess: IUiViewmodel['showSignUpSuccess'] = (payload: {
    shouldShowPremiumPurchase: boolean,
    shouldShowUserEmailConfirmation: boolean,
  }): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            videochatPageStage: EVideochatPageStage.START,
            isCountdownLockDisplayed: false,
            isPremiumPurchaseAttentionDisplayed: (
              payload.shouldShowPremiumPurchase
              || !payload.shouldShowUserEmailConfirmation
            ),
            isUserEmailConfirmationAttentionDisplayed: payload.shouldShowUserEmailConfirmation,
            isAuthorizationControlsDisabled: false,
            isCompleteSignUpAttentionDisplayed: false,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showSignUpSuccessWithFreebie: IUiViewmodel['showSignUpSuccessWithFreebie'] = (payload: {
    shouldShowPremiumPurchase: boolean,
    shouldShowEmailConfirmationForFreebie: boolean,
  }): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            videochatPageStage: EVideochatPageStage.START,
            isCountdownLockDisplayed: false,
            isPremiumPurchaseAttentionDisplayed: payload.shouldShowPremiumPurchase,
            isEmailConfirmationForFreebieAttentionDisplayed: payload.shouldShowEmailConfirmationForFreebie,
            isAuthorizationControlsDisabled: false,
            isCompleteSignUpAttentionDisplayed: false,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showWithdrawalCardAddressServerValidationError: IUiViewmodel['showWithdrawalCardAddressServerValidationError'] = (
    payload: IErrorEntity,
  ): void => {
    try {
      switch (payload.code) {
        case 312:
          this.setBufferProperty(
            'withdrawalRequestCardAddressCityValidationError',
            EWithdrawalRequestCardAddressCityValidationError.EMPTY,
          );

          break;
        case 313:
          this.setBufferProperty(
            'withdrawalRequestCardAddressStreetValidationError',
            EWithdrawalRequestCardAddressStreetValidationError.EMPTY,
          );

          break;
        default:
          throw new Error(`unknown error code: ${payload.code}`);
      }
    } catch (error) {
      throw error;
    }
  }

  showWithdrawalCardAddressStreetClientValidationError:
    IUiViewmodel['showWithdrawalCardAddressStreetClientValidationError'] = (payload: IErrorEntity): void => {
      try {
        this.setBufferProperty('withdrawalRequestCardAddressStreetValidationError', payload.code);
      } catch (error) {
        throw error;
      }
    }

  showWithdrawalCardAddressCityClientValidationError:
    IUiViewmodel['showWithdrawalCardAddressCityClientValidationError'] = (payload: IErrorEntity): void => {
      try {
        this.setBufferProperty('withdrawalRequestCardAddressCityValidationError', payload.code);
      } catch (error) {
        throw error;
      }
    }

  hideWithdrawalCardAddressValidationError: IUiViewmodel['hideWithdrawalCardAddressValidationError'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          buffer: Object.freeze({
            ...this.store.state.ui.buffer,
            withdrawalRequestCardAddressCityValidationError: undefined,
            withdrawalRequestCardAddressStreetValidationError: undefined,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showProfileNameServerValidationError: IUiViewmodel['showProfileNameServerValidationError'] = (
    payload: IErrorEntity,
  ): void => {
    try {
      switch (payload.code) {
        case 401:
          this.setBufferProperty('userProfileNameValidationError', EUserNameValidationError.EMPTY);

          break;
        case 402:
          this.setBufferProperty('userProfileNameValidationError', EUserNameValidationError.SHORT);

          break;
        case 317:
          this.setBufferProperty('userProfileNameValidationError', EUserNameValidationError.CONTAINS_EMOJI);

          break;
        default:
          throw new Error(`unknown error code: ${payload.code}`);
      }
    } catch (error) {
      throw error;
    }
  }

  showProfileNameClientValidationError: IUiViewmodel['showProfileNameClientValidationError'] = (
    payload: IErrorEntity,
  ): void => {
    try {
      this.setBufferProperty('userProfileNameValidationError', payload.code);
    } catch (error) {
      throw error;
    }
  }

  hideProfileNameValidationError: IUiViewmodel['hideProfileNameValidationError'] = (): void => {
    try {
      this.setBufferProperty('userProfileNameValidationError', undefined);
    } catch (error) {
      throw error;
    }
  }

  showProfileEmailServerValidationError: IUiViewmodel['showProfileEmailServerValidationError'] = (
    payload: IErrorEntity,
  ): void => {
    try {
      switch (payload.code) {
        case 411:
          this.setBufferProperty('userProfileEmailValidationError', EUserEmailValidationError.INCORRECT);

          break;
        case 412:
          this.setBufferProperty('userProfileEmailValidationError', EUserEmailValidationError.EXISTS);

          break;
        case 498:
          this.setBufferProperty('userProfileEmailValidationError', EUserEmailValidationError.FORBIDDEN);

          break;
        default:
          throw new Error(`unknown error code: ${payload.code}`);
      }
    } catch (error) {
      throw error;
    }
  }

  showProfileEmailClientValidationError: IUiViewmodel['showProfileEmailClientValidationError'] = (
    payload: IErrorEntity,
  ): void => {
    try {
      this.setBufferProperty('userProfileEmailValidationError', payload.code);
    } catch (error) {
      throw error;
    }
  }

  showEmailConfirmationServerValidationError: IUiViewmodel['showEmailConfirmationServerValidationError'] = (
    payload: IErrorEntity,
  ): void => {
    try {
      switch (payload.code) {
        case 401:
          this.setBufferProperty('userEmailConfirmationValidationError', EUserEmailValidationError.EMPTY);

          break;
        case 411:
          this.setBufferProperty('userEmailConfirmationValidationError', EUserEmailValidationError.INCORRECT);

          break;
        default:
          throw new Error(`unknown error code: ${payload.code}`);
      }
    } catch (error) {
      throw error;
    }
  }

  showEmailConfirmationClientValidationError: IUiViewmodel['showEmailConfirmationClientValidationError'] = (
    payload: IErrorEntity,
  ): void => {
    try {
      this.setBufferProperty('userEmailConfirmationValidationError', payload.code);
    } catch (error) {
      throw error;
    }
  }

  hideEmailConfirmationValidationError: IUiViewmodel['hideEmailConfirmationValidationError'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          buffer: Object.freeze({
            ...this.store.state.ui.buffer,
            userEmailConfirmationValidationError: undefined,
            userEmailConfirmationCodeValidationError: undefined,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showPasswordRecoveryEmailServerValidationError: IUiViewmodel['showPasswordRecoveryEmailServerValidationError'] = (
    payload: IErrorEntity,
  ): void => {
    try {
      switch (payload.code) {
        case 416:
          this.setBufferProperty(
            'passwordRecoveryEmailValidationError',
            EUserEmailValidationError.WRONG_CREDENTIALS,
          );

          break;
        case 411:
          this.setBufferProperty(
            'passwordRecoveryEmailValidationError',
            EUserEmailValidationError.INCORRECT,
          );

          break;
        default:
          throw new Error(`unknown error code: ${payload.code}`);
      }
    } catch (error) {
      throw error;
    }
  }

  showPasswordRecoveryEmailClientValidationError: IUiViewmodel['showPasswordRecoveryEmailClientValidationError'] = (
    payload: IErrorEntity,
  ): void => {
    try {
      this.setBufferProperty('passwordRecoveryEmailValidationError', payload.code);
    } catch (error) {
      throw error;
    }
  }

  hidePasswordRecoveryEmailValidationError: IUiViewmodel['hidePasswordRecoveryEmailValidationError'] = (): void => {
    try {
      this.setBufferProperty('passwordRecoveryEmailValidationError', undefined);
    } catch (error) {
      throw error;
    }
  }

  showProfilePasswordServerValidationError: IUiViewmodel['showProfilePasswordServerValidationError'] = (
    payload: IErrorEntity,
  ): void => {
    try {
      switch (payload.code) {
        case 405:
          this.setBufferProperty('userProfilePasswordValidationError', EUserPasswordValidationError.SHORT);

          break;
        default:
          throw new Error(`unknown error code: ${payload.code}`);
      }
    } catch (error) {
      throw error;
    }
  }

  showProfilePasswordClientValidationError: IUiViewmodel['showProfilePasswordClientValidationError'] = (
    payload: IErrorEntity,
  ): void => {
    try {
      this.setBufferProperty('userProfilePasswordValidationError', payload.code);
    } catch (error) {
      throw error;
    }
  }

  showChangedProfileSettingsAndHideEmailValidationError:
    IUiViewmodel['showChangedProfileSettingsAndHideEmailValidationError'] = (): void => {
      try {
        this.store.commit(
          'updateUi',
          {
            flags: Object.freeze({
              ...this.store.state.ui.flags,
              isUserProfileSettingsFormInInitialState: false,
            }),
            buffer: Object.freeze({
              ...this.store.state.ui.buffer,
              userProfileEmailValidationError: undefined,
            }),
          },
        );
      } catch (error) {
        throw error;
      }
    }

  showChangedProfileSettingsAndHidePasswordValidationError:
    IUiViewmodel['showChangedProfileSettingsAndHidePasswordValidationError'] = (): void => {
      try {
        this.store.commit(
          'updateUi',
          {
            flags: Object.freeze({
              ...this.store.state.ui.flags,
              isUserProfileSettingsFormInInitialState: false,
            }),
            buffer: Object.freeze({
              ...this.store.state.ui.buffer,
              userProfilePasswordValidationError: undefined,
            }),
          },
        );
      } catch (error) {
        throw error;
      }
    }

  hideVideochatActivity: IUiViewmodel['hideVideochatActivity'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isContactRequestAttentionDisplayed: false,
            shouldShowFaceNotDetectedAttention: false,
            isGiftAttentionDisplayed: false,
            isVideochatControlsHidden: false,
            isVideochatControlsAutoHidingEnabled: false,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  hideVideochatControls: IUiViewmodel['hideVideochatControls'] = (): void => {
    try {
      this.setFlagProperty('isVideochatControlsHidden', true);
    } catch (error) {
      throw error;
    }
  }

  disableVideochatControlsAutoHiding: IUiViewmodel['disableVideochatControlsAutoHiding'] = (): void => {
    try {
      this.setFlagProperty('isVideochatControlsAutoHidingEnabled', false);
    } catch (error) {
      throw error;
    }
  }

  showMessengerEmailConfirmation: IUiViewmodel['showMessengerEmailConfirmation'] = (): void => {
    try {
      this.setFlagProperty('isMessengerEmailConfirmationDisplayed', true);
    } catch (error) {
      throw error;
    }
  }

  showSwitchPageDuringVideochat: IUiViewmodel['showSwitchPageDuringVideochat'] = (): void => {
    try {
      this.setFlagProperty('isSwitchPageDuringVideochatAttentionDisplayed', true);
    } catch (error) {
      throw error;
    }
  }

  showWithdrawalRequisitesInsteadRejection: IUiViewmodel['showWithdrawalRequisitesInsteadRejection'] = (
    payload: IWithdrawalRequestEntity<
      EPaymentSystemType,
      IWithdrawalRequestPaymentSystems[EPaymentSystemType]
    >,
  ): void => {
    try {
      switch (payload.type) {
        case EPaymentSystemType.CARD:
          this.store.commit(
            'updateUi',
            {
              ...this.store.state.ui,
              flags: Object.freeze({
                ...this.store.state.ui.flags,
                isWithdrawalCardRequisitesAttentionDisplayed: true,
                isWithdrawalRequestRejectedAttentionDisplayed: false,
              }),
            },
          );

          break;
        case EPaymentSystemType.BANK:
          this.store.commit(
            'updateUi',
            {
              ...this.store.state.ui,
              flags: Object.freeze({
                ...this.store.state.ui.flags,
                isWithdrawalBankFirstStepDisplayed: true,
                isWithdrawalRequestRejectedAttentionDisplayed: false,
              }),
            },
          );

          break;
        case EPaymentSystemType.EPSWALLET:
          this.store.commit(
            'updateUi',
            {
              ...this.store.state.ui,
              flags: Object.freeze({
                ...this.store.state.ui.flags,
                isWithdrawalEpayserviceRequisitesAttentionDisplayed: true,
                isWithdrawalRequestRejectedAttentionDisplayed: false,
              }),
            },
          );

          break;
        case EPaymentSystemType.QIWI:
          this.store.commit(
            'updateUi',
            {
              ...this.store.state.ui,
              flags: Object.freeze({
                ...this.store.state.ui.flags,
                isWithdrawalQiwiRequisitesAttentionDisplayed: true,
                isWithdrawalRequestRejectedAttentionDisplayed: false,
              }),
            },
          );

          break;
        case EPaymentSystemType.WEBMONEY:
          this.store.commit(
            'updateUi',
            {
              ...this.store.state.ui,
              flags: Object.freeze({
                ...this.store.state.ui.flags,
                isWithdrawalWebmoneyRequisitesAttentionDisplayed: true,
                isWithdrawalRequestRejectedAttentionDisplayed: false,
              }),
            },
          );

          break;
        case EPaymentSystemType.YANDEX:
          this.store.commit(
            'updateUi',
            {
              ...this.store.state.ui,
              flags: Object.freeze({
                ...this.store.state.ui.flags,
                isWithdrawalYandexRequisitesAttentionDisplayed: true,
                isWithdrawalRequestRejectedAttentionDisplayed: false,
              }),
            },
          );

          break;
        case EPaymentSystemType.BITCOIN:
          this.store.commit(
            'updateUi',
            {
              ...this.store.state.ui,
              flags: Object.freeze({
                ...this.store.state.ui.flags,
                isWithdrawalBitcoinRequisitesAttentionDisplayed: true,
                isWithdrawalRequestRejectedAttentionDisplayed: false,
              }),
            },
          );

          break;
        case EPaymentSystemType.USDT:
          this.store.commit(
            'updateUi',
            {
              ...this.store.state.ui,
              flags: Object.freeze({
                ...this.store.state.ui.flags,
                isWithdrawalUsdtRequisitesAttentionDisplayed: true,
                isWithdrawalRequestRejectedAttentionDisplayed: false,
              }),
            },
          );

          break;
        default:
          throw new Error(`unknown withdrawal request payment system type ${payload.type}`);
      }
    } catch (error) {
      throw error;
    }
  }

  showAccountDeletionReasonInsteadConfirmation: IUiViewmodel['showAccountDeletionReasonInsteadConfirmation'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isAccountDeletionConfirmationAttentionDisplayed: false,
            isAccountDeletionReasonAttentionDisplayed: true,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  asyncShowVideochatStartAndAccountDeletionSuccess: IUiViewmodel['asyncShowVideochatStartAndAccountDeletionSuccess'] = async (): Promise<void> => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isAccountDeletionReasonAttentionDisplayed: false,
            isAccountDeletionSuccessAttentionDisplayed: true,
            videochatPageStage: EVideochatPageStage.START,
            isCountdownLockDisplayed: false,
            isUserVerificationLockDisplayed: false,
          }),
          buffer: Object.freeze({
            ...this.store.state.ui.buffer,
            isAccountDeletionDisabled: false,
          }),
        },
      );

      await router.push({ name: 'videochat' });
    } catch (error) {
      throw error;
    }
  }

  showUserOutOfMessagesInsteadImageSending: IUiViewmodel['showUserOutOfMessagesInsteadImageSending'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isImageSendAttentionDisplayed: false,
            isUserOutOfMessagesAttentionDisplayed: true,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showUserOutOfMessagesInsteadPhotoSending: IUiViewmodel['showUserOutOfMessagesInsteadPhotoSending'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isPhotoSendAttentionDisplayed: false,
            isUserOutOfMessagesAttentionDisplayed: true,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showUserOutOfMessagesInsteadVideoSending: IUiViewmodel['showUserOutOfMessagesInsteadVideoSending'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isVideoSendAttentionDisplayed: false,
            isUserOutOfMessagesAttentionDisplayed: true,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showVideochatStartInsteadCompanionHasAnotherVideocall:
    IUiViewmodel['showVideochatStartInsteadCompanionHasAnotherVideocall'] = (): void => {
      try {
        this.store.commit(
          'updateUi',
          {
            ...this.store.state.ui,
            flags: Object.freeze({
              ...this.store.state.ui.flags,
              videochatPageStage: EVideochatPageStage.START,
              isCompanionHasAnotherVideocallLockDisplayed: false,
            }),
          },
        );
      } catch (error) {
        throw error;
      }
    }

  showVideochatStartInsteadCompanionPays: IUiViewmodel['showVideochatStartInsteadCompanionPays'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            videochatPageStage: EVideochatPageStage.START,
            isCompanionPaysLockDisplayed: false,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showCompanionReviewSent: IUiViewmodel['showCompanionReviewSent'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isCompanionReviewLockDisplayed: false,
            isCompanionReviewSentLockDisplayed: true,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showVerificationUnverifiedStage: IUiViewmodel['showVerificationUnverifiedStage'] = (): void => {
    try {
      this.setBufferProperty('userVerificationStage', EUserVerificationStage.UNVERIFIED);
    } catch (error) {
      throw error;
    }
  }

  updateEmailConfirmationStatus: IUiViewmodel['updateEmailConfirmationStatus'] = (
    payload: IViewmodel['ui']['buffer']['userEmailConfirmationStatus'],
  ): void => {
    try {
      this.setBufferProperty('userEmailConfirmationStatus', payload);
    } catch (error) {
      throw error;
    }
  }

  updateEmailConfirmationForFreebieEmail: IUiViewmodel['updateEmailConfirmationForFreebieEmail'] = (
    payload: IViewmodel['ui']['buffer']['userEmailConfirmationForFreebieEmail'],
  ): void => {
    try {
      this.setBufferProperty('userEmailConfirmationForFreebieEmail', payload);
    } catch (error) {
      throw error;
    }
  }

  hideEmailConfirmationCodeValidationError: IUiViewmodel['hideEmailConfirmationCodeValidationError'] = (): void => {
    try {
      this.setBufferProperty('userEmailConfirmationCodeValidationError', undefined);
    } catch (error) {
      throw error;
    }
  }

  showEmailConfirmationCodeValidationError: IUiViewmodel['showEmailConfirmationCodeValidationError'] = (
    payload: IErrorEntity,
  ): void => {
    try {
      try {
        switch (payload.code) {
          case 0:
            this.setBufferProperty('userEmailConfirmationCodeValidationError', EEmailConfirmationCodeValidationError.EMPTY);

            break;
          case 1:
          case 401:
            this.setBufferProperty('userEmailConfirmationCodeValidationError', EEmailConfirmationCodeValidationError.SHORT);

            break;
          case 2:
          case 419:
            this.setBufferProperty('userEmailConfirmationCodeValidationError', EEmailConfirmationCodeValidationError.INCORRECT);

            break;
          default:
            throw new Error(`unknown error code: ${payload.code}`);
        }
      } catch (error) {
        throw error;
      }
    } catch (error) {
      throw error;
    }
  }

  disableSendEmailConfirmationCode: IUiViewmodel['disableSendEmailConfirmationCode'] = (): void => {
    try {
      this.setBufferProperty('isSendEmailConfirmationCodeDisabled', true);
    } catch (error) {
      throw error;
    }
  }

  enableSendEmailConfirmationCode: IUiViewmodel['enableSendEmailConfirmationCode'] = (): void => {
    try {
      this.setBufferProperty('isSendEmailConfirmationCodeDisabled', false);
    } catch (error) {
      throw error;
    }
  }

  showAccountDeletionCodeClientValidationError: IUiViewmodel['showAccountDeletionCodeClientValidationError'] = (
    payload: IErrorEntity,
  ): void => {
    try {
      this.setBufferProperty('accountDeletionCodeValidationError', payload.code);
    } catch (error) {
      throw error;
    }
  }

  showAccountDeletionCodeServerValidationError: IUiViewmodel['showAccountDeletionCodeServerValidationError'] = (
    payload: IErrorEntity,
  ): void => {
    try {
      switch (payload.code) {
        case 478:
        case 141:
          this.setBufferProperty(
            'accountDeletionCodeValidationError',
            EUserAccountDeletionCodeValidationError.INCORRECT,
          );

          break;
        case 419:
          this.setBufferProperty(
            'accountDeletionCodeValidationError',
            EUserAccountDeletionCodeValidationError.SHORT,
          );

          break;
        default:
          throw new Error(`unknown error code: ${payload.code}`);
      }
    } catch (error) {
      throw error;
    }
  }

  hideAccountDeletionCodeValidationError: IUiViewmodel['hideAccountDeletionCodeValidationError'] = (): void => {
    try {
      this.setBufferProperty('accountDeletionCodeValidationError', undefined);
    } catch (error) {
      throw error;
    }
  }

  showCompanionReview: IUiViewmodel['showCompanionReview'] = (): void => {
    try {
      this.setFlagProperty('isCompanionReviewLockDisplayed', true);
    } catch (error) {
      throw error;
    }
  }

  showCompanionGift: IUiViewmodel['showCompanionGift'] = (): void => {
    try {
      this.setFlagProperty('isCompanionGiftLockDisplayed', true);
    } catch (error) {
      throw error;
    }
  }

  disableCardConfirmationActions: IUiViewmodel['disableCardConfirmationActions'] = (): void => {
    try {
      this.setFlagProperty('isCardConfirmationLockControlsDisabled', true);
    } catch (error) {
      throw error;
    }
  }

  enableCardConfirmationActions: IUiViewmodel['enableCardConfirmationActions'] = (): void => {
    try {
      this.setFlagProperty('isCardConfirmationLockControlsDisabled', false);
    } catch (error) {
      throw error;
    }
  }

  removeCardConfirmationValidationError: IUiViewmodel['removeCardConfirmationValidationError'] = (): void => {
    try {
      this.setBufferProperty('userCardConfirmationValidationError', undefined);
    } catch (error) {
      throw error;
    }
  }

  showPurchasePaymentError: IUiViewmodel['showPurchasePaymentError'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isPurchaseErrorAttentionDisplayed: true,
          }),
          buffer: Object.freeze({
            ...this.store.state.ui.buffer,
            purchaseError: EPurchaseError.PAYMENT_FAILED,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showBackToVideochat: IUiViewmodel['showBackToVideochat'] = (): void => {
    try {
      this.setFlagProperty('isBackToVideochatDisplayed', true);
    } catch (error) {
      throw error;
    }
  }

  hideBackToVideochat: IUiViewmodel['hideBackToVideochat'] = (): void => {
    try {
      this.setFlagProperty('isBackToVideochatDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  showBonusMinutesAppeal: IUiViewmodel['showBonusMinutesAppeal'] = (): void => {
    try {
      this.setFlagProperty('isBonusMinutesAppealAttentionDisplayed', true);
    } catch (error) {
      throw error;
    }
  }

  hideBonusMinutesAppeal: IUiViewmodel['hideBonusMinutesAppeal'] = (): void => {
    try {
      this.setFlagProperty('isBonusMinutesAppealAttentionDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  showSignUpInsteadBonusMinutesAppeal: IUiViewmodel['showSignUpInsteadBonusMinutesAppeal'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isBonusMinutesAppealAttentionDisplayed: false,
            isSignUpAttentionDisplayed: true,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  subscribeToAppForbiddenChange: IUiViewmodel['subscribeToAppForbiddenChange'] = (
    payload: (value: IViewmodel['ui']['flags']['isAppForbidden']) => void,
  ): void => {
    try {
      this.watchFlagProperty('isAppForbidden', payload);
    } catch (error) {
      throw error;
    }
  }

  asyncShowContactPage: IUiViewmodel['asyncShowContactPage'] = async (
    payload: IContactEntity['id'] | NonNullable<IUserEntity['id']>,
  ): Promise<void> => {
    try {
      await router.push({ name: 'messages', params: { id: `${payload}` } });
    } catch (error) {
      throw error;
    }
  }

  asyncShowMessengerPageInsteadContact: IUiViewmodel['asyncShowMessengerPageInsteadContact'] = async (): Promise<void> => {
    try {
      await router.replace({ name: 'messages' });
    } catch (error) {
      throw error;
    }
  }

  asyncShowMessengerPage: IUiViewmodel['asyncShowMessengerPage'] = async (): Promise<void> => {
    try {
      if (router.currentRoute.value.name !== 'messages') {
        await router.push({ name: 'messages' });
      }
    } catch (error) {
      throw error;
    }
  }

  asyncShowVideochatPage: IUiViewmodel['asyncShowVideochatPage'] = async (): Promise<void> => {
    try {
      if (router.currentRoute.value.name !== 'videochat') {
        await router.push({ name: 'videochat' });
      }
    } catch (error) {
      throw error;
    }
  }

  asyncShowVideochatPageAndUpdateOutgoingCallSource: IUiViewmodel['asyncShowVideochatPageAndUpdateOutgoingCallSource'] = async (
    payload: IViewmodel['ui']['buffer']['outgoingCallSource'],
  ): Promise<void> => {
    try {
      this.setBufferProperty('outgoingCallSource', payload);

      await router.push({ name: 'videochat' });
    } catch (error) {
      throw error;
    }
  }

  asyncShowVideochatPageInsteadGender: IUiViewmodel['asyncShowVideochatPageInsteadGender'] = async (): Promise<void> => {
    try {
      this.setFlagProperty('isAppWaitingActive', true);

      await router.replace({ name: 'videochat' });
    } catch (error) {
      throw error;
    }
  }

  asyncShowStoriesPage: IUiViewmodel['asyncShowStoriesPage'] = async (payload: {
    shouldShowStoryNavigation: IViewmodel['ui']['flags']['shouldShowStoryNavigation'],
    contactsScrollPosition: IViewmodel['ui']['buffer']['contactsScrollPosition'],
  }): Promise<void> => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            shouldShowStoryNavigation: payload.shouldShowStoryNavigation,
          }),
          buffer: Object.freeze({
            ...this.store.state.ui.buffer,
            contactsScrollPosition: payload.contactsScrollPosition,
          }),
        },
      );

      await router.push({ name: 'stories' });
    } catch (error) {
      throw error;
    }
  }

  asyncShowStoriesPageWithLock: IUiViewmodel['asyncShowStoriesPageWithLock'] = async (payload: {
    shouldShowStoryNavigation: IViewmodel['ui']['flags']['shouldShowStoryNavigation'],
    contactsScrollPosition: IViewmodel['ui']['buffer']['contactsScrollPosition'],
  }): Promise<void> => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isStoryLockDisplayed: true,
            shouldShowStoryNavigation: payload.shouldShowStoryNavigation,
          }),
          buffer: Object.freeze({
            ...this.store.state.ui.buffer,
            contactsScrollPosition: payload.contactsScrollPosition,
          }),
        },
      );

      await router.push({ name: 'stories' });
    } catch (error) {
      throw error;
    }
  }

  asyncShowStoriesPageWithNotFound: IUiViewmodel['asyncShowStoriesPageWithNotFound'] = async (payload: {
    shouldShowStoryNavigation: IViewmodel['ui']['flags']['shouldShowStoryNavigation'],
    contactsScrollPosition: IViewmodel['ui']['buffer']['contactsScrollPosition'],
  }): Promise<void> => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isStoryNotFoundDisplayed: true,
            shouldShowStoryNavigation: payload.shouldShowStoryNavigation,
          }),
          buffer: Object.freeze({
            ...this.store.state.ui.buffer,
            contactsScrollPosition: payload.contactsScrollPosition,
          }),
        },
      );

      await router.push({ name: 'stories' });
    } catch (error) {
      throw error;
    }
  }

  hideStoriesPage: IUiViewmodel['hideStoriesPage'] = (): void => {
    try {
      router.go(-1);

      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            shouldShowStoryNavigation: false,
            isStoryNotFoundDisplayed: false,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  asyncShowVideochatPageInsteadStories: IUiViewmodel['asyncShowVideochatPageInsteadStories'] = async (): Promise<void> => {
    try {
      await router.push({ name: 'videochat' });

      this.setFlagProperty('shouldShowStoryNavigation', false);
    } catch (error) {
      throw error;
    }
  }

  hideCountdownLock: IUiViewmodel['hideCountdownLock'] = (): void => {
    try {
      this.setFlagProperty('isCountdownLockDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  showStoryLock: IUiViewmodel['showStoryLock'] = (): void => {
    try {
      this.setFlagProperty('isStoryLockDisplayed', true);
    } catch (error) {
      throw error;
    }
  }

  showVideochatStartAndHideCountdownLock: IUiViewmodel['showVideochatStartAndHideCountdownLock'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            videochatPageStage: EVideochatPageStage.START,
            isCountdownLockDisplayed: false,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  asyncShowVideochatStartAndHideCountdownLock: IUiViewmodel['asyncShowVideochatStartAndHideCountdownLock'] = async (): Promise<void> => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            videochatPageStage: EVideochatPageStage.START,
            isCountdownLockDisplayed: false,
          }),
        },
      );

      if (router.currentRoute.value.name !== 'videochat') {
        await router.push({ name: 'videochat' });
      }
    } catch (error) {
      throw error;
    }
  }

  hideStoryLock: IUiViewmodel['hideStoryLock'] = (): void => {
    try {
      this.setFlagProperty('isStoryLockDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  showStoryNotFound: IUiViewmodel['showStoryNotFound'] = (): void => {
    try {
      this.setFlagProperty('isStoryNotFoundDisplayed', true);
    } catch (error) {
      throw error;
    }
  }

  hideStoryNotFound: IUiViewmodel['hideStoryNotFound'] = (): void => {
    try {
      this.setFlagProperty('isStoryNotFoundDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  asyncHideStoryLockAndShowVideochatPageInsteadStory: IUiViewmodel['asyncHideStoryLockAndShowVideochatPageInsteadStory'] = async (): Promise<void> => {
    try {
      if (router.currentRoute.value.name === 'stories') {
        await router.push({ name: 'videochat' });
      }

      this.setFlagProperty('isStoryLockDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  showVideochatFinishAndCountdownLock: IUiViewmodel['showVideochatFinishAndCountdownLock'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            videochatPageStage: EVideochatPageStage.FINISH,
            isCountdownLockDisplayed: true,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  updateAfterLogout: IUiViewmodel['updateAfterLogout'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            videochatPageStage: EVideochatPageStage.START,
            isUserVerificationLockDisplayed: false,
            isUserBanAttentionDisplayed: false,
            isWithdrawalRequestSendControlsDisabled: false,
            shouldShowMessengerNotificationAppeal: true,
          }),
          buffer: Object.freeze({
            ...this.store.state.ui.buffer,
            userVerificationStage: undefined,
            markedContactsIds: undefined,
            userEmailConfirmationStatus: EUserEmailConfirmationStatus.INITIAL,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showExternalPage: IUiViewmodel['showExternalPage'] = (payload: string): void => {
    try {
      window.open(payload);
    } catch (error) {
      throw error;
    }
  }

  showPrevPage: IUiViewmodel['showPrevPage'] = (): void => {
    try {
      router.go(-1);
    } catch (error) {
      throw error;
    }
  }

  resetUserEmailConfirmationStatus: IUiViewmodel['resetUserEmailConfirmationStatus'] = (): void => {
    try {
      this.setBufferProperty('userEmailConfirmationStatus', EUserEmailConfirmationStatus.INITIAL);
    } catch (error) {
      throw error;
    }
  }

  showMessageSendingError: IUiViewmodel['showMessageSendingError'] = (payload: IErrorEntity): void => {
    try {
      switch (payload.code) {
        case 113:
        case 421:
          this.store.commit(
            'updateUi',
            {
              flags: Object.freeze({
                ...this.store.state.ui.flags,
                isMessageSendErrorAttentionDisplayed: true,
              }),
              buffer: Object.freeze({
                ...this.store.state.ui.buffer,
                messageSendError: payload.code,
              }),
            },
          );

          break;
        case 0:
          this.setFlagProperty('isUserOutOfMessagesAttentionDisplayed', true);

          break;
        default:
          throw new Error(`unknown error code: ${payload.code}`);
      }
    } catch (error) {
      throw error;
    }
  }

  asyncShowOutgoingCallSource: IUiViewmodel['asyncShowOutgoingCallSource'] = async (
    payload: IContactEntity['id'],
  ): Promise<void> => {
    try {
      const { outgoingCallSource } = this.store.state.ui.buffer;

      if (outgoingCallSource === EOutgoingCallSource.MESSENGER) {
        await router.push({ name: 'messages', params: { id: `${payload}` } });
      } else if (outgoingCallSource === EOutgoingCallSource.STORY) {
        this.setFlagProperty('shouldShowStoryNavigation', false);

        await router.push({ name: 'stories' });
      }

      this.setBufferProperty('outgoingCallSource', undefined);
    } catch (error) {
      throw error;
    }
  }

  showAllowedPaymentUrl: IUiViewmodel['showAllowedPaymentUrl'] = (payload: string): void => {
    try {
      window.parent.location.href = payload;
    } catch (error) {
      throw error;
    }
  }

  showExternalUrl: IUiViewmodel['showExternalUrl'] = (payload: string): void => {
    try {
      window.open(payload, '_blank');
    } catch (error) {
      throw error;
    }
  }

  asyncShowForbiddenPage: IUiViewmodel['asyncShowForbiddenPage'] = async (): Promise<void> => {
    try {
      await router.push({ name: 'forbidden' });
    } catch (error) {
      throw error;
    }
  }

  asyncShowStoryRecordPage: IUiViewmodel['asyncShowStoryRecordPage'] = async (): Promise<void> => {
    try {
      await router.push({ name: 'story-record' });
    } catch (error) {
      throw error;
    }
  }

  showStoryRecordClosing: IUiViewmodel['showStoryRecordClosing'] = (): void => {
    try {
      this.setFlagProperty('isStoryRecordCloseDisplayed', true);
    } catch (error) {
      throw error;
    }
  }

  hideStoryRecordClosing: IUiViewmodel['hideStoryRecordClosing'] = (): void => {
    try {
      this.setFlagProperty('isStoryRecordCloseDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  showStoryRemovalConfirm: IUiViewmodel['showStoryRemovalConfirm'] = (): void => {
    try {
      this.setFlagProperty('isStoryRemovalConfirmDisplayed', true);
    } catch (error) {
      throw error;
    }
  }

  hideStoryRemovalConfirm: IUiViewmodel['hideStoryRemovalConfirm'] = (): void => {
    try {
      this.setFlagProperty('isStoryRemovalConfirmDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  asyncShowStoriesPageInsteadStoryRecord: IUiViewmodel['asyncShowStoriesPageInsteadStoryRecord'] = async (): Promise<void> => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            shouldShowStoryNavigation: false,
            isStoryRecordCloseDisplayed: true,
          }),
        },
      );

      await router.replace({ name: 'stories' });
    } catch (error) {
      throw error;
    }
  }

  asyncShowContactInsteadFemalePromo: IUiViewmodel['asyncShowContactInsteadFemalePromo'] = async (
    payload: IContactEntity['id'],
  ): Promise<void> => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            videochatPageStage: EVideochatPageStage.START,
            isFemalePromoVideocallLockDisplayed: false,
          }),
        },
      );

      await router.push({ name: 'messages', params: { id: `${payload}` } });
    } catch (error) {
      throw error;
    }
  }

  asyncShowContactInsteadVideochatRecoveryFail: IUiViewmodel['asyncShowContactInsteadVideochatRecoveryFail'] = async (
    payload: IContactEntity['id'],
  ): Promise<void> => {
    try {
      this.setFlagProperty('isVideochatRecoveryFailAttentionDisplayed', false);

      await router.push({ name: 'messages', params: { id: `${payload}` } });
    } catch (error) {
      throw error;
    }
  }

  asyncShowContactInsteadCompanionDisconnectVideocall: IUiViewmodel['asyncShowContactInsteadCompanionDisconnectVideocall'] = async (
    payload: IContactEntity['id'],
  ): Promise<void> => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            videochatPageStage: EVideochatPageStage.START,
            isCompanionDisconnectVideocallLockDisplayed: false,
          }),
        },
      );

      await router.push({ name: 'messages', params: { id: `${payload}` } });
    } catch (error) {
      throw error;
    }
  }

  asyncShowContactInsteadCompanionLeaveVideocall: IUiViewmodel['asyncShowContactInsteadCompanionLeaveVideocall'] = async (
    payload: IContactEntity['id'],
  ): Promise<void> => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            videochatPageStage: EVideochatPageStage.START,
            isCompanionLeaveVideocallLockDisplayed: false,
          }),
        },
      );

      await router.push({ name: 'messages', params: { id: `${payload}` } });
    } catch (error) {
      throw error;
    }
  }

  asyncShowContactInsteadCompanionOutOfMinutes: IUiViewmodel['asyncShowContactInsteadCompanionOutOfMinutes'] = async (
    payload: IContactEntity['id'],
  ): Promise<void> => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            videochatPageStage: EVideochatPageStage.START,
            isCompanionOutOfMinutesLockDisplayed: false,
          }),
        },
      );

      await router.push({ name: 'messages', params: { id: `${payload}` } });
    } catch (error) {
      throw error;
    }
  }

  asyncShowContactInsteadCompanionHasAnotherVideocall: IUiViewmodel['asyncShowContactInsteadCompanionHasAnotherVideocall'] = async (
    payload: IContactEntity['id'],
  ): Promise<void> => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            videochatPageStage: EVideochatPageStage.START,
            isCompanionHasAnotherVideocallLockDisplayed: false,
          }),
        },
      );

      await router.push({ name: 'messages', params: { id: `${payload}` } });
    } catch (error) {
      throw error;
    }
  }

  asyncShowContactInsteadCompanionRate: IUiViewmodel['asyncShowContactInsteadCompanionRate'] = async (
    payload: IContactEntity['id'],
  ): Promise<void> => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isCompanionRateLockDisplayed: false,
            videochatPageStage: EVideochatPageStage.START,
          }),
        },
      );

      await router.push({ name: 'messages', params: { id: `${payload}` } });
    } catch (error) {
      throw error;
    }
  }

  asyncShowContactInsteadCompanionPays: IUiViewmodel['asyncShowContactInsteadCompanionPays'] = async (
    payload: IContactEntity['id'],
  ): Promise<void> => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            videochatPageStage: EVideochatPageStage.START,
            isCompanionPaysLockDisplayed: false,
          }),
        },
      );

      await router.push({ name: 'messages', params: { id: `${payload}` } });
    } catch (error) {
      throw error;
    }
  }

  asyncShowContactInsteadCompanionGift: IUiViewmodel['asyncShowContactInsteadCompanionGift'] = async (
    payload: IContactEntity['id'],
  ): Promise<void> => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            videochatPageStage: EVideochatPageStage.START,
            isCompanionGiftLockDisplayed: false,
          }),
        },
      );

      await router.push({ name: 'messages', params: { id: `${payload}` } });
    } catch (error) {
      throw error;
    }
  }

  showNewPremiumSubscriptionCardUrl: IUiViewmodel['showNewPremiumSubscriptionCardUrl'] = (payload: string): void => {
    try {
      window.open(payload, 'purchase', 'width=1024,height=680');
    } catch (error) {
      throw error;
    }
  }

  showPurchaseCredentials: IUiViewmodel['showPurchaseCredentials'] = (payload: string): void => {
    try {
      window.open(payload, 'purchase', 'width=1024,height=680');
    } catch (error) {
      throw error;
    }
  }

  showFunctionalUnavailable: IUiViewmodel['showFunctionalUnavailable'] = (): void => {
    try {
      this.setFlagProperty('isFunctionalUnavailableDisplayed', true);
    } catch (error) {
      throw error;
    }
  }

  showPurchaseCredentialsWithRetryInsteadPurchases: IUiViewmodel['showPurchaseCredentialsWithRetryInsteadPurchases'] = (
    payload: IPurchaseGatewayEntity['url'],
  ): void => {
    try {
      const flags = {
        ...this.store.state.ui.flags,
        isMinutesPurchaseAttentionDisplayed: false,
        isPremiumPurchaseAttentionDisplayed: false,
        isPurchaseRetryAttentionDisplayed: false,
        isNotEnoughMinutesAttentionDisplayed: false,
        isNotEnoughMinutesDisabled: false,
      };

      const buffer = { ...this.store.state.ui.buffer };

      const purchaseWindow = window.open(payload, 'purchase', 'width=1024,height=680');

      if (!purchaseWindow) {
        flags.isPurchaseRetryAttentionDisplayed = true;

        buffer.purchaseRetryUrl = payload;
      }

      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze(flags),
          buffer: Object.freeze(buffer),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  hideFunctionalUnavailable: IUiViewmodel['hideFunctionalUnavailable'] = (): void => {
    try {
      this.setFlagProperty('isFunctionalUnavailableDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  showAbuseSendingError: IUiViewmodel['showAbuseSendingError'] = (payload: IErrorEntity): void => {
    try {
      switch (payload.code) {
        case 416:
          this.setBufferProperty('actionImpossibleReason', EActionImpossibleReason.ACCOUNT_DELETED);

          break;
        default:
          throw new Error(`unknown error: ${payload.code}`);
      }
    } catch (error) {
      throw error;
    }
  }

  hideStoriesPageAndShowStoryEarnedPoints: IUiViewmodel['hideStoriesPageAndShowStoryEarnedPoints'] = (): void => {
    try {
      if (router.currentRoute.value.name === 'stories') {
        router.go(-1);
      }

      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            shouldShowStoryNavigation: false,
            isStoryNotificationDisplayed: false,
            isStoryEarnedPointsDisplayed: true,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showStoryEarnedPoints: IUiViewmodel['hideStoryEarnedPoints'] = (): void => {
    try {
      this.setFlagProperty('isStoryEarnedPointsDisplayed', true);
    } catch (error) {
      throw error;
    }
  }

  hideStoryEarnedPoints: IUiViewmodel['hideStoryEarnedPoints'] = (): void => {
    try {
      this.setFlagProperty('isStoryEarnedPointsDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  asyncShowPage: IUiViewmodel['asyncShowPage'] = async (payload: EPage): Promise<void> => {
    try {
      let pageName;

      switch (payload) {
        case EPage.GENDER:
          pageName = 'gender';

          break;
        case EPage.SUBSCRIPTION:
          pageName = 'subscription';

          break;
        case EPage.FORBIDDEN:
          pageName = 'forbidden';

          break;
        case EPage.VIDEOCHAT:
          pageName = 'videochat';

          break;
        case EPage.MESSAGES:
          pageName = 'messages';

          break;
        case EPage.TOP:
          pageName = 'top';

          break;
        case EPage.PROFILE:
          pageName = 'profile';

          break;
        case EPage.STORIES:
          pageName = 'stories';

          break;
        case EPage.STORY_RECORD:
          pageName = 'story-record';

          break;
        case EPage.NOT_FOUND:
          pageName = 'not-found';

          break;
        default:
          throw new Error(`unknown page ${payload}`);
      }

      /**
       * Не делаем переход на тот же самый роутер.
       * В данной реализации нет сверки параметров нового и старого урла,
       * но в текущих кейсах использования достаточно убедиться,что параметров вообще нет.
       */
      if (router.currentRoute.value.name === pageName && Object.keys(router.currentRoute.value.params).length === 0) {
        return;
      }

      /**
       * Ошибки потушены, потому что при прерывании перехода на другую страницу пакет кидает ошибку
       * Ссылка на обсуждение: https://github.com/vuejs/vue-router/issues/2881
       */
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      await router.push({ name: pageName }).catch(() => {});
    } catch (error) {
      throw error;
    }
  }

  removeContactsScrollPosition: IUiViewmodel['removeContactsScrollPosition'] = (): void => {
    try {
      this.setBufferProperty('contactsScrollPosition', undefined);
    } catch (error) {
      throw error;
    }
  }

  showPremiumSubscriptionBonusMinutesRenewal: IUiViewmodel['showPremiumSubscriptionBonusMinutesRenewal'] = (): void => {
    try {
      this.setFlagProperty('isPremiumSubscriptionBonusMinutesRenewalDisplayed', true);
    } catch (error) {
      throw error;
    }
  }

  hidePremiumSubscriptionBonusMinutesRenewal: IUiViewmodel['hidePremiumSubscriptionBonusMinutesRenewal'] = (): void => {
    try {
      this.setFlagProperty('isPremiumSubscriptionBonusMinutesRenewalDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  asyncShowAndWaitCameraNotSupported: IUiViewmodel['asyncShowAndWaitCameraNotSupported'] = (): Promise<unknown> => {
    try {
      return new Promise((resolve): void => {
        this.cameraNotSupportedResolver = resolve;

        this.showCameraNotSupported();
      });
    } catch (error) {
      throw error;
    }
  }

  hideAndResolveCameraNotSupported: IUiViewmodel['hideAndResolveCameraNotSupported'] = (): void => {
    try {
      if (this.cameraNotSupportedResolver === null) {
        throw new Error('cameraNotSupportedResolver is null');
      }

      this.hideCameraNotSupported();

      this.cameraNotSupportedResolver(undefined);

      this.cameraNotSupportedResolver = null;
    } catch (error) {
      throw error;
    }
  }

  asyncShowAndWaitCameraNotExist: IUiViewmodel['asyncShowAndWaitCameraNotExist'] = (): Promise<void> => {
    try {
      return new Promise((resolve): void => {
        this.cameraNotExistResolver = resolve;

        this.showCameraNotExist();
      });
    } catch (error) {
      throw error;
    }
  }

  hideAndResolveCameraNotExist: IUiViewmodel['hideAndResolveCameraNotExist'] = (): void => {
    try {
      if (this.cameraNotExistResolver === null) {
        throw new Error('cameraNotExistResolver is null');
      }

      this.hideCameraNotExist();

      this.cameraNotExistResolver();

      this.cameraNotExistResolver = null;
    } catch (error) {
      throw error;
    }
  }

  asyncShowAndWaitCameraNotAvailable: IUiViewmodel['asyncShowAndWaitCameraNotAvailable'] = (): Promise<boolean> => {
    try {
      return new Promise((resolve): void => {
        this.cameraNotAvailableResolver = resolve;

        this.showCameraNotAvailable();
      });
    } catch (error) {
      throw error;
    }
  }

  /**
   * @param payload - была ли нажата кнопка "Принять"
   */
  hideAndResolveCameraNotAvailable: IUiViewmodel['hideAndResolveCameraNotAvailable'] = (payload: boolean): void => {
    try {
      if (this.cameraNotAvailableResolver === null) {
        throw new Error('cameraNotAvailableResolver is null');
      }

      this.hideCameraNotAvailable();

      this.cameraNotAvailableResolver(payload);

      this.cameraNotAvailableResolver = null;
    } catch (error) {
      throw error;
    }
  }

  asyncShowProfilePage: IUiViewmodel['asyncShowProfilePage'] = async (): Promise<void> => {
    try {
      await router.push({ name: 'profile' });
    } catch (error) {
      throw error;
    }
  }

  enableVideochatStop: IUiViewmodel['enableVideochatStop'] = (): void => {
    try {
      this.setFlagProperty('isVideochatStopDisabled', false);
    } catch (error) {
      throw error;
    }
  }

  disableVideochatStop: IUiViewmodel['disableVideochatStop'] = (): void => {
    try {
      this.setFlagProperty('isVideochatStopDisabled', true);
    } catch (error) {
      throw error;
    }
  }

  showPersonalOffer: IUiViewmodel['showPersonalOffer'] = (): void => {
    try {
      this.setFlagProperty('isPersonalOfferDisplayed', true);
    } catch (error) {
      throw error;
    }
  }

  hidePersonalOffer: IUiViewmodel['hidePersonalOffer'] = (): void => {
    try {
      this.setFlagProperty('isPersonalOfferDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  showPremiumSubscriptionCardSelectionInsteadPersonalOffer: IUiViewmodel['showPremiumSubscriptionCardSelectionInsteadPersonalOffer'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isPersonalOfferDisplayed: false,
            isPremiumSubscriptionCardSelectionDisplayed: true,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  hidePremiumSubscriptionCardSelection: IUiViewmodel['hidePremiumSubscriptionCardSelection'] = (): void => {
    try {
      this.setFlagProperty('isPremiumSubscriptionCardSelectionDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  showActivePremiumSubscription: IUiViewmodel['showActivePremiumSubscription'] = (): void => {
    try {
      this.setFlagProperty('isActivePremiumSubscriptionDisplayed', true);
    } catch (error) {
      throw error;
    }
  }

  hideActivePremiumSubscription: IUiViewmodel['hideActivePremiumSubscription'] = (): void => {
    try {
      this.setFlagProperty('isActivePremiumSubscriptionDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  showInactivePremiumSubscription: IUiViewmodel['showInactivePremiumSubscription'] = (): void => {
    try {
      this.setFlagProperty('isInactivePremiumSubscriptionDisplayed', true);
    } catch (error) {
      throw error;
    }
  }

  hideInactivePremiumSubscription: IUiViewmodel['hideInactivePremiumSubscription'] = (): void => {
    try {
      this.setFlagProperty('isInactivePremiumSubscriptionDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  showPremiumSubscriptionPurchased: IUiViewmodel['showPremiumSubscriptionPurchased'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isPurchaseSuccessAttentionDisplayed: false,
            isMinutesAddedAttentionDisplayed: false,
            isMinutesReducedAttentionDisplayed: false,
            isPremiumAddedAttentionDisplayed: false,
            isPremiumSubscriptionPurchasedDisplayed: true,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  hidePremiumSubscriptionPurchased: IUiViewmodel['hidePremiumSubscriptionPurchased'] = (): void => {
    try {
      this.setFlagProperty('isPremiumSubscriptionPurchasedDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  showPremiumSubscriptionExplanationInsteadActive: IUiViewmodel['showPremiumSubscriptionExplanationInsteadActive'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isPremiumSubscriptionExplanationDisplayed: true,
            isActivePremiumSubscriptionDisplayed: false,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showActivePremiumSubscriptionInsteadExplanation: IUiViewmodel['showActivePremiumSubscriptionInsteadExplanation'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isPremiumSubscriptionExplanationDisplayed: false,
            isActivePremiumSubscriptionDisplayed: true,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showPremiumSubscriptionCardRemovalInsteadCardSelection: IUiViewmodel['showPremiumSubscriptionCardRemovalInsteadCardSelection'] = (
    payload: ICardEntity['lastNumbers'],
  ): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isPremiumSubscriptionCardSelectionDisplayed: false,
            isPremiumSubscriptionCardRemovalDisplayed: true,
          }),
          buffer: Object.freeze({
            ...this.store.state.ui.buffer,
            cardLastNumbersForRemoval: payload,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showPremiumSubscriptionCardSelectionInsteadCardRemoval: IUiViewmodel['showPremiumSubscriptionCardSelectionInsteadCardRemoval'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isPremiumSubscriptionCardRemovalDisplayed: false,
            isPremiumSubscriptionCardSelectionDisplayed: true,
          }),
          buffer: Object.freeze({
            ...this.store.state.ui.buffer,
            cardLastNumbersForRemoval: undefined,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showActivePremiumSubscriptionInsteadCardRemoval: IUiViewmodel['showActivePremiumSubscriptionInsteadCardRemoval'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isPremiumSubscriptionCardRemovalDisplayed: false,
            isActivePremiumSubscriptionDisplayed: true,
          }),
          buffer: Object.freeze({
            ...this.store.state.ui.buffer,
            cardLastNumbersForRemoval: undefined,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showInactivePremiumSubscriptionInsteadCardRemoval: IUiViewmodel['showInactivePremiumSubscriptionInsteadCardRemoval'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isPremiumSubscriptionCardRemovalDisplayed: false,
            isInactivePremiumSubscriptionDisplayed: true,
          }),
          buffer: Object.freeze({
            ...this.store.state.ui.buffer,
            cardLastNumbersForRemoval: undefined,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showActivePremiumSubscriptionInsteadCardSelection: IUiViewmodel['showActivePremiumSubscriptionInsteadCardSelection'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isPremiumSubscriptionCardSelectionDisplayed: false,
            isActivePremiumSubscriptionDisplayed: true,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showPremiumSubscriptionPurchaseErrorInsteadCardSelection: IUiViewmodel['showPremiumSubscriptionPurchaseErrorInsteadCardSelection'] = (
    payload: IErrorEntity,
  ): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isPremiumSubscriptionCardSelectionDisplayed: false,
            isPremiumSubscriptionPurchaseErrorDisplayed: true,
          }),
          buffer: Object.freeze({
            ...this.store.state.ui.buffer,
            purchaseError: payload.code,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showPremiumSubscriptionCardSelectionInsteadPurchaseError: IUiViewmodel['showPremiumSubscriptionCardSelectionInsteadPurchaseError'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isPremiumSubscriptionPurchaseErrorDisplayed: false,
            isPremiumSubscriptionCardSelectionDisplayed: true,
          }),
          buffer: Object.freeze({
            ...this.store.state.ui.buffer,
            purchaseError: undefined,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showPremiumSubscriptionPurchasedInsteadCardSelection: IUiViewmodel['showPremiumSubscriptionPurchasedInsteadCardSelection'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isPremiumSubscriptionCardSelectionDisplayed: false,
            isPremiumSubscriptionPurchasedDisplayed: true,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showPremiumSubscriptionDeactivate: IUiViewmodel['showPremiumSubscriptionDeactivate'] = (): void => {
    try {
      this.setFlagProperty('isPremiumSubscriptionDeactivateDisplayed', true);
    } catch (error) {
      throw error;
    }
  }

  hidePremiumSubscriptionDeactivate: IUiViewmodel['hidePremiumSubscriptionDeactivate'] = (): void => {
    try {
      this.setFlagProperty('isPremiumSubscriptionDeactivateDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  showPremiumSubscriptionCardSelectionInsteadActive: IUiViewmodel['showPremiumSubscriptionCardSelectionInsteadActive'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isActivePremiumSubscriptionDisplayed: false,
            isUserPremiumSubscriptionChangingCardSelectionDisplayed: true,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showPremiumSubscriptionInactiveInsteadDeactivation: IUiViewmodel['showPremiumSubscriptionInactiveInsteadDeactivation'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isPremiumSubscriptionDeactivateDisplayed: false,
            isInactivePremiumSubscriptionDisplayed: true,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showPersonalOfferInsteadPremiumPurchase: IUiViewmodel['showPersonalOfferInsteadPremiumPurchase'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isPremiumPurchaseAttentionDisplayed: false,
            isPersonalOfferDisplayed: true,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  enableVideochatModeration: IUiViewmodel['enableVideochatModeration'] = (): void => {
    try {
      this.setFlagProperty('isVideochatModerationDisabled', false);
    } catch (error) {
      throw error;
    }
  }

  disableVideochatModeration: IUiViewmodel['disableVideochatModeration'] = (): void => {
    try {
      this.setFlagProperty('isVideochatModerationDisabled', true);
    } catch (error) {
      throw error;
    }
  }

  updateContactNameServerValidationError: IUiViewmodel['updateContactNameServerValidationError'] = (
    payload: IErrorEntity,
  ): void => {
    try {
      switch (payload.code) {
        case 401:
          this.setBufferProperty('contactNameValidationError', EContactNameValidationError.EMPTY);

          break;
        case 402:
          this.setBufferProperty('contactNameValidationError', EContactNameValidationError.SHORT);

          break;
        case 317:
          this.setBufferProperty('contactNameValidationError', EContactNameValidationError.CONTAINS_EMOJI);

          break;
        default:
          throw new Error(`unknown error code: ${payload.code}`);
      }
    } catch (error) {
      throw error;
    }
  }

  updateContactNameClientValidationError: IUiViewmodel['updateContactNameClientValidationError'] = (
    payload: IErrorEntity,
  ): void => {
    try {
      this.setBufferProperty('contactNameValidationError', payload.code);
    } catch (error) {
      throw error;
    }
  }

  removeContactNameValidationError: IUiViewmodel['removeContactNameValidationError'] = (): void => {
    try {
      this.setBufferProperty('contactNameValidationError', undefined);
    } catch (error) {
      throw error;
    }
  }

  showContactNameEditing: IUiViewmodel['showContactNameEditing'] = (): void => {
    try {
      this.setFlagProperty('isContactNameEditingDisplayed', true);
    } catch (error) {
      throw error;
    }
  }

  showContactNameEditingAfterContactAccepting: IUiViewmodel['showContactNameEditingAfterContactAccepting'] = (): void => {
    try {
      this.setFlagProperty('isContactNameEditingAfterContactAcceptingDisplayed', true);
    } catch (error) {
      throw error;
    }
  }

  hideContactNameEditing: IUiViewmodel['hideContactNameEditing'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isContactNameEditingDisplayed: false,
            isContactNameEditingAfterContactAcceptingDisplayed: false,
          }),
          buffer: Object.freeze({
            ...this.store.state.ui.buffer,
            contactNameValidationError: undefined,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showSearchUnavailable: IUiViewmodel['showSearchUnavailable'] = (): void => {
    try {
      this.setFlagProperty('isUnavailableSearchAttentionDisplayed', true);
    } catch (error) {
      throw error;
    }
  }

  hideSearchUnavailable: IUiViewmodel['hideSearchUnavailable'] = (): void => {
    try {
      this.setFlagProperty('isUnavailableSearchAttentionDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  showSignUpInsteadSearchUnavailable: IUiViewmodel['showSignUpInsteadSearchUnavailable'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isUnavailableSearchAttentionDisplayed: false,
            isSignUpAttentionDisplayed: true,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showPremiumPurchaseInsteadSearchUnavailable: IUiViewmodel['showPremiumPurchaseInsteadSearchUnavailable'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isUnavailableSearchAttentionDisplayed: false,
            isPremiumPurchaseAttentionDisplayed: true,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showStoryAbuse: IUiViewmodel['showStoryAbuse'] = (): void => {
    try {
      this.setFlagProperty('isStoryAbuseAttentionDisplayed', true);
    } catch (error) {
      throw error;
    }
  }

  hideStoryAbuse: IUiViewmodel['hideStoryAbuse'] = (): void => {
    try {
      this.setFlagProperty('isStoryAbuseAttentionDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  hideUsageRules: IUiViewmodel['hideUsageRules'] = (): void => {
    try {
      this.setFlagProperty('isUsageRulesAttentionDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  asyncCopyToClipboard: IUiViewmodel['asyncCopyToClipboard'] = async (payload: string): Promise<void> => {
    try {
      await window.navigator.clipboard.writeText(payload);
    } catch (error) {
      throw error;
    }
  }

  showOneClickPremiumPurchaseInsteadPremiumPurchase: IUiViewmodel['showOneClickPremiumPurchaseInsteadPremiumPurchase'] = (
    payload: string,
  ): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isPurchaseControlsDisabled: false,
            isPremiumPurchaseAttentionDisplayed: false,
          }),
        },
      );

      window.open(payload, 'purchase', 'width=1024,height=680');
    } catch (error) {
      throw error;
    }
  }

  showOneClickMinutesPurchaseInsteadPremiumPurchase: IUiViewmodel['showOneClickMinutesPurchaseInsteadPremiumPurchase'] = (
    payload: string,
  ): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isPurchaseControlsDisabled: false,
            isMinutesPurchaseAttentionDisplayed: false,
          }),
        },
      );

      window.open(payload, 'purchase', 'width=1024,height=680');
    } catch (error) {
      throw error;
    }
  }

  showOneClickPremiumSubscriptionPurchase: IUiViewmodel['showOneClickPremiumSubscriptionPurchase'] = (payload: string): void => {
    try {
      window.open(payload, 'purchase', 'width=1024,height=680');
    } catch (error) {
      throw error;
    }
  }

  enableWithdrawalSending: IUiViewmodel['enableWithdrawalSending'] = (): void => {
    try {
      this.setFlagProperty('isWithdrawalRequestSendControlsDisabled', false);
    } catch (error) {
      throw error;
    }
  }

  showEmailConfirmationInsteadSettings: IUiViewmodel['showEmailConfirmationInsteadSettings'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isSettingsAttentionDisplayed: false,
            isUserEmailConfirmationAttentionDisplayed: true,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showEmailConfirmationForFreebieInsteadSettings: IUiViewmodel['showEmailConfirmationForFreebieInsteadSettings'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isSettingsAttentionDisplayed: false,
            isEmailConfirmationForFreebieAttentionDisplayed: true,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showEmailConfirmationInsteadPremiumSubscriptionPurchased: IUiViewmodel['showEmailConfirmationInsteadPremiumSubscriptionPurchased'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isPremiumSubscriptionPurchasedDisplayed: false,
            isUserEmailConfirmationAttentionDisplayed: true,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showEmailConfirmationForFreebieInsteadPremiumSubscriptionPurchased: IUiViewmodel['showEmailConfirmationForFreebieInsteadPremiumSubscriptionPurchased'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isPremiumSubscriptionPurchasedDisplayed: false,
            isEmailConfirmationForFreebieAttentionDisplayed: true,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showSettingsInsteadPremiumSubscriptionPurchased: IUiViewmodel['showSettingsInsteadPremiumSubscriptionPurchased'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isPremiumSubscriptionPurchasedDisplayed: false,
            isSettingsAttentionDisplayed: true,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showContactBlockConfirmation: IUiViewmodel['showContactBlockConfirmation'] = (): void => {
    try {
      this.setFlagProperty('isContactBlockConfirmationAttentionDisplayed', true);
    } catch (error) {
      throw error;
    }
  }

  hideContactBlockConfirmation: IUiViewmodel['hideContactBlockConfirmation'] = (): void => {
    try {
      this.setFlagProperty('isContactBlockConfirmationAttentionDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  asyncShowPersonalOfferInsteadStoryLock: IUiViewmodel['asyncShowPersonalOfferInsteadStoryLock'] = async (): Promise<void> => {
    try {
      await router.push({ name: 'videochat' });

      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isStoryLockDisplayed: false,
            isPersonalOfferDisplayed: true,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showPersonalOfferInsteadCountdownLock: IUiViewmodel['showPersonalOfferInsteadCountdownLock'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            videochatPageStage: EVideochatPageStage.START,
            isCountdownLockDisplayed: false,
            isPersonalOfferDisplayed: true,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  disableMessagesSending: IUiViewmodel['disableMessagesSending'] = (): void => {
    try {
      this.setFlagProperty('isMessagesSendingDisabled', true);
    } catch (error) {
      throw error;
    }
  }

  enableMessagesSending: IUiViewmodel['enableMessagesSending'] = (): void => {
    try {
      this.setFlagProperty('isMessagesSendingDisabled', false);
    } catch (error) {
      throw error;
    }
  }

  showEmailConfirmationForFreebieAttention: IUiViewmodel['showEmailConfirmationForFreebieAttention'] = (): void => {
    try {
      this.setFlagProperty('isEmailConfirmationForFreebieAttentionDisplayed', true);
    } catch (error) {
      throw error;
    }
  }

  hideEmailConfirmationForFreebieAttention: IUiViewmodel['hideEmailConfirmationForFreebieAttention'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isEmailConfirmationForFreebieAttentionDisplayed: false,
          }),
          buffer: Object.freeze({
            ...this.store.state.ui.buffer,
            userEmailConfirmationValidationError: undefined,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showWithdrawalRejectionInsteadConfirmation: IUiViewmodel['showWithdrawalRejectionInsteadConfirmation'] = (
    payload: IViewmodel['ui']['buffer']['withdrawalRequestRejectionReason'],
  ): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isWithdrawalRequestRejectedAttentionDisplayed: true,
            isWithdrawalConfirmationAttentionDisplayed: false,
          }),
          buffer: Object.freeze({
            ...this.store.state.ui.buffer,
            withdrawalRequestRejectionReason: payload,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showWithdrawalFinishInsteadConfirmation: IUiViewmodel['showWithdrawalFinishInsteadConfirmation'] = (): void => {
    try {
      this.store.commit(
        'updateUi',
        {
          ...this.store.state.ui,
          flags: Object.freeze({
            ...this.store.state.ui.flags,
            isWithdrawalConfirmationAttentionDisplayed: false,
            isFinishWithdrawalAttentionDisplayed: true,
          }),
        },
      );
    } catch (error) {
      throw error;
    }
  }

  showSearchStartWarning: IUiViewmodel['showSearchStartWarning'] = (): void => {
    try {
      this.setFlagProperty('isSearchStartWarningDisplayed', true);
    } catch (error) {
      throw error;
    }
  }

  hideSearchStartWarning: IUiViewmodel['hideSearchStartWarning'] = (): void => {
    try {
      this.setFlagProperty('isSearchStartWarningDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  disableAccountDeletion: IUiViewmodel['disableAccountDeletion'] = (): void => {
    try {
      this.setBufferProperty('isAccountDeletionDisabled', true);
    } catch (error) {
      throw error;
    }
  }

  showUserAccessDeniedAttention: IUiViewmodel['showUserAccessDeniedAttention'] = (): void => {
    try {
      this.setFlagProperty('isUserAccessDeniedAttentionDisplayed', true);
    } catch (error) {
      throw error;
    }
  }

  hideUserAccessDeniedAttention: IUiViewmodel['hideUserAccessDeniedAttention'] = (): void => {
    try {
      this.setFlagProperty('isUserAccessDeniedAttentionDisplayed', false);
    } catch (error) {
      throw error;
    }
  }

  hideNewVersionButtonHighlight: IUiViewmodel['hideNewVersionButtonHighlight'] = (): void => {
    try {
      this.setFlagProperty('isNewVersionButtonHighlighted', false);
    } catch (error) {
      throw error;
    }
  }
}

export default UiViewmodel;
