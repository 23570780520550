// eslint-disable-next-line import/prefer-default-export
export enum EPushNotificationType {
  PingClient = 'PingClient',
  PingClientComplete = 'PingClientComplete',
  AppLoadComplete = 'AppLoadComplete',
}

export interface IPushNotificationMessage {
  context: string,
  procedure: EPushNotificationType,
  payload?: any,
}

export interface IPushNotificationPingClient {
  contactId?: string,
  receiverId: string,
}

export interface IPushNotificationPingClientComplete {}

export interface IPushNotificationAppLoadComplete {}
