import store from '@/store';
import IViewmodel, { IVideochatViewmodel } from '@/core/interactors/types/viewmodels';
import IVideochatEntity from '@/core/entities/videochat.entity';
import ICompanionEntity from '@/core/entities/companion.entity';
import IMessageEntity from '@/core/entities/message.entity';
import { NonNullablePick } from '@/core/types';
import { EVideochatStage } from '@/enums';

class VideochatViewmodel {
  private store = store;

  private static instance: VideochatViewmodel;

  static getInstance(): VideochatViewmodel {
    try {
      if (VideochatViewmodel.instance === undefined) {
        VideochatViewmodel.instance = new VideochatViewmodel();
      }

      return VideochatViewmodel.instance;
    } catch (error) {
      throw error;
    }
  }

  private setProperty<
    Property extends keyof IVideochatEntity,
    Payload extends IVideochatEntity[Property],
  >(
    property: Property,
    payload: Payload,
  ): void {
    try {
      this.store.commit(
        'updateVideochat',
        Object.freeze({
          ...this.store.state.entities.videochat,
          [property]: payload,
        }),
      );
    } catch (error) {
      throw error;
    }
  }

  private setCompanionProperty<
    Property extends keyof ICompanionEntity,
    Payload extends ICompanionEntity[Property],
  >(
    property: Property,
    payload: Payload,
  ): void {
    try {
      const { videochat } = this.store.state.entities;

      if (videochat.companion === null) {
        throw new Error('videochat companion is null');
      }

      this.setProperty('companion', {
        ...videochat.companion,
        [property]: payload,
      });
    } catch (error) {
      throw error;
    }
  }

  private watchProperty<Property extends keyof IVideochatEntity>(
    property: Property,
    callback: (payload: IVideochatEntity[Property]) => void,
  ): void {
    try {
      this.store.watch((state) => state.entities.videochat[property], callback);
    } catch (error) {
      throw error;
    }
  }

  get: IVideochatViewmodel['get'] = (): IViewmodel['entities']['videochat'] => {
    try {
      return this.store.state.entities.videochat;
    } catch (error) {
      throw error;
    }
  }

  set: IVideochatViewmodel['set'] = (payload: IViewmodel['entities']['videochat']): void => {
    try {
      this.store.commit('updateVideochat', Object.freeze({ ...payload }));
    } catch (error) {
      throw error;
    }
  }

  updateOnSearchSuccess: IVideochatViewmodel['updateOnSearchSuccess'] = (
    payload: NonNullablePick<IVideochatEntity, 'id' | 'companion' | 'stage' | 'startTime'>,
  ): void => {
    try {
      this.store.commit(
        'updateVideochat',
        Object.freeze({
          ...this.store.state.entities.videochat,
          ...payload,
          queuePosition: undefined,
        }),
      );
    } catch (error) {
      throw error;
    }
  }

  setId: IVideochatViewmodel['setId'] = (payload: IVideochatEntity['id']): void => {
    try {
      this.setProperty('id', payload);
    } catch (error) {
      throw error;
    }
  }

  setCompanion: IVideochatViewmodel['setCompanion'] = (payload: ICompanionEntity): void => {
    try {
      this.setProperty('companion', payload);
    } catch (error) {
      throw error;
    }
  }

  setCompanionIsCommunicationRestrictionWarningRequired: IVideochatViewmodel['setCompanionIsCommunicationRestrictionWarningRequired'] = (payload: ICompanionEntity['isCommunicationRestrictionWarningRequired']): void => {
    try {
      this.setCompanionProperty('isCommunicationRestrictionWarningRequired', payload);
    } catch (error) {
      throw error;
    }
  }

  setCompanionNumberOfMillisecondsRemaining:
    IVideochatViewmodel['setCompanionNumberOfMillisecondsRemaining'] = (
      payload: ICompanionEntity['maleNumberOfMillisecondsRemaining'],
    ): void => {
      try {
        this.setCompanionProperty('maleNumberOfMillisecondsRemaining', payload);
      } catch (error) {
        throw error;
      }
    }

  addCompanionNumberOfMillisecondsRemaining: IVideochatViewmodel['addCompanionNumberOfMillisecondsRemaining'] = (
    payload: number,
  ): void => {
    try {
      const { videochat } = this.store.state.entities;

      if (
        videochat.companion === null
        || videochat.companion.maleNumberOfMillisecondsRemaining === undefined
      ) {
        throw new Error('videochat companion remaining time is undefined');
      }

      this.setCompanionProperty(
        'maleNumberOfMillisecondsRemaining',
        videochat.companion.maleNumberOfMillisecondsRemaining + payload,
      );
    } catch (error) {
      throw error;
    }
  }

  setCompanionIsTyping: IVideochatViewmodel['setCompanionIsTyping'] = (
    payload: ICompanionEntity['isTyping'],
  ): void => {
    try {
      this.setCompanionProperty('isTyping', payload);
    } catch (error) {
      throw error;
    }
  }

  setCompanionGifts: IVideochatViewmodel['setCompanionGifts'] = (payload: ICompanionEntity['gifts']): void => {
    try {
      this.setCompanionProperty('gifts', payload);
    } catch (error) {
      throw error;
    }
  }

  setCompanionNote: IVideochatViewmodel['setCompanionNote'] = (payload: ICompanionEntity['note']): void => {
    try {
      this.setCompanionProperty('note', payload);
    } catch (error) {
      throw error;
    }
  }

  setCompanionRate: IVideochatViewmodel['setCompanionRate'] = (payload: ICompanionEntity['rate']): void => {
    try {
      this.setCompanionProperty('rate', payload);
    } catch (error) {
      throw error;
    }
  }

  setCompanionIsFaceDetected: IVideochatViewmodel['setCompanionIsFaceDetected'] = (
    payload: ICompanionEntity['isFaceDetected'],
  ): void => {
    try {
      this.setCompanionProperty('isFaceDetected', payload);
    } catch (error) {
      throw error;
    }
  }

  setCompanionContactStatus: IVideochatViewmodel['setCompanionContactStatus'] = (
    payload: ICompanionEntity['contactStatus'],
  ): void => {
    try {
      this.setCompanionProperty('contactStatus', payload);
    } catch (error) {
      throw error;
    }
  }

  setCompanionContactData: IVideochatViewmodel['setCompanionContactData'] = (payload: {
    isContact: ICompanionEntity['isContact'],
    contactStatus: ICompanionEntity['contactStatus'],
  }): void => {
    try {
      const { videochat } = this.store.state.entities;

      if (videochat.companion === null) {
        throw new Error('videochat companion is null');
      }

      this.setProperty('companion', {
        ...videochat.companion,
        isContact: payload.isContact,
        contactStatus: payload.contactStatus,
      });
    } catch (error) {
      throw error;
    }
  }

  setCompanionMessagesListItem: IVideochatViewmodel['setCompanionMessagesListItem'] = (
    payload: IMessageEntity,
  ): void => {
    try {
      const { videochat } = this.store.state.entities;

      if (videochat.companion === null) {
        throw new Error('videochat companion is null');
      }

      this.setCompanionProperty('messages', {
        list: {
          ...videochat.companion.messages.list,
          [payload.id]: payload,
        },
      });
    } catch (error) {
      throw error;
    }
  }

  unsetCompanionMessagesListItem: IVideochatViewmodel['unsetCompanionMessagesListItem'] = (
    payload: IMessageEntity['id'],
  ): void => {
    try {
      const { videochat } = this.store.state.entities;

      if (videochat.companion === null) {
        throw new Error('videochat companion is null');
      }

      const companionMessagesList = { ...videochat.companion.messages.list };

      delete companionMessagesList[payload];

      this.setCompanionProperty('messages', {
        list: companionMessagesList,
      });
    } catch (error) {
      throw error;
    }
  }

  setCompanionMessagesListItemInsteadTemporary: IVideochatViewmodel['setCompanionMessagesListItemInsteadTemporary'] = (
    payload: {
      temporaryMessageId: IMessageEntity['id'],
      message: IMessageEntity,
    },
  ): void => {
    try {
      const { videochat } = this.store.state.entities;

      if (videochat.companion === null) {
        throw new Error('videochat companion is null');
      }

      const companionMessagesList = { ...videochat.companion.messages.list };

      delete companionMessagesList[payload.temporaryMessageId];

      this.setCompanionProperty('messages', {
        list: {
          ...companionMessagesList,
          [payload.message.id]: payload.message,
        },
      });
    } catch (error) {
      throw error;
    }
  }

  setStage: IVideochatViewmodel['setStage'] = (payload: IVideochatEntity['stage']): void => {
    try {
      this.setProperty('stage', payload);
    } catch (error) {
      throw error;
    }
  }

  setSearchStageAndRemoveCompanion: IVideochatViewmodel['setSearchStageAndRemoveCompanion'] = (): void => {
    try {
      this.store.commit(
        'updateVideochat',
        Object.freeze({
          ...this.store.state.entities.videochat,
          companion: null,
          stage: EVideochatStage.SEARCH,
          stopReason: undefined,
          shouldContinueSearch: undefined,
          rateCompanionId: undefined,
          promoCompanionId: undefined,
          startTime: undefined,
          duration: undefined,
        }),
      );
    } catch (error) {
      throw error;
    }
  }

  setDuration: IVideochatViewmodel['setDuration'] = (payload: IVideochatEntity['duration']): void => {
    try {
      this.setProperty('duration', payload);
    } catch (error) {
      throw error;
    }
  }

  setLastScreenshotTime: IVideochatViewmodel['setLastScreenshotTime'] = (payload: IVideochatEntity['lastScreenshotTime']): void => {
    try {
      this.setProperty('lastScreenshotTime', payload);
    } catch (error) {
      throw error;
    }
  }

  setQueuePosition: IVideochatViewmodel['setQueuePosition'] = (
    payload: NonNullable<IVideochatEntity['queuePosition']>,
  ): void => {
    try {
      this.setProperty('queuePosition', payload);
    } catch (error) {
      throw error;
    }
  }

  removeQueuePosition: IVideochatViewmodel['removeQueuePosition'] = (): void => {
    try {
      this.setProperty('queuePosition', undefined);
    } catch (error) {
      throw error;
    }
  }

  setCompanionAndRemoveQueuePosition: IVideochatViewmodel['setCompanionAndRemoveQueuePosition'] = (
    payload: ICompanionEntity,
  ): void => {
    try {
      this.store.commit(
        'updateVideochat',
        Object.freeze({
          ...this.store.state.entities.videochat,
          companion: payload,
          queuePosition: undefined,
        }),
      );
    } catch (error) {
      throw error;
    }
  }

  setStageAndRemoveQueuePosition: IVideochatViewmodel['setStageAndRemoveQueuePosition'] = (
    payload: IVideochatEntity['stage'],
  ): void => {
    try {
      this.store.commit(
        'updateVideochat',
        Object.freeze({
          ...this.store.state.entities.videochat,
          stage: payload,
          queuePosition: undefined,
        }),
      );
    } catch (error) {
      throw error;
    }
  }

  setLastFirstFrameTime: IVideochatViewmodel['setLastFirstFrameTime'] = (
    payload: IVideochatEntity['lastFirstFrameTime'],
  ): void => {
    try {
      this.setProperty('lastFirstFrameTime', payload);
    } catch (error) {
      throw error;
    }
  }

  setLastCompanionTime: IVideochatViewmodel['setLastCompanionTime'] = (
    payload: IVideochatEntity['lastCompanionTime'],
  ): void => {
    try {
      this.setProperty('lastCompanionTime', payload);
    } catch (error) {
      throw error;
    }
  }

  setPostProcessStage: IVideochatViewmodel['setPostProcessStage'] = (payload: Pick<
    IVideochatEntity,
    'stopReason'
    | 'shouldContinueSearch'
    | 'rateCompanionId'
    | 'promoCompanionId'
  >): void => {
    try {
      const { videochat } = this.store.state.entities;

      this.store.commit(
        'updateVideochat',
        Object.freeze({
          ...videochat,
          stage: EVideochatStage.POST_PROCESS,
          stopReason: payload.stopReason,
          shouldContinueSearch: payload.shouldContinueSearch,
          rateCompanionId: payload.rateCompanionId,
          promoCompanionId: payload.promoCompanionId,
          lastFirstFrameTime: undefined,
          lastCompanionTime: undefined,
          companion: {
            ...videochat.companion,
            isTyping: false,
          },
        }),
      );
    } catch (error) {
      throw error;
    }
  }

  setCompanionName: IVideochatViewmodel['setCompanionName'] = (payload: ICompanionEntity['name']): void => {
    try {
      this.setCompanionProperty('name', payload);
    } catch (error) {
      throw error;
    }
  }

  subscribeToStageChange: IVideochatViewmodel['subscribeToStageChange'] = (
    payload: (value: IVideochatEntity['stage']) => void,
  ): void => {
    try {
      this.watchProperty('stage', payload);
    } catch (error) {
      throw error;
    }
  }
}

export default VideochatViewmodel;
