import store from '@/store';
import IViewmodel, { IUserViewmodel } from '@/core/interactors/types/viewmodels';
import IUserEntity from '@/core/entities/user.entity';
import ICardEntity from '@/core/entities/card.entity';
import IPremiumSubscriptionEntity from '@/core/entities/premium-subscription.entity';

class UserViewmodel {
  private store = store;

  private static instance: UserViewmodel;

  // eslint-disable-next-line no-useless-constructor, no-empty-function, @typescript-eslint/no-empty-function
  private constructor() {}

  static getInstance(): UserViewmodel {
    try {
      if (UserViewmodel.instance === undefined) {
        UserViewmodel.instance = new UserViewmodel();
      }

      return UserViewmodel.instance;
    } catch (error) {
      throw error;
    }
  }

  private setProperty<
    Property extends keyof IUserEntity,
    Payload extends IUserEntity[Property],
  >(
    property: Property,
    payload: Payload,
  ): void {
    try {
      this.store.commit(
        'updateUser',
        Object.freeze({
          ...this.store.state.entities.user,
          [property]: payload,
        }),
      );
    } catch (error) {
      throw error;
    }
  }

  private watchProperty<Property extends keyof IUserEntity>(
    property: Property,
    callback: (payload: IUserEntity[Property]) => void,
  ): void {
    try {
      this.store.watch((state) => state.entities.user[property], callback);
    } catch (error) {
      throw error;
    }
  }

  get: IUserViewmodel['get'] = (): IViewmodel['entities']['user'] => {
    try {
      return this.store.state.entities.user;
    } catch (error) {
      throw error;
    }
  }

  set: IUserViewmodel['set'] = (payload: IViewmodel['entities']['user']): void => {
    try {
      /**
       * Если в новых данных отсутствует хэш пользователя, берем его из текущих данных юзера
       * TODO: временное решение, придумать другое
       */
      if (payload.hash === undefined) {
        this.store.commit(
          'updateUser',
          Object.freeze({
            ...payload,
            hash: this.store.state.entities.user.hash,
          }),
        );
      } else {
        this.store.commit(
          'updateUser',
          Object.freeze({
            ...payload,
          }),
        );
      }
    } catch (error) {
      throw error;
    }
  }

  update: IUserViewmodel['update'] = (payload: Partial<IUserEntity>): void => {
    try {
      this.store.commit(
        'updateUser',
        Object.freeze({
          ...this.store.state.entities.user,
          ...payload,
        }),
      );
    } catch (error) {
      throw error;
    }
  }

  updateWithPremiumSubscription: IUserViewmodel['updateWithPremiumSubscription'] = (
    payload: {
      user: Partial<IUserEntity>,
      bonusMillisecondsRemaining: IPremiumSubscriptionEntity['bonusMillisecondsRemaining'] | undefined,
    },
  ): void => {
    try {
      const user = {
        ...this.store.state.entities.user,
        ...payload.user,
      };

      if (
        this.store.state.entities.user.malePremiumSubscription !== null
        && payload.bonusMillisecondsRemaining !== undefined
      ) {
        user.malePremiumSubscription = {
          ...this.store.state.entities.user.malePremiumSubscription,
          bonusMillisecondsRemaining: payload.bonusMillisecondsRemaining,
        };
      }

      this.store.commit('updateUser', Object.freeze(user));
    } catch (error) {
      throw error;
    }
  }

  setGender: IUserViewmodel['setGender'] = (payload: IUserEntity['gender']): void => {
    try {
      this.setProperty('gender', payload);
    } catch (error) {
      throw error;
    }
  }

  setHash: IUserViewmodel['setHash'] = (payload: IUserEntity['hash']): void => {
    try {
      this.setProperty('hash', payload);
    } catch (error) {
      throw error;
    }
  }

  setAvatar: IUserViewmodel['setAvatar'] = (payload: IUserEntity['avatar']): void => {
    try {
      this.setProperty('avatar', payload);
    } catch (error) {
      throw error;
    }
  }

  removeAvatar: IUserViewmodel['removeAvatar'] = (): void => {
    try {
      this.setProperty('avatar', null);
    } catch (error) {
      throw error;
    }
  }

  setName: IUserViewmodel['setName'] = (payload: IUserEntity['name']): void => {
    try {
      this.setProperty('name', payload);
    } catch (error) {
      throw error;
    }
  }

  setEmail: IUserViewmodel['setEmail'] = (payload: IUserEntity['email']): void => {
    try {
      this.setProperty('email', payload);
    } catch (error) {
      throw error;
    }
  }

  setLockExpirationTime: IUserViewmodel['setLockExpirationTime'] = (
    payload: IUserEntity['lockExpirationTime'],
  ): void => {
    try {
      this.setProperty('lockExpirationTime', payload);
    } catch (error) {
      throw error;
    }
  }

  removeLockExpirationTime: IUserViewmodel['removeLockExpirationTime'] = (): void => {
    try {
      this.setProperty('lockExpirationTime', undefined);
    } catch (error) {
      throw error;
    }
  }

  removeConfirmationEmail: IUserViewmodel['removeConfirmationEmail'] = (): void => {
    try {
      this.setProperty('confirmationEmail', undefined);
    } catch (error) {
      throw error;
    }
  }

  updatePersonalData: IUserViewmodel['updatePersonalData'] = (payload: {
    ageRange: IUserEntity['ageRange'],
    isEmailChangeBlocked: IUserEntity['isEmailChangeBlocked'],
    confirmationEmail: IUserEntity['confirmationEmail'],
  }): void => {
    try {
      this.store.commit(
        'updateUser',
        Object.freeze({
          ...this.store.state.entities.user,
          ageRange: payload.ageRange,
          isEmailChangeBlocked: payload.isEmailChangeBlocked,
          confirmationEmail: payload.confirmationEmail,
        }),
      );
    } catch (error) {
      throw error;
    }
  }

  setAccountDeletionCodeFirstPart: IUserViewmodel['setAccountDeletionCodeFirstPart'] = (
    payload: IUserEntity['accountDeletionCodeFirstPart'],
  ): void => {
    try {
      this.setProperty('accountDeletionCodeFirstPart', payload);
    } catch (error) {
      throw error;
    }
  }

  setAccountDeletionCodeFull: IUserViewmodel['setAccountDeletionCodeFull'] = (
    payload: IUserEntity['accountDeletionCodeFull'],
  ): void => {
    try {
      this.setProperty('accountDeletionCodeFull', payload);
    } catch (error) {
      throw error;
    }
  }

  setEmailNotificationSettings: IUserViewmodel['setEmailNotificationSettings'] = (
    payload: IUserEntity['emailNotificationSettings'],
  ): void => {
    try {
      this.setProperty('emailNotificationSettings', payload);
    } catch (error) {
      throw error;
    }
  }

  setPushNotificationSettingsIsCountdownLockEndNotificationEnabled:
    IUserViewmodel['setPushNotificationSettingsIsCountdownLockEndNotificationEnabled'] = (
      payload: IUserEntity['pushNotificationSettings']['isCountdownLockEndNotificationEnabled'],
    ): void => {
      try {
        this.setProperty('pushNotificationSettings', {
          ...this.store.state.entities.user.pushNotificationSettings,
          isCountdownLockEndNotificationEnabled: payload,
        });
      } catch (error) {
        throw error;
      }
    }

  setPushNotificationSettingsIsForClosedAppRequired: IUserViewmodel['setPushNotificationSettingsIsForClosedAppRequired'] = (
    payload: IUserEntity['pushNotificationSettings']['isForClosedAppRequired'],
  ): void => {
    try {
      this.setProperty('pushNotificationSettings', {
        ...this.store.state.entities.user.pushNotificationSettings,
        isForClosedAppRequired: payload,
      });
    } catch (error) {
      throw error;
    }
  }

  setPushNotificationSettingsIsMessageTextDisplayed: IUserViewmodel['setPushNotificationSettingsIsMessageTextDisplayed'] = (
    payload: IUserEntity['pushNotificationSettings']['isMessageTextDisplayed'],
  ): void => {
    try {
      this.setProperty('pushNotificationSettings', {
        ...this.store.state.entities.user.pushNotificationSettings,
        isMessageTextDisplayed: payload,
      });
    } catch (error) {
      throw error;
    }
  }

  setPushNotificationSettingsIsSubscribed: IUserViewmodel['setPushNotificationSettingsIsSubscribed'] = (
    payload: IUserEntity['pushNotificationSettings']['isSubscribed'],
  ): void => {
    try {
      this.setProperty('pushNotificationSettings', {
        ...this.store.state.entities.user.pushNotificationSettings,
        isSubscribed: payload,
      });
    } catch (error) {
      throw error;
    }
  }

  disablePushNotificationSettings: IUserViewmodel['disablePushNotificationSettings'] = (): void => {
    try {
      this.setProperty('pushNotificationSettings', {
        ...this.store.state.entities.user.pushNotificationSettings,
        isSubscribed: false,
        isForClosedAppRequired: false,
        isMessageTextDisplayed: false,
        isCountdownLockEndNotificationEnabled: false,
        isBonusMinutesEnabled: false,
      });
    } catch (error) {
      throw error;
    }
  }

  enablePushNotificationSettings: IUserViewmodel['enablePushNotificationSettings'] = (): void => {
    try {
      const { user } = this.store.state.entities;

      this.setProperty('pushNotificationSettings', {
        ...user.pushNotificationSettings,
        isSubscribed: true,
        isForClosedAppRequired: true,
        isMessageTextDisplayed: true,
        isBonusMinutesEnabled: user.malePremiumSubscription !== null,
      });
    } catch (error) {
      throw error;
    }
  }

  setIsUsageRulesAttentionAccepted: IUserViewmodel['setIsUsageRulesAttentionAccepted'] = (
    payload: IUserEntity['isUsageRulesAttentionAccepted'],
  ): void => {
    try {
      this.setProperty('isUsageRulesAttentionAccepted', payload);
    } catch (error) {
      throw error;
    }
  }

  updateTranslation: IUserViewmodel['updateTranslation'] = (payload: {
    isTranslationEnabled: IUserEntity['isTranslationEnabled'],
    translationLang: IUserEntity['translationLang'],
  }): void => {
    try {
      this.store.commit(
        'updateUser',
        Object.freeze({
          ...this.store.state.entities.user,
          isTranslationEnabled: payload.isTranslationEnabled,
          translationLang: payload.translationLang,
        }),
      );
    } catch (error) {
      throw error;
    }
  }

  setIsSettingsViewed: IUserViewmodel['setIsSettingsViewed'] = (payload: IUserEntity['isSettingsViewed']): void => {
    try {
      this.setProperty('isSettingsViewed', payload);
    } catch (error) {
      throw error;
    }
  }

  setMalePremiumPackages: IUserViewmodel['setMalePremiumPackages'] = (
    payload: IUserEntity['malePremiumPackages'],
  ): void => {
    try {
      this.setProperty('malePremiumPackages', payload);
    } catch (error) {
      throw error;
    }
  }

  setIsTrialMalePremiumPackageEnabled: IUserViewmodel['setIsTrialMalePremiumPackageEnabled'] = (
    payload: IUserEntity['isTrialMalePremiumPackageEnabled'],
  ): void => {
    try {
      this.setProperty('isTrialMalePremiumPackageEnabled', payload);
    } catch (error) {
      throw error;
    }
  }

  setIsMalePremiumExtensionEnabled: IUserViewmodel['setIsMalePremiumExtensionEnabled'] = (
    payload: IUserEntity['isMalePremiumExtensionEnabled'],
  ): void => {
    try {
      this.setProperty('isMalePremiumExtensionEnabled', payload);
    } catch (error) {
      throw error;
    }
  }

  setMaleCards: IUserViewmodel['setMaleCards'] = (payload: IUserEntity['maleCards']): void => {
    try {
      this.setProperty('maleCards', payload);
    } catch (error) {
      throw error;
    }
  }

  updateCardsAfterRemoval: IUserViewmodel['updateCardsAfterRemoval'] = (payload: {
    cards: IUserEntity['maleCards'],
    minutesPackages: IUserEntity['maleMinutesPackages'],
    premiumSubscription: IUserEntity['malePremiumSubscription'],
  }): void => {
    try {
      this.store.commit(
        'updateUser',
        Object.freeze({
          ...this.store.state.entities.user,
          maleMinutesPackages: payload.minutesPackages,
          maleCards: payload.cards,
          malePremiumSubscription: payload.premiumSubscription,
        }),
      );
    } catch (error) {
      throw error;
    }
  }

  updateCardsAfterChange: IUserViewmodel['updateCardsAfterChange'] = (payload: {
    maleCards: IUserEntity['maleCards'],
    malePremiumSubscription: IUserEntity['malePremiumSubscription'],
    isMalePremiumExtensionEnabled: IUserEntity['isMalePremiumExtensionEnabled'],
    pushNotificationSettings: {
      isBonusMinutesEnabled: IUserEntity['pushNotificationSettings']['isBonusMinutesEnabled'],
    },
  }): void => {
    try {
      const { user } = this.store.state.entities;

      this.store.commit(
        'updateUser',
        Object.freeze({
          ...user,
          maleCards: payload.maleCards,
          malePremiumSubscription: payload.malePremiumSubscription,
          isMalePremiumExtensionEnabled: payload.isMalePremiumExtensionEnabled,
          pushNotificationSettings: {
            ...user.pushNotificationSettings,
            isBonusMinutesEnabled: payload.pushNotificationSettings.isBonusMinutesEnabled,
          },
        }),
      );
    } catch (error) {
      throw error;
    }
  }

  setMaleCardsMinutesAutoPurchase: IUserViewmodel['setMaleCardsMinutesAutoPurchase'] = (
    payload: IUserEntity['maleCards']['minutesAutoPurchase'],
  ): void => {
    try {
      this.setProperty('maleCards', {
        ...this.store.state.entities.user.maleCards,
        minutesAutoPurchase: payload,
      });
    } catch (error) {
      throw error;
    }
  }

  setMaleCardConfirmation: IUserViewmodel['setMaleCardConfirmation'] = (
    payload: IUserEntity['maleCardConfirmation'],
  ): void => {
    try {
      this.setProperty('maleCardConfirmation', payload);
    } catch (error) {
      throw error;
    }
  }

  setMaleMinutesPackages: IUserViewmodel['setMaleMinutesPackages'] = (
    payload: IUserEntity['maleMinutesPackages'],
  ): void => {
    try {
      this.setProperty('maleMinutesPackages', payload);
    } catch (error) {
      throw error;
    }
  }

  setMalePremiumExpirationTime: IUserViewmodel['setMalePremiumExpirationTime'] = (
    payload: IUserEntity['malePremiumExpirationTime'],
  ): void => {
    try {
      this.setProperty('malePremiumExpirationTime', payload);
    } catch (error) {
      throw error;
    }
  }

  setMaleNumberOfMessagesAvailableForSending: IUserViewmodel['setMaleNumberOfMessagesAvailableForSending'] = (
    payload: IUserEntity['maleNumberOfMessagesAvailableForSending'],
  ): void => {
    try {
      this.setProperty('maleNumberOfMessagesAvailableForSending', payload);
    } catch (error) {
      throw error;
    }
  }

  setMaleNumberOfMillisecondsRemaining: IUserViewmodel['setMaleNumberOfMillisecondsRemaining'] = (
    payload: IUserEntity['maleNumberOfMillisecondsRemaining'],
  ): void => {
    try {
      this.setProperty('maleNumberOfMillisecondsRemaining', payload);
    } catch (error) {
      throw error;
    }
  }

  setIsMalePremium: IUserViewmodel['setIsMalePremium'] = (payload: IUserEntity['isMalePremium']): void => {
    try {
      this.setProperty('isMalePremium', payload);
    } catch (error) {
      throw error;
    }
  }

  setFemalePoints: IUserViewmodel['setFemalePoints'] = (payload: IUserEntity['femalePoints']): void => {
    try {
      this.setProperty('femalePoints', payload);
    } catch (error) {
      throw error;
    }
  }

  setFemaleHolidaysAndDailyStatistics: IUserViewmodel['setFemaleHolidaysAndDailyStatistics'] = (payload: Pick<
    IUserEntity,
    'femaleHolidays'
    | 'femaleDailyStatistics'
  >): void => {
    try {
      this.store.commit(
        'updateUser',
        Object.freeze({
          ...this.store.state.entities.user,
          femaleHolidays: payload.femaleHolidays,
          femaleDailyStatistics: payload.femaleDailyStatistics,
        }),
      );
    } catch (error) {
      throw error;
    }
  }

  setWithdrawalSavedMethods: IUserViewmodel['setWithdrawalSavedMethods'] = (
    payload: IUserEntity['withdrawalSavedMethods'],
  ): void => {
    try {
      this.setProperty('withdrawalSavedMethods', payload);
    } catch (error) {
      throw error;
    }
  }

  setBan: IUserViewmodel['setBan'] = (payload: IUserEntity['ban']): void => {
    try {
      this.setProperty('ban', payload);
    } catch (error) {
      throw error;
    }
  }

  removeBan: IUserViewmodel['removeBan'] = (): void => {
    try {
      this.setProperty('ban', null);
    } catch (error) {
      throw error;
    }
  }

  setMaleMinutesAutoPurchase: IUserViewmodel['setMaleMinutesAutoPurchase'] = (payload: {
    hasError: IUserEntity['hasMaleMinutesAutoPurchaseError'],
    minutesPackage: IUserEntity['maleMinutesPackages']['autoPurchase'],
    card: IUserEntity['maleCards']['minutesAutoPurchase'],
  }): void => {
    try {
      const { user } = this.store.state.entities;

      this.store.commit(
        'updateUser',
        Object.freeze({
          ...user,
          hasMaleMinutesAutoPurchaseError: payload.hasError,
          maleMinutesPackages: {
            ...user.maleMinutesPackages,
            autoPurchase: payload.minutesPackage,
          },
          maleCards: {
            ...user.maleCards,
            minutesAutoPurchase: payload.card,
          },
        }),
      );
    } catch (error) {
      throw error;
    }
  }

  updatePremiumSubscriptionCard: IUserViewmodel['updatePremiumSubscriptionCard'] = (
    payload: ICardEntity['lastNumbers'],
  ): void => {
    try {
      const { user } = this.store.state.entities;

      this.store.commit(
        'updateUser',
        Object.freeze({
          ...user,
          malePremiumSubscription: {
            ...user.malePremiumSubscription,
            cardLastNumbers: payload,
            isActive: true,
          },
        }),
      );
    } catch (error) {
      throw error;
    }
  }

  setPremiumSubscription: IUserViewmodel['setPremiumSubscription'] = (
    payload: IUserEntity['malePremiumSubscription'],
  ): void => {
    try {
      this.setProperty('malePremiumSubscription', payload);
    } catch (error) {
      throw error;
    }
  }

  removeMalePremiumSubscriptionAndSetPremiumExtensionEnabled: IUserViewmodel['removeMalePremiumSubscriptionAndSetPremiumExtensionEnabled'] = (
    payload: IUserEntity['isMalePremiumExtensionEnabled'],
  ): void => {
    try {
      const { user } = this.store.state.entities;

      this.store.commit(
        'updateUser',
        Object.freeze({
          ...user,
          isMalePremiumExtensionEnabled: payload,
          malePremiumSubscription: null,
          maleCards: {
            ...user.maleCards,
            premiumSubscription: null,
          },
        }),
      );
    } catch (error) {
      throw error;
    }
  }

  disableMalePremiumSubscription: IUserViewmodel['disableMalePremiumSubscription'] = (
    payload: IUserEntity['isMalePremiumExtensionEnabled'],
  ): void => {
    try {
      const { user } = this.store.state.entities;

      this.store.commit(
        'updateUser',
        Object.freeze({
          ...user,
          isMalePremiumExtensionEnabled: payload,
          malePremiumSubscription: {
            ...user.malePremiumSubscription,
            isActive: false,
          },
        }),
      );
    } catch (error) {
      throw error;
    }
  }

  setPushNotificationBonus: IUserViewmodel['setPushNotificationBonus'] = (
    payload: IUserEntity['pushNotificationBonusMinutes'],
  ): void => {
    try {
      this.setProperty('pushNotificationBonusMinutes', payload);
    } catch (error) {
      throw error;
    }
  }

  removePushNotificationBonus: IUserViewmodel['removePushNotificationBonus'] = (): void => {
    try {
      this.setProperty('pushNotificationBonusMinutes', undefined);
    } catch (error) {
      throw error;
    }
  }

  subscribeToHashChange: IUserViewmodel['subscribeToHashChange'] = (
    payload: (value: IUserEntity['hash']) => void,
  ): void => {
    try {
      this.watchProperty('hash', payload);
    } catch (error) {
      throw error;
    }
  }

  subscribeToLockExpirationTimeChange: IUserViewmodel['subscribeToLockExpirationTimeChange'] = (
    payload: (value: IUserEntity['lockExpirationTime']) => void,
  ): void => {
    try {
      this.watchProperty('lockExpirationTime', payload);
    } catch (error) {
      throw error;
    }
  }

  subscribeToGenderChange: IUserViewmodel['subscribeToGenderChange'] = (
    payload: (value: IUserEntity['gender']) => void,
  ): void => {
    try {
      this.watchProperty('gender', payload);
    } catch (error) {
      throw error;
    }
  }

  updatePurchase: IUserViewmodel['updatePurchase'] = (payload: {
    isMalePremium: NonNullable<IUserEntity['isMalePremium']>,
    malePremiumExpirationTime: NonNullable<IUserEntity['malePremiumExpirationTime']>,
    maleNumberOfMessagesAvailableForSending: NonNullable<IUserEntity['maleNumberOfMessagesAvailableForSending']>,
    maleNumberOfMillisecondsRemaining: NonNullable<IUserEntity['maleNumberOfMillisecondsRemaining']>,
    isTrialMalePremiumPackageEnabled: NonNullable<IUserEntity['isTrialMalePremiumPackageEnabled']>,
    isMalePremiumExtensionEnabled: NonNullable<IUserEntity['isMalePremiumExtensionEnabled']>,
    hasMalePayments: NonNullable<IUserEntity['hasMalePayments']>,
  }): void => {
    try {
      this.store.commit(
        'updateUser',
        Object.freeze({
          ...this.store.state.entities.user,
          isMalePremium: payload.isMalePremium,
          malePremiumExpirationTime: payload.malePremiumExpirationTime,
          maleNumberOfMessagesAvailableForSending: payload.maleNumberOfMessagesAvailableForSending,
          maleNumberOfMillisecondsRemaining: payload.maleNumberOfMillisecondsRemaining,
          isTrialMalePremiumPackageEnabled: payload.isTrialMalePremiumPackageEnabled,
          isMalePremiumExtensionEnabled: payload.isMalePremiumExtensionEnabled,
          hasMalePayments: payload.hasMalePayments,
        }),
      );
    } catch (error) {
      throw error;
    }
  }

  removeMinutesAutoPurchase: IUserViewmodel['removeMinutesAutoPurchase'] = (): void => {
    try {
      const { user } = this.store.state.entities;

      this.store.commit(
        'updateUser',
        Object.freeze({
          ...user,
          maleMinutesPackages: {
            ...user.maleMinutesPackages,
            autoPurchase: null,
          },
          maleCards: {
            ...user.maleCards,
            minutesAutoPurchase: null,
          },
        }),
      );
    } catch (error) {
      throw error;
    }
  }

  setStoryLockExpirationTime: IUserViewmodel['setStoryLockExpirationTime'] = (
    payload: IUserEntity['storyLockExpirationTime'],
  ): void => {
    try {
      this.setProperty('storyLockExpirationTime', payload);
    } catch (error) {
      throw error;
    }
  }

  removeStoryLockExpirationTime: IUserViewmodel['removeStoryLockExpirationTime'] = (): void => {
    try {
      this.setProperty('storyLockExpirationTime', undefined);
    } catch (error) {
      throw error;
    }
  }

  completeEmailConfirmation: IUserViewmodel['completeEmailConfirmation'] = (): void => {
    try {
      this.store.commit(
        'updateUser',
        Object.freeze({
          ...this.store.state.entities.user,
          isNeedEmailConfirmation: false,
          isEmailConfirmationBonusAvailable: false,
        }),
      );
    } catch (error) {
      throw error;
    }
  }

  setNumberOfMillisecondsAndMessagesRemaining: IUserViewmodel['setNumberOfMillisecondsAndMessagesRemaining'] = (
    payload: {
      maleNumberOfMillisecondsRemaining: IUserEntity['maleNumberOfMillisecondsRemaining'],
      maleNumberOfMessagesAvailableForSending: IUserEntity['maleNumberOfMessagesAvailableForSending'],
    },
  ): void => {
    try {
      const { user } = this.store.state.entities;

      this.store.commit(
        'updateUser',
        Object.freeze({
          ...user,
          maleNumberOfMillisecondsRemaining: payload.maleNumberOfMillisecondsRemaining,
          maleNumberOfMessagesAvailableForSending: payload.maleNumberOfMessagesAvailableForSending,
        }),
      );
    } catch (error) {
      throw error;
    }
  }

  updatePremium: IUserViewmodel['updatePremium'] = (payload: {
    isMalePremium: NonNullable<IUserEntity['isMalePremium']>,
    malePremiumExpirationTime: NonNullable<IUserEntity['malePremiumExpirationTime']>,
    maleNumberOfMessagesAvailableForSending: NonNullable<IUserEntity['maleNumberOfMessagesAvailableForSending']>,
    maleNumberOfMillisecondsRemaining: NonNullable<IUserEntity['maleNumberOfMillisecondsRemaining']>,
    isMalePremiumExtensionEnabled: NonNullable<IUserEntity['isMalePremiumExtensionEnabled']>,
  }): void => {
    try {
      this.store.commit(
        'updateUser',
        Object.freeze({
          ...this.store.state.entities.user,
          isMalePremium: payload.isMalePremium,
          malePremiumExpirationTime: payload.malePremiumExpirationTime,
          maleNumberOfMessagesAvailableForSending: payload.maleNumberOfMessagesAvailableForSending,
          maleNumberOfMillisecondsRemaining: payload.maleNumberOfMillisecondsRemaining,
          isMalePremiumExtensionEnabled: payload.isMalePremiumExtensionEnabled,
        }),
      );
    } catch (error) {
      throw error;
    }
  }

  setRecordedStory: IUserViewmodel['setRecordedStory'] = (payload: IUserEntity['recordedStory']): void => {
    try {
      this.setProperty('recordedStory', payload);
    } catch (error) {
      throw error;
    }
  }

  removeRecordedStory: IUserViewmodel['removeRecordedStory'] = (): void => {
    try {
      this.setProperty('recordedStory', null);
    } catch (error) {
      throw error;
    }
  }

  setIsEmailConfirmationBonusAvailable: IUserViewmodel['setIsEmailConfirmationBonusAvailable'] = (
    payload: IUserEntity['isEmailConfirmationBonusAvailable'],
  ): void => {
    try {
      this.setProperty('isEmailConfirmationBonusAvailable', payload);
    } catch (error) {
      throw error;
    }
  }

  removeRecordedStoryAndSetFemalePoints: IUserViewmodel['removeRecordedStoryAndSetFemalePoints'] = (
    payload: {
      femalePoints: NonNullable<IUserEntity['femalePoints']>,
      storyEarnedPoints: NonNullable<IUserEntity['storyEarnedPoints']>,
    },
  ): void => {
    try {
      this.store.commit(
        'updateUser',
        Object.freeze({
          ...this.store.state.entities.user,
          recordedStory: null,
          femalePoints: payload.femalePoints,
          storyEarnedPoints: payload.storyEarnedPoints,
        }),
      );
    } catch (error) {
      throw error;
    }
  }

  removeStoryEarnedPoints: IUserViewmodel['removeStoryEarnedPoints'] = (): void => {
    try {
      this.setProperty('storyEarnedPoints', undefined);
    } catch (error) {
      throw error;
    }
  }

  setPushNotificationSettingsIsBonusMinutesEnabled: IUserViewmodel['setPushNotificationSettingsIsBonusMinutesEnabled'] = (
    payload: IUserEntity['pushNotificationSettings']['isBonusMinutesEnabled'],
  ): void => {
    try {
      this.setProperty('pushNotificationSettings', {
        ...this.store.state.entities.user.pushNotificationSettings,
        isBonusMinutesEnabled: payload,
      });
    } catch (error) {
      throw error;
    }
  }

  updatePremiumSubscriptionMinutes: IUserViewmodel['updatePremiumSubscriptionMinutes'] = (
    payload: {
      maleNumberOfMillisecondsRemaining: IUserEntity['maleNumberOfMillisecondsRemaining'],
      premiumSubscriptionBonusMinutesNextAdditionDate: IPremiumSubscriptionEntity['bonusMinutesNextAdditionDate'],
      premiumSubscriptionBonusMinutesToAdd: IPremiumSubscriptionEntity['bonusMinutesToAdd'],
      premiumSubscriptionBonusMillisecondsRemaining: IPremiumSubscriptionEntity['bonusMillisecondsRemaining'],
    },
  ): void => {
    try {
      const { user } = this.store.state.entities;

      this.store.commit(
        'updateUser',
        Object.freeze({
          ...user,
          maleNumberOfMillisecondsRemaining: payload.maleNumberOfMillisecondsRemaining,
          malePremiumSubscription: {
            ...user.malePremiumSubscription,
            bonusMinutesNextAdditionDate: payload.premiumSubscriptionBonusMinutesNextAdditionDate,
            bonusMinutesToAdd: payload.premiumSubscriptionBonusMinutesToAdd,
            bonusMillisecondsRemaining: payload.premiumSubscriptionBonusMillisecondsRemaining,
          },
        }),
      );
    } catch (error) {
      throw error;
    }
  }

  setNumberOfPremiumUnits: IUserViewmodel['setNumberOfPremiumUnits'] = (
    payload: {
      maleNumberOfMillisecondsRemaining: IUserEntity['maleNumberOfMillisecondsRemaining'],
      premiumSubscriptionBonusMillisecondsRemaining: IPremiumSubscriptionEntity['bonusMillisecondsRemaining'],
      maleNumberOfMessagesAvailableForSending: IUserEntity['maleNumberOfMessagesAvailableForSending'],
    },
  ): void => {
    try {
      this.store.commit(
        'updateUser',
        Object.freeze({
          ...this.store.state.entities.user,
          maleNumberOfMillisecondsRemaining: payload.maleNumberOfMillisecondsRemaining,
          maleNumberOfMessagesAvailableForSending: payload.maleNumberOfMessagesAvailableForSending,
          malePremiumSubscription: {
            ...this.store.state.entities.user.malePremiumSubscription,
            bonusMillisecondsRemaining: payload.premiumSubscriptionBonusMillisecondsRemaining,
          },
        }),
      );
    } catch (error) {
      throw error;
    }
  }

  setNumberOfTotalAndBonusMillisecondsRemaining: IUserViewmodel['setNumberOfTotalAndBonusMillisecondsRemaining'] = (
    payload: {
      maleNumberOfMillisecondsRemaining: IUserEntity['maleNumberOfMillisecondsRemaining'],
      premiumSubscriptionBonusMillisecondsRemaining: IPremiumSubscriptionEntity['bonusMillisecondsRemaining'],
    },
  ): void => {
    try {
      this.store.commit(
        'updateUser',
        Object.freeze({
          ...this.store.state.entities.user,
          maleNumberOfMillisecondsRemaining: payload.maleNumberOfMillisecondsRemaining,
          malePremiumSubscription: {
            ...this.store.state.entities.user.malePremiumSubscription,
            bonusMillisecondsRemaining: payload.premiumSubscriptionBonusMillisecondsRemaining,
          },
        }),
      );
    } catch (error) {
      throw error;
    }
  }

  disableEmailConfirmationReminder: IUserViewmodel['disableEmailConfirmationReminder'] = (): void => {
    try {
      this.setProperty('isEmailConfirmationReminderEnabled', false);
    } catch (error) {
      throw error;
    }
  }

  activatePremiumSubscriptionAndUpdateCard: IUserViewmodel['activatePremiumSubscriptionAndUpdateCard'] = (
    payload: ICardEntity,
  ): void => {
    try {
      const { user } = this.store.state.entities;

      this.store.commit(
        'updateUser',
        Object.freeze({
          ...user,
          malePremiumSubscription: {
            ...user.malePremiumSubscription,
            cardLastNumbers: payload,
            isActive: true,
          },
          maleCards: {
            ...user.maleCards,
            premiumSubscription: payload,
          },
        }),
      );
    } catch (error) {
      throw error;
    }
  }

  setPremiumSubscriptionData: IUserViewmodel['setPremiumSubscriptionData'] = (
    payload: {
      premiumSubscriptionCard: ICardEntity,
      malePremiumSubscription: IUserEntity['malePremiumSubscription'],
      pushNotificationSettings: {
        isBonusMinutesEnabled: IUserEntity['pushNotificationSettings']['isBonusMinutesEnabled'],
      },
    },
  ): void => {
    try {
      const { user } = this.store.state.entities;

      this.store.commit(
        'updateUser',
        Object.freeze({
          ...user,
          malePremiumSubscription: payload.malePremiumSubscription,
          maleCards: {
            ...user.maleCards,
            premiumSubscription: payload.premiumSubscriptionCard,
          },
          pushNotificationSettings: {
            ...user.pushNotificationSettings,
            isBonusMinutesEnabled: payload.pushNotificationSettings.isBonusMinutesEnabled,
          },
        }),
      );
    } catch (error) {
      throw error;
    }
  }
}

export default UserViewmodel;
