import {
  ELocale,
  EBanStatus,
  EContactsFiltration,
} from '@/enums';

const isProduction = process.env.NODE_ENV === 'production';

export default {
  isProduction,

  storage: {
    userGender: 'coomeet-user-gender',
    userHash: 'coomeet-user-hash',
    userLockExpirationTime: 'coomeet-user-lock-expiration-time',
    volume: 'coomeet-volume',
    microphone: 'coomeet-microphone',
    locale: 'coomeet-locale',
    pageLoadTimestamp: 'coomeet-page-load-timestamp',
    doubleEnter: 'coomeet-double-enter-check',
    reporter: 'coomeet-reporter',
    clickMap: 'coomeet-click-map',
  },

  cdn: 'https://n.coomeet.com',

  ajax: {
    // Внимание! При изменении адреса translation изменить его в src/locale-loader.js
    configuration: process.env.VUE_APP_CONFIGURATION_URL || (isProduction ? 'https://ap1.coomeet.com/server.get' : 'https://ap1.coomeet.com/server.get?env=test'),
    translation: 'https://ap1.coomeet.com/v20/i18n/web/%CODE%',
  },

  socialLoginUrl: 'https://ap1.coomeet.com/soc_%TYPE%.redirect?hash=%HASH%?webpush_code=%CODE%',
  referralLink: 'https://coomeet.me/id%ID%',
  supportLink: 'https://comewel.com/index.%LOCALE%.html',
  iosApplicationLink: 'https://apps.apple.com/us/app/coomeet/id1459611740',
  androidApplicationLink: 'https://play.google.com/store/apps/details?id=com.coomeet.app',
  chromeDownloadLink: 'https://www.google.com/chrome/',

  coomeetMainAppLink: 'https://coomeet.com',
  coomeetRulesLink: 'https://coomeet.com/%LOCALE%/chatrules',
  coomeetTermsLink: 'https://coomeet.com/%LOCALE%/agreement',
  coomeetPrivacyPolicyLink: 'https://coomeet.com/%LOCALE%/privacy-policy',
  coomeetCookiePolicyLink: 'https://coomeet.com/%LOCALE%/cookie',

  rusVideoDatingMainAppLink: 'https://rusvideodating.ru',
  rusVideoDatingRulesLink: 'https://rusvideodating.ru/chatrules',
  rusVideoDatingTermsLink: 'https://rusvideodating.ru/agreement',
  rusVideoDatingPrivacyPolicyLink: 'https://rusvideodating.ru/privacy-policy',
  rusVideoDatingCookiePolicyLink: 'https://rusvideodating.ru/cookie',

  isPayPalForceEnabled: !!process.env.VUE_APP_PAYPAL_FORCE_ENABLED,
  payPalAllowedUrls: ['https://coomeet.com', 'https://new.coomeet.com', 'https://www-ng.coomeet.com'],

  isReferralForceEnabled: !!process.env.VUE_APP_REFERRAL_FORCE_ENABLED,
  referralAllowedUrls: [
    'https://coomeet.me',
    'https://new.coomeet.com',
    'https://www-ng.coomeet.com',
    'https://coomeet.com',
  ],

  isPartnerIdForceEnabled: !!process.env.VUE_APP_PARTNER_ID_FORCE_ENABLED,
  partnerIdAllowedUrls: [
    'https://coomeet.me',
    'https://new.coomeet.com',
    'https://www-ng.coomeet.com',
  ],

  isPartnerChannelForceEnabled: !!process.env.VUE_APP_PARTNER_CHANNEL_FORCE_ENABLED,
  partnerChannelAllowedUrls: [
    'https://coomeet.me',
    'https://new.coomeet.com',
    'https://www-ng.coomeet.com',
  ],

  isLocalePathnameForceEnabled: !!process.env.VUE_APP_LOCALE_PATHNAME_FORCE_ENABLED,
  localePathnameAllowedUrls: ['https://coomeet.com', 'https://coomeet.me', 'https://new.coomeet.com', 'https://www-ng.coomeet.com', 'https://www-next.coomeet.com/'],

  oneTapAllowedUrls: ['https://coomeet.com', 'https://coomeet.local'],

  forceReloadAllowedUrls: ['https://coomeet.com', 'https://www-ng.coomeet.com', 'https://www-next.coomeet.com/'],

  isFingerprintEnabled: !!process.env.VUE_APP_FINGERPRINT_ENABLED,

  supportEmail: 'support@coomeet.com',

  /**
   * С какого места начинается топ пользователей.
   * Используется для отображения тултипа в шапке.
   * Вынесено в конфиг, т.к получить это значение с сервера мы не можем,
   * если не находимся непосредственно на странице топа
   */
  topStartingPlace: 8,

  femaleStatisticsDaysForDisplay: 7, // Количество прошедших дней, за которые выводится статистика Ж

  withdrawalMinimumPoints: 5000, // Минимальное кол-во баллов для вывода денег

  // Внимание! При изменении дефолтной локали изменить ее в src/locale-loader.js
  defaultLocale: ELocale.EN,

  referralAttentionMaxPagesAmount: 15,
  referralsPerPage: 4,
  referralsStartPage: 1,

  app: {
    build: process.env.VUE_APP_BUILD || 'w/n', // Идентификатор сборки ифрейма
    timeToLive: process.env.VUE_APP_TTL || 12 * 60 * 60 * 1000, // Время жизни приложения
    ts: process.env.VUE_APP_COMMIT_TIMESTAMP, // Время сборки ифрейма
  },

  userRatingLowLine: 3,
  userRatingHighLine: 4,

  maxContactsNumberPerDownload: 30,
  maxMessagesNumberPerDownload: 20,

  supportCommonContactId: 0,

  /**
   * Реализация элементов списка контактов построена на сущностях контактов. Даже для фейкового UI контакта поддержки
   * создается сущность фейкового контакта с идентификатором 0. Для отображения призыва регистрации в списке контактов
   * у М гостя так же пришлось завести фейковый контакт (на данный момент так было быстрее и проще,
   * не пришлось бы переделывать много зависимого кода). В качестве идентификатора фейкового контакта регистрации
   * взяли значение 555, которого нет в данный момент у реальных юзеров (уточнял у Андрея, т.к. есть
   * тестовый юзер идентификатором 1 и какие-то еще, что могли бы вызвать коллизии)
   */
  registrationContactId: 555,

  lockScreensGiftsNumber: 3,

  strictBans: [
    EBanStatus.AGE,
    EBanStatus.RULES,
    EBanStatus.LIFETIME,
    EBanStatus.CHILD,
    EBanStatus.TEN_DAYS,
  ],

  pingUrl: '/ping.gif',

  /**
   * TODO: избавиться от необходимости использования брейкпойнтов в js
   * При изменении mobileMaxWidth нужно поменять значение в variables.less
   */
  styleVariables: {
    mobileMaxWidth: '699px',
    smallMobileMaxHeight: '552px',
  },

  tracker: {
    isEnabled: !!process.env.VUE_APP_TRACKER_ENABLED || false,
    token: '8130ffae378d4097868dc23385b969c8',
    application: process.env.VUE_APP_TRACKER_APPLICATION || (isProduction ? 'production' : 'development'),
    forwardingDomain: 'trck.coomeet.com',
  },

  logger: {
    isEnabled: !!process.env.VUE_APP_LOGGER_ENABLED || false,
  },

  reporter: {
    isEnabled: !!process.env.VUE_APP_REPORTER_ENABLED || false,
    url: 'https://ap1.coomeet.com/v20/service/frontlog.save',
    pingTimeout: 5 * 1000,
  },

  isUnsecuredConnectionEnabled: !!process.env.VUE_APP_UNSECURED_CONNECTION_ENABLED || !isProduction,

  contactsFiltration: EContactsFiltration.RECENT,

  maxVideochatMessagesNumber: 20,

  features: {
    isPingAttentionEnabled: !!process.env.VUE_APP_PING_ATTENTION_ENABLED || false,
    isStreamTuningEnabled: !!process.env.VUE_APP_STREAM_TUNING_ENABLED || false,
    isWithdrawalToBankAccountEnabled: !!process.env.VUE_APP_WITHDRAWAL_TO_BANK_ACCOUNT_ENABLED || false,
    isNewVideochatStartEnabled: !!process.env.VUE_APP_NEW_VIDEOCHAT_START_ENABLED || false,
    isSupportInfoMessagesEnabled: !!process.env.VUE_APP_SUPPORT_INFO_MESSAGES_ENABLED || false,
    isSearchWithProxyBlockingEnabled: !!process.env.VUE_APP_SEARCH_WITH_PROXY_BLOCKING_ENABLED || false,
    isStoryUrlCopyEnabled: !!process.env.VUE_APP_STORY_URL_COPY_ENABLED || false,
    isEmailConfirmationForFreebieEnabled: !!process.env.VUE_APP_EMAIL_CONFIRMATION_FOR_FREEBIE_ENABLED || false,
    isNewVersionButtonEnabled: !!process.env.VUE_APP_NEW_VERSION_BUTTON_ENABLED || false,
  },

  clickMapUrl: 'https://ap1.coomeet.com/stats/click',
  clickMapDisabled: true, // искусственно отключен из-за ДДОСа

  // Особая группа М, чей баланс минут не нужно показывать Ж в видеочате
  companionsWithHiddenMaleRemainingMinutesIds: [
    14672485078401,
    14243454797657,
    15803892625007,
    16029669693805,
    15901711477892,
    16597222751525,
  ],

  googleOauthClientId: '885818793346-ao5hjkrj7vvhrnu50s6shtq805eptp07.apps.googleusercontent.com',

  cardVendorRegexes: {
    visa: /^4[0-9]{0,18}$/,
    masterCard: /^(5$|^5[1-5][0-9]{0,18}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{0,18}$/,
    americanExpress: /^3$|^3[47][0-9]{0,16}$/,
    dinersClub: /^(30[0-5][0-9]{0,15}|3095[0-9]{0,15}|36[0-9]{0,15}|3[8-9][0-9]{0,15})$|^(36[0-9]{0,15})$/,
    discover: /^(60110[5-9][0-9]{0,15}|60110[0-3][0-9]{0,15}|6011[2-4][0-9]{0,15}|601174[0-9]{0,15}|60117[7-9][0-9]{0,15}|60118[6-9][0-9]{0,15}|60119[0-9]{0,15}|64[4-9][0-9]{0,15}|650[0-5][0-9]{0,15}|65060[1-9][0-9]{0,15}|65061[1-9][0-9]{0,15}|65[0-9]{0,15})$/,
    jcb: /^(308[89][0-9]{0,15}|309[0-4][0-9]{0,15}|309[6-9][0-9]{0,15}|310[0-2][0-9]{0,15}|311[2-9][0-9]{0,15}|3120[0-9]{0,15}|315[89][0-9]{0,15}|333[7-9][0-9]{0,15}|334[0-9]{0,15}|35[2-8][89][0-9]{0,15}|35[3-8][0-9]{0,15})$/,
    maestro: /^(5018[0-9]{0,19}|5020[0-9]{0,19}|5038[0-9]{0,19}|5[6-8][0-9]{0,19}|6304[0-9]{0,19}|67[0-9]{0,19}|0604[0-9]{0,19}|6390[0-9]{0,19})$/,
    mir: /^220[0-4][0-9]{0,18}$/,
  },

  serviceWorkerUrl: '/service-worker.js',

  cardConfirmationMinSum: 0.5,
  cardConfirmationMaxSum: 1.5,

  /**
   * MIME-типы видео в сообщениях, в которые конвертирует бэк
   * Для mp4 используется кодек AVC с версией High Level 5.1, что соответствует "avc1.640033"
   * https://cconcolato.github.io/media-mime-support/
   */
  mp4PlaybackMimeType: 'video/mp4; codecs="avc1.640033"',
  webmPlaybackMimeType: 'video/webm; codecs="vp8, vorbis"',
  webmRecordMimeType: 'video/webm;codecs=vp8,opus',

  fingerprint: {
    token: 'xWueI8UWMg0D77jQaFRv',
    storageKey: 'coomeet-fpi',
    endpoint: 'https://fp.coomeet.com',
    region: 'eu',
    timeout: 20000,
  },
};
